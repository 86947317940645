import React from 'react';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { HeyHoService } from '../../../services/Heyho/heyHoService';

interface RenderImageProps {
    attachment: any;
    selectedRow: any;
    setData: (data: string) => void;
    setOpenAttachment(openAttachment: boolean): void;
    setAttachment(attachment: any): void;
    setSelectedRow(ropw: any): void
}

export const RenderImage: React.FC<RenderImageProps> = ({
    attachment,
    selectedRow,
    setData,
    setOpenAttachment,
    setAttachment,
    setSelectedRow
}) => {

    const downloadAttachment = async (attachment) => {
        const ServiceHeyho = new HeyHoService({})
        await ServiceHeyho.downloadAttachmentById(attachment)
            .finally(() => {
            })
    }

    const handleData = (attachment: any) =>{
        setData(attachment.image);
        setAttachment(attachment);
        setOpenAttachment(true);
    }

    return (
        <div className={"divOrderAttachments"} onMouseEnter={() => setSelectedRow(attachment)} onMouseLeave={() => setSelectedRow([])}>
        <div className={"imgCropContainer"}>
                {selectedRow === attachment && (
                    <div className={"divIcons"} style={{ position: "absolute", display: 'flex' }}>
                        <div style={{ marginRight: "20px" }}>
                            <LcIconLink
                                icon={"ppi ppi-download"}
                                className={"myHoverIconLink"}
                                color={"#898DA6"}
                                tooltip={"Download"}                                        
                                size={"medium"}
                                onClick={() => downloadAttachment(attachment)}
                            />
                        </div>
                        <LcIconLink
                            icon={"ppi ppi-document-search"}
                            className={"myHoverIconLink"}
                            color={"#898DA6"}
                            tooltip={"Visualizar"}
                            size={"medium"}
                            onClick={() => handleData(attachment)}
                        />
                    </div>
                )}                        
            <img
                className={"imgAttachment"}
                src={attachment.image}
                alt=""
                style={{
                    maxWidth: "100%",
                }}
                onClick={() => handleData(attachment)}
            />                      
        </div>
    </div>
    );
}
