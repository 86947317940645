import axios from 'axios';
import api, { APIClient } from '../../api';
import { Base64File, FileSearch, VectorStoreFileListResponse } from '../assistantsTypes';
 export default class FileSearchService extends APIClient {
    baseUrl = process.env.REACT_APP_AIX_LLM_API;
    constructor(props: any) {
        super(props);
    }

    public async createVectorStore(fileSearch: FileSearch): Promise<any> {
        var url = `${this.endpoint}/api/file_search/vector_stores?refresh=${Math.random()}`;
        const response = await api.post(url, fileSearch);
        console.log(response.data)
        return response.data;
    }

     public async uploadBase64File(assistant_id: string, base64File: Base64File): Promise<any> {
         var url = `${this.baseUrl}/api/file_search/assistants/${assistant_id}/uploadfiles`;
         console.log('uploadBase64File')
         console.log(url);

         const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
         const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');

         if (!LiveCloud_AuthMS || !LiveCloud_AuthLC) {
             throw new Error('Authorization tokens are missing');
         }

         const apiAIX = axios.create({
             headers: {
                 Authorization: `Bearer ${LiveCloud_AuthMS}`,
                 AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                 Platform: '1'
             }
         });

         try {
             const response = await apiAIX.post(url, base64File);
             console.log(response.data);
             return response.data;
         } catch (error) {
             console.error('Error uploading file:', error);
             throw error; // Rethrow error for handling by caller
         }
     } 

    //public async uploadBase64File(assistant_id: string, base64File: Base64File): Promise<any> {
    //    var url = `${this.baseUrl}/assistants/${assistant_id}/files?refresh=${Math.random()}`;
    //    console.log(url)
    //    const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
    //    const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
    //    let apiAIX = axios.create({
    //        headers: {
    //            Authorization: `Bearer ${LiveCloud_AuthMS}`,
    //            AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
    //            Platform: `1`,
    //            //     PathRoute: window.location.pathname,
    //            'Access-Control-Allow-Origin': '*'
    //        }
    //    });
    //    //var url = `${this.endpoint}/api/assistants?refresh=${Math.random()}`;
    //    console.log(url);
    //    var response = await apiAIX.post(
    //        url,
    //        {
    //            headers: {
    //                Authorization: `Bearer ${LiveCloud_AuthMS}`,
    //                AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
    //                Platform: `1`,
    //                //     PathRoute: window.location.pathname,
    //                'Access-Control-Allow-Origin': '*'
    //            }, data: base64File
    //        }
    //    );
    //    //  const response = await api.post(url, base64File);
    //    console.log(response.data)
    //    return response.data;
    //}

    public async deleteBase64File(vector_store_id: string, file_id: string): Promise<any> {
        var url = `${this.endpoint}/api/file_search/vector_stores/${vector_store_id}/files/${file_id}?refresh=${Math.random()}`;
        console.log(url)
        const response = await api.delete(url);
        console.log(response.data)
        return response.data;
    }

     public async getFiles(assistant_id: string): Promise<any> {
        try {
            //assistants
            var url = `${this.baseUrl}/api/file_search/assistants/${assistant_id}/list_files`
            console.log(url)
            const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
            const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
            let apiAIX = axios.create({
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    //    PathRoute: window.location.pathname,
                    //    'Access-Control-Allow-Origin': '*'
                }
            });

            //var url = `${this.endpoint}/api/assistants?refresh=${Math.random()}`;
            console.log(url);
            var responseNew = await apiAIX.get(
                url,
                {
                    headers: {
                        Authorization: `Bearer ${LiveCloud_AuthMS}`,
                        AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                        Platform: `1`,
                        //     PathRoute: window.location.pathname,
                       //   'Access-Control-Allow-Origin': '*'
                    }
                }
            );


            return responseNew.data; 
        } catch (e) {
            // debugger;
            return [];
        }

    }
}
