import React, { ReactNode, useEffect, useMemo, useRef } from "react";
import { useHistory } from 'react-router-dom'

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { MenuState } from '../../store/reducers/menuReducer';
import { MessagesState } from "../../store/reducers/messagesReducer";
import { RootState } from '../../store/index';

//para o Vimeo
import "./Layout.css";

//Logo
import logo from '../../assets/images/logo.svg';

//Plataforma
import { PPLocalStorage, PPModal, PPDropMenu, PPTooltip } from 'processor-plataform-ui'
import "../../assets/lib/lci/style.css"; //Icons
import { UserState } from "../../store/reducers/userReducer";
import { useState } from "react";
import UserMenu from "./UserMenu";
import { CacheManagerService } from "../../services/cacheManagerService";
import moment from "moment";
import LcIconLink from "../Generic/LcIconLink";
import LcTooltip from "../Generic/LcTooltip";
import LcDropDown from "../Generic/LcDropDown";
import Confirmation from "../Dialog/Confirmation";
import { InputField } from "../Form/Input";
import Helper from "./Helper";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { PortalUsersService } from "../../services/portalUsers/portalUsers";
import { NotificationCenterService } from "../../services/notificationCenter/notificationService";
import ToolBarRight from "../Layout/ToolBarRight";
import News from "../Layout/News";
import { PivotProvider } from "../../contexts/PivotContext";
import { LinearProgress } from "@material-ui/core";
import { BsCloudCheck } from "react-icons/bs";
import Marketing from "../Marketing";
import { PiBellBold, PiBellLight, PiCaretLeftLight, PiCaretRightLight, PiCloud, PiCloudCheck, PiCloudCheckLight, PiCurrencyCircleDollar, PiCurrencyCircleDollarBold, PiCurrencyCircleDollarLight, PiHeadsetBold, PiHeadsetLight, PiHouse, PiHouseBold, PiHouseLight, PiList, PiListBold, PiListLight } from "react-icons/pi";
import NotificationsComponent from "./NotificationsComponent";
import LcLoading from "../Generic/LcLoading";
import NPS from "../../pages/SlaSlo/NPS";


export interface Period {
    id?: string,
    label: string,
    text: string,
    fields: {
        name: string,
        label?: string,
        value: string
    }[],
    default?: boolean
}

interface LayoutProps {

    id?:string,
    //MenuTop
    pageTitle?: string,
    lastUpdate?: string | Date
    runUpdate?: Function,
    loading?: boolean,
    className?: string,
    changePeriod?: boolean
    periodSelection?: {
        periods: Period[],
        customPeriod?: Period,
        customPeriodType: string,
        onChange: Function
    },
    functionsGeneric?: {
        icon: string | React.ReactNode,
        color?: string
        onClick: Function,
        tooltip?: string | Function,
        badge?: { number: number, status: string },
        disabled?: boolean,
        hidden?: boolean
        clickble?: boolean
    }[],
    gridFilter?: {
        toggleVisibility: Function,
        size?: number
        color?: string
        clickble?: boolean
    },
    row?: ReactNode,
    dots?: ReactNode,
    pivot?: boolean;
    aixAssistant?: boolean;
    colorIcon?: string,
    hidePivotHeader?: boolean;
    cardType?: boolean;
    steps?: Step[];
    handleJoyrideCallback?: (data: CallBackProps) => void;
    stepIndex?: number
}

const Layout: React.FC<LayoutProps> = (props) => {


    //useEffect(() => {
    //    let qs = document.querySelector(":root") as any;
    //    qs.style.setProperty("--primary", "#273B85");
    //    qs.style.setProperty("--primary-hover", "#4C68CE");
    //    qs.style.setProperty("--dark", "#8197EA");
    //    qs.style.setProperty("--light", "#2E1956");
    //    qs.style.setProperty("--emphasis", "#F29100");
    //}, [])

    useEffect(() => {
        let qs = document.querySelector(":root") as any;
        qs.style.setProperty("--primary", "#173192");
        qs.style.setProperty("--primary-hover", "#2E1956");
        // qs.style.setProperty("--text-light", "#8B90A3");
        // qs.style.setProperty("--text-default", "#2E1956");
        qs.style.setProperty("--bg-light-1", "#E2E6F3");
        qs.style.setProperty("--line-dark", "#9196A7");
        qs.style.setProperty("--dark", "#8197EA");
        qs.style.setProperty("--light", "#2E1956");
        qs.style.setProperty("--emphasis", "#F29100");
    }, [])

    //useEffect(() => {
    //    const root = document.querySelector(":root") as HTMLElement;
    //    root.style.setProperty("--color-primary-blue", "#471FCC");
    //    root.style.setProperty("--color-primary-purple-dark", "#2E1956");
    //    root.style.setProperty("--color-primary-purple", "#8C40E3");
    //    root.style.setProperty("--color-primary-magenta", "#AB19E7");
    //    root.style.setProperty("--color-primary-purple-light", "#966CF3");
    //    root.style.setProperty("--color-primary-pink", "#DA43F2");
    //    root.style.setProperty("--color-secondary-purple-bright", "#9FA3FF");
    //    root.style.setProperty("--color-secondary-cyan", "#00DBFF");
    //    root.style.setProperty("--color-secondary-lime", "#C9FF46");
    //    root.style.setProperty("--color-secondary-black", "#000000");
    //    root.style.setProperty("--color-secondary-mint", "#A6FFE8");
    //    root.style.setProperty("--color-secondary-green-dark", "#255A4B");
    //    root.style.setProperty("--color-secondary-green-light", "#34C38F");
    //    root.style.setProperty("--background-lightest", "#FAFBFF");
    //    root.style.setProperty("--background-light", "#E2E6F3");
    //    root.style.setProperty("--background-neutral", "#EBEBEB");
    //    root.style.setProperty("--background-white", "#FFFFFF");
    //    root.style.setProperty("--background-purple-dark", "#2E1956");
    //    root.style.setProperty("--text-default", "#2E1956");
    //    root.style.setProperty("--text-dark", "#6B6F7D");
    //    root.style.setProperty("--text-light", "#8B90A3");
    //    root.style.setProperty("--text-link", "#00DBFF");
    //    root.style.setProperty("--text-link-hover", "#471FCC");
    //    root.style.setProperty("--text-white", "#FFFFFF");
    //    root.style.setProperty("--icon-default", "#2E1956");
    //    root.style.setProperty("--icon-blue", "#471FCC");
    //    root.style.setProperty("--icon-not-selected", "#898DA6");
    //    root.style.setProperty("--icon-disabled", "#EBEBEB");
    //    root.style.setProperty("--icon-white", "#FFFFFF");
    //    root.style.setProperty("--status-green", "#3EBB3F");
    //    root.style.setProperty("--status-yellow", "#F6DE00");
    //    root.style.setProperty("--status-red", "#DC291E");
    //    root.style.setProperty("--line-default", "#E2E6F3");
    //    root.style.setProperty("--line-dark", "#9196A7");
    //    root.style.setProperty("--line-light", "#EBEBEB");
    //    root.style.setProperty("--line-outline", "#CCCCCF");
    //    root.style.setProperty("--menu-item-active", "#2E1956");
    //    root.style.setProperty("--menu-item-hover", "#E2E6F3");
    //    root.style.setProperty("--button-primary-default", "#9FA3FF");
    //    root.style.setProperty("--button-primary-hover", "#9FA3FF");
    //    root.style.setProperty("--button-secondary-default", "#E2E6F3");
    //    root.style.setProperty("--button-secondary-hover", "#E2E6F3");
    //    root.style.setProperty("--button-disabled", "#8B90A3");
    //}, []);

    const history = useHistory();
    const portalUserService = new PortalUsersService(props);

    const notification = new NotificationCenterService(props);

    //Redux
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const user = useSelector<RootState, UserState>(state => state.user);
    const msgs = useSelector<RootState, MessagesState>(state => state.msgs);

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);

    const [showMeuLCMenu, setShowMeuLCMenu] = useState(false);
    const [toolBarItems, setToolBarItems] = useState<{ label: string, onClick: Function, active: boolean }[]>([]);


    //2022-08-04 -> Recarda automática do item selecionado
    useEffect(() => {

        let foundItem = false;

        menu.items.forEach(itemA => {
            itemA.children.forEach(itemB => {

                if ("/" + itemB.route === (document.location.pathname + document.location.search)) {

                    foundItem = true;

                    menu.selectedItem !== itemB &&
                        dispatch({ type: 'MENU_SELECTED_UPDATE', payload: itemB });
                    dispatch({ type: 'MENU_SUBSELECTED_UPDATE', payload: null });

                    setToolBarItems([{
                        label: (itemB.name || props.pageTitle) as string,
                        onClick: () => { },
                        active: true
                    }])

                }

                !foundItem &&
                    itemB.children.forEach(itemC => {
                        if ("/" + itemC.route === (document.location.pathname + document.location.search)) {

                            foundItem = true;

                            menu.selectedItem !== itemB &&
                                dispatch({ type: 'MENU_SELECTED_UPDATE', payload: itemB });

                            menu.selectedSubItem !== itemC &&
                                dispatch({ type: 'MENU_SUBSELECTED_UPDATE', payload: itemC });

                            if (toolBarItems.map(i => { return i.label }) !== itemB.children.map(i => { return i.name })) {

                                itemB.children.forEach(menu => {
                                    if (menu.route === "EndpointDetectionResponse/ResumeEvent") {
                                        menu.blocked = true;
                                        dispatch({ type: 'MENU_SUBSELECTED_UPDATE', payload: menu });
                                    }
                                })
                                setToolBarItems(itemB.children
                                    .sort((a, b) => a.menuOrder > b.menuOrder ? 1 : -1)
                                    .map(c => {
                                        return {
                                            label: c.name as string,
                                            onClick: () => {
                                                history.push("/" + c.route)
                                                dispatch({ type: 'MENU_SUBSELECTED_UPDATE', payload: c });
                                            },
                                            active: c.id === itemC.id
                                        }
                                    }))
                            }
                        }


                    })
            })
        });

        if (!foundItem) {
            dispatch({ type: 'MENU_SELECTED_UPDATE', payload: null });
            dispatch({ type: 'MENU_SUBSELECTED_UPDATE', payload: null });

            setToolBarItems([{
                label: props.pageTitle || "Sem nome",
                onClick: () => { },
                active: true
            }])
        }

    }, [dispatch, history, menu.items, menu.selectedItem, menu.selectedSubItem, props.pageTitle])

    //UserPreferences - carregamento
    useEffect(() => {
        PPLocalStorage.init("userPreferences");
        let _lh = PPLocalStorage.load() || {};
        dispatch({ type: 'PREF_LOAD', payload: _lh[document.location.pathname] });
    }, [dispatch])

    const cacheManagerServiceInstance = new CacheManagerService(props);

    useEffect(() => {
        user.ClientGroupSelected && user.UserId &&
            dispatch({ type: 'MENU_UPDATE', payload: user.Menus });
    }, [dispatch, user.ClientGroupSelected, user.Menus, user.UserId])

    //TopBarItems

    //#region Tutorials
    const [modalTutorialsVisible, setModalTutorialsVisible] = useState(false);
    const tutorials = [
        {
            name: 'Abertura de Chamado',
            url: 'https://vimeo.com/571387703/34b7fe408c',
        },
        {
            name: 'Tech CSP',
            url: 'https://vimeo.com/571788301/242f998030',
        },
        {
            name: 'Novos Clientes SAAS/CSP',
            url: 'https://vimeo.com/572733557/0c23b8aac1',
        },
        {
            name: 'Novos Clientes EA/EAS',
            url: 'https://vimeo.com/572734187/41acc34494',
        },
        {
            name: 'Autenticação/Login',
            url: 'https://vimeo.com/570004625/9f8fb13cba',
        }
    ]
    //#endregion

    //#region UpdateData
    const [waitingUpdating, setWaitingUpdate] = useState(false);
    const [open, setOpen] = useState(false);
    const handleConfirmClose = () => {

        let msg = {
            subject: "Atualização",
            content: "Atualização dos dados em andamento.",
            context: "warning"
        };
        //   notification.PostInterfaceNotification(dispatch, msg);

        setWaitingUpdate(true);
        cacheManagerServiceInstance.Refresh()
            .then((response: any) => {
                let msg = {
                    subject: "Atualização",
                    content: "A atualização dos dados da tela foi concluída com sucesso.",
                    context: "success"
                };
                //      notification.PostInterfaceNotification(dispatch, msg);
            })
            .catch((error) => {
                console.warn(error)
                let msg = {
                    subject: "Atualização",
                    content: "Erro na atualização dos dados da tela.",
                    context: "danger"
                };
                //  notification.PostInterfaceNotification(dispatch, msg);
            })
            .then(() => {
                dispatch({ type: 'USER_UPDATE_REFRESH', payload: Math.random() });
                setWaitingUpdate(false);
            });

        setOpen(false);
    }
    //#endregion

    //#region lastUpdate
    const [lastUpdate, setLastUpdate] = useState<Date>(new Date());
    
    useEffect(() => {
        const GetLastUpdateTimeCache = () => {
            if (props.lastUpdate && props.lastUpdate !== '') {
                setLastUpdate(new Date(props.lastUpdate));
                return;
            }
            const cacheManagerServiceInstance = new CacheManagerService({});
    
            cacheManagerServiceInstance.LastUpdate().then(response => {
                setLastUpdate(new Date(response.data))
            }).catch(console.warn);
        }
        GetLastUpdateTimeCache();
    }, [user.refreshFlag, props.lastUpdate]);
    //#endregion

    //#region SelectPeriod
    const [showModalCustomPeriod, setShowModalCustomPeriod] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState<Period>();
    const [customPeriod, setCustomPeriod] = useState<Period | undefined>(props.periodSelection?.customPeriod);
    const [selectionDate, setSelectionDate] = useState<Date>(new Date());
    useEffect(() => {
        props.periodSelection &&
            setSelectedPeriod(props.periodSelection.periods.find(ps => ps.default));
    }, [user.refreshFlag, props.changePeriod]);

    const onChangePeriod = (period: Period) => {
        setSelectedPeriod(period);
        props.periodSelection?.onChange(period);
    }

    const onChangePeriodCustom = (custom: any) => {
        let { name, value } = custom;
        let _customPeriod = customPeriod as any;
        _customPeriod.fields.find((f: any) => f.name === name).value = value;
        switch (_customPeriod.fields[0].name) {
            case 'day':
                {
                    let newDate = moment(_customPeriod.fields[0].value + 'T00:00:00');
                    _customPeriod.text = `[${newDate.format('L')}]`;
                }
                break;
            case 'month':
                {
                    let newDate = moment(_customPeriod.fields[0].value + '-01T00:00:00');
                    _customPeriod.text = `[${newDate.format("MMM [de] YYYY")}]`;
                }
                break;
            case 'currentMonth':
                {
                    let newDate = moment(_customPeriod.fields[0].value + '-01T00:00:00');
                    _customPeriod.text = `[${newDate.format("MMM [de] YYYY")}]`;
                }
                break;
            case 'year':
                {
                    let newDate = moment(_customPeriod.fields[0].value + '-01-01T00:00:00');
                    _customPeriod.text = `[${newDate.format("YYYY")}]`;
                }
                break;
        }

        setCustomPeriod(_customPeriod);
    }
    const submitCustomPeriod = () => {
        setShowModalCustomPeriod(false);
        setSelectedPeriod(customPeriod);
        props.periodSelection?.onChange(customPeriod);
    }
    //#endregion

    const checkExpiredDate = (date) => {
        var _d = moment.utc(date)
        var n = new Date()
        var now = moment.utc(n)
        return now > _d;
    }

    const getExtraData = (extraData: string, data: string) => {
        if (extraData) {
            let ed = JSON.parse(extraData);
            if (ed[data]) {
                return <div className="group">{ed[data]}</div>
            } return null;
        } else
            return null;
    }

    //#region Notifications
    const [badgeNotification, setBadgeNotification] = useState<{ number: number, status: string }>({ number: 0, status: '' })
    const [modalVisibleNotification, setModalVisibleNotification] = useState(false);
    useEffect(() => {
        // console.log(msgs)
        let _msgs = [...msgs.messages];

        if (_msgs.length > 0) {
            let _m = _msgs.filter(x => !x.isRead);
            setBadgeNotification({ number: _m.length, status: 'warning' });
        } else {
            setBadgeNotification({ number: 0, status: '' });
        }

    }, [msgs])
    //#endregion


    //#region Troca do Logo e LastUpdate
    const [infoArea, setInfoArea] = useState("0");
    const infoAreaRef = useRef(infoArea); 
    const timerInfoRef = useRef<any>(null);

    const updateInfoArea = (newInfoArea) => {
        infoAreaRef.current = newInfoArea; // Atualiza o ref
        setInfoArea(newInfoArea); // Atualiza o estado
    };
    const lastUpdateContent  = useMemo(() => (
        <div className="lastupdate">
            <span>Atualizado {moment().format("DD/MM/YYYY HH:mm")}</span>
            <span>Dados de {moment(lastUpdate).format("DD/MM/YYYY HH:mm")}</span>
        </div>
    ), [lastUpdate]); 

    const notificationContent = useMemo(() => {
        if (msgs.alerts.length > 0) {
            const lastMsg = msgs.alerts.slice(-1)[0];
            const now = Date.now();
            const msgTime = new Date(lastMsg.notificationDateTime).getTime();
            const timeDifference = (now - msgTime) / 1000;

            if (timeDifference <= 10) {
                return (
                    <div className="notification">
                        <span>
                            <i className={`ppi ppi-bell mr-3 text-${lastMsg.context}`} />
                            {lastMsg.content.length >= 110 ? lastMsg.content.substring(0, 110) + "..." : lastMsg.content}
                        </span>
                    </div>
                );
            }
        }
        return null;
    }, [msgs.alerts]);

    useEffect(() => {
        if (timerInfoRef.current) {
            clearTimeout(timerInfoRef.current);
        }

        if (infoAreaRef.current === "0") {
            if (lastUpdateContent) {
                dispatch({
                    type: 'MENU_ALERTAREA',
                    payload: lastUpdateContent
                });
            }
            timerInfoRef.current = setTimeout(() => {
                    if (infoAreaRef.current === "0") {
                        updateInfoArea("1");
                    }
                }, 10000);
        } else if (infoAreaRef.current === "1" && msgs.alerts.length > 0) {
            const lastMsg = msgs.alerts.slice(-1)[0];
            const now = Date.now();
            const msgTime = new Date(lastMsg.notificationDateTime).getTime();
            const timeDifference = (now - msgTime) / 1000;

            if (timeDifference <= 10  && notificationContent) {
                dispatch({
                    type: 'MENU_ALERTAREA',
                    payload: notificationContent
                });;

                timerInfoRef.current = setTimeout(() => {
                    if (infoAreaRef.current === "1") {
                        updateInfoArea("0");
                    }
                }, 10000);
            } else {
                updateInfoArea("0");
            }
        } else {
            updateInfoArea("0");
        }

        return () => clearTimeout(timerInfoRef.current);

    },[dispatch, lastUpdate, lastUpdateContent, msgs.alerts, notificationContent]);;


    // useEffect(() => {
    //     console.log('timerinfo')
    //     let timerInfo;

    //     switch (infoArea) {
    //         case "0":
    //             dispatch({
    //                 type: 'MENU_ALERTAREA',
    //                 payload: (
    //                     <div className="lastupdate">
    //                         <span>Atualizado {moment().format("DD/MM/YYYY HH:mm")}</span>
    //                         <span>Dados de {moment(lastUpdate).format("DD/MM/YYYY HH:mm")}</span>
    //                     </div>
    //                 )
    //             });
    //             timerInfo = setTimeout(() => {
    //                 if (infoArea === "0") {
    //                     setInfoArea("1");
    //                 }
    //             }, 10000)
    //             break;
    //         case "1":
    //             if (msgs.alerts.length > 0) {

    //                 let _lastMsg = msgs.alerts.slice(-1)[0];

    //                 let now = new Date();
    //                 let msgTime = new Date(_lastMsg.notificationDateTime.toString());
    //                 let dif = now.getTime() - msgTime.getTime();

    //                 if (dif / 1000 <= 10) { //Menos de 10 segundos
    //                     dispatch({
    //                         type: 'MENU_ALERTAREA',
    //                         payload: (
    //                             <div className="notification">
    //                                 <span><i className={`ppi ppi-bell mr-3 text-${_lastMsg.context}`} /> {_lastMsg.content.length >= 110 ? _lastMsg.content.substring(0, 110) + "..." : _lastMsg.content}</span>
    //                             </div>
    //                         )
    //                     });
    //                     timerInfo = setTimeout(() => {
    //                         if (infoArea === "1") {
    //                             setInfoArea("0");
    //                         }
    //                     }, 10000)
    //                 }
    //             } else {
    //                 setInfoArea("0");
    //             }
    //             break;
    //     }

    //     return () => {
    //         clearInterval(timerInfo);
    //     }

    // }, [])

    useEffect(() => {
        setInfoArea(msgs.alerts.length === 0 ? "0" : "1");
    }, [msgs.alerts])
    //#endregion
    const enabledNotasFicais = () => {
        return user.Menus && user.Menus[1].children.find(permission => permission.name === "Notas fiscais" && permission.permitido)
    }

    //#region 🚀TopBarLeft
    const topBarLeft = <>
        <div className="items">
            <LcTooltip content={!menu.showMenu ? 'Menu' : ''} trigger="hover" position="left">
                <div className="item" id={'topbarleft_menu_button'} onClick={() => { dispatch({ type: 'MENU_VISIBLE', payload: !menu.showMenu }); setShowMeuLCMenu(false) }}>
                    {/*<i className="ppi ppi-menu" />*/}
                    <PiListLight size='24px' />
                </div>
            </LcTooltip>

            <LcTooltip content={!showMeuLCMenu ? 'Meu LiveCloud' : ''} trigger="hover" position="left">
                <div className="item" id={'topbarleft_meu_live_cloud_button'} onClick={() => { setShowMeuLCMenu(!showMeuLCMenu); loadMeuLCSubMenu(); dispatch({ type: 'MENU_VISIBLE', payload: false }) }} >
                    <PiCloudCheckLight size='26px' />
                </div>
            </LcTooltip>

            <LcTooltip content="Home" trigger="hover">
                <div className="item" id={'topbarleft_home_button'} onClick={() => history.push("/")}>
                    {/*<RiHome4Line size='24px' />*/}
                    <PiHouseLight size='24px' />
                    {/*<i className="ppi ppi-home" />*/}
                </div>
            </LcTooltip>
            {
                menu.selectedItem &&
                <div style={{ overflow: 'hidden' }}>
                    <div className="gtm" >
                        {
                            menu.selectedItem.icon.includes('ppi ppi-') &&
                            <i className={`${menu.selectedItem.icon} mr-5`} />
                        }
                        <span className="title">{menu.selectedItem.name}</span>
                    </div>
                </div>


            }
            {
                window.location.pathname === '/SecOps' &&
                <div style={{ overflow: 'hidden' }}>
                    <div className="gtm secops">
                        <div style={{ borderRadius: '100%', width: '.5rem', height: '.5rem', backgroundColor: '#dc291e' }} />
                        <span className="title ml-4">Secops</span>
                    </div>
                </div>
            }

        </div>

    </>
    //#endregion

    //#region 🚀TopBarCenter
    const topBarCenter = <>
        <img className="logo" src={logo} alt="Livecloud" />
    </>
    //#endregion

    //#region 🚀topBarRight
    const topBarRight = <>
        <div className="changearea">{menu.alertArea}</div>
        <LcTooltip content="Notas fiscais" trigger="hover"   >
            <div className="item" id={'topbarright_notas_fiscais_button'} style={{ opacity: enabledNotasFicais() ? '1' : '.5' }} onClick={() => enabledNotasFicais() && history.push("/notasFiscais")}>
                <PiCurrencyCircleDollarLight size='24px' />

                {/*<i className="ppi ppi-currency-dollar" />*/}
            </div>
        </LcTooltip>
        <LcTooltip content="Notificações" trigger="hover" >
            <div className={`item notifications${modalVisibleNotification ? " active" : ""}`} id={'topbarright_notificacoes_button'} onClick={() => setModalVisibleNotification(!modalVisibleNotification)} >
                {
                    badgeNotification.number > 0 &&
                    <span className={`badge ${badgeNotification.status}`}>{badgeNotification.number >= 9 ? '9+' : badgeNotification.number} </span>
                }
                <span></span>

                <PiBellLight size='24px' />

                {/*<i className="ppi ppi-bell" />*/}
            </div>
        </LcTooltip>
        <LcTooltip content="Novo Chamado" trigger="hover">
            <div className="item" id={'topbarright_novo_chamado_button'} onClick={() => { history.push("/Chamados"); dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }} >
                <PiHeadsetLight size='22px' />

                {/*<i className="ppi ppi-headset" />*/}
            </div>
        </LcTooltip>
        <LcTooltip content="Suas opções" trigger="hover" position="right">
            <div className="item avatar" id={'topbarright_suas_opcoes_button'} onClick={() => { dispatch({ type: 'MENU_USER_VISIBLE', payload: !menu.showUserMenu }) }}>
                <img className="avatar" alt={user.name!} src={user.Photo ? `data:image/jpeg;base64,${user.Photo}` : ""} />
            </div>
        </LcTooltip>
    </>
    //#endregion



    //#region 🧾toolBarRight
    //const toolBarRight = () => {
    //    return <>
    //        {
    //            functions.length > 0 &&
    //            <div className="functions" style={{ width: `${(functions.length * 36) + 21}px` }}>
    //                {
    //                    functions.map((f, i) => {
    //                        return !f.hidden && <LcIconLink key={i} icon={f.icon} tooltip={typeof f.tooltip == 'function' ? f.tooltip() : f.tooltip} onClick={f.onClick} tooltipPosition="right" disabled={f.disabled} />;
    //                    })
    //                }
    //            </div>
    //        }
    //        {
    //            props.periodSelection &&
    //            <div className='select-period'>
    //                <LcDropDown position='right' element={<span>{selectedPeriod?.text}&nbsp;&nbsp;<i className={`ppi ppi-chevron-down`} style={{ fontWeight: 'bolder' }} /></span>}>
    //                    {
    //                        props.periodSelection.periods.map((p, i) => {
    //                            return <span key={i} className="item" onClick={() => onChangePeriod(p)}>{p.label}</span>
    //                        })
    //                    }
    //                    {
    //                        props.periodSelection.customPeriod && props.periodSelection.customPeriodType &&
    //                        <>
    //                            <hr />
    //                            <span className="item" onClick={() => { setShowModalCustomPeriod(!showModalCustomPeriod) }}>Personalizado</span>
    //                        </>
    //                    }
    //                </LcDropDown>


    //            </div>
    //        }
    //        {
    //            props.gridFilter &&
    //            <div className="gridfilter">
    //                <div className="size">{props.gridFilter.size}</div>
    //                <span>&nbsp;{props.gridFilter.size > 1 ? "itens" : "item"}&nbsp;</span>
    //                <LcIconLink icon="ppi ppi-filter" onClick={() => { props.gridFilter?.toggleVisibility() }} />
    //            </div>
    //        }
    //        {
    //            rowContent &&
    //            <div className="row">
    //                {rowContent}
    //            </div>
    //        }
    //        {
    //            user.ClientGroupList && user.ClientGroupList.length > 1 &&
    //            <div className="clientgroup">
    //                <span>
    //                    {user.ClientGroupSelectedDescription}
    //                </span>
    //            </div>
    //        }

    //        <div className="last">
    //            <Confirmation display={open} close={setOpen} confirm={handleConfirmClose} text="Esta operação levará vários minutos para completar. Você poderá continuar a usar o sistema enquanto isso e será avisado quando terminar. Continuar?" />

    //            <LcIconLink
    //                tooltip={!waitingUpdating ? "Atualizar" : "Atualizando. Esta operação pode demorar algum tempo"}
    //                icon={`ppi ppi-refresh${waitingUpdating ? ' ppi-spin' : ''}`}
    //                disabled={waitingUpdating}
    //                onClick={() => { setOpen(true) }}
    //                tooltipPosition="right"
    //                tooltipDelay
    //            />
    //            {
    //                props.dots &&
    //                <LcDropDown position='right' element={<LcIconLink icon="ppi ppi-dots-vertical" />}>
    //                    {props.dots}
    //                </LcDropDown>
    //            }
    //        </div>
    //    </>
    //}
    //#endregion

    //#region ❔❓❔❓ Ajuda
    const menuOpen = () => {
        if (!document.getElementsByClassName("pp-dropmenu left")[0]) {
            let el = document.getElementsByClassName("ppi ppi-menu")[0] as any;
            el && el.click();
        }
    }
    const menuClose = () => {
        if (document.getElementsByClassName("pp-dropmenu left")[0]) {
            let el = document.getElementsByClassName("ppi ppi-menu")[0] as any;
            el && el.click();
        }
    }
    const helperList = [
        {
            top: 0,
            left: 0,
            width: "52px",
            height: "52px",
            name: "Menu principal",
            description: "Ao clicar aqui o menu principal será aberto",
            hand: {
                top: 52,
                left: 52,
                rotate: -45
            }
        },
        {
            top: 0,
            left: 0,
            width: "247px",
            height: "310px",
            name: "Menu principal",
            description: "Menu de acesso a todas as soluções, administração do Livecloud e configurações disponíveis",
            onShow: () => { menuOpen() },
            onHide: () => { menuClose() },
            hand: {
                top: 215,
                left: 247,
                rotate: -90
            }
        },
        {
            top: 98,
            left: 0,
            width: "247px",
            height: "40px",
            name: "Soluções",
            description: "Todas as soluções contratadas por você",
            onShow: () => { menuOpen() },
            onHide: () => { menuClose() },
            hand: {
                top: 110,
                left: 240,
                rotate: -45
            }
        },

        // {
        //     top: 306,
        //     left: 0,
        //     width: "247px",
        //     height: "40px",
        //     name: "Repositório de arquivos",
        //     description: "Arquivos disponíveis para download",
        //     onShow: () => { menuOpen() },
        //     onHide: () => { menuClose() },
        //     hand: {
        //         top: 320,
        //         left: 240,
        //         rotate: -45
        //     }
        // },
        {
            top: 234,
            left: 0,
            width: "247px",
            height: "80px",
            name: "Requisições",
            description: "Criar uma nova requisição ou visualizar as requisições abertas",
            onShow: () => { menuOpen() },
            onHide: () => { menuClose() },
            hand: {
                top: 380,
                left: 240,
                rotate: -45
            }
        },
        {
            top: 100,
            left: 0,
            width: "100%",
            height: "calc(100vh - 60px)",
            name: "Soluções LiveCloud",
            description: "As soluções contratadas por você",
        },
        // {
        //     top: 0,
        //     left: 60,
        //     width: `${Math.floor((window.innerWidth - 700) / 52) * 60}px`,
        //     height: "52px",
        //     name: "Soluções LiveCloud",
        //     description: "As soluções contratadas por você",
        //     hand: {
        //         top: 52,
        //         left: 240,
        //         rotate: 0
        //     }
        // },
        {
            top: 0,
            left: window.innerWidth - 430,
            width: "200px",
            height: "52px",
            name: "Última atualização",
            description: "Datas da última atualização da página e da fonte de dados",
            onShow: () => {
                setInfoArea("0");
            },
            hand: {
                top: 52,
                left: window.innerWidth - 330,
                rotate: 0
            }
        },
        {
            top: 0,
            left: window.innerWidth - 232,
            width: '52px',
            height: "52px",
            name: "Notas fiscais e contratos",
            description: "Notas fiscais e todas as informações sobre os contratos",
            // onShow: () => { menuOpen() },
            // onHide: () => { menuClose() },
            hand: {
                top: 52,
                left: window.innerWidth - 205,
                rotate: 45
            }
        },
        {
            top: 0,
            left: window.innerWidth - 112,
            width: "52px",
            height: "52px",
            name: "Abertura de requisições",
            hand: {
                top: 52,
                left: window.innerWidth - 85,
                rotate: 45
            }
        },
        {
            top: 0,
            left: window.innerWidth - 56,
            width: "56px",
            height: "52px",
            name: "Menu do usuário",
            description: "Abre as opções do usuário",
            hand: {
                top: 52,
                left: window.innerWidth - 28,
                rotate: 45
            }
        },
        {
            top: 0,
            left: window.innerWidth - 247,
            width: "247px",
            height: "288px",
            name: "Menu do usuário",
            description: "Aqui você pode ver mais informações sobre o LiveCloud entre outras opções",
            onShow: () => {
                dispatch({ type: 'MENU_USER_VISIBLE', payload: true });
            },
            onHide: () => {
                dispatch({ type: 'MENU_USER_VISIBLE', payload: false });
            },
            hand: {
                top: 144,
                left: window.innerWidth - 247,
                rotate: 90
            }
        },
        // {
        //     top: 52,
        //     left: 0,
        //     width: "100%",
        //     height: "48px",
        //     name: "Barra de ferramentas",
        //     description: "Aqui você verá as abas disponíveis do lado esquerdo e as opções disponíveis para a página atual do lado direito",
        //     hand: {
        //         top: 100,
        //         left: window.innerWidth / 2,
        //         rotate: 0
        //     }
        // },
        // {
        //     top: 52,
        //     left: 0,
        //     width: "89px",
        //     height: "48px",
        //     name: "Aba em uso",
        //     hand: {
        //         top: 100,
        //         left: 89,
        //         rotate: -45
        //     }
        // },
        {
            top: 52,
            left: window.innerWidth - 60,
            width: "60px",
            height: "52px",
            name: "Atualização dos dados",
            description: "Clique aqui para buscar as informações mais atualizadas",
            hand: {
                top: 100,
                left: window.innerWidth - 60,
                rotate: 45
            }
        },
        // {
        //     top: 52,
        //     left: window.innerWidth - 270,
        //     width: "100px",
        //     height: "52px",
        //     name: "Seleção do período",
        //     description: "Clique aqui para escolher o período",
        //     hand: {
        //         top: 100,
        //         left: window.innerWidth - 220,
        //         rotate: 0
        //     }
        // },
        // {
        //     top: 52,
        //     left: window.innerWidth - 370,
        //     width: "200px",
        //     height: "235px",
        //     name: "Períodos disponíveis",
        //     description: "Esta campo permite escolher os períodos acima ou definir um personalizado",
        //     onShow: () => {
        //         if (!document.getElementsByClassName("content scrollable-v")[0]) {
        //             let el = document.getElementsByClassName("select-period")[0].firstChild as any;
        //             el && el.click();
        //         }
        //     },
        //     onHide: () => {
        //         if (document.getElementsByClassName("content scrollable-v")[0]) {
        //             let el = document.getElementsByClassName("select-period")[0].firstChild as any;
        //             el && el.click();
        //         }
        //     },
        //     hand: {
        //         top: 175,
        //         left: window.innerWidth - 370,
        //         rotate: 90
        //     }
        // },

        {
            top: 203,
            left: window.innerWidth - 247,
            width: "247px",
            height: "40px",
            name: "Ajuda",
            description: "Você pode ver essa ajuda quando quiser",
            onShow: () => {
                dispatch({ type: 'MENU_USER_VISIBLE', payload: true });
            },
            onHide: () => {
                dispatch({ type: 'MENU_USER_VISIBLE', payload: false });
            },
            hand: {
                top: 223,
                left: window.innerWidth - 245,
                rotate: 90
            }
        }
    ]
    useEffect(() => {
        setLoading(true); // Inicia o carregamento
        portalUserService.GetHelperStatusByUserId()
            .then(response => {
                try {
                    if (response.data == 0) {
                        document.location.pathname == '/' &&
                            setTimeout(() => {
                                dispatch({ type: 'MENU_HELPER_VISIBLE', payload: true })
                            }, 3000);
                    }
                    setLoading(false); // Finaliza o carregamento após receber a resposta
                } catch (e) {
                    //console.debug(e);
                    setLoading(false); // Finaliza o carregamento em caso de erro
                }
            })
            .catch(error => {
                //console.warn(error);
                setLoading(false); // Finaliza o carregamento em caso de erro na chamada de API
            });
    }, []);

    const helperClose = () => {
        dispatch({ type: 'MENU_HELPER_VISIBLE', payload: false })
        saveHelperStatus(1);
    }
    const saveHelperStatus = (status: number) => {
        portalUserService.SaveHelperStatus(status)
            .then(response => {
            })
            .catch(error => {
                console.debug(error);
            });
    }
    const [helperConfirm, setHelperConfirm] = useState(false);
    const handleShowHelp = () => {
        if (window.location.pathname == "/") {
            dispatch({ type: 'MENU_HELPER_VISIBLE', payload: true })
            setModalNewsVisible(true)
        } else {
            setHelperConfirm(true);

        }
    }
    const helpRedirect = () => {
        setModalNewsVisible(true);
        history.push('/');
        dispatch({ type: 'MENU_HELPER_VISIBLE', payload: true });
    }
    //#endregion

    //#region Novo menu na direita

    const [meuLCMenuItems, setMeuLCMenuItems] = useState<any[]>([]);
    const [subMenuItems, setSubMenuItems] = useState<any[]>([]);
    const [subMenuItemsAdmin, setSubMenuItemsAdmin] = useState<any[]>([]);
    const [subMenuTop, setSubMenuTop] = useState(0);

    const groupColors = {
        '0': '#5F5DAD', //Cloud Managed Services
        '1': '#0055A8', //Proteção de dados
        '2': '#16C2F3', //Subscrições de Software
        '3': '#6E2D8E', //Aplicações de negócios
        '4': '#DC291E', //Segurança
        '5': '#59BAC5', //Gestão, Governança e Suporte
        '6': '#8B90A3' //Informações e histórico
    };

    const loadMeuLCSubMenu = () => {

        const _item = menu.items.find(i => i.id === '6F96AEFB-3676-4995-9512-A15A6C617E1E');
        if (_item) {
            let _s = _item.children.filter(c => c.permitido && JSON.parse(c.extraData).group !== 'None');

            // Agrupar as soluções por "group"
            let groupedSolutions = _s.reduce((groups, solution) => {
                let group = JSON.parse(solution.extraData).group;
                if (!groups[group]) {
                    groups[group] = [];
                }
                groups[group].push(solution);
                return groups;
            }, {});

            // Converter o objeto em um array e ordenar os grupos
            let _groups = Object.keys(groupedSolutions)
                .sort((a, b) => {
                    let indexA = groupedSolutions[a][0].menuOrder;
                    let indexB = groupedSolutions[b][0].menuOrder;
                    return indexA - indexB;
                })
                .map(group => {
                    return {
                        title: group,
                        children: groupedSolutions[group]
                            .sort((a, b) => a.menuOrder - b.menuOrder)
                            .map(e => {
                                let sufixo = JSON.parse(e.extraData).sufixo;
                                if (sufixo && sufixo.startsWith("- ")) {
                                    sufixo = sufixo.slice(2);
                                }
                                return {
                                    name: e.name,
                                    route: e.children.length > 0 ? e.children.sort((a, b) => a.menuOrder - b.menuOrder)[0].route : e.route,
                                    group: group,
                                    group_id: JSON.parse(e.extraData).group_id,
                                    permitido: e.permitido,
                                    sufixo: sufixo,
                                    solution: JSON.parse(e.extraData).solution
                                }
                            })
                    };
                });
            setMeuLCMenuItems(_groups);
        }
    }

    function getUniqueGroups(items) {
        const groups = items.map(item => {
            if (item.extraData) {
                const data = JSON.parse(item.extraData);
                return data.group;
            }
            return null;
        });

        // Filtrar grupos únicos
        return [...new Set(groups)];
    }

    const liveCloud = menu.items.find(m => m.id === '6F96AEFB-3676-4995-9512-A15A6C617E1E');
    const groups = liveCloud
        ? getUniqueGroups(liveCloud.children)
            .filter(group => group !== 'None')
            .sort((a, b) => {
                const aChild = liveCloud.children.find(child => JSON.parse(child.extraData).group === a);
                const bChild = liveCloud.children.find(child => JSON.parse(child.extraData).group === b);
                if (aChild == null) {
                    return 1;
                }
                if (bChild == null) {
                    return -1;
                }
                return (aChild.menuOrder || 0) - (bChild.menuOrder || 0);
            })
        : [];


    const [selectedSolution, setSelectedSolution] = useState<string | null>(null);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const openMarketing = (solution: string) => {
        setSelectedSolution(solution);
    };

    const closeMarketing = () => {
        setSelectedSolution(null);
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
    };

    const handleMouseOver = (item) => {
        if (!item.permitido) {
            setTimeoutId(setTimeout(() => {
                openMarketing(item.solution);
            }, 2500)); // 2500 milissegundos = 2,5 segundos
        }
    };

    const handleMouseOut = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
    };

    const hasPermittedSubItems = (item) => {
        return item.children.some(subItem => subItem.permitido);
    };

    const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
    const [selectedItem, setSelectedItem] = useState<string | null>(null);


    const findGroupByItemName = (itemName: string, groups: string[], liveCloud: any) => {
        for (let group of groups) {
            const groupItems = liveCloud.children.filter(child => {
                const childData = JSON.parse(child.extraData);
                return ((childData.group) as string) === group;
            });
            if (groupItems.some(item => item.name === itemName)) {
                return group;
            }
        }
        return null; // Retorna null se o item não pertencer a nenhum grupo
    }


    useEffect(() => {
        const storedSelectedItem = localStorage.getItem('selectedItem');
        const storedSelectedGroup = localStorage.getItem('selectedGroup');
        if (storedSelectedItem) {
            setSelectedItem(storedSelectedItem);
        }
        if (storedSelectedGroup) {
            setSelectedGroup(storedSelectedGroup);
        }
    }, []);


    const historico = useHistory();
    const routesToClear = ["/", "/notasFiscais", "/Chamados", "/FileRepository"];


    useEffect(() => {
        const unlisten = historico.listen((location) => {
            if (routesToClear.includes(location.pathname)) {
                setSelectedItem(null);
                setSelectedGroup(null);
                localStorage.removeItem('selectedItem');
                localStorage.removeItem('selectedGroup');
            }
        });

        // Limpe o listener quando o componente for desmontado
        return () => {
            unlisten();
        };
    }, [historico]);

    const loadSubMenu = (itemsOrItem: any[] | any, index: number) => {
        // 
        setSubMenuItems([]);
        setSubMenuItemsAdmin([]);


        if (Array.isArray(itemsOrItem)) {

            const items = itemsOrItem;

            let _s = items.filter(c => JSON.parse(c.extraData).group !== 'None');

            // Agrupar as soluções por "group"
            let groupedSolutions = _s.reduce((groups, solution) => {
                let group = JSON.parse(solution.extraData).group;
                if (!groups[group]) {
                    groups[group] = [];
                }
                groups[group].push(solution);
                return groups;
            }, {});

            // Converter o objeto em um array e ordenar os grupos de acordo com a ordem desejada
            let _groups = Object.keys(groupedSolutions)
                .sort((a, b) => {
                    let indexA = groupedSolutions[a][0].menuOrder;
                    let indexB = groupedSolutions[b][0].menuOrder;
                    return indexA - indexB;
                })
                .map(group => {
                    return {
                        title: group,
                        children: groupedSolutions[group]
                            .sort((a, b) => a.menuOrder - b.menuOrder) // Ordenar as soluções por "menuOrder"
                            .map(e => {
                                let sufixo = JSON.parse(e.extraData).sufixo;
                                if (sufixo && sufixo.startsWith("- ")) {
                                    sufixo = sufixo.slice(2);
                                }
                                return {
                                    name: e.name,
                                    route: e.children.length > 0 ? e.children.sort((a, b) => a.menuOrder - b.menuOrder)[0].route : e.route,
                                    group: group,
                                    group_id: JSON.parse(e.extraData).group_id,
                                    permitido: e.permitido,
                                    sufixo: sufixo,
                                    solution: JSON.parse(e.extraData).solution
                                }
                            })
                    };
                });
            setSubMenuItems(_groups);
        } else {
            // Processa um único item (como os itens administrativos)
            index += 6.5;
            const item = itemsOrItem;
            let _item = menu.items.find(i => i.id === item.id);
            if (_item) {
                const processedMenuOrders = {};
                _item.children.map(item => {
                    if (processedMenuOrders[item.menuOrder] === undefined) {
                        processedMenuOrders[item.menuOrder] = true;
                    } else {
                        // Já existe um item com esse menuOrder, incremente-o
                        let newMenuOrder = item.menuOrder;
                        while (processedMenuOrders[newMenuOrder] !== undefined) {
                            newMenuOrder++;
                        }
                        processedMenuOrders[newMenuOrder] = true;
                        item.menuOrder = newMenuOrder;
                    }
                    return item;
                });
                setSubMenuItemsAdmin(_item.children.sort((a, b) => a.menuOrder - b.menuOrder));
            }
        }
        setSubMenuTop(46 + index * 38);
    }

    //Delay close menu
    var timerMenu;
    const onLeaveMenu = () => {
        clearTimeout(timerMenu);
        timerMenu = setTimeout(() => { dispatch({ type: 'MENU_VISIBLE', payload: false }); }, 500);
        setSubMenuItems([]);
    }
    const onClickMenuItem = (item: any) => {

        const group = findGroupByItemName(item.name, groups as string[], liveCloud);

        setSelectedItem(item.name);
        if (group) {
            setSelectedGroup(group);
            localStorage.setItem('selectedGroup', group);
        } else {
            localStorage.removeItem('selectedGroup'); // Remove o grupo do localStorage se o item não pertencer a nenhum grupo
        }
        localStorage.setItem('selectedItem', item.name);

        dispatch({ type: 'MENU_VISIBLE', payload: false });
        dispatch({ type: 'MENU_SUBSELECTED_UPDATE', payload: item });

        setSubMenuItems([]);
        const route = item.route;

        if (item.permitido) {
            if (route && route !== '#') {
                if (route.toString().includes("http")) {
                    window.open(route, '_blank');
                } else {
                    history.push("/" + route);
                }
            } else if (item.children && item.children[0] && item.children[0].route) {
                history.push('/' + item.children[0].route);
            }

        } else {
            openMarketing(item.solution);
        }
    }
    //#endregion

    function NewsA() {
        return (<News></News>);
        //<div>

        //    <p>Para tornar nossas soluções mais intuitivas e fáceis de navegar, implementamos uma atualização na nomenclatura de várias delas e as reagrupamos em seis categorias distintas:</p>

        //    <ul>
        //        <li>Infra Multinuvem</li>
        //        <li>Proteção de Dados</li>
        //        <li>SaaS</li>
        //        <li>Aplicações de Negócios</li>
        //        <li>Segurança</li>
        //        <li>Gestão e Governança</li>
        //    </ul>

        //    <p>Para que você não tenha problemas na adaptação, gostaríamos de detalhar as alterações de nomenclatura para cada solução:</p>

        //    <h3>Infra Multinuvem</h3>
        //    <ol>
        //        <li>'Hosting' agora é 'Cloud Managed Services Corporate'</li>
        //        <li>'Admin' agora é 'Cloud Managed Services Enterprise'</li>
        //        <li>'WebPresence' agora é 'Cloud Managed Services Premier'</li>
        //    </ol>

        //    <h3>Proteção de Dados</h3>
        //    <ol>
        //        <li>'Backup Silver' agora é 'Backup Corporate'</li>
        //        <li>'Backup Gold' agora é 'Backup Enterprise'</li>
        //        <li>'Retenção' e 'Disaster Recovery' mantêm os mesmos nomes</li>
        //    </ol>

        //    <h3>SaaS</h3>
        //    <ol>
        //        <li>'CSP' agora é 'Basic'</li>
        //        <li>'SaaS' agora é 'Premium'</li>
        //        <li>'SaaS Backup' agora é 'Backup'</li>
        //        <li>'EA/EAS' mantém o mesmo nome</li>
        //    </ol>

        //    <h3>Aplicações de Negócios</h3>
        //    <p>Apresentamos quatro novas soluções: 'Diime', 'Doone', 'Hey!Ho!' e 'Receeba'. 'Max Outsource', 'Max Software' e 'Mérito' continuam com os mesmos nomes.</p>

        //    <h3>Segurança</h3>
        //    <ol>
        //        <li>'EDR' e 'LiveWatch' mantêm os mesmos nomes</li>
        //        <li>Introduzimos três novas soluções: 'Firewall', 'Identity Access' e 'Patch Manager'</li>
        //        <li>'EndPoint Security' agora é 'SecureEndPoint'</li>
        //    </ol>

        //    <h3 style={{ fontWeight: 'bold' }}>Gestão e Governança</h3>

        //    <p>'FinOps' continua com o mesmo nome.</p>
        //    <br />
        //    <p>Além disso, para facilitar a exploração de nossas soluções ainda não contratadas, elas agora aparecem esmaecidas em nossa interface. Ao clicar nelas, você será direcionado ao nosso site, onde poderá obter mais detalhes sobre a solução e, se desejar, proceder com a contratação.</p>

        //    <p>Nosso objetivo com essas mudanças é melhorar sua experiência e oferecer uma interface mais clara e intuitiva. Há mais novidades por vir nos próximos meses, onde teremos major releases </p>

        //    <p>Estamos sempre à disposição para qualquer dúvida ou preocupação que você possa ter. Sinta-se à vontade para entrar em contato conosco.</p>

        //    <p>Agradecemos pela sua parceria e esperamos que goste da nova experiência no LiveCloud!</p>

        //    <p>Atenciosamente</p>
        //</div>

    }

    const locale = { back: 'Voltar', close: 'Fechar', last: 'Fim', next: 'Próximo', skip: 'Pular' }
    const [modalNewsVisible, setModalNewsVisible] = useState(menu.showHelper);
    const [showHelper, setShowHelper] = useState(false);
    const handleJoyrideCallback = (data: CallBackProps) => {
        const { action, index, status, type } = data;
        if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
            setStepIndex(0);
            dispatch({ type: 'MENU_VISIBLE', payload: false });
            dispatch({ type: 'MENU_USER_VISIBLE', payload: false });
            helperClose()
            setShowHelper(false);
        } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
            const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            if (!menu.showMenu && index === 0) {
                setStepIndex(nextStepIndex);
            }
            else if (index === 1 && action === ACTIONS.NEXT) {
                dispatch({ type: 'MENU_VISIBLE', payload: true });
                setShowHelper(true);
                setStepIndex(nextStepIndex);
            }
            else if (index === 2 && action === ACTIONS.PREV) {
                dispatch({ type: 'MENU_VISIBLE', payload: false });
                setStepIndex(nextStepIndex);
            }
            else if (index === 6 && action === ACTIONS.NEXT) {
                dispatch({ type: 'MENU_VISIBLE', payload: false });
                setStepIndex(nextStepIndex);
            }
            else if (index === 7 && action === ACTIONS.PREV) {
                dispatch({ type: 'MENU_VISIBLE', payload: true });
                setStepIndex(nextStepIndex);
            }
            else if (index === 12 && action === ACTIONS.NEXT) {
                dispatch({ type: 'MENU_USER_VISIBLE', payload: true });
                setStepIndex(nextStepIndex);
            }
            else if (index === 13 && action === ACTIONS.PREV) {
                dispatch({ type: 'MENU_USER_VISIBLE', payload: false });
                setStepIndex(nextStepIndex);
            }
            else {
                setStepIndex(nextStepIndex);
            }
        }
    }
    //#endregion

    const [stepIndex, setStepIndex] = useState<number>(0);
    const steps: Step[] = [
        {
            target: 'body',
            placement: 'center',
            title: 'Bem vindo a ajuda do LiveCloud',
            content: "Você será guiado pelos componentes de tela do novo layout do Livecloud.",
        },
        {
            target: '.ppi.ppi-menu',
            content: 'Ao clicar aqui o menu principal será aberto',
            title: 'Menu Principal'
        },
        {
            target: '.pp-dropmenu.left',
            placement: 'right',
            content: "Menu de acesso a todas as soluções, administração do Livecloud e configurações disponíveis",
            title: 'Menu Principal',
            spotlightPadding: 0,


        },
        {
            target: '.item.Soluções',
            placement: 'right',
            content: "Todas as soluções contratadas por você",
            title: 'Soluções',
            spotlightPadding: 0
        },

        {
            target: '.item.Repositorio',
            placement: 'right',
            content: "Arquivos disponíveis para download",
            title: 'Repositório de arquivos',
            spotlightPadding: 0,
        },
        {
            target: '.item.newRequest',
            placement: 'right',
            content: "Criar uma nova requisição",
            title: 'Nova requisição',
            spotlightPadding: 0
        },
        {
            target: '.item.requests',
            placement: 'right',
            content: "Visualizar as requisições abertas",
            title: 'Requisições',
            spotlightPadding: 0,

        },
        {
            target: '.homegrid',
            placement: 'auto',
            content: "As soluções contratadas por você",
            title: 'Soluções LiveCloud',
            spotlightPadding: 0,
        },
        {
            target: '.lastupdate',
            placement: 'auto',
            content: "Datas da última atualização da página e da fonte de dados",
            title: 'Última atualização',
            floaterProps: {
                disableAnimation: true,
            },
            spotlightPadding: 10,
        },
        {
            target: '.ppi.ppi-currency-dollar',
            placement: 'auto',
            content: "Notas fiscais e todas as informações sobre os contratos",
            title: 'Notas fiscais e contratos',
            spotlightPadding: 10,
        },
        {
            target: '.ppi.ppi-headset',
            placement: 'auto',
            content: "Abertura de uma nova requisição",
            title: 'Abertura de requisições',
            spotlightPadding: 10
        },
        {
            target: '.ppi.ppi-refresh',
            placement: 'auto',
            content: "Clique aqui para buscar as informações mais atualizadaso",
            title: 'Atualização dos dados',
            floaterProps: {
                disableAnimation: true,
            },
            spotlightPadding: 10
        },
        {
            target: '.item.avatar',
            placement: 'auto',
            content: "Abre as opções do usuário",
            title: 'Menu do usuário',
            spotlightPadding: 0
        },
        {
            target: '.usermenu',
            placement: 'auto',
            content: "Aqui você pode ver mais informações sobre o LiveCloud entre outras opções",
            title: 'Menu do usuário',
            spotlightPadding: 0
        },
        {
            target: '.item.Ajuda',
            placement: 'auto',
            content: "Você pode ver essa ajuda quando quiser",
            title: 'Ajuda',
            spotlightPadding: 0
        }
    ]

    const [showOverflowArrowLeft, setShowOverflowArrowLeft] = useState(false);
    const [showOverflowArrowRight, setShowOverflowArrowRight] = useState(false);
    const [scroll, setScroll] = useState(0);
    const itemsContainerRef = useRef<any>();


    const scrollRight = () => {
        const container = itemsContainerRef.current;
        if (container) {
            container.scrollLeft = container.scrollLeft + 50;
            setScroll(container.scrollLeft);
        }
    };

    const scrollLeft = () => {
        const container = itemsContainerRef.current;
        if (container) {
            container.scrollLeft = container.scrollLeft - 50;
            setScroll(container.scrollLeft);
        }
    };
    useEffect(() => {
        const container = itemsContainerRef.current;
        if (container) {

            const activeItem = container.querySelector('.item.active');
            if (activeItem) {
                const containerRect = container.getBoundingClientRect();
                const itemRect = activeItem.getBoundingClientRect();
                if (itemRect.right > containerRect.right) {
                    container.scrollLeft += itemRect.right - containerRect.right + 50;
                    setShowOverflowArrowLeft(true)
                }
                else if (itemRect.left < containerRect.left) {
                    container.scrollLeft -= containerRect.left - itemRect.left;

                }
            }
        }
    }, [toolBarItems]);

    useEffect(() => {
        const container = itemsContainerRef.current;
        if (container) {
            let canScrollRight = container.scrollWidth > container.clientWidth + scroll;
            let canScrollLeft = scroll > 0 || container.scrollLeft > 9
            setShowOverflowArrowLeft(canScrollLeft);
            setShowOverflowArrowRight(canScrollRight);
        }
    }, [toolBarItems, scroll, showOverflowArrowLeft, showOverflowArrowRight]);

    return (
        <>
            <div className="topbar">
                <div className="left">
                    {topBarLeft}
                </div>
                <div className="center">
                    {topBarCenter}
                </div>
                <div className="right">
                    {topBarRight}
                </div>
            </div>
            <div className="toolbar">
                <div className="left">
                    {showOverflowArrowLeft &&
                        <div className="btnscroll left">
                            <LcIconLink icon={<PiCaretLeftLight />} size="small" onClick={scrollLeft} />
                        </div>
                    }
                    <div className="items" ref={itemsContainerRef} >
                        {
                            toolBarItems.filter(b => b.label !== 'Início').map((t, i) => {
                                return <span key={i} id={`CMS_Corporate_toolbar_button_${i}`} className={`item${t.active ? ' active' : ''}`}
                                    onClick={() => t.onClick()}
                                >
                                    {t.label}
                                </span>
                            })
                        }
                    </div>
                    {showOverflowArrowRight && (
                        <div className="btnscroll">
                            <LcIconLink icon={<PiCaretRightLight />} size="small" onClick={scrollRight} />
                        </div>
                    )}
                </div>
                <div className="right">
                    <ToolBarRight
                        functionsGeneric={props.functionsGeneric}
                        pivot={props.pivot}
                        hidePivotHeader={props.hidePivotHeader}
                        row={props.row}
                        selectedPeriod={selectedPeriod}
                        onChangePeriod={onChangePeriod}
                        props={props}
                        user={user}
                        open={open}
                        setOpen={setOpen}
                        handleConfirmClose={handleConfirmClose}
                        waitingUpdating={waitingUpdating}
                        setShowModalCustomPeriod={setShowModalCustomPeriod}
                        showModalCustomPeriod={showModalCustomPeriod}
                    />
                </div>
            </div>
            <div className="page">
                <Confirmation
                    title="Ajuda do LiveCloud"
                    text="Para visualizar a ajuda você precisa ser redirecionando para a página inicial do LiveCloud"
                    confirm={() => { helpRedirect() }}
                    close={() => { setHelperConfirm(false) }}
                    textBtnOk="Redirecionar"
                    display={helperConfirm}
                />
                {/* <Helper list={helperList} visible={menu.showHelper && showHelper} onClose={helperClose}/> */}
                <Joyride
                    steps={props.steps ? props.steps : steps}
                    run={showHelper}
                    continuous
                    hideCloseButton
                    showProgress={false}
                    showSkipButton
                    locale={locale}
                    callback={props.handleJoyrideCallback ? props.handleJoyrideCallback : handleJoyrideCallback}
                    stepIndex={props.stepIndex ? props.stepIndex : stepIndex}
                    styles={{
                        options: {
                            zIndex: 10000,
                            primaryColor: '#273B85'
                        },
                    }}
                />
                {<PPModal title="Novidades" size="720px" visible={menu.showHelper && modalNewsVisible}
                    onClose={() => { setModalNewsVisible(false); setShowHelper(true) }}>
                    <News />
                </PPModal>}
                {
                    menu.showMenu && (
                        <div style={{ position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
                            <PPDropMenu
                                position="left"
                                onLeave={onLeaveMenu}
                                subMenuTop={subMenuTop}
                                items={
                                    <>
                                        <div className="title-menu">LiveCloud</div>
                                        {groups.map((groupTitle, i) => {
                                            let groupId;
                                            if (liveCloud == null) {
                                                return {};
                                            }
                                            // Localizando o group_id para o grupo atual
                                            for (let child of liveCloud.children) {
                                                const childData = JSON.parse(child.extraData);
                                                if (childData.group === groupTitle) {
                                                    groupId = childData.group_id;
                                                    break; // Interrompe o loop assim que encontrar o group_id
                                                }
                                            }
                                            return (
                                                <div
                                                    id={`TopMenuLeft_${groupTitle}_${i}`}
                                                    key={i}
                                                    className={`item ${selectedGroup === groupTitle ? 'selected' : ''}`}
                                                    onClick={() => {
                                                        const groupItems = liveCloud.children.filter(child => {
                                                            const childData = JSON.parse(child.extraData);
                                                            return childData.group === groupTitle;
                                                        });
                                                        loadSubMenu(groupItems, i);
                                                        setSelectedGroup(groupTitle as string);
                                                    }}
                                                >
                                                    <div
                                                        className="group-solution"
                                                    //style={{ color: groupColors[groupId] }}
                                                    >
                                                        <div
                                                            className="color-group"
                                                            style={{ backgroundColor: groupColors[groupId] }}
                                                        ></div>
                                                        <div className="label">{groupTitle}</div>
                                                    </div>
                                                    <i className="ppi ppi-chevron-right" />
                                                </div>
                                            );
                                        })}

                                        <hr />

                                        {menu.items
                                            .filter(item =>
                                                item.id !== '6F96AEFB-3676-4995-9512-A15A6C617E1E' && //Meu LiveCloud
                                                item.id !== '1539404e-032a-4bda-b1ea-ab55ea99d4de' && //Outros
                                                hasPermittedSubItems(item) //Quando o usuário tem permissão em algum subitem de menu
                                            )
                                            .map((item, i) => (
                                                <div id={`TopMenuLeft_items_${item.name}_${i}`} key={i}
                                                    className={`item ${item.name} ${selectedGroup === item.id ? 'selected' : ''}`}
                                                    onClick={() => {
                                                        loadSubMenu(item, i);
                                                        setSelectedGroup(item.id);
                                                    }}
                                                    onMouseOut={handleMouseOut}
                                                >
                                                    <div className="label">{item.name}</div>
                                                    <i className="ppi ppi-chevron-right" />
                                                </div>
                                            ))}
                                    </>

                                }
                                subItems={
                                    (subMenuItems.length > 0 || subMenuItemsAdmin.length > 0) && <>
                                        {subMenuItems.length > 0 &&
                                            subMenuItems.map((u, i) => (
                                                <div className="group-solution" key={i}>
                                                    {u.children.map((m, l) => (
                                                        <div id={`TopMenuLeft_subItems_${m.name}_${i}`}
                                                            key={l}
                                                            className={`item ${m.name === selectedItem ? 'selected' : ''} ${m.permitido ? 'permitido' : 'nao-permitido'}`}
                                                            onClick={() => { onClickMenuItem(m); setSelectedItem(m.name); }}
                                                            onMouseOver={() => handleMouseOver(m)}
                                                            onMouseOut={handleMouseOut}
                                                        >
                                                            <div className="solution">
                                                                <div className="label solution-name">{m.name}</div>
                                                                <div className="sufixo">{m.sufixo}</div>
                                                            </div>
                                                            {m.permitido ? <BsCloudCheck size='14px' color="green" /> : null}
                                                        </div>
                                                    ))}
                                                    {i !== subMenuItems.length - 1 && <hr />}
                                                </div>
                                            ))
                                        }
                                        {subMenuItemsAdmin.length > 0 &&
                                            subMenuItemsAdmin
                                                .sort((a, b) => { return a.menuOrder > b.menuOrder ? 1 : -1 })
                                                .map((m, i) => (
                                                    <div id={`TopMenuLeft_subItems_${m.name}_${i}`} key={i} className="item" onClick={() => onClickMenuItem(m)} onMouseOut={handleMouseOut}>
                                                        <div className="label">{m.name}</div>
                                                    </div>
                                                ))
                                        }
                                    </>
                                }
                            />
                        </div>
                    )
                }
                {
                    showMeuLCMenu &&
                    <div style={{ position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
                        <PPDropMenu
                            position="left"
                            onLeave={() => { setShowMeuLCMenu(false) }}
                            items={
                                <>
                                    <div className="title-menu">Meu LiveCloud</div>

                                    {meuLCMenuItems.length > 0 &&
                                        meuLCMenuItems.map((u, i) => (


                                            <div className={`group-solution group_${i}`} key={i}>

                                                <div className="group-solution-meu-lc">
                                                    <div className="color-group"
                                                        style={{ backgroundColor: groupColors[u.children[0].group_id] }}
                                                    >
                                                    </div>
                                                    <div className="group-title"
                                                        style={{ color: groupColors[u.children[0].group_id] }}
                                                    >
                                                        {u.title}
                                                    </div>
                                                </div>

                                                {u.children.map((m, l) => (
                                                    <div id={`TopMenuLeft_LCMenu_subItems_${m.name}_${i}`} key={l}
                                                        className={`item ${selectedItem === m.name ? 'selected' : ''}`}
                                                        onClick={() => { onClickMenuItem(m); setSelectedItem(m.name); }}
                                                    >
                                                        <div className="label solution-name">{m.name}</div>
                                                        <div className="sufixo">{m.sufixo}</div>
                                                    </div>
                                                ))}
                                                {i !== meuLCMenuItems.length - 1 && <hr />}
                                            </div>
                                        ))
                                    }
                                </>
                            }
                        />
                    </div>
                }
                <UserMenu items={[{ label: "Ajuda", onClick: () => handleShowHelp() }]} />

                <NotificationsComponent visible={modalVisibleNotification} onClose={setModalVisibleNotification} />

                <PPModal title="Tutoriais" size="720px" visible={modalTutorialsVisible} onClose={() => { setModalTutorialsVisible(false) }}>
                    <div className="row wrap gap-5">
                        {
                            modalTutorialsVisible &&
                            tutorials.map((t: any, k: number) => {
                                return (
                                    <div key={k} className="mb-5">
                                        <h5>{t.name}</h5>

                                    </div>
                                )
                            })
                        }
                    </div>
                </PPModal>
                {
                    props.periodSelection &&
                    <PPModal
                        title="Período personalizado"
                        visible={showModalCustomPeriod}
                        size="medium"
                        onClose={() => { setShowModalCustomPeriod(false) }}
                        functions={[
                            { label: "Consultar", onClick: () => { onChangePeriodCustom(selectionDate); submitCustomPeriod() } }
                        ]}
                    >
                        {
                            props.periodSelection.customPeriod &&
                            props.periodSelection.customPeriod.fields.map((field: any, index) => {

                                let max = '';
                                if (props.periodSelection && props.periodSelection.customPeriodType) {
                                    switch (props.periodSelection.customPeriodType) {
                                        case 'date':
                                        case 'datetime-local':
                                            max = new Date().toISOString().split('T')[0]
                                            break;
                                        case 'time':
                                            max = new Date().toISOString().split('T')[1]
                                            break;
                                        case 'datetime':
                                            max = new Date().toISOString();
                                            break;
                                        case 'month':
                                            max = new Date().toISOString().substring(0, 7);
                                            break;
                                        default:
                                            break;
                                    }
                                }

                                return <InputField key={index} name={field.name} label={field.label} type={props.periodSelection ? props.periodSelection.customPeriodType : 'date'} onChange={setSelectionDate} max={max} />
                            })
                        }
                    </PPModal>
                }
                {
                    (user && user.ClientGroupSelected && user.ClientGroupSelected > 0 && !props.loading)
                        ?
                        props.cardType
                            ? <div className="card-page scrollable-v">{props.children}</div>
                            : props.children
                        :
                        <LcLoading loading >

                        </LcLoading>
                }

            </div>
            <NPS />
            <Marketing selectedSolution={selectedSolution} onSolutionClose={closeMarketing} />
        </>
    )
}

export default Layout;