import React, { useCallback, useEffect, useRef, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
/*import './index.css';*/
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import LcLoading from '../../../components/Generic/LcLoading';
import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import LCDashboard, { Card, PositionCard } from '../../../components/Data/Dashboard/LCDashboard';
import { Chart, CollectoresModel, ExceptionModal, OperacialSystemEvent, ResumeEvent, devicesEvent, processEvent } from '../resumeEvent/Models';
import EventsGraph from '../resumeEvent/EventsGraph';
import GaugeChartCore from '../../../components/Layout/Dashboard/gaugeChartCore';
import ReactECharts from 'echarts-for-react';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import PPDropDown from '../../../components/PPDropDown';
import history from '../../../history';
import { PiArrowSquareOutLight, PiFileCsvLight, PiFilePdfLight, PiFunnelDuotone, PiFunnelLight, PiShieldWarningLight } from 'react-icons/pi';
import './index.css'
import LcNoData from '../../../components/Generic/LcNoData'; 
import moment from 'moment';
import ImgLog from '../../../assets/images/logo.svg';
import { createRoot } from 'react-dom/client';
import ReportResumePDF from './ReportResumePDF';
import imgHorizantalBar from '../../../assets/images/horizontalbar_print.png';
import imgHorizantalBarFooter from '../../../assets/images/horizontalbar_print_footer.png';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { useSnackbar } from 'notistack';
import exportFromJSON from 'export-from-json';
import { WorkEnvironment } from './WorkEnvironment';
import { MostTargetTable } from './MostTargetTable';
import { CollectingDeviceChart } from './CollectingDeviceChart';
import { EventClassificationChart } from './EventClassificationChart';
import { LiberationCard } from './LiberationCard';
import FilterPanel from '../../../components/Data/FilterPanel';

interface DoughnutChartValue {
    title: string;
    labels: string[];
    values: number[]
}
interface EndpointDetectionResponseResumeProps {
    isHome: boolean,
    qtdGroups: number
    secOpsQtdGroups: number
    isSecOpsPage: boolean
}
export interface MostTargetChartModel {
    labels: string[]
    colors: string[]
    data: number[]
}
interface DeviceData {
    device: string;
    classification: string;
    count: number;
}

export const statusColor = (label: any) => {
    switch (label) {
        case 'Online':
            return '#81bc00';
        case 'Executando':
            return '#81bc00';
        case 'Desabilitado':
            return '#b3b3b3';
        case 'Desconectado':
            return '#dc291e';
        case 'Degradado':
            return '#ffa400';
        default:
            return '#777777';
    }
}
export const status = (label: any) => {
    switch (label) {
        case 'Running':
            return 'Online';
        case 'Disconnected':
            return 'Desconectado';
        case 'Disabled':
            return 'Desabilitado';
        case 'Degraded':
            return 'Degradado';
        case 'RebootPending':
            return 'Pendente reiniciar';
        case 'Isolated':
            return 'Isolado';
        default:
            return 'Executando';
    }
}
export const groupBy = (items, key) => items.sort(function (a, b) {
    if (a.classificationRank < b.classificationRank) return 1;
    if (a.classificationRank > b.classificationRank) return -1;
    return 0;
}).reduce(
    (result, item) => ({
        ...result,
        [item[key]]: [
            ...(result[item[key]] || []),
            item,
        ],
    }),
    {},
);

type DataEntry = devicesEvent | ExceptionModal | CollectoresModel | processEvent;

const EndpointDetectionResponseResume: React.FC<EndpointDetectionResponseResumeProps> = ({isHome, isSecOpsPage, qtdGroups, secOpsQtdGroups}) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [iotData, setIotData] = useState<any>();
    const [serversData, setServersData] = useState<any>();
    const [workstationsData, setWorkstationsData] = useState<any>();
    const [sloChart, setSloChart] = useState<number>(0);
    const [slaChart, setSlaChart] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingEventsBlock, setLoadingEventsBlock] = useState<boolean>(true);
    const [loadingCollectoresChart, setLoadingCollectoresChart] = useState<boolean>(true);
    const [loadingMostTarget, setLoadingMostTarget] = useState<boolean>(true);
    const [chamadosChart, setChamadosChart] = useState<Chart>();
    const [isLoadingChamadosChart, setIsLoadingChamadosChart] = useState<boolean>(true);
    const [totalChamadosChart, setTotalChamadosChart] = useState<Chart>();
    const [isLoadingTotalChamadosChart, setIsLoadingTotalChamadosChart] = useState<boolean>(true);
    const [chamadosPorOrigemChart, setChamadosPorOrigemChart] = useState<Chart>();
    const [resumeEventReport, setResumeEventReport] = useState<ResumeEvent>({devices: [], process: [], classification:[], collectors: [], coletoresOperationalSystems: [], listCollectores: [], mostTargetsCollectores: []});
    const [collectorsView, setCollectorsView] = useState<string>('geral');
    const [slaView, setSlaView] = useState<string>('sla');
    const [totalEventsBlock, setTotalEventsBlock] = useState<number>(0);
    const [periodFilter, setPeriodFilter] = useState<string>((isSecOpsPage || isHome) ?'alltime' : 'last30Days')
    const [mostTargetSelected, setMostTargetSelected] = useState<string>('user')
    const [eventBlocked, setEventsBlocked] = useState<devicesEvent[]>([])
    const [eventBlockedCollectors, setEventsBlockedCollectors] = useState<devicesEvent[]>([])
    const [processEvents, setProcessEvents] = useState<processEvent[]>([])
    const [systemOperation, setSystemOperation] = useState<OperacialSystemEvent[]>([])
    //identificando charts
    const chartDispositivosGeral = useRef<ReactECharts>(null);
    const [chartRefEvents, setChartRefEvents] = useState<any>();
    const [chartRefDivices, setChartRefDivices] = useState<any>();
    const { enqueueSnackbar } = useSnackbar();
    const [eventFiltered, setEventsFiltered] = useState<devicesEvent[]>([])
    const [rowsFilteredMostTarget, setRowsFilteredMostTarget] = useState<any[]>([]);
    const [showFilterEnable, setShowFilterEnable] = useState<boolean>(false);
    const [filterEnable, setFilterEnable] = useState<boolean>(false);
    const [filtered, setFiltered] = useState<boolean>(false);
    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setPeriodFilter(date);
    };
    const beginOfMonth = () => {
        const currentDate = new Date();
        const dayOfMonth = currentDate.getDate();
    
        let result = false;
    
        if (dayOfMonth > 0) {
            result = false;
        } else {
            result = true;
        }
        return result;
    }
    const periodSelection = {
        periods: [
            {
                label: 'Útimos 7 dias', text: 'Útimos 7 dias',
                fields: [
                    { name: 'last7Days', value: 'last7Days' },
                    
                ],
                default: beginOfMonth()
            },
            {
                label: 'Útimos 30 dias', text: 'Útimos 30 dias',
                fields: [
                    { name: 'last30Days', value: 'last30Days' }
                ],
                default: true
            },
            {
                label: 'Últimos 3 meses', text: 'Últimos 3 meses',
                fields: [
                    { name: 'last90Days', value: 'last90Days' }
                ],
                default: beginOfMonth()
            },
            {
                label: 'Período total', text: 'Período total',
                fields: [
                    { name: 'alltime', value: 'alltime' },
                    
                ],
                default:  beginOfMonth()
            },
        ],
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    const slaOptions = [
        { label: 'SLA', value: "sla" }, { label: 'SLO', value: "slo" }
    ];

    const collectorsOptions = [
        { label: 'Geral', value: "geral" }, { label: 'Operacional', value: "system" }
    ];

    const mostTargetsOpt = [{label: 'Usuários', value: 'user' }, {label: 'Coletores', value: 'collectors' }]

    const positions5Groups: Card[] = [
        {
            id: 1, position: { ColSpan: 2, RowSpan: 1, row: 5, col: 1, hidden: !isSecOpsPage},
        },
        {
            id: 2, position: { ColSpan: 2, RowSpan: 1, row: 5, col: 1, hidden: false},
        },
        {
            id: 3, position: { ColSpan: 2, RowSpan: 1, row: 6, col: 1, hidden: false},
        },
        {
            id: 4, position: { ColSpan: 1, RowSpan: 1, row: 1, col: 10, hidden: true },
        },
        {
            id: 5, position: { ColSpan: 2, RowSpan: 4, row: 1, col: 1, hidden: false },
        },
        {
            id: 6, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 5, hidden: true },
        },
        {
            id: 7, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
        }];

    const positions3Groups: Card[] = [
        {
            id: 1, position: { ColSpan: 2, RowSpan: 1, row: 5, col: 1, hidden: !isSecOpsPage},
        },
        {
            id: 2, position: { ColSpan: 2, RowSpan: 1, row: 5, col: 1, hidden: false},
        },
        {
            id: 3, position: { ColSpan: 2, RowSpan: 1, row: 6, col: 1, hidden: false},
        },
        {
            id: 4, position: { ColSpan: 1, RowSpan: 4, row: 1, col: 10, hidden: true },
        },
        {
            id: 5, position: { ColSpan: 2, RowSpan: 3, row: 1, col: 1, hidden: false },
        },
        {
            id: 6, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 5, hidden: true },
        },
        {
            id: 7, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
        }];

    const positions2Groups: Card[] = [
        {
            id: 1, position: { ColSpan: 2, RowSpan: 1, row: 5, col: 1, hidden: secOpsQtdGroups > 1 ? true: false},
        },
        {
            id: 2, position: { ColSpan: 2, RowSpan: 1, row: 5, col: 1, hidden:false},
        },
        {
            id: 3, position: { ColSpan: 2, RowSpan: 1, row: 6, col: 1, hidden: false},
        },
        {
            id: 4, position: { ColSpan: 1, RowSpan: 1, row: 1, col: 10, hidden: true },
        },
        {
            id: 5, position: { ColSpan: 2, RowSpan: 3, row: 1, col: 1, hidden: false },
        },
        {
            id: 6, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 5, hidden: true },
        },
        {
            id: 7, position: { ColSpan: 1, RowSpan: 3, row: 4, col: 2, hidden: true },
        }];

    function positionHome(cardId: number | undefined, qtdGroups: number): PositionCard {
        var Id = cardId as number;
        if (qtdGroups <= 2) {
            return positions2Groups.filter(c => c.id === Id)[0].position;
        }

        if (qtdGroups <= 3) {
            return positions3Groups.filter(c => c.id === Id)[0].position;
        }

        if (qtdGroups <= 6) {
            return positions5Groups.filter(c => c.id === Id)[0].position;
        }

        return {} as unknown as PositionCard;
    }

    useEffect(() => {
        setSlaChart(0);
        setLoading(true);
        setLoadingEventsBlock(true)
        setLoadingMostTarget(true)
        setLoadingCollectoresChart(true)

        if (user.ClientGroupSelected) {
            const endpointDetectionResponseService = new EndpointDetectionResponseService({});
            endpointDetectionResponseService.RequestEventsReportResume()
                .then(responseEventsReportResume => {
                    setResumeEventReport(responseEventsReportResume.data);
                    setEventsBlocked(responseEventsReportResume.data.devices)
                    setEventsBlockedCollectors(responseEventsReportResume.data.mostTargetsCollectores)
                    setProcessEvents(responseEventsReportResume.data.process)

                }).catch(error => {
                    console.error(error);

                }).finally(() => {
                    setLoadingEventsBlock(false)
                    setLoadingCollectoresChart(false)
                    setLoadingMostTarget(false)
                })
                setLoading(true);

            endpointDetectionResponseService
                .GetCards(moment().format('M'), moment().format('YYYY'))
                .then(response => {

                    if (response.data !== null) {
                        setSloChart(response.data.slaConclusao === 'NaN' ? 100 : ((response.data.slaConclusao.toFixed(1) / 100) * 100));
                        setSlaChart(response.data.slaAtendimento === 'NaN' ? 100 : ((response.data.slaAtendimento.toFixed(1) / 100) * 100));

                        // let maxChamadosChart = response.data.totalChamadosHC >= response.data.totalChamadosFHC ?
                        //     (response.data.totalChamadosHC + 10)
                        //     : (response.data.totalChamadosFHC + 10);

                        let chamadosChart: DoughnutChartValue = {
                            title: 'Chamados por horário',
                            labels: [],
                            values: []
                        }
                        let newChamadosChart: any = [];

                        if (response.data.totalChamadosHC > 0) {
                            chamadosChart.values.push(response.data.totalChamadosHC);
                            chamadosChart.labels.push('Comercial');
                            let comercial = {
                                value: response.data.totalChamadosHC,
                                name: "Comercial",
                                itemStyle: {
                                    color: '#5969cd'
                                }
                            }
                            newChamadosChart.push(comercial)
                        }

                        if (response.data.totalChamadosFHC > 0) {
                            chamadosChart.values.push(response.data.totalChamadosFHC);
                            chamadosChart.labels.push('Não comercial');
                            let notComercial = {
                                value: response.data.totalChamadosFHC,
                                name: "Não comercial",
                                itemStyle: {
                                    color: '#e273c5'
                                }
                            }
                            newChamadosChart.push(notComercial)
                        }

                        setChamadosChart(newChamadosChart);
                        setIsLoadingChamadosChart(false)

                        let totalChamadosChart: DoughnutChartValue = {
                            title: 'Chamados por situação',
                            labels: [],
                            values: []
                        }
                        let newTotalChamadosChart: any = []

                        if (response.data.totalChamadosAbertas > 0) {
                            totalChamadosChart.values.push(response.data.totalChamadosAbertas);
                            totalChamadosChart.labels.push('Abertos');
                            let open = {
                                value: response.data.totalChamadosAbertas,
                                name: 'Abertos',
                                itemStyle: {
                                    color: '#5969cd'
                                }
                            }
                            newTotalChamadosChart.push(open);
                        }

                        if (response.data.totalChamadosFechadas > 0) {
                            totalChamadosChart.values.push(response.data.totalChamadosFechadas);
                            totalChamadosChart.labels.push('Fechados');
                            let closed = {
                                value: response.data.totalChamadosFechadas,
                                name: 'Fechados',
                                itemStyle: {
                                    color: '#e273c5'
                                }
                            }
                            newTotalChamadosChart.push(closed);
                        }

                        if (response.data.totalChamadosCanceladas > 0) {
                            totalChamadosChart.values.push(response.data.totalChamadosCanceladas);
                            totalChamadosChart.labels.push('Cancelados');
                            let cancel = {
                                value: response.data.totalChamadosCanceladas,
                                name: 'Cancelados',
                                itemStyle: {
                                    color: '#804e3b'
                                }
                            }
                            newTotalChamadosChart.push(cancel);
                        }

                        setTotalChamadosChart(newTotalChamadosChart);
                        setIsLoadingTotalChamadosChart(false)

                        let clientName: string = user.ClientGroupSelectedDescription?.toString().toLowerCase() as string;
                        let _c = clientName.split(' ')
                        let capitalized = '';
                        _c.map((e: any) => {
                            capitalized = capitalized + e[0].toUpperCase() + e.substr(1) + ' '
                        })

                        let chamadosPorOrigemChart: DoughnutChartValue = {
                            title: 'Chamados por origem',
                            labels: [],
                            values: []
                        }

                        let newChamadosPorOrigem: any = []

                        if (response.data.totalChamadosAbertosPeloCliente > 0) {
                            chamadosPorOrigemChart.labels.push(capitalized)
                            chamadosPorOrigemChart.values.push(response.data.totalChamadosAbertosPeloCliente)
                            let openByClients = {
                                value: response.data.totalChamadosAbertosPeloCliente,
                                name: capitalized,
                                itemStyle: {
                                    color: '#5969cd'
                                }
                            }
                            newChamadosPorOrigem.push(openByClients)
                        }

                        if (response.data.totalChamadosAbertosPelaProcessor > 0) {
                            chamadosPorOrigemChart.labels.push('Processor')
                            chamadosPorOrigemChart.values.push(response.data.totalChamadosAbertosPelaProcessor)
                            let openByProc = {
                                value: response.data.totalChamadosAbertosPeloCliente,
                                name: 'Processor',
                                itemStyle: {
                                    color: '#e273c5'
                                }
                            }
                            newChamadosPorOrigem.push(openByProc)
                        }
                        setChamadosPorOrigemChart(newChamadosPorOrigem)
                    }
                })
                .catch(error => {
                    console.error(error);
                    setIsLoadingTotalChamadosChart(false)
                    setIsLoadingChamadosChart(false)
                })
                .finally(() => {
                    setLoading(false);
                    setIsLoadingTotalChamadosChart(false)
                    setIsLoadingChamadosChart(false)
                });
        }
    }, [ user.ClientGroupSelected, user.ClientGroupSelectedDescription, user.refreshFlag]);


    const cards: Card[] = [
        //*Ambiente Protegido
        {
            id: 1,
            bgColor:(isHome || isSecOpsPage) ? "#fff" :"var(--color-primary-purple)",
            hideHeader: false,
            type: 'Custom', 
            // cssClass: (isHome)? 'fix-card-content' : undefined,
            position: (isHome || isSecOpsPage)?  { ColSpan: 2, RowSpan: 1, row: 5, col: 7 } : { ColSpan: 2, RowSpan: 2, row: 1, col: 1 },
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div style={{color: (isHome || isSecOpsPage) ? '' : '#fff'}} className="lc-title ellipsis">Ambiente protegido</div>
                </div>
            ),
            customContentRender: () => {
                return (
                    <WorkEnvironment 
                        provider={1}
                        isHome={isHome} 
                        isSecops={isSecOpsPage}
                        setIot={setIotData} 
                        setServers={setServersData}
                        setWorkstations={setWorkstationsData}
                    />
                )
            }
        },
        //*Niveis de serviço
        {
            id: 4,
            type: 'Custom', 
            position: { ColSpan: 4, RowSpan: 1, row: 1, col: 9 },
            showFocusButton: true,
            title: () => {
                return (
                    <div className="lc-segment-title-row-resume">
                        <div className="lc-title ellipsis">Níveis de serviço</div>
                        <PPDropDown
                            options={slaOptions}
                            onChange={(e) => e.value === '' ? setSlaView('sla') : setSlaView(e.value)}
                            defaultValue={slaOptions.find(option => option.value === slaView)}
                            value={slaOptions.find(option => option.value === slaView)}
                        />
                    </div>
                )
            },
            customContentRender: (focused: boolean) => {
                if (focused) {
                    // setPeriodSelected(periodFocusSelection);
                    // const style = document.createElement("style");
                    // style.innerHTML = `.ppdropdown {display:none}`;
                    // document.head.appendChild(style);
                    return (
                        <div className="inner-grid" >
                            <div className="card-frame focused" style={{ gridArea: "1 / 1 / 3 / 7" }}>
                                <div className="lc-title ellipsis"></div>
                                <div className="card-content-body">
                                    <LcLoading loading={loading} >
                                        <GaugeChartCore className='focused' style={{ maxWidth: '100%', minWidth: '100%', minHeight: '100%', maxHeight: '200px' }} value={slaChart} title="SLA de atendimento"></GaugeChartCore>
                                    </LcLoading >
                                </div>
                            </div>

                            <div className="card-frame focused" style={{ gridArea: "1 / 7 / 3 / 13" }}>
                                <div className="lc-title ellipsis"></div>
                                <div className="card-content-body">
                                    <LcLoading loading={loading} >
                                        <GaugeChartCore className='focused' style={{ maxWidth: '100%', minWidth: '100%', minHeight: '100%', maxHeight: '200px' }} value={sloChart} title="SLO de conclusão"></GaugeChartCore>
                                    </LcLoading >
                                </div>
                            </div>

                            <div className="card-frame focused" style={{ gridArea: "3 / 1 / 5 / 5" }}>
                                <div className="lc-title ellipsis">Chamados por horário</div>
                                <div className="card-content-body">
                                    <LcLoading loading={isLoadingChamadosChart}>
                                        {
                                            chamadosChart ?
                                            
                                            // <ReactECharts style={{ maxWidth: '100%', minWidth: '100%', minHeight: '100%', maxHeight: '100%' }} 
                                            //     opts={{ renderer: 'svg', locale: 'PT-br' }}
                                            //     option={finOpsChartService.getDougnutChartWithLegend(chamadosChart)}
                                            // />
                                            <EventsGraph data={chamadosChart}/>
                                            : 
                                            <LcNoData/>
                                        }
                                    </LcLoading>
                                </div>
                            </div>
                            <div className="card-frame focused" style={{ gridArea: "3 / 5 / 5 / 9" }}>
                                <div className="lc-title ellipsis">Chamados por origem</div>
                                <div className="card-content-body">
                                    <LcLoading loading={isLoadingChamadosChart}>
                                        {
                                            chamadosPorOrigemChart ?
                                            // <ReactECharts style={{ maxWidth: '100%', minWidth: '100%', minHeight: '100%', maxHeight: '100%' }} 
                                            //     opts={{ renderer: 'svg', locale: 'PT-br' }}
                                            //     option={finOpsChartService.getDougnutChartWithLegend(chamadosPorOrigemChart)}
                                            // />
                                            <EventsGraph data={chamadosPorOrigemChart}/>
                                            :
                                            <LcNoData/>
                                        }
                                    </LcLoading>
                                </div>
                            </div>
                            <div className="card-frame focused" style={{ gridArea: "3 / 9 / 5 / 13" }}>
                                <div className="lc-title ellipsis">Chamados por situação</div>
                                <div className="card-content-body">
                                    <LcLoading loading={isLoadingTotalChamadosChart}>
                                        {
                                            totalChamadosChart ?
                                            // <ReactECharts
                                            //     option={finOpsChartService.getDougnutChartWithLegend(totalChamadosChart)}
                                            // />
                                            <EventsGraph data={totalChamadosChart}/>
                                            :
                                            <LcNoData/>
                                        }
                                        {/*<DoughnutChart data={totalChamadosChart} ></DoughnutChart> */}
                                    </LcLoading>
                                </div>
                            </div>
                        </div>
                    )
                }
                else {
                    // if( periodSelected.periods.length === 1) setPeriodSelected(periodSelection)
                    // setPeriodSelected(periodSelection)
                    // const style = document.createElement("style");
                    // style.innerHTML = `.ppdropdown {display:block}`;
                    // document.head.appendChild(style);
                    return (
                        <LcLoading loading={loading} >
                            {
                                slaView === 'sla' ?
                                    <GaugeChartCore style={{ maxWidth: '100%', minWidth: '50%', minHeight: '100%', maxHeight: '90px' }} value={slaChart} title="SLA de atendimento"/>
                                :
                                    <GaugeChartCore style={{ maxWidth: '100%', minWidth: '50%', minHeight: '100%', maxHeight: '90px' }} value={sloChart} title="SLO de conclusão"/>
                            }
                        </LcLoading >
                    )
                }
            }
        },
        //* Classificação de Eventos
        {
            id: 5,
            type: 'Custom',
            position: (isHome || isSecOpsPage)?{ ColSpan: 4, RowSpan: 5, row: 2, col: 1 }:  { ColSpan: 4, RowSpan: 5, row: 2, col: 5 },
            showFocusButton: false,
            hideHeader: (isHome || isSecOpsPage),
            ticketButtonLabel: "Ver eventos",            
            ticketButton: !(isHome || isSecOpsPage)? () => { return <PiArrowSquareOutLight onClick={() => { history.push("/EndpointDetectionResponse/Events") }} /> } : undefined,
            title: !(isHome || isSecOpsPage)? () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Classificação de eventos</div>
                </div>
            ) : undefined,
            customContentRender: (focused?: boolean) => {
                return (
                    <EventClassificationChart 
                        loadingEventsBlock={loadingEventsBlock}
                        resumeEventReport={resumeEventReport}
                        isHome={isHome}
                        isSecOpsPage={isSecOpsPage}
                        setChartRefEvents={setChartRefEvents} 
                        periodSelected={periodFilter}
                    />
                );
            }
        },
        //*Principais alvos
        {
            id: 6,
            type: 'Custom', position: { ColSpan: 4, RowSpan: 4, row: 3, col: 1 },
            showFocusButton: true,
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Principais alvos</div>
                    <PPDropDown
                            options={mostTargetsOpt}
                            onChange={(e) =>  setMostTargetSelected(e.value)}
                            defaultValue={mostTargetsOpt.find(option => option.value === mostTargetSelected)}
                            value={mostTargetsOpt.find(option => option.value === mostTargetSelected)}
                            width={'10vw'}
                        />
                </div>
            ),
            customContentRender: (focused?: boolean) => {
                setFilterEnable(focused === true);
                return (
                    <LcLoading loading={loadingMostTarget} >
                        {
                            resumeEventReport ?
                            <MostTargetTable 
                                rowsFiltered={eventFiltered} 
                                filtered={filtered}
                                setFilterWithPeriod={setRowsFilteredMostTarget} 
                                resumeEventReport={mostTargetSelected === 'user' ? resumeEventReport.devices : resumeEventReport.mostTargetsCollectores} 
                                mostTargetSelected={mostTargetSelected} 
                                period={periodFilter}
                            />
                            :
                            <LcNoData />
                        }
                    </LcLoading>
                )
            }
        },
        //* Dispositivos (Coletores)
        {
            id: 7,
            type: 'Custom', position: { ColSpan: 4, RowSpan: 5, row: 2, col: 9 },
            showFocusButton: false,
            ticketButtonLabel: "Ver coletores",
            ticketButton: !(isHome || isSecOpsPage)? () => { return <PiArrowSquareOutLight onClick={() => { history.push("/EndpointDetectionResponse/Collectors") }} /> } : undefined,
            title: () => {
                return (
                    <div className="lc-segment-title-row-resume">
                        <div className="lc-title ellipsis">Dispositivos (Coletores) </div>
                        <PPDropDown
                            options={collectorsOptions}
                            onChange={(e) => {
                                // if (chartDispositivosGeral.current) {
                                //     chartDispositivosGeral.current.getEchartsInstance().clear();
                                // }
                                e.value === '' ? setCollectorsView('geral') : setCollectorsView(e.value);
                            }}

                            defaultValue={collectorsOptions.find(option => option.value === collectorsView)}
                            value={collectorsOptions.find(option => option.value === collectorsView)}
                            width={!(isHome || isSecOpsPage)? '130px' : undefined}
                        />
                    </div>
                )
            },
            customContentRender: (focused?: boolean) => {
                return (
                    <CollectingDeviceChart 
                        periodSelected={periodFilter}
                        loading={loadingCollectoresChart}
                        collectorsView={collectorsView} 
                        isHome={isHome} 
                        resumeEventReport={resumeEventReport} 
                        setChartRefDivices={setChartRefDivices} 
                        chartDispositivosGeral={chartDispositivosGeral}
                        setSystemOperation={setSystemOperation}
                        />
                )
            }
        },

    ]

    const cardNotHome = [
        //* Relatórios
        {
            id: 8,
            type: 'Custom', position: { ColSpan: 4, RowSpan: 1, row: 1, col: 5 },
            showFocusButton: false,
            title: () => "Relatórios",
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={loadingEventsBlock} >
                        <div className="row" style={{justifyContent: 'space-around'}}>
                            <div className='row'>
                                <LcIconLink
                                    icon={<PiFilePdfLight size={32} />}
                                    tooltip='Gerar sumário executivo'
                                    onClick={exportToPdf}
                                    tooltipPosition='left'
                                    color='var(--status-red)'
                                    size='big'
                                    />
                                <p
                                    className='lc-title font-sm pointer' 
                                    style={{cursor: 'pointer'}} 
                                    onClick={exportToPdf}
                                    >Gerar sumário executivo
                                </p>
                            </div>
                            <div className='row'>
                                <LcIconLink
                                    icon={<PiFileCsvLight size={32}  />}
                                    tooltip='Gerar lista de coletores'
                                    onClick={getCollectorsCSV}
                                    tooltipPosition='left'
                                    color='var(--status-green)'
                                    size='big'
                                />
                                <p
                                    className='lc-title font-sm pointer'
                                    style={{cursor: 'pointer'}} 
                                    onAbort={getCollectorsCSV}
                                    > Gerar lista de coletores
                                </p>
                            </div>
                        </div>
                    </LcLoading>
                )
            } 
        },
        //* Eventos bloqueados
        {
            id: 9,
            type: 'Custom', position: { ColSpan: 2, RowSpan: 1, row: 1, col: 3 },
            showFocusButton: false,
            ticketButtonLabel: "Ver eventos",
            ticketButton: () => { return <PiArrowSquareOutLight onClick={() => { history.push("/EndpointDetectionResponse/Events") }} /> },
            title: () => "Eventos",
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={loadingEventsBlock}>
                        <IconValue
                            icon={<PiShieldWarningLight size={32} />}
                            value={totalEventsBlock}
                            color='var(--status-red)'
                        />
                    </LcLoading>
                )
            } 
        },
        {
            //*Liberações
            id: 10,
            type: 'Custom', position: { ColSpan: 2, RowSpan: 1, row: 2, col: 3 },
            showFocusButton: false,
            ticketButtonLabel: "Ver liberações",
            ticketButton: () => { return <PiArrowSquareOutLight onClick={() => { history.push("/EndpointDetectionResponse/Exceptions") }} /> },
            title: () => "Liberações",
            customContentRender: (focused?: boolean) => {
                return ( 
                    <LiberationCard 
                        periodSelected={periodFilter}
                    />
                )
            }
        },
    ]

    const processPeriodData = useCallback((days: number, devicesKey: string, collectorsKey: string) => {
        let initialValue = 0
        const eventsBlockPerDays = filterArrayPerDate(resumeEventReport.devices, days, devicesKey);
        const eventsBlockCollectorsPerDays = filterArrayPerDate(resumeEventReport.mostTargetsCollectores, days, devicesKey);
        const processPerDays = filterArrayPerDate(resumeEventReport.process, days, devicesKey);
        setProcessEvents(processPerDays as processEvent[])
        setResumeEventReport(resumeEventReport)
        setEventsBlocked(eventsBlockPerDays as devicesEvent[])
        setEventsBlockedCollectors(eventsBlockCollectorsPerDays as devicesEvent[])
        setTotalEventsBlock(eventsBlockPerDays.reduce((accumulator, currentValue) => accumulator + (currentValue as devicesEvent).count, initialValue));

    },[resumeEventReport]);

    useEffect(() => {

        let initialValue = 0

        if (resumeEventReport) {
            if (periodFilter === 'last7Days') {
                processPeriodData(7, 'devices', 'devices');
            } else if (periodFilter === 'last30Days') {
                processPeriodData(30, 'devices', 'devices');
            } else if (periodFilter === 'last90Days') {
                processPeriodData(90, 'devices', 'devices');
            } else {
                setTotalEventsBlock(resumeEventReport.devices.reduce((accumulator, currentValue) => accumulator + currentValue.count,
                initialValue,))
                setProcessEvents(resumeEventReport.process)
                setEventsBlocked(resumeEventReport.devices)
                setEventsBlockedCollectors(resumeEventReport.mostTargetsCollectores)
            }
        }
    },[periodFilter, resumeEventReport, processPeriodData])
    
    const getCollectorsCSV = () => {
        enqueueSnackbar(`Gerando arquivo`, {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        })

        if (user.ClientGroupSelected) {
            const endpointDetectionResponseService = new EndpointDetectionResponseService({});
            endpointDetectionResponseService.GetCollectorsCSV('', '', '')
            .then(response => {
                const csvRows = response.data.split("|EndLine|");

                const csvHeader = csvRows.shift();

                const statusMapping = {
                    "Running": "Online",
                    "Degraded": "Degradado",
                    "Disabled": "Desabilitado",
                    "Disconnected": "Desconectado",
                    "Pending Reboot": "Reinicialização pendente",
                    "Isolated": "Isolado",
                    "Expired": "Expirado",
                    "Migrated": "Migrado",
                    "Pending Migration": "Migração pendente"
                }

                const csvData = csvRows.map((csvRow) => {
                    const fields = csvRow.split("||");
                    return {
                        "Grupo": fields[0],
                        "Nome do dispositivo": fields[1],
                        "SO": fields[2],
                        "Último login": fields[3],
                        "Status": statusMapping[fields[4]],
                        "Atualizado há": fields[5],
                    }
                })

                const data = csvData;
                const fileName = 'Coletores';
                const exportType = 'csv';
                const withBOM = true;
                const fields = csvHeader.split("||");
                exportFromJSON({ data, fields, fileName, exportType, withBOM, delimiter: ';' });

                enqueueSnackbar(`Arquivo gerado com sucesso`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                })
            })
        }
    }
    const exportToPdf = () => {
        let periodDate = periodFilter === 'last7Days' ? 
        ` ${moment().add(-7, 'days').format('DD/MM/YYYY')}` : 
        periodFilter === 'last30Days' ? 
        `de ${moment().add(-30, 'days').format('DD/MM/YYYY')}` : 
        periodFilter === 'last90Days' ? 
        `de ${moment().add(-90, 'days').format('DD/MM/YYYY')}` : 
        'inicial';
        let topEvents : any[] = getTopEvents(processEvents)
        let topEventsUsers = calculateTopDevices(  mostTargetSelected === 'user' ? eventBlocked : eventBlockedCollectors)
        const iframe = document.createElement('iframe');
        let client = user.ClientGroupSelectedDescription as string;
        document.body.appendChild(iframe);
        const janela: any = iframe.contentWindow;
        janela.document.open();
        janela.document.write(`
        <!DOCTYPE html>
        <html>
            <head>
                <title>Sumário Executivo</title>
                <style>
                    @media print {
                        @page {
                            margin: 0;
                        }
                        *{
                            font-family: ubuntu;        
                        }
                        body {
                            margin: 0mm;
                            size: A4 portrait; 
                        }
                        header {
                            left: 0;
                            position: fixed;
                            top: 0;
                            text-align: left; 
                            margin-bottom: 100px;
                            color:#fff;
                            width: 100%;

                        }
                        footer {
                            margin-top: 50px;
                            left 0;
                            margin: 0;
                            position: fixed;
                            bottom: 0;
                            width: 100%;
                            height: 60px;
                            color: #fff; 
                            text-align: left; 
                            display:flex;
                            align-item: center;
                        }
                        #componente-pdf {
                            margin-left: 10px;
                            margin-top: 120px;
                            margin-bottom: 200px;
                            height: auto;
                        }
                        h2 {
                            margin-left:10px;
                        }
                        h3{
                            margin-left:15px;
                        }
                        .colored {
                            background-color: #d3d3d3;
                        }
                    }
                </style>
            </head>
            <body>
                <header>
                    <title>Sumário executivo LiveCloud xDR</title>
                    <img src=${imgHorizantalBar} style="position: absolute; width: 100%; height: 100px; z-index: -1;"/>
                    <div style="display:flex; justify-content: space-between; width: 100%; position:relative; z-index:1; ">
                        <div>
                            <h1 style="margin-top:20px; margin-left: 20px;  color:#fff; fontSize:18px; fontWeight:bold";>Sumário executivo LiveCloud xDR</h1>
                            <p style="margin-top:-20px; margin-left: 20px; color:#fff; fontSize:14px; fontWeight:600;">Dados coletados do cliente 
                                ${client.charAt(0).toUpperCase() + client.slice(1)} no período ${periodDate} à ${moment().format('DD/MM/YYYY')}</p>
                        </div>
                        <img style="margin-top:40px; margin-right:20px" src=${ImgLog} width="10%" height="15%"></img>
                    </div>
                </header>
                <div id="componente-pdf" style="width: 100%; height:auto "></div>
                <footer style="margin-top:5px; color:#fff; fontSize:14px; fontWeight:600; display: flex; justify-content: space-between;">
                    <img src=${imgHorizantalBarFooter} style="position: absolute; width: 1000px; height: 60px;  z-index: -1;"/>
                    <p style="margin-top:20px; margin-left: 20px; color:#fff; fontSize:14px; fontWeight:600; display: flex; z-index:1">
                        Relatório gerado em ${moment().format("DD/MM/YYYY")} às ${moment().format("HH:mm")} na plataforma&nbsp;
                        <a target="_blank" style="color:#fff; text-decoration:none; font-weight:bold" href="https://livecloud.processoronline.com.br/EndpointDetectionResponse/Resume">LiveCloud</a>
                        </p>
                    <img style="margin-top:20px; margin-right:20px" src=${ImgLog} width="10%" height="30%" ></img>
                </footer>
            </body>
        </html>`
        );
        janela.document.close()
        const root = createRoot(janela.document.getElementById('componente-pdf'))
            root.render(<
                    ReportResumePDF
                        eventImg={chartRefEvents}
                        dispositivosImg={chartRefDivices} 
                        totalEventsBlock={totalEventsBlock}
                        resumeReport={systemOperation} 
                        topEvents={topEvents}
                        topEventsUsers={topEventsUsers}
                        workstation={workstationsData}
                        IOT={iotData}
                        servers={serversData}
                        period={periodFilter}
                        mostTargetSelected={mostTargetSelected}
                    />)
        setTimeout(() => {
            janela.print();
        }, 500);
    };

    function getTopEvents(actions: processEvent[]) {
        const maxProcessByClassification: any = {};
    
        actions.forEach((action) => {
            const key = action.classification;
            if (!maxProcessByClassification[key] || maxProcessByClassification[key].count < action.count) {
                maxProcessByClassification[key] = { process: action.process, count: action.count, classification: action.classification };
            }
        });
    
        const maxProcessActions = Object.values(maxProcessByClassification);
    
        return maxProcessActions;
    }

    
    const filterArrayPerDate = (array: DataEntry[] , period: number, tag: string) => {
        let today = new Date();
        let daysAgoDate = new Date();
        daysAgoDate.setDate(today.getDate() - period);
        daysAgoDate.setHours(0, 0, 0, 0); // Define horas, minutos, segundos e milissegundos para 0
        // Filtra as datas dos últimos 7 dias
        let datasNosUltimosDias = array.filter(data => {
            if(tag === 'devices') {
                return 'lastSeen' in data && (data as devicesEvent).lastSeen && new Date((data as devicesEvent).lastSeen) >= daysAgoDate && new Date((data as devicesEvent).lastSeen) <= today;
            } else {
                return 'lastSeenTime' in data && (data as CollectoresModel).lastSeenTime && new Date((data as CollectoresModel).lastSeenTime) >= daysAgoDate && new Date((data as CollectoresModel).lastSeenTime) <= today;
            }
        });
        return datasNosUltimosDias
    }
    
    function calculateTopDevices(data: DeviceData[]): { classification: string, topDevices: { device: string, totalCount: number }[] }[] {
        const classifiedDevices: { [classification: string]: { [device: string]: number } } = {};
        data.forEach(entry => { 
            if (entry.device.trim() !== "") { // Verifica se o dispositivo não está vazio
                if (!classifiedDevices[entry.classification]) {
                    classifiedDevices[entry.classification] = {};
                }
                if (!classifiedDevices[entry.classification][entry.device]) {
                    classifiedDevices[entry.classification][entry.device] = 0;
                }
                classifiedDevices[entry.classification][entry.device] += entry.count;
            }
        });
    
        const topDevicesByClassification: { classification: string, topDevices: { device: string, totalCount: number }[] }[] = [];
        for (const classification in classifiedDevices) {
            const devicesArray = Object.entries(classifiedDevices[classification]).map(([device, totalCount]) => ({ device, totalCount }));
            const sortedDevices = devicesArray.sort((a, b) => b.totalCount - a.totalCount).slice(0, 5);
            topDevicesByClassification.push({ classification, topDevices: sortedDevices });
        } 
        return topDevicesByClassification;
    }
    const translations = {
        device: {
            label: "Dispositivo/Usuário",
            type: "text",
        },
    
    }

    const handleFilter = (dataFilteredParm: any) => {
        setEventsFiltered(dataFilteredParm);
        setFiltered(true);
    }
    
    const filterSystem = () => {
        return (
            <div className="right-sidepanel-filters">
                <FilterPanel
                    data={rowsFilteredMostTarget}
                    onFilteredData={(dataFilteredParm) => handleFilter(dataFilteredParm)}
                    translations={translations} 
                    clearFilters={() => {setEventsFiltered([]); setFiltered(false);}}
                    filter={Object.keys(translations).map(key => ({ 
                        label: translations[key].label,
                        name: key,
                        type: translations[key].type,
                        values: translations[key].values,
                    }))}                
                    />
            </div>
        );
    };

    return (<>
        {!(isHome || isSecOpsPage) ? 
        <Layout
            pageTitle="Resumo"
            periodSelection={periodSelection}
            functionsGeneric={filterEnable ? [{
                icon: <PiFunnelLight />,
                tooltip: 'Filtro',
                onClick: () => setShowFilterEnable(!showFilterEnable),
            }]: undefined}
            >
            <LCDashboard cards={[...cards, ...cardNotHome]} 
                grid={[5, 12]}
                rightSidePanel={{
                title: 'Filtros',
                content: () => filterSystem(),
                pinned: false,
                show: showFilterEnable
            }} />
        </Layout>
        :
            <LCDashboard cssClass={(secOpsQtdGroups === 1 ? true : false ) ? '' : 'EdrHome'} 
            isHomeType grid={isHome ? [2,2] : [4, 2]} cards={secOpsQtdGroups && secOpsQtdGroups > 0 ? cards.map((card, index) => {
                if (secOpsQtdGroups) {
                    var posi = positionHome(card.id, secOpsQtdGroups);
                    if (posi !== undefined) {
                        card.position = posi;
                    }
                }
                return card;
            }) : []} rightSidePanel={{
                title: '',
                content: () => '',
                pinned: false,
                show: false
            }} />
        }
    </>
    );
}
export default EndpointDetectionResponseResume;
