import React, { useCallback, useEffect, useState } from 'react';
import { PiDatabaseLight, PiMonitorLight, PiPackageLight } from 'react-icons/pi';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { useWorkEnvironmentData } from './Hook/UseWorkEnviromentData';

interface WorkEnvironmentProps {
    isHome: boolean
    isSecops: boolean
    setIot: (iot: any) => void
    setServers: (servers: any) => void
    setWorkstations: (servers: any) => void
    provider: number
}

export interface EnvironmentData {
    allocated: number
    inUse: number
    sip: number
}

export const WorkEnvironment: React.FC<WorkEnvironmentProps> = (
    {
        isHome, 
        isSecops,
        setIot, 
        setServers,
        setWorkstations,
        provider
    }) => {
    const { data, isLoading} = useWorkEnvironmentData(provider);
    useEffect(() => {
        setIot(data?.iotData);
        setServers(data?.serversData);
        setWorkstations(data?.workstationsData);
    },[data, setIot, setServers, setWorkstations])

    return (
        <LcLoading loading={isLoading} >
            <div className='divWorkStations'>
            {data?.serversData && data?.serversData.inUse >= 0 && data?.serversData.sip >= 0 ? (
                <IconValue
                    icon={<PiDatabaseLight size={isHome? 24 : 22}/>}
                    value={data?.serversData ? `${data?.serversData.inUse} / ${data?.serversData.sip}*` : `${0} de ${0}*`}
                    color={isHome || isSecops ?'var(--color-primary-purple-dark)' : '#fff'}
                    cssClass={isHome || isSecops ? 'workstations' : 'workstations colored'}
                    description={"Servers"} isHome={isHome}
                    />
                )
                :
                <LcNoData size="small" />
            }
            {data?.workstationsData && data?.workstationsData.inUse >= 0 && data?.workstationsData.sip >= 0 ?
                <IconValue
                icon={<PiMonitorLight size={isHome? 24 : 22} />}
                value={data?.workstationsData ? `${data?.workstationsData.inUse} / ${data?.workstationsData.sip}*` : `${0} de ${0}*`}
                color={isHome || isSecops ?'var(--color-primary-purple-dark)' : '#fff'}
                cssClass={isHome || isSecops ? 'workstations' : 'workstations colored'}
                description={"Workstations"} isHome={isHome}
                />
                :
                <LcNoData size="small" />
            }
        
            {data?.iotData &&
            <>
                {
                data?.iotData.inUse >= 0 && data?.iotData.sip >= 0 ?
                    <IconValue
                        cssClass={isHome || isSecops ? 'workstations' : 'workstations colored'}
                        icon={<PiPackageLight size={isHome? 24 : 22} /> }
                        value={data?.iotData ? `${data?.iotData.inUse} / ${data?.iotData.sip}*` : `${0} de ${0}*`}
                        color={isHome || isSecops ?'var(--color-primary-purple-dark)' : '#fff'}
                        description={"IoT"}
                        isHome={isHome}
                    />
                    :
                    <LcNoData size="small" />
                } 
            </>
            }
        </div>
        <p className={(isHome || isSecops) ? 'legendWorkStations' :'legendWorkStations colored'}>* Utilizado / contratado</p>
    </LcLoading>
    );
}
