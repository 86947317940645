import React, { useEffect, useState } from 'react';

import './index.css';
import { VendorService } from '../../../services/vendorService';
import VendorCredentialEdit from '../Edit';
import DialogConfirmForm from '../../../components/Layout/Dialog/DialogConfirmForm';
import { useSnackbar } from 'notistack';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";

//Redux
import { useSelector } from 'react-redux'
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { VendorCredential } from '../Model/VendorCredential';
import LcTable from '../../../components/Data/LcTable';

const VendorCredentialList: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [idsToDelete, setIdsToDelete] = React.useState([0]);
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 25, term: "", column: "", direction: "asc" });
    const [visible, setVisible] = useState<boolean>(false)
    const [providers, setProviders] = useState<number[]>([0])

    const handleDeleteClickOpen = () => {
        setOpen(true);
    };

    const handleDeleteClose = (deleteConfirm: boolean) => {
        if (deleteConfirm == true) {
            enqueueSnackbar(`A exclusão foi iniciada, aguarde um momento.`, {
                variant: 'info',
                preventDuplicate: true,
                persist: false
            })
            idsToDelete.map((iddel) => { Delete(iddel); });
            setIdsToDelete([]);
        }
        if (deleteConfirm == false) {
            setIdsToDelete([]);
        }
        setOpen(false);
    };

    const ServiceInstance = new VendorService(props);
    const emptyRecordMessage = {
        empty: true, record: ServiceInstance.emptyRecord
    };
    let [record, setRecord] = useState(emptyRecordMessage);

    const [listData, setListData] = useState<any[]>([]);
    const [filteredListData, setFilteredListData] = useState<any[]>([]);

    useEffect(() => {
        setRecord(emptyRecordMessage);
        RetrieveList();
    }, [props, user.refreshFlag, user.ClientGroupSelected]);

    useEffect(() => {

        var filtered = listData.filter((p: any) =>
            p.clienteDescription?.toLowerCase().indexOf(filter.term.toLowerCase()) > -1 ||
            p.cloudOSProviderDesc?.toLowerCase().indexOf(filter.term.toLowerCase()) > -1
        )
            .sort((a: any, b: any) => {
                if (filter.direction == 'asc')
                    return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

        let listOfProviders: any[] = [];
        filtered.map(credencial => {
            if (!listOfProviders.find(provider => provider == credencial.cloudOSProviderId)) {
                listOfProviders.push(credencial.cloudOSProviderId);
            }
        })
        setProviders(listOfProviders);
        setFilteredListData(filtered);
    }, [filter]);

    const columns = [
        { field: 'clienteDescription', headerName: 'Cliente', width: "30%"},
        { field: 'cloudOSProviderDesc', headerName: 'Provedor', width: "30%", flex: 1 },
        {
            field: 'vendorCredentialAccountTypeID', headerName: 'Tipo de Conta', width: "30%",
            renderCell: (row: any) => (
                ServiceInstance.getGraphPerfil(row.vendorCredentialAccountTypeID)
            ), flex: 1
        }
    ];

    let functions = {
        functionsList: [
            {
                id: "administracao_novo_button",
                title: 'Novo',
                tooltip: 'Novo',
                icon: 'ppi ppi-plus',
                func: () => {
                    ADD()
                }
            }
        ]
    };

    const functionsRow = [
        {
            label: 'Editar',
            func: (row: any) => {
                selecionadoEditar(row.id)
                setVisible(!visible)
            }
        },
        {
            label: 'Excluir',
            func: (row: any) => {
                DeleteButtonCallRow(row.id)
            }
        }
    ]

    function DeleteButtonCallRow(ids: any) {
        let array: any[] = []
        array.push(ids)
        setIdsToDelete(array);
        handleDeleteClickOpen();
        record.empty = true;
        RetrieveList();
    }
    function DeleteButtonCall(ids: any) {
        setVisible(!visible)
        let array: any[] = []
        array.push(ids)
        setIdsToDelete(array);
        handleDeleteClickOpen();
        record.empty = true;
        RetrieveList();
    }

    function RetrieveList() {
        setLoading(true);

        setListData([]);
        setFilteredListData([]);

        ServiceInstance.RetrieveList()
            .then(response => {
                setRecord(emptyRecordMessage);

                let _data = response.data.dataCollection.map(d => { return { ...d, cloudOSProviderDesc: ServiceInstance.getProviderName(d.cloudOSProviderId) } })

                setListData(_data);
                setFilteredListData(_data)
            })
            .catch(console.error)
            .finally(() => { setLoading(false); });
    };

    async function Delete(id: number) {
        ServiceInstance.Delete(id).then(items => {
            enqueueSnackbar(`Assinatura deletada com sucesso! Atualizando dados..`, {
                variant: 'success',
                preventDuplicate: true,
                persist: false,
            });
            RetrieveList();
        });
    };

    function ADD() {
        let addRecord = { ...emptyRecordMessage };
        addRecord.empty = false;
        addRecord.record = { ...ServiceInstance.emptyRecord };
        setVisible(!visible);
        setRecord(addRecord);
    };

    function EDIT(item: any) {
        let addRecord = { ...emptyRecordMessage };
        addRecord.empty = false;
        addRecord.record = item;
        setRecord({ ...addRecord });
    };

    function selecionadoEditar(childData: any) {
        if (childData != null) {
            var item = listData.filter(x => x.id == childData)[0];
            EDIT(item);
        }
        else {
            setRecord(emptyRecordMessage)
        }
    }

    const filterAdvanced = {
        fields: [{ label: 'Pesquisar', name: 'search', type: 'text' },],
        onChange: (_filter: any, size: number) => setFilter({ ...filter, size: size, term: _filter[0].value.toLowerCase() })
    }

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    return (
        <>
            <LcInfiniteTable
                loading={loading}
                columns={columns}
                functionsRow={functionsRow}
                functionGeneric={functions.functionsList}
                rows={filteredListData}
                filter={filterAdvanced}
                size={filteredListData.length}
                loadMore={() => { }}
                onSortChange={onSortChange}
            />

            <VendorCredentialEdit
                visible={visible}
                parentSelectionCallback={() => {
                    record.empty = true;
                    setVisible(!visible)
                    RetrieveList();
                }}
                parentDeleteActionCallback={DeleteButtonCall}
                record={record.record}
                parentRetrieveListCallback={RetrieveList}
                providers={providers}
            ></VendorCredentialEdit>

            <DialogConfirmForm onCloseEvent={handleDeleteClose}
                open={open}
                confirmMessage='Confirmar'
                abortMessage='Cancelar'
                title='Exclusão de assinatura'
                content='Deseja realmente deletar esta assinatura? Esta ação não pode ser desfeita.'>
            </DialogConfirmForm>
        </>
    );
}

export default VendorCredentialList;