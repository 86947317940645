import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';

import { FileRepositoryService } from '../../services/fileRepository/fileRepository'
import LcLoading from '../../components/Generic/LcLoading';

import { FileField, SelectField } from '../../components/Form/Input';
import SideModal from '../../components/Layout/SideModal/SideModal';
import { useSnackbar } from 'notistack';
import HeyHoNewRequest, { FormDataHeyho } from '../../components/Generic/HeyHoNewRequest';
import LcNoData from '../../components/Generic/LcNoData';
import { PiDownloadSimpleThin, PiUploadLight } from 'react-icons/pi';
import LcInfiniteTable from '../../components/Data/LcInfiniteTable';
import LCDashboard from '../../components/Data/Dashboard/LCDashboard';
import Tooltip from '@material-ui/core/Tooltip';
import FilterPanel from '../../components/Data/FilterPanel';
import { setFilterActive } from '../../store/reducers/filterReducer';
import { useQuery } from 'react-query';

export interface FileModel {
    children: FileModel[];
    clientAccessHeyHo: boolean;
    key: string;
    label: string;
    lastModified: string | null;
    size: number | null;
    storageClass: string | null;
    urlDownload?: string;
}

const flatMapDirectoryStructure = (data: FileModel[], parentPath: string = ''): { [key: string]: any[] } => {
    let rows: { [key: string]: any[] } = {};
    data.forEach((item) => {
        if (!item.label) return;

        let currentPath = parentPath;

        if (item.urlDownload) {
            const url = new URL(item.urlDownload);
            const pathParts = url.pathname.split('/').filter(part => part.length > 0);
            const rootIndex = pathParts.indexOf('prd') !== -1 ? pathParts.indexOf('prd') + 1 : 0;
            currentPath = pathParts.slice(rootIndex, -1).join(' > ');
        } else if (item.key.includes('/')) {
            const pathParts = item.key.split('/').filter(part => part.length > 0);
            currentPath = pathParts.join(' > ');
        }
        currentPath = currentPath || parentPath || 'Outros';
        if (item.children && item.children.length > 0) {
            const childRows = flatMapDirectoryStructure(item.children, currentPath);

            Object.keys(childRows).forEach(key => {
                if (!rows[key]) rows[key] = [];
                rows[key] = [...rows[key], ...childRows[key]];
            });
        } else {
            if (!rows[currentPath]) rows[currentPath] = [];
            rows[currentPath].push(item);
        }
    });
    return rows;
}

const FileRepository: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const service = new FileRepositoryService(props);
    const dispatch = useDispatch();
    const [notFoundMessage, setNotFoundMessage] = useState<string>('');
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
    const [records, setRecords] = useState<FileModel[]>([]);
    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [allFiles, setAllFiles] = useState<FileModel[]>([]);
    const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
    const [showFormFile, setShowFormFile] = useState<boolean>(false);
    const [file, setFile] = useState<any>();
    const { enqueueSnackbar } = useSnackbar();
    const [refresh, setRefresh] = useState<boolean>(false)
    const [openModalNewRequest, setOpenModalNewRequest] = useState<boolean>(false)
    const [record, setRecord] = useState<FileModel>();
    const [groups, setGroups] = useState<any>();
    const [filteredGroups, setFilteredGroups] = useState<any>();
    const [rows, setRows] = useState<any>();
    const [filteredRows, setFilteredRows] = useState<any>();
    const [count, setCount] = useState<number>(0);
    const [formData, setFormData] = useState<FormDataHeyho>({
        title: '',
        description: '',
        gtmType: 0
    });

    const flatAllFiles = (rows): FileModel[] => Object.values(rows).flat() as FileModel[];
    const fetchFileRepository = async () => {
        const service = new FileRepositoryService({});
        const result = await service.GetListFileRepository();
        if (result.status !== 200 || result.data.fileTree === null) {
            throw new Error('Não foram encontrados arquivos.');
        }
        return result.data.fileTree.children;
    };

    const {isLoading} = useQuery(
        ['fileRepository', user.ClientGroupSelected, user.refreshFlag, refresh],
        fetchFileRepository,
        {
            enabled: !!user.ClientGroupSelected,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            onSuccess: (data: FileModel[]) => {
                let _rows = flatMapDirectoryStructure(data);
                let _groups = Object.keys(_rows).sort((a, b) => {
                    return (a === 'Outros' && 1)
                        || (b === 'Outros' && -1)
                        || a.localeCompare(b);
                });
                let _flat = flatAllFiles(_rows);
                setRecords(data);
                setRows([_rows]);
                setFilteredRows([_rows]);
                setGroups(_groups);
                setFilteredGroups(_groups);
                setAllFiles(_flat);
                setCount(_flat.length);
            },
            onError: (error) => {
                console.error(error)
                setNotFoundMessage('Não foram encontrados arquivos');
            }
        }
    );

    function handleUpload() {
        setUploadModalVisible(!uploadModalVisible);
        setShowFormFile(!showFormFile)
    };

    const onChangeFileField = (fieldOpt: any) => {
        setFile(fieldOpt)
    }

    const resetData = () => {
        setUploadModalVisible(!uploadModalVisible);
        setShowFormFile(!showFormFile)
    };

    async function convertAttachment(anexo: any, toBinary: (file: Blob) => Promise<unknown>) {
        for (var i = 0; i < anexo.length; i++) {

            var fileBinary: any = {};
            var reponseBinary = await toBinary(anexo[i]);
            fileBinary.baseBinary = reponseBinary;
            fileBinary.fileName = anexo[i].name;
            fileBinary.contentType = anexo[i].type;
            if (anexo[i].anexosBinary == null) {
                anexo[i].anexosBinary = [];
            }
            anexo[i].anexosBinary = (fileBinary);
        }
    };

    const toBinary = (file: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const sendFile = async (e: any) => {
        e.preventDefault()
        if (!file) return
        resetData()
        enqueueSnackbar(`O documento está sendo preparado para envio. Aguarde`, {
            variant: 'warning',
            preventDuplicate: true,
            persist: false,
        });
        setIsLoadingData(true);
        await convertAttachment(file, toBinary)

        const formFile = new FormData()

        formFile.append("formFile", file[0])

        await service.PostFileRepository(formFile, record)
            .then(response => {
                enqueueSnackbar(`O documento foi enviado com sucesso.`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                });
                setRefresh(!refresh);
            })
            .catch((error) => {
                console.error(error)
                enqueueSnackbar(`Ocorreu um erro ao enviar o arquivo`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                });
            }).finally(() => {
                setIsLoadingData(false);
                setFile([])
            })

    };

    const handleChange = (event: any) => {
        let _record = event.value;
        setRecord(_record);
    };

    const handleDownloadFile = async (file: FileModel) => {
        if (file.storageClass === "DEEP_ARCHIVE") {
            enqueueSnackbar(`Para baixar o arquivo ${file.label} é preciso abrir um chamado.`, {
                variant: 'info',
                preventDuplicate: true,
                persist: false,
            });
            setOpenModalNewRequest(true);
            setFormData({
                title: `Solicitação de download de Arquivo: ${file.label}`,
                description: `Solicito o download do Arquivo: ${file.label}.\nlocalizado na pasta ${file.key} `,
                gtmType: 0
            })

        } else {
            window.open(file.urlDownload, '_blank')
            //try {
            //    enqueueSnackbar(`Preparando para baixar o arquivo ${file.label}.`, {
            //        variant: 'info',
            //        preventDuplicate: true,
            //        persist: false,
            //    });
            //    const response = await service.DownloadFile(file.key)
            //    enqueueSnackbar(`Baixando o arquivo`, {
            //        variant: 'info',
            //        preventDuplicate: true,
            //        persist: false,
            //    });
            //    const url = window.URL.createObjectURL(response);
            //    const a = document.createElement('a');
            //    a.href = url;
            //    a.download = file.label;
            //    document.body.appendChild(a);
            //    a.click();
            //    window.URL.revokeObjectURL(url);
            //    document.body.removeChild(a);
            //} catch (error) {
            //    console.error('Erro ao fazer o download do arquivo:', error);
            //    enqueueSnackbar(`Erro ao baixar o arquivo ${file.label}.`, {
            //        variant: 'error',
            //        preventDuplicate: true,
            //        persist: false,
            //    });
            //}
        }
    }

    const onSort = (sortData) => {
        const { sort, size } = sortData;

        let _rows = { ...rows[0] };
        Object.keys(_rows).forEach(group => {
            _rows[group] = _rows[group].sort((a: any, b: any) => {
                if (sort.direction === 'asc') return (a[sort.column] > b[sort.column]) ? 1 : -1;
                else return (a[sort.column] > b[sort.column]) ? -1 : 1;
            });
        });
        setRows([_rows]);
    }

    const columns = [
        {
            field: 'label', headerName: 'Nome do arquivo', width: "50%"
        },
        {
            field: 'lastModified', headerName: 'Data', width: "20%",
            renderCell: (row: any) => {
                const date = new Date(row.lastModified)
                return <>{date.toLocaleString()}</>
            }
        },
        {
            field: 'size', headerName: 'Tamanho do arquivo', width: "20%",
            renderCell: (row: any) => `${(row.size / 1024).toFixed(0)} Kb`
        },
        {
            field: 'urlDownload', headerName: 'Download', width: "20%", sort: false,
            renderCell: (row: any) =>
                <Tooltip arrow title={`Download ${row.label}`} placement='right-end'>
                    <span style={{ cursor: 'pointer' }} onClick={() => handleDownloadFile(row)}>
                        <PiDownloadSimpleThin size={24} />
                    </span>
                </Tooltip>
        }
    ];

    const card = [
        {
            id: 1,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 13, RowSpan: 4, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcInfiniteTable
                        loading={isLoading || isLoadingData}
                        columns={columns}
                        rows={filteredRows}
                        groupName={filteredGroups}
                        collapsedGroups={true}
                        size={count}
                        notLoadMore={true}
                        onSortChange={onSort}
                        disableFilterModal
                        density={"high"}
                        hidePagination
                        noDataMessage='Não há dados para serem exibidos no momento.'
                    />
                )
            }
        }
    ]

    const translations = {
        label: {
            label: "Nome do arquivo",
            type: "text",
        },
        lastModified: {
            id:'date_field',
            label: "Data",
            type: "spanDateTime",
            value: "teste"
            
        }
    }

    const handleFilteredData = (filteredData) => {
        let _rows = { ...rows[0] }
        let _newCount = 0;

        Object.keys(_rows).forEach(group => {
            _rows[group] = _rows[group].filter(file => filteredData.includes(file))
            _newCount += _rows[group].length;
        })

        Object.keys(_rows).forEach(group => {
            if (_rows[group].length === 0)
                delete _rows[group]
        })

        setFilteredGroups(Object.keys(_rows).sort((a, b) => {
            return (a === 'Outros' && 1)
                || (b === 'Outros' && -1)
                || a.localeCompare(b);
        }));
        setFilteredRows([_rows]);
        setCount(_newCount);
        let _filterChanged = JSON.stringify(_rows) !== JSON.stringify({ ...rows[0] });
        dispatch(setFilterActive(_filterChanged))
    }

    const handleCleanFilter = () => {
        setFilteredRows(rows)
        setFilteredGroups(groups)
        setCount(allFiles.length)
        dispatch(setFilterActive(false))
    }

    const filterSystem = () => {
        return (
            <div className="right-sidepanel-filters">
                <FilterPanel
                    filter={Object.keys(translations).map(key => ({
                        label: translations[key].label,
                        name: key,
                        type: translations[key].type,
                        values: translations[key].values,
                    }))}
                    data={allFiles}
                    onFilteredData={handleFilteredData}
                    translations={translations}
                    clearFilters={handleCleanFilter}
                />
            </div>
        )
    }

    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    return (
        <Layout
            pageTitle="Repositório de arquivos"
            functionsGeneric={[{
                icon: <PiUploadLight id={'repositoriodearquivos_upload_button'}/>,
                tooltip: 'Upload de arquivos',
                onClick: () => handleUpload()
            }]}
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: count
            }}
        >
            <LcLoading label="Carregando arquivos" loading={isLoading}>
                {/* <LcIconLink icon="lci lci-download lci-rotate-180" tooltip={"Upload de arquivos"} tooltipPosition="left" onClick={() => handleUpload()} /> */}
                <SideModal header="Upload de arquivos" visible={uploadModalVisible}
                    onClose={() => { setUploadModalVisible(!uploadModalVisible); setShowFormFile(!showFormFile) }}>
                    {showFormFile &&
                        <form onSubmit={sendFile} method='post'>
                            <SelectField name="folderName" label="Selecione a pasta"
                                onChange={handleChange}
                                options={
                                    [{ name: 'HeyHo', value: "0" },
                                    { name: 'HeyHo/Atas', value: "1" },
                                    { name: 'HeyHo/Documentos', value: "2" },
                                    { name: 'SaaSUser', value: "3" },
                                    { name: 'Documentos', value: "4" },
                                    { name: 'Status Report', value: "5" },
                                    ]} required />

                            <span> O nome original do arquivo será mantido após o upload </span>
                            <FileField name="formFile" type="file" onChange={onChangeFileField} />

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                                <button type="submit" id="d01fcc74-5bc1-4075-b8af-b89d782a83fb" className="lc-button bg-primary">
                                    Enviar
                                </button>
                            </div>
                        </form>
                    }
                </SideModal>
                {
                    records.length > 0 ?
                        <LCDashboard
                            cards={card}
                            rightSidePanel={{
                                title: 'Filtros',
                                pinned: false,
                                show: filterVisible,
                                content: filterSystem,
                            }}
                        />
                        :
                        <LcNoData size='large' label={notFoundMessage} />
                }
            </LcLoading>
            <HeyHoNewRequest openModal={openModalNewRequest} formData={formData} onClose={setOpenModalNewRequest} />
        </Layout >
    )
}

export default FileRepository;