import React, { useEffect, useState } from 'react';
import LcTable from '../../../../components/Data/LcTable';
import LcLoading from '../../../../components/Generic/LcLoading';
import { NpsService, Question, AnswerView } from '../../../../services/npsService';

//Redux
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import { PiUploadLight } from 'react-icons/pi';
import LcIconLink from '../../../../components/Generic/LcIconLink';
import { useSnackbar } from 'notistack';
import exportFromJSON from 'export-from-json';

interface Props {
    question: Question,
    onClose: Function
}

const Interactions: React.FC<Props> = (props) => {

    const nps = new NpsService(props);
    const user = useSelector<RootState, UserState>(state => state.user);

    const [loading, setLoading] = useState<boolean>(false);
    const [interactions, setInteractions] = useState<AnswerView[]>([]);
    const snackbar = useSnackbar();

    const loadAnswers = () => {
        setLoading(true);
        nps.GetAnswersByQuestion(props.question.id)
            .then(response => {
                if (response.data) {
                    let _answers = response.data
                        .map(question => ({ ...question, note: question.note === null ? -1 : question.note }))
                        .sort((a: any, b: any) => a.when > b.when ? -1 : 1);
                    setInteractions(_answers);
                }
            })
            .catch(console.warn)
            .then(() => {
                setLoading(false);
            })
    }

    useEffect(loadAnswers, []);

    const columns = [
        { field: 'user', headerName: 'Usuário', width: '25%' },
        { field: 'clientGroup', headerName: 'Grupo', width: '20%' },
        {
            field: 'when', headerName: 'Data e Hora', width: '20%',
            renderCell: (row: any) => {
                const date = new Date(row.when);
                const offset = date.getTimezoneOffset();
                date.setMinutes(date.getMinutes() - offset);

                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                const seconds = date.getSeconds().toString().padStart(2, '0');

                return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
            }
        },
        { field: 'note', headerName: 'Nota', width: '10%', renderCell: (row: any) => row.note >= 0 ? row.note : 'Adiada' },
        { field: 'reason', headerName: 'Motivo', width: '25%' },
    ]

    const marks = [{ label: 'Adiadas', value: -1 }, { label: props.question.minimum, value: props.question.minimum }, { label: props.question.maximum, value: props.question.maximum }]

    const filterFields = [
        { label: 'Data entre', name: 'when', type: 'spanDateTime' },
        {
            label: 'Grupo', name: 'clientGroup', type: 'select', multiselect: true,
            options: [...new Set(interactions.map(i => i.clientGroup))].map(i => ({ name: i, value: i, selected: true }))
        },
        { label: 'Usuário', name: 'user', type: 'text' },
        { label: 'Notas', name: 'note', type: 'interval', max: props.question.maximum, min: -1, marks: marks },
    ]

    const exportToCSV = () => {
        snackbar.enqueueSnackbar(`Gerando arquivo com todas as interações da pergunta...`, {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        });

        const csvData = interactions.map((csvRow) => {
            return {
                'ID': csvRow.id,
                'Pergunta': props.question.text,
                'Usuário': csvRow.user,
                'Cliente': csvRow.clientGroup,
                'Data e hora': csvRow.when,
                'Nota': csvRow.note,
                'Motivo': csvRow.reason ?? "(usuário não respondeu)"
            }
        })
        const fields = ['ID', 'Pergunta', 'Usuário', 'Cliente', 'Data e hora', 'Nota', 'Motivo']
        const data = csvData;
        const fileName = 'InteracoesNPS';
        const exportType = 'csv';
        const withBOM = true;

        exportFromJSON({ data, fields, fileName, exportType, withBOM, delimiter: ';' })

        snackbar.enqueueSnackbar(`Completada geração de excel de interações!`, {
            variant: 'success',
            preventDuplicate: true,
            persist: false,
        });
    }

    return (
        <div>
            <LcLoading loading={loading} label="Carregando interações...">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', marginBottom: '1rem' }}>
                        <h3>{props.question.name}</h3>
                        <div style={{ flexBasis: '100%', height: 0, marginTop: '5px' }}></div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '80px' }}>
                            <span className="text-danger">
                                <i className="lci lci-chevron-down-alt" /> {props.question.minimum}
                            </span>
                            <span className="text-success">
                                <i className="lci lci-chevron-up-alt" />  {props.question.maximum}
                            </span>
                        </div>
                        <div style={{ flexBasis: '100%', height: 0, marginTop: '10px' }}></div>
                        <h4>Pergunta: <em>{props.question.text}</em></h4>
                    </div>
                    <div style={{ marginTop: 'auto' }}>
                        <LcIconLink
                            size='big'
                            icon={<PiUploadLight size={32} />}
                            tooltip="Exportar interações em CSV"
                            onClick={exportToCSV}
                        />
                    </div>
                </div>

                <LcTable data={interactions} columns={columns} filterFields={filterFields} height="calc(100vh - 275px)" />

            </LcLoading>
        </div>
    );
}

export default Interactions;