
import React, { useEffect} from 'react';
import { useQuery } from 'react-query';
import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import LcLoading from '../../../components/Generic/LcLoading';
import EventsGraph from '../resumeEvent/EventsGraph';
import { groupBy } from '../resume';
import { translateAttackStatus } from './MaliciousURLCard';
import moment from 'moment';
import { translateConfidenceLevel, translateAction } from './MaliciousFileCard';
import { Tooltip } from '@material-ui/core';
import { translateAttackSeverity } from './MostTargeTableCheckpoint';
import { RecordLogs } from '../../../services/endpointDetectionResponse/XDRCheckpointModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';

export const translationsAttackStatus = {
    resource: {
        label: "Recuso acessado",
        type: "text",
    },
    src_user_name: {
        label: "Usuário",
        type: "text",
    },
    attack_status: {
        label: "Status",
        type: "multiSelect",
        values: {
            'Dormant':'Inativo',
            'Active':'Ativo',
            'Blocked':'Bloqueado',
            'Cleaned':'Limpo',
            'Unknown':'Desconhecido',
        }
    },
    severity: {
        label: "Severidade",
        type: "multiSelect",
        values: {
            'Critical': 'Critico',
            'High': 'Alto',
            'Medium': 'Médio',
            'Low': 'Baixo',
            'Informational': 'Informativo'
        }
    },
}

export const collumnAttcakStatus = [
    { field: 'resource', headerName: 'Recurso acessado', width: "20%", renderCell: (row: any) => {
        return <Tooltip title={row.resource} arrow >
            <div className='overflowEllipsis' style={{width: '20vw'}}>
                {row.resource[0]}
            </div>
        </Tooltip>
    }},
    { field: 'severity', headerName: 'Severidade', width: "15%", renderCell: (row: any) => {
        return      <div className='severityLabelDetails' style={{ padding: '0px 8px', backgroundColor: StatusColor(row.severity) }}>
        {translateAttackSeverity(row.severity)}
    </div>
    }},
    // { field: 'file_name', headerName: 'Nome do Arquivo', width: "20%" },
    { field: 'src', headerName: 'Origem', width: "10%" },
    { field: 'user_name', headerName: 'Usuário', width: "20%", renderCell:(row: any) => row.src_user_name[0]},
    { field: 'attack_status', headerName: 'Status', width: "8%",  renderCell: (row: any) => {
        return <div>
            {translateAttackStatus(row.attack_status)}
        </div>
    }},
    { field: 'confidence_level', headerName: 'Confiança', width: "8%", renderCell: (row: any) => translateConfidenceLevel(row.confidence_level) },
    { field: 'action', headerName: 'Ação', width: "8%", renderCell: (row: any) => translateAction(row.action) },
    { field: 'time', headerName: 'Data', width: "12%", renderCell: (row: any) =>  moment(row.time).format('DD/MM/YYYY HH:mm:ss') },
]

export const StatusColor = (status: string) => {
    const classficiation = {
        'Critical':'#DC291E',
        'High':'#ffa400',
        'Medium':'#F6DE00',
        'Low':'#3EBB3F',
        'Informational': '#00DBFF'
    }
    return classficiation[status] || null
}

const getClassificationChart = (attacksReport: RecordLogs[]) :any => {
    const severitiesOrder = ['Critical', 'High', 'Medium', 'Low', 'Informational'];
    attacksReport.sort((a, b) => severitiesOrder.indexOf(a.severity) - severitiesOrder.indexOf(b.severity));
    const ress = groupBy(attacksReport, "severity");
    let eventsx = Object.keys(ress).map((t, index) => {
        const filteredEvents = attacksReport.filter(b => b.severity === t).sort((a, b) => severitiesOrder.indexOf(a.severity) - severitiesOrder.indexOf(b.severity));
        const value = filteredEvents.length > 0
            ? filteredEvents.reduce((sum) => sum + 1, 0)
            : 0;
        return {
            name: translateAttackSeverity(t),
            value,
            itemStyle: {
                color: StatusColor(t)
            }
        };
    });
    return eventsx
}

export const AttacksChart: React.FC = () => {
    const dispatch = useDispatch();
    const period = useSelector((state: RootState) => state.xdrCheckpointResume.periodFilter);
    const attackChartData = useSelector((state: RootState) => state.xdrCheckpointResume.attacksChartData);
    const checkpointService = new EndpointDetectionResponseService({});
    const {isLoading} = useQuery({
        queryKey: ['AttacksChart', period],
        queryFn: () => checkpointService.GetStatusAttack(period),
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onSuccess: (response) => {
            dispatch({ type: 'SET_LOADING', payload: { key: 'loadingAttacksChartData', value: isLoading }});
            dispatch({ type: 'SET_ATTACKS_CHART_DATA', payload: response });
            
        },
        onError: (error) => {
            console.error('Error fetching  AttacksChart data:', error);
        }
    });

    useEffect(() => {
        dispatch({ type: 'SET_LOADING', payload: { key: 'loadingAttacksChartData', value: (isLoading) }});
    },[isLoading, dispatch])

    return (
        <LcLoading loading={isLoading}>
        {
            <EventsGraph
                data={getClassificationChart(attackChartData.record)}
                legendOrientation={"vertical"}
                disabeleSortWithValue={true}
            />
        }
    </LcLoading>
    );
}
