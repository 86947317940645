import React from "react";
import LcIconLink from "../../../components/Generic/LcIconLink";
import LcTooltip from "../../../components/Generic/LcTooltip";
import { getFileIcon, getType } from "../HHUtilities";
import { HeyHoService } from "../../../services/Heyho/heyHoService";

interface RenderAttachmentProps {
    attachment: any;
    setData: (data: string) => void;
    setOpenAttachment(openAttachment: boolean): void;
    setAttachment(attachment: any): void;
    setSelectedRow(ropw: any): void
}


export const RenderAttachment: React.FC<RenderAttachmentProps> = ({
    attachment,
    setData,
    setOpenAttachment,
    setAttachment ,
    setSelectedRow
}) => {
    
    const isViewable = getType(attachment) !== "Sem visualização";
    const downloadAttachment = async (attachment) => {
        const ServiceHeyho = new HeyHoService({})
        await ServiceHeyho.downloadAttachmentById(attachment)
            .finally(() => {
            })
    }
    const handleData = (attachment: any) =>{
        setData(attachment.image);
        setAttachment(attachment);
        setOpenAttachment(true);
    }

    return (  
        <div                
            onMouseEnter={() => setSelectedRow(attachment)}
            onMouseLeave={() => setSelectedRow([])}
            style={{margin: '10px'}}
            
        >
            <>{isViewable?(
                <LcTooltip
                    content={"Visualizar"}
                    trigger={"hover"}
                    position={"left"}
                >
                    <div className={"attachment-box"}>
                        <div className={"attachment-icon-format"}>
                            {getFileIcon(attachment)}
                        </div>
                        <div 
                        className={"attachment-info-row"} 
                        onClick={() => handleData(attachment)}
                        style={{ cursor: isViewable ? "pointer" : "default" }}
                        >
                            <span className={"attachment-name"}>{attachment?.originalName}</span>
                            
                        </div>
                        <div className={"attachment-actions"}>
                            <LcIconLink
                                icon={"ppi ppi-download"}
                                tooltip={"Download"}
                                size={"small"}
                                color={"#898DA6"}
                                onClick={() => downloadAttachment(attachment)}
                            />
                        </div>
                    </div>
                </LcTooltip>
                ):(
                    <div className={"attachment-box"}>
                        <div className={"attachment-icon-format"}>
                            {getFileIcon(attachment)}
                        </div>
                        <div className={"attachment-info-row"}>
                            <span className={"attachment-name"}>{attachment?.originalName}</span>
                            <span className={"spanSize"}>{attachment?.size}</span>
                        </div>
                        <div className={"attachment-actions"}>
                            <LcIconLink
                                icon={"ppi ppi-download"}
                                tooltip={"Download"}
                                size={"small"}
                                color={"#898DA6"}
                                onClick={() => downloadAttachment(attachment)}
                            />
                        </div>
                    </div>                       
                )}
            </>
        </div>
        )
};
