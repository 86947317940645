import React, { useState } from 'react';
import LcAccordion from '../../../components/Generic/LcAccordion';
import LcIconLink from '../../../components/Generic/LcIconLink';
import './index.css'
import PPModal from 'processor-plataform-ui/dist/components/PPModal';
import { PPUploader } from 'processor-plataform-ui';
import { PiPaperclipLight, PiXLight } from 'react-icons/pi';

// import { Container } from './styles';

export interface props {
    anexos: any
    removeFile : Function
    setAnexos: Function
    visible: boolean
}

const Attachment: React.FC<props> = (props) => {

    const [openUpload, setOpenUpload] = useState<boolean>(false);


    const  formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    return (
    <>
        <div className='footerModalAnexos'>
        <button className='pp-button lc-button bg-gray'  id={'form_novochamado_anexar_button'}onClick={() => setOpenUpload(true)}>Anexar</button>
        {
            
        props.anexos && props.anexos.length > 0 &&
            <LcAccordion title={"Anexos (" + props.anexos.length + ")"} visible={props.visible} >
                <div className={"divOrderAttachmentSolicitation"}>
                    {
                        props.anexos && props.anexos.map(file => {
                            let size = formatBytes(file.size)
                            return (
                                <div key={file.size} className='divShowfile'>
                                    <LcIconLink
                                        icon={<PiPaperclipLight />}
                                        size={"medium"}
                                        color={'#898DA6'}
                                    />
                                    <div key={file.name} className="divOrderAttachmentShow">
                                        <span className={"spanLabel"}>{file.name}</span>
                                        <span className={"spanSize"}>{size}</span>
                                    </div>
                                    <LcIconLink
                                        icon={<PiXLight />}
                                        tooltip={"Remover"}
                                        tooltipPosition={'inline-left'}
                                        size={"small"}
                                        onClick={() => props.removeFile(file.name)}
                                        color={"red"}
                                        />
                                </div>
                            );
                        })
                    }
                </div>
            </LcAccordion>
        }
    </div>
    <PPModal title={"Anexar arquivo"} visible={openUpload} onClose={() => setOpenUpload(false)}>
        <PPUploader
                onDropFile={(files: any[]) => {props.setAnexos(files); setOpenUpload(false)}}
                promptMessage={"Solte o arquivo aqui (máx. 10MB)"}
                maxSize={10240}
                largeFile={"Este componente não suporta arquivos maiores que 10Mb."}
            />
    </PPModal>
    </>
  );
}

export default Attachment;