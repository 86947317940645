import React from 'react';
import AR from '../../../assets/images/flags/AR.svg';
import BR from '../../../assets/images/flags/BR.svg';
import CA from '../../../assets/images/flags/CA.svg';
import CH from '../../../assets/images/flags/CH.svg';
import CL from '../../../assets/images/flags/CL.svg';
import CN from '../../../assets/images/flags/CN.svg';
import CO from '../../../assets/images/flags/CO.svg';
import GB from '../../../assets/images/flags/GB.svg';
import MX from '../../../assets/images/flags/MX.svg';
import PE from '../../../assets/images/flags/PE.svg';
import PT from '../../../assets/images/flags/PT.svg';
import SG from '../../../assets/images/flags/SG.svg';
import US from '../../../assets/images/flags/US.svg';
import UY from '../../../assets/images/flags/UY.svg';

// Gerdau
import GLN from '../../../assets/images/globallicensemanager/152/gln.png';
import GSN from '../../../assets/images/globallicensemanager/152/gsn.png';

const Flags = ({ countryCode }) => {
    const flagMap = {
        'AR': AR,
        'BR': BR,
        'CA': CA,
        'CH': CH,
        'CL': CL,
        'cl': CL,
        'CN': CN,
        'cn': CN,
        'CO': CO,
        'GB': GB,
        'gb': GB,
        'MX': MX,
        'PE': PE,
        'PT': PT,
        'pt': PT,
        'SG': SG,
        'US': US,
        'UY': UY,
        'GLN': GLN,
        'GSN': GSN
    };

    return (
        <img src={flagMap[countryCode]} alt={`${countryCode} flag`} width="30" height="20" />
    );
};

export default Flags;