import React, { useEffect, useState } from 'react';
import LCDashboard, { Card, PositionCard } from '../../../components/Data/Dashboard/LCDashboard';
import Layout from '../../../components/Layout/Layout';
import { WorkEnvironment } from '../resume/WorkEnvironment';
import MaliciousFileCard, { collumnMaliciousFile } from './MaliciousFileCard';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcLoading from '../../../components/Generic/LcLoading';
import MaliciousURLCard, { collumnMaliciousURL, translationsMaliciousURL } from './MaliciousURLCard';
import { collumnZeroPhising, tranlationsZeroPhising, ZeroPhisingCard } from './ZeroPhisingCard';
import RansowareAttackCard, { getColumnsRansomwareAttack, translationsRamsowareAttack } from './RamsowareAttackCard';
import { PPModal } from 'processor-plataform-ui';
import { collumnURLFiltering, translationsURLFiltering, URLFilteringChat } from './URLFilteringChat';
import { AttacksChart, collumnAttcakStatus, translationsAttackStatus } from './AttacksChart';
import { MostTargeTableCheckpoint } from './MostTargeTableCheckpoint';
import TotalEventsChart from './TotalEventsChart';
import FilterPanel from '../../../components/Data/FilterPanel';
import { RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { PrefState } from '../../../store/reducers/prefReducer';
import { LogEventsRequest } from '../../../services/endpointDetectionResponse/XDRCheckpointModel';

interface XDRCheckpointResumeProps {
    isHome: boolean,
    qtdGroups: number
    secOpsQtdGroups: number
    isSecOpsPage: boolean
}

const XDRCheckpointResume: React.FC<XDRCheckpointResumeProps> = ({
    isHome,
    isSecOpsPage,
    qtdGroups,
    secOpsQtdGroups

}) => {
    //redux
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const maliciousURLData = useSelector((state: RootState) => state.xdrCheckpointResume.maliciousURLData);
    const { currentPage, itemsPerPage } = useSelector((state: RootState) => state.xdrCheckpointResume.maliciousURLPagination);
    const { currentPageAttack, itemsPerPageAttack } = useSelector((state: RootState) => state.xdrCheckpointResume.attacksChartDataPagination);

    const maliciousFileData = useSelector((state: RootState) => state.xdrCheckpointResume.maliciousFileData);
    const attacksChartData = useSelector((state: RootState) => state.xdrCheckpointResume.attacksChartData);
    const loadingMaliciousURL = useSelector((state: RootState) => state.xdrCheckpointResume.loadingStates.loadingMaliciousURL);
    const zeroPhinsingData = useSelector((state: RootState) => state.xdrCheckpointResume.zeroPhinsingData);
    const loadingZeroPhising = useSelector((state: RootState) => state.xdrCheckpointResume.loadingStates.loadingZeroPhising);
    const URLFilteringData = useSelector((state: RootState) => state.xdrCheckpointResume.URLFilteringData);
    const loadingURLFiltering = useSelector((state: RootState) => state.xdrCheckpointResume.loadingStates.loadingURLFiltering);
    const ransowareAttackData = useSelector((state: RootState) => state.xdrCheckpointResume.ransowareAttackData);
    const dataFiltering = useSelector((state: RootState) => state.xdrCheckpointResume.dataFiltering);
    const loadingRansoware = useSelector((state: RootState) => state.xdrCheckpointResume.loadingStates.loadingRansoware);
    const loadingMaliciousFile = useSelector((state: RootState) => state.xdrCheckpointResume.loadingStates.loadingMaliciousFile);
    const loadingAttacksChartData = useSelector((state: RootState) => state.xdrCheckpointResume.loadingStates.loadingAttacksChartData);
    const period = useSelector((state: RootState) => state.xdrCheckpointResume.periodFilter);
    const dispatch = useDispatch();
    const [showFilterEnable, setShowFilterEnable] = useState<boolean>(false);

    const [serversData, setServersData] = useState<any>();
    const [workstationsData, setWorkstationsData] = useState<any>();

    const [filterEnable, setFilterEnable] = useState<boolean>(false);
    const [filterEnableURLFiltering, setFilterEnableURLFiltering] = useState<boolean>(false);
    const [filterEnableZeroPhising, setFilterEnableZeroPhising] = useState<boolean>(false);
    const [filterEnableRamsoware, setFilterEnableRamsoware] = useState<boolean>(false);
    const [filterEnableMalicicuesSites, setFilterEnableMalicicuesSites] = useState<boolean>(false);
    const [selectedDevice, setSelectedDevice] = useState<any>('');
    const [detailsModalVisible, setDetailsModalVisible] = useState<boolean>(false);
    const [filtered, setFiltered] = useState<boolean>(false);
    const [translations, setTranslations] = useState({});
    const [initialDataFiltering, setinitialDataFiltering] = useState<LogEventsRequest>({ record: [], recordCount: 0 });

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        dispatch({ type: 'SET_PERIOD_FILTER', payload: date });
    };


    const positions5Groups: Card[] = [
        {
            id: 1, position: { ColSpan: 2, RowSpan: 1, row: 5, col: 1, hidden: !isSecOpsPage },
        },
        {
            id: 2, position: { ColSpan: 2, RowSpan: 1, row: 5, col: 1, hidden: true },
        },
        {
            id: 3, position: { ColSpan: 2, RowSpan: 1, row: 6, col: 1, hidden: false },
        },
        {
            id: 4, position: { ColSpan: 1, RowSpan: 1, row: 1, col: 10, hidden: true },
        },
        {
            id: 5, position: { ColSpan: 2, RowSpan: 4, row: 1, col: 1, hidden: false },
        },
        {
            id: 6, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 5, hidden: true },
        },
        {
            id: 7, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
        }];

    const positions3Groups: Card[] = [
        {
            id: 1, position: { ColSpan: 2, RowSpan: 1, row: 5, col: 1, hidden: !isSecOpsPage },
        },
        {
            id: 2, position: { ColSpan: 2, RowSpan: 1, row: 5, col: 1, hidden: true },
        },
        {
            id: 3, position: { ColSpan: 2, RowSpan: 1, row: 6, col: 1, hidden: true },
        },
        {
            id: 4, position: { ColSpan: 1, RowSpan: 4, row: 1, col: 10, hidden: true },
        },
        {
            id: 5, position: { ColSpan: 2, RowSpan: 3, row: 1, col: 1, hidden: false },
        },
        {
            id: 6, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 5, hidden: true },
        },
        {
            id: 7, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
        }];

    const positions2Groups: Card[] = [
        {
            id: 1, position: { ColSpan: 2, RowSpan: 1, row: 5, col: 1, hidden: secOpsQtdGroups > 1 ? true : false },
        },
        {
            id: 2, position: { ColSpan: 2, RowSpan: 1, row: 5, col: 1, hidden: true },
        },
        {
            id: 3, position: { ColSpan: 2, RowSpan: 1, row: 6, col: 1, hidden: false },
        },
        {
            id: 4, position: { ColSpan: 1, RowSpan: 1, row: 1, col: 10, hidden: true },
        },
        {
            id: 5, position: { ColSpan: 2, RowSpan: 3, row: 1, col: 1, hidden: false },
        },
        {
            id: 6, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 5, hidden: true },
        },
        {
            id: 7, position: { ColSpan: 1, RowSpan: 3, row: 4, col: 2, hidden: true },
        }];

    function positionHome(cardId: number | undefined, qtdGroups: number): PositionCard {
        var Id = cardId as number;
        if (qtdGroups <= 2) {
            return positions2Groups.filter(c => c.id === Id)[0].position;
        }

        if (qtdGroups <= 3) {
            return positions3Groups.filter(c => c.id === Id)[0].position;
        }

        if (qtdGroups <= 6) {
            return positions5Groups.filter(c => c.id === Id)[0].position;
        }

        return {} as unknown as PositionCard;
    }
    const periodSelection = {
        periods: [
            {
                label: 'Útimos 7 dias', text: 'Útimos 7 dias',
                fields: [
                    { name: 'last7days', value: 'last7days' },

                ],
            },
            {
                label: 'Útimos 30 dias', text: 'Útimos 30 dias',
                fields: [
                    { name: 'last30days', value: 'last30days' }
                ],
                default: true
            },
            {
                label: 'Últimos 3 meses', text: 'Últimos 3 meses',
                fields: [
                    { name: 'last90days', value: 'last90days' }
                ],
            },
            // {
            //     label: 'Período total', text: 'Período total',
            //     fields: [
            //         { name: 'alltime', value: 'alltime' },

            //     ],
            //     default:  beginOfMonth()
            // },
        ],
        customPeriodType: 'month',
        onChange: onChagePeriod
    };
    const loadMoreInvoices = () => {
        const nextPage = currentPage + 1;
        dispatch({ type: 'SET_MALICIOUS_URL_PAGINATION', payload: { currentPage: nextPage } });
    };


    const loadMoreAttackClassification = () => {
        const nextPage = currentPageAttack + 1;
        dispatch({ type: 'SET_ATTACKS_CHART_PAGINATION', payload: { currentPage: nextPage } });
    };

    const getVisibleMaliciousURLData = () => {
        const endIndex = currentPage * itemsPerPage;
        return maliciousURLData.record.slice(0, endIndex);
    };
    const getVisibleAttackClassificationData = () => {
        const endIndex = currentPageAttack * itemsPerPageAttack;
        return attacksChartData.record.slice(0, endIndex);
    };

    const cards: Card[] = [
        //*Ambiente Protegido
        {
            id: 1,
            bgColor: (isHome || isSecOpsPage) ? "#fff" : "var(--color-primary-purple)",
            hideHeader: false,
            type: 'Custom',
            cssClass: (isHome) ? 'fix-card-content' : undefined,
            position: (isHome || isSecOpsPage) ? { ColSpan: 2, RowSpan: 1, row: 5, col: 7 } : { ColSpan: 3, RowSpan: 2, row: 1, col: 1 },
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div style={{ color: (isHome || isSecOpsPage) ? '' : '#fff' }} className="lc-title ellipsis">Ambiente protegido</div>
                </div>
            ),
            customContentRender: () => {
                return (
                    <WorkEnvironment
                        provider={2}
                        isHome={isHome}
                        isSecops={isSecOpsPage}
                        setServers={setServersData}
                        setWorkstations={setWorkstationsData}
                        setIot={() => { }}
                    />
                )
            }
        },
        //*Arquivos Maliciosos
        {
            id: 2,
            showFocusButton: isHome || isSecOpsPage ? false : true,
            type: 'Custom',
            position: { ColSpan: 3, RowSpan: 1, row: 1, col: 4 },
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Arquivos maliciosos</div>
                </div>
            ),
            customContentRender: (focused: boolean) => {
                if (focused) {
                    return (
                        <LcLoading loading={loadingMaliciousFile}>
                            <LcInfiniteTable
                                rows={maliciousFileData.record}
                                columns={collumnMaliciousFile}
                                height='98%'
                                hidePagination
                                notLoadMore

                            />

                        </LcLoading>
                    )
                } else {
                    return (
                        <MaliciousFileCard />
                    )
                }
            }
        },
        //*URL Maliciosos
        {
            id: 3,
            showFocusButton: isHome || isSecOpsPage ? false : true,
            type: 'Custom',
            position: { ColSpan: 3, RowSpan: 1, row: 2, col: 4 },
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Sites maliciosos</div>
                </div>
            ),
            customContentRender: (focused: boolean) => {
                setFilterEnableMalicicuesSites(focused === true);
                if (focused) {
                    return (
                        <LcLoading loading={loadingMaliciousURL}>
                            <LcInfiniteTable
                                rows={!filtered ? getVisibleMaliciousURLData() : dataFiltering.record}
                                columns={collumnMaliciousURL}
                                height='98%'
                                size={!filtered ? maliciousURLData.recordCount : dataFiltering.recordCount}
                                hidePagination
                                loadMore={loadMoreInvoices}
                                density={pref.data?.density || "high"}
                            />
                        </LcLoading>
                    )
                } else {
                    return (
                        <MaliciousURLCard />
                    )
                }
            }
        },
        //*Zero phinsing
        {
            id: 4,
            showFocusButton: isHome || isSecOpsPage ? false : true,
            type: 'Custom',
            position: { ColSpan: 3, RowSpan: 2, row: 1, col: 7 },
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Zero phishing</div>
                </div>
            ),
            customContentRender: (focused: boolean) => {
                setFilterEnableZeroPhising(focused === true);
                if (focused) {
                    return (
                        <LcLoading loading={loadingZeroPhising}>
                            <LcInfiniteTable
                                rows={dataFiltering.recordCount === 0 ? zeroPhinsingData.record : dataFiltering.record}
                                columns={collumnZeroPhising}
                                height='98%'
                                hidePagination
                                notLoadMore
                            />

                        </LcLoading>
                    )
                } else {
                    return (
                        <ZeroPhisingCard />
                    )
                }
            }
        },
        //*Ataques Ransoware
        {
            id: 5,
            showFocusButton: isHome || isSecOpsPage ? false : true,
            type: 'Custom',
            position: { ColSpan: 3, RowSpan: 2, row: 1, col: 10 },
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Ataques Ransomware</div>
                </div>
            ),
            customContentRender: (focused: boolean) => {
                setFilterEnableRamsoware(focused === true);
                if (focused) {
                    return (
                        <LcLoading loading={loadingRansoware}>
                            <LcInfiniteTable
                                rows={dataFiltering.recordCount === 0 ? ransowareAttackData.record : dataFiltering.record}
                                columns={getColumnsRansomwareAttack(setSelectedDevice, setDetailsModalVisible)}
                                height='98%'
                                hidePagination
                                notLoadMore

                            />

                        </LcLoading>
                    )
                } else {
                    return (
                        <RansowareAttackCard />
                    )
                }
            }
        },
        //*URl Filtering
        {
            id: 6,
            showFocusButton: isHome || isSecOpsPage ? false : true,
            type: 'Custom',
            position: { ColSpan: 3, RowSpan: 3, row: 3, col: 10 },
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Filtragem de URL</div>
                </div>
            ),
            customContentRender: (focused: boolean) => {
                setFilterEnableURLFiltering(focused === true);
                if (focused) {
                    return (
                        <LcLoading loading={loadingURLFiltering} >
                            <LcInfiniteTable
                                rows={dataFiltering.recordCount === 0 ? URLFilteringData.record : dataFiltering.record}
                                columns={collumnURLFiltering}
                                height='98%'
                                hidePagination
                                notLoadMore

                            />
                        </LcLoading>
                    )
                } else {
                    return <URLFilteringChat />
                }
            }
        },
        //* Classificação de Ataques
        {
            id: 6,
            showFocusButton: isHome || isSecOpsPage ? false : true,
            type: 'Custom',
            position: { ColSpan: 3, RowSpan: 3, row: 3, col: 4 },
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Classificação de ataques</div>
                </div>
            ),
            customContentRender: (focused: boolean) => {
                setFilterEnable(focused === true);
                if (focused) {
                    return (
                        <LcLoading loading={loadingAttacksChartData}>
                            <LcInfiniteTable
                                rows={dataFiltering.record.length === 0 ? getVisibleAttackClassificationData() : dataFiltering.record}
                                columns={collumnAttcakStatus}
                                size={!filtered ? attacksChartData.record.length : dataFiltering.record.length}
                                height='98%'
                                hidePagination
                                loadMore={loadMoreAttackClassification}
                                density={pref.data?.density || "high"}
                            />
                        </LcLoading>
                    )
                } else {
                    return (
                        <AttacksChart />
                    )
                }
            }
        },
        //* Principais Alvos de Ataques
        {
            id: 6,
            showFocusButton: isHome || isSecOpsPage ? false : true,
            type: 'Custom',
            position: { ColSpan: 3, RowSpan: 3, row: 3, col: 1 },
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Principais Alvos</div>
                </div>
            ),
            customContentRender: (focused: boolean) => {
                return (
                    <MostTargeTableCheckpoint
                        data={attacksChartData.record}
                        hideBar={focused === true}
                    />
                )
            }
        },
        //* Total de eventos
        {
            id: 6,
            showFocusButton: false,
            type: 'Custom',
            position: { ColSpan: 3, RowSpan: 3, row: 3, col: 7 },
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Total de eventos</div>
                </div>
            ),
            customContentRender: () => {
                return (
                    <TotalEventsChart
                        period={period}
                    />
                )
            }
        },
    ]
    useEffect(() => {
        const filters = [
            { enabled: filterEnable, translations: translationsAttackStatus, data: attacksChartData },
            { enabled: filterEnableZeroPhising, translations: tranlationsZeroPhising, data: zeroPhinsingData },
            { enabled: filterEnableMalicicuesSites, translations: translationsMaliciousURL, data: maliciousURLData },
            { enabled: filterEnableURLFiltering, translations: translationsURLFiltering, data: URLFilteringData },
            { enabled: filterEnableRamsoware, translations: translationsRamsowareAttack, data: ransowareAttackData }
        ];

        const activeFilter = filters.find(filter => filter.enabled);

        if (activeFilter) {
            setTranslations(activeFilter.translations);
            setinitialDataFiltering(activeFilter.data);
        } else {
            setinitialDataFiltering({ record: [], recordCount: 0 });
            setTranslations({});
            setFiltered(false);
            setFilterEnable(false);
            dispatch({ type: 'SET_DATA_FILTERING', payload: {record: [], recordCount: 0} });
        }
    }, [
        URLFilteringData,
        attacksChartData,
        filterEnable,
        filterEnableMalicicuesSites,
        filterEnableRamsoware,
        filterEnableURLFiltering,
        filterEnableZeroPhising,
        maliciousURLData,
        ransowareAttackData,
        zeroPhinsingData,
        dispatch
    ]);

    const handleFiltering = (filtered: any) => {
        setFiltered(true);
        dispatch({ type: 'SET_DATA_FILTERING', payload: {record: filtered, recordCount: filtered.length} });
    }

    const filterAtaccks = () => {
        return (
            <div className="right-sidepanel-filters">
                <FilterPanel
                    data={initialDataFiltering.record}
                    filter={Object.keys(translations).map(key => ({
                        label: translations[key].label,
                        name: key,
                        type: translations[key].type,
                        values: translations[key].values,
                    }))}
                    onFilteredData={handleFiltering}
                    translations={translations}
                    clearFilters={() => { setFiltered(false); dispatch({ type: 'SET_DATA_FILTERING', payload: {record: [], recordCount: 0} }); }}

                />
            </div>
        );
    };
    return (
        <>
            {!(isHome || isSecOpsPage) ?
                <Layout
                    pageTitle="Resumo"
                    periodSelection={periodSelection}
                    gridFilter={initialDataFiltering.record.length > 0 ? {
                        toggleVisibility: () => {
                            setShowFilterEnable(!showFilterEnable)
                        },
                        size: dataFiltering.recordCount === 0 ? initialDataFiltering.record.length : dataFiltering.recordCount,
                        color: filtered ? '#C9FF46' : '',
                    } : undefined}
                >
                    <LCDashboard cards={[...cards]}
                        grid={[5, 12]}
                        rightSidePanel={(showFilterEnable && Object.keys(translations).length > 0) ? {
                            title: initialDataFiltering.record.length > 0 ? 'Filtros' : '',
                            content: () => initialDataFiltering.record.length > 0 ? filterAtaccks() : '',
                            pinned: false,
                            show: showFilterEnable,
                            close: setShowFilterEnable
                        } : undefined} />
                    <PPModal title={"Detalhes " + selectedDevice?.src_machine_name} onClose={() => { setDetailsModalVisible(false); setSelectedDevice(undefined); }} visible={detailsModalVisible} loading={!selectedDevice} >
                        {
                            selectedDevice &&
                            <p>
                                {/* <p><b>Dispositivo:</b> {selectedDevice.src_machine_name}</p> */}
                                <p ><b>Usuário:</b> {selectedDevice.src_user_name[0] || selectedDevice.user_name}</p>
                                <p className='mt-3'><b>Tipo de evento:</b> {selectedDevice.event_type}</p>
                                <p className='mt-3'><b>Nome da proteção:</b> {selectedDevice.protection_name}</p>
                                <p className='mt-3'><b>Adicional Info:</b> {selectedDevice.additional_info}</p>
                                {/*  <p><b>Processo:</b> {selectedDevice.process}</p>*/}
                                {/* <p><b>Criticidade:</b> {severities.find(s => s.value === selectedDevice.severity)?.label ?? "(Sem informações)"}</p> */}
                                {/*<p><b>Registrado a partir de:</b> {selectedDevice.firstSeenFrom}</p>*/}
                                {/*<p><b>Última ocorrência a partir de:</b> {selectedDevice.lastSeenFrom}</p>*/}
                            </p>
                        }
                    </PPModal>
                </Layout>

                :
                <LCDashboard cssClass={(secOpsQtdGroups === 1 ? true : false) ? '' : 'EdrHome'}
                    isHomeType grid={isHome ? [2, 2] : [4, 2]} cards={secOpsQtdGroups && secOpsQtdGroups > 0 ? cards.map((card, index) => {
                        if (secOpsQtdGroups) {
                            var posi = positionHome(card.id, secOpsQtdGroups);
                            if (posi !== undefined) {
                                card.position = posi;
                            }
                        }
                        return card;
                    }) : []} rightSidePanel={{
                        title: '',
                        content: () => '',
                        pinned: false,
                        show: false
                    }} />
            }

        </>
    )
}
export default XDRCheckpointResume;
