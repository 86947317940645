import React, { useEffect } from 'react';
import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import { useQuery } from 'react-query';
import LcLoading from '../../../components/Generic/LcLoading';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import { PiGlobeLight } from 'react-icons/pi';
import { RiVirusFill } from 'react-icons/ri';
import moment from 'moment';
import { translateAction, translateConfidenceLevel } from './MaliciousFileCard';
import { Tooltip } from '@material-ui/core';
import { StatusColor } from './AttacksChart';
import { translateAttackSeverity } from './MostTargeTableCheckpoint';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';

export const translationsMaliciousURL = {
    resource: {
        label: "Site acessado",
        type: "text",
    },
    src_user_name: {
        label: "Usuário",
        type: "text",
    },
    attack_status: {
        label: "Status",
        type: "multiSelect",
        values: {
            'Dormant':'Inativo',
            'Active':'Ativo',
            'Blocked':'Bloqueado',
            'Cleaned':'Limpo',
        }
    },
    severity: {
        label: "Severidade",
        type: "multiSelect",
        values: {
            'Critical': 'Critico',
            'High': 'Alto',
            'Medium': 'Médio',
            'Low': 'Baixo',
            'Informational': 'Informativo'
        }
    },
}

export const collumnMaliciousURL = [
    { field: 'resource', headerName: 'Site acessada', width: "15%", renderCell:(row: any) =>{
        return <Tooltip title={row.resource} arrow >
            <div className='overflowEllipsis' style={{width: '15vw'}}>
                {row.resource[0]}
            </div>
        </Tooltip>
    } },
    { field: 'serverity', headerName: 'Severidade', width: "10%", renderCell: (row: any) => {
        return  <div className='severityLabelDetails' style={{ padding: '0px 8px', backgroundColor: StatusColor(row.severity) }}>
        {translateAttackSeverity(row.severity)}
    </div>
    }},
    // { field: 'file_name', headerName: 'Nome do Arquivo', width: "12%" },
    { field: 'src', headerName: 'Origem', width: "10%" },
    { field: 'src_user_name', headerName: 'Usuário', width: "12%", renderCell: (row: any) => {
        return  <Tooltip arrow title={row.description}>
        <div style={{ width: '12vw' }} className="overflowEllipsis">{row.src_user_name}</div>
        </Tooltip>
    }},
    { field: 'attack_status', headerName: 'Status', width: "7%", renderCell: (row: any) => translateAttackStatus(row.attack_status) },
    { field: 'confidence_level', headerName: 'Confiança', width: "7%", renderCell: (row: any) => translateConfidenceLevel(row.confidence_level) },
    { field: 'action', headerName: 'Ação', width: "7%", renderCell: (row: any) => translateAction(row.action) },
    { field: 'time', headerName: 'Data', width: "10%", renderCell: (row: any) =>moment(row.time).format('DD/MM/YYYY HH:mm:ss') },
    { field: 'description', headerName: 'Descrição', width: "18%", renderCell: (row: any) => {
        return(
            <Tooltip arrow title={row.description}>
                <div style={{ width: '18vw' }} className="overflowEllipsis">{row.description}</div>
            </Tooltip>
        )
    }  },
]

export const translateAttackStatus = (status: string) => {
    const classficiation = {
        'Dormant':'Inativo',
        'Active':'Ativo',
        'Blocked':'Bloqueado',
        'Cleaned':'Limpo',
        'Unknown':'Desconhecido',
    }
    return classficiation[status] || "-"
}

const MaliciousURLCard: React.FC = () => {
    const dispatch = useDispatch();
    const period = useSelector((state: RootState) => state.xdrCheckpointResume.periodFilter);
    const maliciousURLData = useSelector((state: RootState) => state.xdrCheckpointResume.maliciousURLData);
    const checkpointService = new EndpointDetectionResponseService({});
    const {isLoading } = useQuery({
        queryKey: ["MaliciousURL",period],
        queryFn: () => checkpointService.GetMaliciousURLAttacks(period),
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
            const severitiesOrder = ['Critical', 'High', 'Medium', 'Low', 'Informational'];
            response.record.sort((a, b) => severitiesOrder.indexOf(a.severity) - severitiesOrder.indexOf(b.severity));
            dispatch({ type: 'SET_MALICIOUS_URL_DATA', payload: response });
        },
        onError: (error) => {
            console.error('Error fetching URL Malicious data:', error);
        }
    });

    useEffect(() => {
        dispatch({ type: 'SET_LOADING', payload: { key: 'loadingMaliciousURL', value: isLoading } });
    }, [dispatch, isLoading]);

    return (
        <LcLoading loading={isLoading}> 
            <IconValue 
                value={maliciousURLData ? maliciousURLData.recordCount : 0}
                icon={ 
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <PiGlobeLight size={30} />
                    <RiVirusFill  size={20} style={{ position: 'absolute', bottom:-2 , zIndex: '100', left: 17, color: 'var(--emphasis)' }} />
                </div>
                }
                color='var(--emphasis)'
                description='Detectados'
            />

        </LcLoading>
    );
}

export default MaliciousURLCard;