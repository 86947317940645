import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { CostCenterService, FilterDashboard, FinOpsPivotDataResult } from '../../../services/costCenter/costCenterService';
import LcLoading from '../../../components/Generic/LcLoading';
import {  useDispatch, useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import './index.css';
import PivotTableWebix, { isValidFilter, isValidStructure } from '../../../components/webix';
import moment from 'moment';
import { useQuery } from 'react-query';
import LcNoData from '../../../components/Generic/LcNoData';
import { PiCalendarBlankLight, PiCalendarPlusLight,  PiCloudArrowDownLight } from 'react-icons/pi';
import { AnalyticalCostCenterState } from '../../../store/reducers/analyticalReducer';
import { useLocation } from 'react-router-dom';

const BASE_FIELDS = [
    { id: 'Srv', value: "Serviço", type: "text" },
    { id: 'Prv', value: "Provedor", type: "text" },
    { id: 'Ano', value: "Ano", type: "number" },
    { id: 'Mes', value: "Mês", type: "number" },
    { id: 'Dia', value: "Dia", type: "number" },
    { id: 'Cdc', value: "Centro de Custo", type: "text" },
    { id: 'Ctr', value: "Contrato", type: "text" },
    { id: 'Rsg', value: "Grupo", type: "text" },
    { id: 'Rec', value: "Recurso", type: "text" },
    // { id: 'parentCostCenter', value: "Centro de Custo Pai", type: "text" }
];

function groupByMesAnoAndExtract(data: FinOpsPivotDataResult[]): { Mes: number; Ano: number }[] {
    var resultMonthYear = [] as { Mes: number, Ano: number }[];
    const grouped = data.reduce((acc, item) => {
        const key = `${item.Ano}-${item.Mes}`; // Grouping key, formatted as "Year-Month"
        if (!acc[key]) {
            acc[key] = [];
        }
        // Check if the current combination of Mes and Ano already exists to avoid duplicates
        const exists = acc[key].some(x => x.Mes === item.Mes && x.Ano === item.Ano);
        if (!exists) {
            acc[key].push({ Mes: item.Mes, Ano: item.Ano });
            resultMonthYear.push({ Mes: item.Mes, Ano: item.Ano });
        }
        return acc;
    }, {} as Record<string, { Mes: number; Ano: number }[]>);

    return resultMonthYear;
}

const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let header = '';

    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let index in array[i]) {
            if (header.split(';').indexOf(index) === -1) {
                header += index + ';';
            }
            if (line !== '') line += ';';
            line += array[i][index];
        }
        str += line + '\r\n';
    }
    return header.slice(0, -1) + '\r\n' + str;
};

const FinOpsAnalyticDashBoard: React.FC = (props) => {
    const analytical = useSelector<RootState, AnalyticalCostCenterState>(state => state.analytical);
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector<RootState, UserState>(state => state.user);
    const [showMargin, setShowMargin] = useState<boolean>(false);
    const [accumulate, setAccumulate] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [datePack, setDatePack] = useState<{ month: string, year: string, clientGroupId: number | undefined, refresh: string, accumulate: boolean }>();
    const [fields, setFields] = useState<any[]>();
    const [selectedView, setSelectedView] = useState<boolean>(false);
    const [refreshPivot, setRefreshPivot] = useState<number>(0);
    const [pivotStructure, setPivotStructure] = useState<any>( {
        rows: ["Prv", "Acn", "Srv"],
        columns: accumulate ? ["Ano", "Mes"] : ["Mes"],
        values:
            showMargin ?
                [{ name: "Cst", operation: ["sum"] },
                { name: "Tax", operation: ["sum"] },
                { name: "Mrg", operation: ["sum"] },
                { name: "Vbr", operation: ["sum"] }] : [{ name: "Vbr", operation: ["sum"] }],
        filters: [
            { name: "Prv" },
            { name: "Acc" },
            { name: "Cdc" },
            { name: "Ctr" },
            { name: "Srv" },
            { name: "Cat" },
            { name: "Mes" },
            { name: "Dia" },
            { name: "Rec" }
        ]
    });
    
    const { data: pivotData, isLoading: loadingpivotData, refetch: refetchReportKPI, isRefetching: isRefetchingKPIs }
        = useQuery<FinOpsPivotDataResult[]>(['pivotData', datePack], async () => {
            return await loadData(datePack);
        }, {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: true,
            staleTime: 7200000,
            onSuccess: () => {
                console.log('onSuccess');
                setLoading(false);
            },
            optimisticResults: true
        });

    useEffect(() => {
       // setAccumulate(false);
        var dateR = new Date();
        dateR.setDate(dateR.getDate() - 1);
        var currentMonth = (dateR.getMonth() + 1).toString();
        var currentYear = dateR.getFullYear().toString();
        setDatePack({ month: currentMonth, year: currentYear, clientGroupId: user.ClientGroupSelected, refresh: Math.random().toString(), accumulate: accumulate });
        refetchReportKPI();
        setLoading(true);
        setSelectedView(localStorage.getItem('visionSelected') ? true : false)
    }, [accumulate, refetchReportKPI, user.ClientGroupSelected, user.refreshFlag]);

    // useEffect(() => { setLoading(false) }, [pivotData]);
    
    // const initData = () => {
    //     setLoading(true);
    //     var dateR = new Date();
    //     dateR.setDate(dateR.getDate() - 1);
    //     var currentMonth = (dateR.getMonth() + 1).toString();
    //     var currentYear = dateR.getFullYear().toString();
    //     setDatePack({ month: currentMonth, year: currentYear, clientGroupId: user.ClientGroupSelected, refresh: '', accumulate: accumulate });
    // }

    const loadData = useCallback(async (): Promise<FinOpsPivotDataResult[]> => {
        setLoading(true)
        const filterDate = datePack || { year: new Date().getFullYear(), month: new Date().getMonth() + 1 };
        const { year: currentYear, month: currentMonth } = filterDate;
        try {
            const costCenterService = new CostCenterService({});
            var filter = {
                clientGroupId: Number(user.ClientGroupSelected ? user.ClientGroupSelected : '0'),
                costCenterID: 0,
                contractID: 0,
                componentName: '',
                productName: '',
                serviceCode: '',
                serviceName: '',
                provedor: '',
                ano: Number(currentYear),
                mes: Number(currentMonth),
                dia: 0,
                gtmId: 0,
                user: user.UserId,
                reportType: '1',
                displayType: '1',
                showChildrenCostCenters: 1,
                periodo: 2
            } as FilterDashboard;
            var responseAData = (await costCenterService.GetDataDetails(filter)) as FinOpsPivotDataResult[];
            let fieldBase = [...BASE_FIELDS];

            if (responseAData[0].Smg === true) {
                setShowMargin(true);
                fieldBase.push({ id: 'Vbr', value: "Valor", type: "number" });
                fieldBase.push({ id: 'Tag', value: "Tags", type: "text" });
                fieldBase.push({ id: 'Cat', value: "Categoria", type: "text" });
                fieldBase.push({ id: 'Sub', value: "SubCategoria", type: "text" });
                fieldBase.push({ id: 'Met', value: "Metrica", type: "text" });
                fieldBase.push({ id: 'Uni', value: "Unidade", type: "text" });
                fieldBase.push({ id: 'Bdg', value: "Orçamento", type: "number" });
                fieldBase.push({ id: 'Ccr', value: "Regra CdeC", type: "text" });
                fieldBase.push({ id: 'Ccrd', value: "Regra CdeC Nome", type: "text" });
                fieldBase.push({ id: 'Csq', value: "Qtd", type: "number" });
                fieldBase.push({ id: 'Cst', value: "Custo", type: "number" });
                fieldBase.push({ id: 'Mrg', value: "Margem", type: "number" });
                fieldBase.push({ id: 'Tax', value: "Taxa", type: "number" });
                fieldBase.push({ id: 'Dat', value: "Date", type: "text" });
                fieldBase.push({ id: 'Acc', value: "Assinatura", type: "text" });
                fieldBase.push({ id: 'Acn', value: "Nome Assinatura", type: "text" });
                fieldBase.push({ id: 'Rgn', value: "Região", type: "text" });
                fieldBase.push({ id: 'Rci', value: "Id do Recurso", type: "text" });
            } else if (responseAData[0].Spt === true) {
                fieldBase.push({ id: 'Pct', value: "Porcento", type: "number" });
            } else {
                fieldBase.push({ id: 'Vbr', value: "Valor", type: "number" });
                fieldBase.push({ id: 'Bdg', value: "Orçamento", type: "number" });
                fieldBase.push({ id: 'Tag', value: "Tags", type: "text" });
                fieldBase.push({ id: 'Cat', value: "Categoria", type: "text" });
                fieldBase.push({ id: 'Sub', value: "SubCategoria", type: "text" });
                fieldBase.push({ id: 'Met', value: "Metrica", type: "text" });
                fieldBase.push({ id: 'Uni', value: "Unidade", type: "text" });
                fieldBase.push({ id: 'Acc', value: "Assinatura", type: "text" });
                fieldBase.push({ id: 'Ccr', value: "Regra CdeC", type: "text" });
                fieldBase.push({ id: 'Ccrd', value: "Regra CdeC Nome", type: "text" });
                fieldBase.push({ id: 'Dat', value: "Date", type: "text" });
                fieldBase.push({ id: 'Csq', value: "Qtd", type: "number" });
                fieldBase.push({ id: 'Acn', value: "Nome Assinatura", type: "text" });
                fieldBase.push({ id: 'Rgn', value: "Região", type: "text" });
                fieldBase.push({ id: 'Rci', value: "Id do Recurso", type: "text" });
            }

            setFields([...fieldBase]);
            if (pivotData && pivotData.length > 0 && accumulate) {
                const dataPivotDates = groupByMesAnoAndExtract(pivotData);
                const dataResult = [
                    ...responseAData.filter(c => !dataPivotDates.some(x => Number(x.Ano) === Number(c.Ano) && x.Mes === c.Mes)),
                    ...pivotData,
                ];
                return costCenterService.OrderDataDetailsAnalitico(dataResult);
            }

            return responseAData;
        } catch (error) {
            if (error && error.toString().includes('ERR_CERT_AUTHORITY_INVALID')) {
                alert(error);
            }
            console.error(error);
        }
        return [];
    },[accumulate, datePack, pivotData, user.ClientGroupSelected, user.UserId])


    const updateStructure = useCallback((name: string, value: string, abbreviation: string, initialStructure: any) => {
        const currentPath = location.pathname;
    
        const updateFilters = (additionalFilters: any[], excludeNames: string[]) => {
            return [
            ...additionalFilters,
            ...initialStructure.filters.filter((item) => !excludeNames.includes(item.name)),
            ];
        };
        const persistStructure = (structure: any) => {
            localStorage.setItem(
            `pivotConfig-${currentPath}-${user.ClientGroupSelected}-Analytical`,
            JSON.stringify(structure)
            );
            dispatch({ type: 'RESET_ANALYTICAL' });
        };
    
        let newStructure: any = {};
        const isCostCenterBudget = analytical.typeParam === 'costcenter_budget';
    
        if (isCostCenterBudget) {
            console.log(analytical)
            console.log(abbreviation)
            // const hasChildren = analytical.hasConstCenterChildren;
            const newFilters = updateFilters(
            // hasChildren
                // ? [{ name: abbreviation, value: value ? { includes: [value] } : undefined }, { name: 'Cdc' }]
                // : 
                [{ name: abbreviation, value: { includes: [value] } }],
                [abbreviation]
            // hasChildren ? ['parentCostCenter', 'Cdc'] : ['parentCostCenter']
            );
    
            newStructure = {
            rows: ['Cdc'],
            columns: initialStructure.columns,
            filters: newFilters,
            groupBy: initialStructure.groupBy,
            values: [
                { name: 'Vbr', operation: 'sum' },
                { name: 'Bdg', operation: 'max' },
            ],
            };
        } else {
            const newFilters = updateFilters(
            [{ name: abbreviation, value: { includes: [value] } }],
            [abbreviation]
            );
    
            newStructure = {
            rows: [abbreviation, ...initialStructure.rows.filter((item) => item !== abbreviation)],
            columns: initialStructure.columns,
            filters: newFilters,
            groupBy: initialStructure.groupBy,
            values: initialStructure.values,
            };
        }
    
        persistStructure(newStructure);
        return newStructure;
        }, [analytical.hasConstCenterChildren, analytical.typeParam, dispatch, location.pathname, user.ClientGroupSelected]
    );

    const reduxFilter = useMemo(() => (initialStructure: any, pivotStructure: any) => {
        const resetAndUpdate = (updates: Array<[string, string | undefined, string]>) => {
            let structure = JSON.parse(JSON.stringify(pivotStructure)); // Deep clone para "zerar" a estrutura
            updates.forEach(([abbr, value, field]) => {
                if (value !== undefined) {
                    structure = updateStructure(abbr, value, field, structure);
                }
            });
            return structure;
        };
        const singleFieldUpdates: Array<[string, string | undefined, string]> = [
            ["Rec", analytical.resource, "Rec"],
            ["Srv", analytical.service, "Srv"],
            ["Rgn", analytical.region, "Rgn"],
            ["Acn", analytical.account, "Acn"],
            ["Cdc", analytical.costcenter, "Cdc"]
        ];

        const combinedUpdates: { [key: string]: Array<[string, string | undefined, string]> } = {
            'service_costcenter': [["Cdc", analytical.costcenter, "Cdc"], ["Srv", analytical.service, "Srv"]],
            'costcenter_service': [["Srv", analytical.service, "Srv"], ["Cdc", analytical.costcenter, "Cdc"]],
            'service_resource': [["Rec", analytical.resource, "Rec"], ["Srv", analytical.service, "Srv"]],
            'costcenter_resource': [["Rec", analytical.resource, "Rec"], ["Cdc", analytical.costcenter, "Cdc"]],
            'account_service': [["Srv", analytical.service, "Srv"], ["Acn", analytical.account, "Acn"]],
            'region_service': [["Srv", analytical.service, "Srv"], ["Rgn", analytical.region, "Rgn"]]
        };
        if (analytical.typeParam === undefined || analytical.typeParam === '') {
            initialStructure = resetAndUpdate(singleFieldUpdates.filter(([, value]) => value && value !== ''));
        } else if (combinedUpdates[analytical.typeParam]) {
            initialStructure = resetAndUpdate(combinedUpdates[analytical.typeParam]);
        } else if (analytical.typeParam === 'costcenter_budget' && analytical.costcenter != null) {
            initialStructure = resetAndUpdate([["Cdc", analytical.costcenter, "Cdc"]]);
        }

        return initialStructure;
    },[analytical, updateStructure])

    useEffect(() => {
        if (!pivotData || pivotData.length === 0 || !fields) return;
        
        const pivotStructureBase = {
            rows: ["Prv", "Acn", "Srv"],
            columns: accumulate ? ["Ano", "Mes"] : ["Mes"],
            values: showMargin
            ? [
                { name: "Cst", operation: ["sum"] },
                { name: "Tax", operation: ["sum"] },
                { name: "Mrg", operation: ["sum"] },
                { name: "Vbr", operation: ["sum"] },
                ]
            : [{ name: "Vbr", operation: ["sum"] }],
            filters: [
            { name: "Prv" },
            { name: "Acc" },
            { name: "Cdc" },
            { name: "Ctr" },
            { name: "Srv" },
            { name: "Cat" },
            { name: "Mes" },
            { name: "Dia" },
            { name: "Rec" },
            ],
        };
        
        const currentPath = location.pathname;
        const clientGroupSelected = sessionStorage.getItem("ClientGroupSelected");
        const storedConfigKey = `pivotConfig-${currentPath}-${clientGroupSelected}-Analytical`;
        const storedConfig = localStorage.getItem(storedConfigKey);
        
        let savedConfig = storedConfig ? JSON.parse(storedConfig) : null;
        if (savedConfig && !isValidStructure(savedConfig, fields)) {
            savedConfig = null;
        }
        
        if (savedConfig?.filters) {
            savedConfig.filters = savedConfig.filters.filter((filter: any) =>
            isValidFilter(filter, pivotData)
            );
        }
        let initialStructure = savedConfig || pivotStructureBase;
        const hasNonEmptyValues = Object.entries(analytical).some(
            ([key, value]) =>
            !["id", "hasConstCenterChildren"].includes(key) &&
            value !== "" &&
            value !== null &&
            value !== undefined
        );
        
        if (hasNonEmptyValues) {
            setSelectedView(false);
            localStorage.removeItem('visionSelected');
            initialStructure = reduxFilter(initialStructure, pivotStructureBase);
        }
        
        setPivotStructure(initialStructure);
        }, [
        analytical,
        location.pathname,
        pivotData,
        fields,
        reduxFilter,
        accumulate,
        showMargin,
    ]);
    useEffect(() => {
        if (!pivotData || pivotData.length === 0 || !fields || refreshPivot === 0) return;
        
        const selectedViewSaved = localStorage.getItem('visionSelected');
        let selectedViewConfig = selectedViewSaved ? JSON.parse(selectedViewSaved) : null;
        if(selectedViewConfig && isValidStructure(selectedViewConfig, fields)) {
            setPivotStructure(selectedViewConfig);
            setRefreshPivot(0);
        } else {
            setPivotStructure( {
                rows: ["Prv", "Acn", "Srv"],
                columns: accumulate ? ["Ano", "Mes"] : ["Mes"],
                values:
                    showMargin ?
                        [{ name: "Cst", operation: ["sum"] },
                        { name: "Tax", operation: ["sum"] },
                        { name: "Mrg", operation: ["sum"] },
                        { name: "Vbr", operation: ["sum"] }] : [{ name: "Vbr", operation: ["sum"] }],
                filters: [
                    { name: "Prv" },
                    { name: "Acc" },
                    { name: "Cdc" },
                    { name: "Ctr" },
                    { name: "Srv" },
                    { name: "Cat" },
                    { name: "Mes" },
                    { name: "Dia" },
                    { name: "Rec" }
                ]
            })
        }
    },[accumulate, fields, pivotData, refreshPivot, showMargin])

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setDatePack({ month: date.split('-')[1], year: date.split('-')[0], clientGroupId: user.ClientGroupSelected, refresh: '', accumulate: accumulate });
        setLoading(true)
    };
    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    // useEffect(() => {
    //     // console.log('isRefetchingKPIs' + isRefetchingKPIs + 'isLoadingKPIs' + loadingpivotData)
    //     setLoading(loadingpivotData || isRefetchingKPIs);
    // }, [loadingpivotData, isRefetchingKPIs])

    const transformObjectKeys = useMemo(() => (object: FinOpsPivotDataResult) => {
        if (!fields) {
            return [];
        }
        const transformedObject = {};
        Object.keys(object).forEach(key => {
            const field = fields.find(field => field.id === key);
            if ((key === 'Dat' || key === '_1' || key === '_2' || key === '_3' || key === '_4')) {
                return;
            }
            if (field) {
                transformedObject[field.value] = object[key];
            }
        });
        return transformedObject;
    }, [fields])

    const handleDownload = useCallback(() => {
        var jsonData: any[] = [];
        pivotData?.forEach((row) => {
            var line = transformObjectKeys(row);
            if (line) {
                jsonData.push(line);
            }
        });
        const csvString = convertToCSV(jsonData);
        const BOM = "\uFEFF"; // UTF-8 Byte Order Mark
        const blob = new Blob([BOM + csvString], { type: 'text/csv;charset=utf-8;' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = "data.csv"; // Specify the download filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    },[pivotData, transformObjectKeys]);
    

    return (<Layout
        functionsGeneric={user.IsAdm ? [
        { icon: !accumulate ? <PiCalendarBlankLight /> : <PiCalendarPlusLight />, onClick: () => { setAccumulate(!accumulate); }, tooltip: "Acumular períodos" },
        { icon: <PiCloudArrowDownLight />, onClick: () => { handleDownload(); }, tooltip: "Download CSV" },
    ]
        : 
        [{ icon: !accumulate ? <PiCalendarBlankLight /> : <PiCalendarPlusLight />, onClick: () => { setAccumulate(!accumulate); }, tooltip: "Acumular períodos" }]}
        pivot pageTitle={'Dash'} colorIcon={selectedView ? '#FFBE15' : ''} periodSelection={periodSelection} >
        <LcLoading loading={loadingpivotData || isRefetchingKPIs || loading} loadingType='Helix' >
            {fields && fields.length > 0 && pivotData && pivotData.length > 0
                ? <PivotTableWebix
                    idView='Analytical'
                    setSelectedView={setSelectedView}
                    key={"1"}
                    data={pivotData}
                    fields={fields}
                    classTable='analytical'
                    // mode="tree"
                    chart={{ scale: 'logarithmic', yAxis: { start: 0 } }}
                    structure={pivotStructure}
                    setRefreshPivot={setRefreshPivot}
                /> : <LcNoData />}
        </LcLoading>
    </Layout>);
};
export default FinOpsAnalyticDashBoard;