import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';
import styles from './index.module.css';
import { TechEAEASService } from '../../../services/EASubscriptionService/techEAEASService';
import DoughNutEChart from '../../../components/Data/Dashboard/DoughnutEchart';
import LcNoData from '../../../components/Generic/LcNoData';
import LineChart from '../../../components/Data/Dashboard/LineChart';
import moment from 'moment';
import { formatCurrency, formatNumber, getDateMinusMonths } from '../../../services/toolsService';
import { PiPercentLight, PiUserLight } from 'react-icons/pi';

const LicenseControlManager: React.FC = (props) => {
    let service = new TechEAEASService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [ql, setQL] = useState<any[]>([]);
    const [arrayOfLicensesPosition, setArrayOfLicensesPosition] = useState<any[]>([]);
    const [arrayOfLicensesLocation, setArrayOfLicensesLocation] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reportYear, setReportYear] = useState<number>(new Date().getFullYear());
    const [countries, setCountries] = useState<any[]>(["BR"]);
    const [selectCountry, setSelectCountry] = useState<string>('BR');
    const [totalGlobal, setTotalGlobal] = useState<number>(0);
    const [totalHiredGlobal, setTotalHiredGlobal] = useState<number>(0);
    const [listExtras, setListExtras] = useState<any[]>([]);
    const [totalGlobalQtd, setTotalGlobalQtd] = useState<number>(0);
    const [showPercent, setShowPercent] = useState<boolean>(true);
    //#endregion

    const processDashboardData = async (rawData: any[]) => {

        var totalGlobal = 0.00;
        var totalHiredGlobal = 0.00;
        var totalGlobalQtd = 0.00;

        var countriesLocal: string[] = [];
        rawData.map((item) => {
            countriesLocal.push(item.country);
        });

        setCountries(countriesLocal);
        var countryFilter = "BR";
        if (selectCountry) {
            countryFilter = selectCountry;
        }
        totalGlobal = rawData.filter(b => b.country == countryFilter)[0].hiredCost;
        totalHiredGlobal = rawData.filter(b => b.country == countryFilter)[0].hiredCost;
        totalGlobalQtd = rawData.filter(b => b.country == countryFilter)[0].used;
        var total = rawData.filter(b => b.country == countryFilter)[0].usedCost;
        var arrayOfLicensesLocation = rawData.filter(b => b.country == countryFilter)[0].byGroupOfficeLocation.map((item) => {
            return {
                label: item.officeLocation,
                value: (total > 0 ? item.usedCost / total : 0) * 100
            };
        });

        if (countryFilter == "BR" && user.ClientGroupSelected == 152) {
            var arrayOfLicensesLocationData = [
                { label: 'BR10', value: ((13997217.71)) },
                { label: 'BR12', value: ((6902313.54)) },
                { label: 'BR14', value: ((4015272.22)) },
                { label: 'BR53', value: ((676399.82)) },
                { label: 'BR58', value: ((40182.17)) },
                { label: 'BR59', value: ((60273.25)) }];
            arrayOfLicensesLocation = [
                { label: 'BR10', value: ((13997217.71 / 25691658.71 * 100)) },
                { label: 'BR12', value: ((6902313.54 / 25691658.71 * 100)) },
                { label: 'BR14', value: ((4015272.22 / 25691658.71 * 100)) },
                { label: 'BR53', value: ((676399.82 / 25691658.71 * 100)) },
                { label: 'BR58', value: ((40182.17 / 25691658.71 * 100)) },
                { label: 'BR59', value: ((60273.25 / 25691658.71 * 100)) }];
            var totalGlobalV = arrayOfLicensesLocationData.reduce((sum, item) => sum + item.value, 0);
            var dife = totalGlobal - totalGlobalV;
            console.log(dife);
        }

        try {
            var responseQueryRecordAdicionais = user && await service.QueryRecordAdicionais();
            if (responseQueryRecordAdicionais && responseQueryRecordAdicionais.data && responseQueryRecordAdicionais.data.length > 0) {
                var recordsQueryRecordAdicionaisL = responseQueryRecordAdicionais.data.filter(x => x.country == countryFilter);
                console.log(recordsQueryRecordAdicionaisL);
                setListExtras(recordsQueryRecordAdicionaisL)
                console.log(recordsQueryRecordAdicionaisL);
            }
        } catch (e) {
            console.debug(e)
        }

        var arrayOfLicensesPosition = rawData.filter(b => b.country == countryFilter)[0].licenseGroup.filter(x => x.grupoAvaliableforExecutives == true).map((item) => {
            if (showPercent && item.postions) {
                let sum = item.postions.reduce((acc, current) => acc + current.value, 0);
                item.postions = item.postions.map(pos => {
                    return {
                        label: pos.label,
                        value: sum > 0 ? (pos.value / sum) * 100 : 0
                    };
                });
            }
            return item;
        });

        setTotalGlobalQtd(totalGlobalQtd);
        setArrayOfLicensesPosition(arrayOfLicensesPosition);
        setArrayOfLicensesLocation(arrayOfLicensesLocation);
        setTotalGlobal(totalGlobal);
        setReportYear(new Date().getFullYear());

        try {
            var responseQL = user && await service.QueryRecordQL();
            if (responseQL && responseQL.data && responseQL.data.length > 0) {
                var recordsQL = responseQL.data.filter(x => x.country == countryFilter);
                if (!recordsQL) {
                    recordsQL = responseQL.data.filter(x => x.country == "*");
                }

                const qlDataOrcado = recordsQL.map((item, index) => ({
                    name: moment(`${item.year}-${item.month}-01`).toDate(),
                    value: item.value
                }));
                console.log(qlDataOrcado);
                setQL(qlDataOrcado)
            }
        } catch (e) {

            console.debug(e)
        }

        try {
            var response = user && await service.QueryRecordOrcado();
            if (response && response.data && response.data.length > 0) {
                var recordsOrcado = response.data.filter(x => x.country == countryFilter);
                if (!recordsOrcado) {
                    recordsOrcado = response.data.filter(x => x.country == "*");
                }
                var totalHiredGlobalOrcado = recordsOrcado.sort((a, b) => { return a.month + a.year * 100 > b.month + b.year * 100 ? 1 : -1; })[0].value;
                setTotalHiredGlobal(totalHiredGlobalOrcado);
            }
            else {
                setTotalHiredGlobal(totalHiredGlobal);
            }

        } catch (e) {

            console.debug(e);
            setTotalHiredGlobal(totalHiredGlobalOrcado);
        }
    }

    const getData = async () => {
        setIsLoading(true);
        try {
            var UsersSaasLicenseByCountryCostCenterByClientGroupId = user && await service.GetUsersSaasLicenseByCountryCostCenterByClientGroupId();
            await processDashboardData(JSON.parse(UsersSaasLicenseByCountryCostCenterByClientGroupId.data));
        } catch (error) {

            console.debug(error)
        }
        setIsLoading(false);
    }

    const layoutButtons = [
        {
            icon: (showPercent ? <PiPercentLight /> : <PiUserLight />),
            tooltip: (showPercent ? 'Ver por quantidade' : 'Ver por porcentagem'),
            onClick: () => setShowPercent(!showPercent)
        }
    ];

    useEffect(() => {
        getData();
    }, [user.ClientGroupSelected, user.refreshFlag, selectCountry, showPercent]);

    const onChageData = (selected: any) => {
        const firstFieldValue = selected.fields[0]?.value;
        const firstFieldName = selected.fields[0]?.name;
        setSelectCountry(firstFieldName);
    };

    const periodSelection = {
        periods: countries.map((item, index) => {
            return ({
                label: item, text: item,
                fields: [
                    { name: item, value: item }
                ],
                default: item == "BR" ? true : false
            });
        }),
        customPeriodType: 'month',
        onChange: onChageData
    };

    const dashboardCards = [
        { //Licenças Adquirido/Utilizado
            type: "Custom",
            position: {
                RowSpan: 1,
                ColSpan: 10, row: 1, col: 1
            },

            title: () => <span style={user.ClientGroupSelected == 152 && !user.email?.includes('processor') && !user.email?.includes('gotobiz') ? { color: "#273B85", fontWeight: '600' } : undefined} >Licenças Adquirido/Utilizado</span>,
            customContentRender: () =>
                <div className={'scrollable-h'} style={{ padding: '5px', width: '100hw', height: '100%', display: 'flex', flexDirection: 'row', placeContent: 'space-evenly' }} >
                    <div style={{ height: '100%', alignItems: 'center', display: 'flex', flexDirection: 'row', placeContent: 'space-evenly', minWidth: '100%' }}  >
                        {arrayOfLicensesPosition ? arrayOfLicensesPosition.sort((a, b) => { return b.hired - a.hired > 0 ? 1 : -1; }).map((item) =>
                            <div style={{ height: 'fit-content', display: 'flex', flexDirection: 'column' }} >
                                <div style={{ color: ((item.hired - item.used > 0) ? '#0068b2' : '#EE6666') }} data-title={'Diferença:' + ((item.hired - item.used > 0) ? '+' : '') + formatNumber(item.hired - item.used)} className={styles.cardSubTitle} > {item.groupName} </div>
                                <div style={{ fontWeight: '500', fontFamily: 'Ubuntu', width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
                                    <div data-title={formatCurrency(item.hiredCost)} style={{ padding: '5px', textAlign: 'right', width: '50%', height: '100%', display: 'flex', flexDirection: 'column' }}
                                        className={styles.licenseSummaryLine}>
                                        <div style={{ fontWeight: '800', height: 'auto', display: 'flex', flexDirection: 'column' }}>
                                            {item.hired}</div>
                                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>Adquirido</div>
                                    </div>
                                    <div data-title={formatCurrency(item.usedCost)} style={{ padding: '5px', width: '50%', height: '100%', display: 'flex', flexDirection: 'column' }}
                                        className={styles.licenseSummaryLine}>
                                        <div style={{ fontWeight: '800', height: 'auto', display: 'flex', flexDirection: 'column' }} >{item.used}</div>
                                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>Utilizado</div>
                                    </div>
                                </div>
                            </div>) : <LcNoData />}
                    </div>
                </div>
        },
        { //PEX {(new Date()).getFullYear()} {selectCountry} (R$)
            bgColor: user.ClientGroupSelected == 152 ? '#5470C6' : 'var(--color-primary-purple)',
            fontColor: '#FFFFFF',
            type: "Custom",
            position: {
                RowSpan: 1,
                ColSpan: 2, row: 1, col: 11
            },
            title: () => <div className={'lc-title ellipsis'} style={{ color: '#FFFFFF', textAlignLast: 'center', minWidth: '100%' }}> <span style={{ color: '#FFFFFF', textAlignLast: 'center', minWidth: '100%' }}>PEX {getDateMinusMonths(1, new Date()).getFullYear()} {selectCountry} (R$)</span></div>,
            customContentRender: () =>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ fontWeight: '800', fontFamily: 'Ubuntu', width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ textAlign: 'right', padding: '5px', width: '50%', height: '100%', display: 'flex', flexDirection: 'column' }}
                            className={styles.licenseSummaryLine}>
                            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>Realizado</div>
                            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}> {formatNumber(totalGlobal + listExtras.reduce((acc, b) => acc + b.valueUnit, 0))}</div>
                        </div>
                        <div style={{ padding: '5px', textAlign: 'left', width: '50%', height: '100%', display: 'flex', flexDirection: 'column' }}
                            className={styles.licenseSummaryLine}>
                            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>Orçado</div>
                            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>{formatNumber(totalHiredGlobal)}</div>
                        </div>
                    </div>
                </div>
        },
        { //Licença posição
            type: "Custom",
            position: {
                RowSpan: 2,
                ColSpan: 10, row: 2, col: 1
            },
            title: () => <div style={user.ClientGroupSelected == 152 && !user.email?.includes('processor') && !user.email?.includes('gotobiz') ? { color: "#273B85", fontWeight: '600' } : undefined} >Licença posição</div>,
            customContentRender: () =>
                <div className={'scrollable-h'} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }} >
                    <div style={{ height: '95%', display: 'flex', flexDirection: 'row' }}  >
                        {
                            arrayOfLicensesPosition ?
                                arrayOfLicensesPosition
                                    .sort((a, b) => { return b.hired - a.hired > 0 ? 1 : -1; })
                                    .map((item, index) => (
                                        <div key={index} style={{ width: '370px', height: '130%', display: 'flex', flexDirection: 'column' }} >
                                            <div style={{ color: ((item.hired - item.used > 0) ? '#0068b2' : '#EE6666') }} data-title={`Contratado: ${item.hired}, Disponivel: ${item.used}, Diferença ${item.hired - item.used}`} className={styles.cardSubTitle}>
                                                {item.groupName}
                                            </div>
                                            <DoughNutEChart offsetPie={{ bottom: 40 }} ArrayData={item.postions} decimals={0} showPercent={showPercent} totalNumber={item.used} />
                                        </div>
                                    ))
                                :
                                <LcNoData />
                        }
                    </div>
                </div>
        },
        { //Realizado por empresa
            showFocusButton: true,
            type: "Custom",
            position: {
                RowSpan: 2,
                ColSpan: 2, row: 2, col: 11
            },
            title: () => <div className={'lc-title ellipsis'} style={user.ClientGroupSelected == 152 && !user.email?.includes('processor') && !user.email?.includes('gotobiz') ? { color: "#273B85", fontWeight: '600', textAlignLast: 'center', minWidth: '100%' } : { textAlignLast: 'center', minWidth: '100%' }} >Realizado por empresa</div>,
            customContentRender: (focused?: boolean) => {
                const chart = <DoughNutEChart
                    ArrayData={arrayOfLicensesLocation}
                    showPercent={false}
                    totalNumber={totalGlobal + listExtras.reduce((acc, b) => acc + b.valueUnit, 0)}
                    legendOrientation="horizontal"
                />

                return arrayOfLicensesLocation ?
                    (focused ? chart : <div className={styles.executedByCompanyContainer}>{chart}</div>) :
                    <LcNoData />
            }
        },
        { //Acompanhamento QL {selectCountry}
            type: "Custom",
            position: {
                RowSpan: 1,
                ColSpan: 10, row: 4, col: 1
            },
            title: () => <span style={user.ClientGroupSelected == 152 && !user.email?.includes('processor') && !user.email?.includes('gotobiz') ? { color: "#273B85", fontWeight: '600' } : undefined} >Acompanhamento QL {selectCountry}</span>,
            customContentRender: () =>
                <div style={{ minHeight: '50px', height: '100px' }}>
                    <LcLoading loading={false} label="Carregando gráfico...">
                        {
                            ql ?
                                <LineChart data={ql.sort((a, b) => { return a.name > b.name ? 1 : -1; })} showline={true} showDays={false} />
                                :
                                <LcNoData />
                        }
                    </LcLoading>
                </div>
        },
        { //Compras adicionais
            type: "Custom",
            position: {
                RowSpan: 1,
                ColSpan: 2, row: 4, col: 11
            },
            title: () => <div className={'lc-title ellipsis'} style={user.ClientGroupSelected == 152 && !user.email?.includes('processor') && !user.email?.includes('gotobiz') ? { color: "#273B85", fontWeight: '600', textAlignLast: 'center', minWidth: '100%' } : { textAlignLast: 'center', minWidth: '100%' }} > Compras adicionais</div>,
            customContentRender: () =>
                <div className={`${styles.summaryContainer} scrollable-v`} style={{ display: 'flex', minHeight: '100%' }}>
                    <div style={{ display: 'flex' }} >
                        {!listExtras && <span className={styles.licenseSummaryLine}>(Nenhuma compra adicional.)</span>}
                        <div style={{ flexDirection: 'column' }}>
                            {
                                listExtras && listExtras
                                    .filter(item => (item.quantidade * item.valueUnit) == 0 || user.ClientGroupSelected != 152)
                                    .map((item) => (
                                        <div className={styles.summaryLineByCountry} style={{ flexDirection: 'row' }}>
                                            {item.eaSubscriptionName} {(item.quantidade * item.valueUnit) > 0 ? ' ' + formatCurrency(item.valueUnit * item.quantidade) : ' '}
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                </div>
        }
    ]

    return (
        <Layout pageTitle='Pais/Licenças' periodSelection={periodSelection} functionsGeneric={layoutButtons}>
            <LcLoading loading={isLoading} loadingType='Helix'>
                <LCDashboard cards={dashboardCards} rightSidePanel={{ title: "", content: () => { }, pinned: false, show: false }} />
            </LcLoading>
        </Layout>
    )
}

export default LicenseControlManager;