import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { Grid } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';

//Redux
import { useSelector } from 'react-redux';
import { UserState } from '../../store/reducers/userReducer';
import { RootState } from '../../store/index';
import styles from './index.module.css'

import { GTMConfigurationService } from '../../services/gtmConfigurationService/gtmConfigurationService';

import SideModal from '../../components/Layout/SideModal/SideModal';
import { useSnackbar } from 'notistack';
import { InputField, SelectField } from '../../components/Form/Input';
import LcCheckBox from '../../components/Form/LcCheckBox';
import LCFlexContainer from '../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../components/Layout/LCFlexItem';
import DialogConfirmForm from '../../components/Layout/Dialog/DialogConfirmForm';
import LcInfiniteTable from "../../components/Data/LcInfiniteTable";
import LcLoading from '../../components/Generic/LcLoading';
import ReactHtmlParser from 'react-html-parser';

const GTMConfiguration: React.FC = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [rows, setRows] = useState<any>([])
    const [filteredRows, setFilteredRows] = useState<any>([])
    const [visibleFilteredRows, setVisibleFilteredRows] = useState<any>([])
    const [counter, setCounter] = useState<number>(0)
    const [clients, setClients] = useState<any>([])
    const [webSites, setWebSites] = useState<any>([])
    const [gtms, setGtms] = useState<any>([{ name: 'Web Presence', value: 2 }])
    const { enqueueSnackbar } = useSnackbar();
    const service = new GTMConfigurationService(props);
    const [sideModalLabel, setSideModalLabel] = useState<string>('')
    const [selected, setSelected] = useState<any>();
    const [selectedID, setSelectedID] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    //?GTM TEMPORARIAMENTE COMO 2-WEB PRESENCE fixado
    const [gtmSelected, setGtmSelected] = useState<number>(2)
    const [gtmLabel, setGtmLabel] = useState<string>('')
    const [sideModalVisible, setSideModalVisible] = useState<boolean>(false)

    //?CONTEÚDO DA SIDEMODAL DE CONFIGURAÇÃO DE GTM
    const [clientSelectedSideModal, setClientSelectedSideModal] = useState<any>()
    const [gtmSelectedSideModal, setGtmSelectedSideModal] = useState<any>()
    const [sideModalVisibleFunction, setSideModalVisibleFunction] = useState<boolean>(false)
    const [contracts, setContracts] = useState<any>()
    const [domains, setDomains] = useState<any[]>([])
    const [domainsOpt, setDomainsOpt] = useState<any>()
    const [costCenterOpt, setCostCenterOpt] = useState<any>()
    const [hostGroupOpt, setHostGroupOpt] = useState<any>()
    const [isInDeployment, setIsInDeployment] = useState<boolean>(false)
    const [allConfiguration, setAllConfiguration] = useState<any[]>([])
    const [open, setOpen] = useState(false);
    const [idsToDelete, setIdsToDelete] = useState<string[]>(['']);
    const [LoadingForm, setLoadingForm] = useState<boolean>(false);
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 20, term: "", column: "", direction: "asc" });

    useEffect(() => {
        Promise.all(
            [
            getClientsActive(),
            getGtmByClientGroup()
        ]
        )
    }, [user.ClientGroupSelected, user.refreshFlag, gtmSelected, counter,])

    const getGtmByClientGroup = async() => {
        await service.GetGtmByClientGroup(-1).then(result => {
            if (result.status == 200) {
                let formatedResult: any = []
                result.data.dataCollection.map((e: any, index: any) => {
                    let label = e.label.toString().replace(`${e.value} - `, '')
                    let gtmsLabel = label.split(',')
                    let array: any = []
                    e.gtmids.map((_e: any, index: any) => {
                        array.push(
                            {
                                contract: e.value,
                                gtmId: _e,
                                gtm: gtmsLabel[index]
                            }
                        )
                    })
                    formatedResult.push(array)
                })
                console.debug("formatedResult", formatedResult)
            }
        }).catch((error) => {
            console.error('GetGtmByClientGroup Erro:' + error)
        });
    }

    const getClientsActive = async () => {
        await service.getClientActive().then(result => {
            if (result.status == 200) {
                let arrayClient = result.data.map((e: any) => {
                    return (
                        {
                            name: e.description,
                            value: e.id
                        }
                    )
                });
                arrayClient = arrayClient.sort(function (a, b) {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 0;
                });
                setClients(arrayClient)
                effectsWebPresence(arrayClient)
            }
        }).catch((error) => {
            console.error('getClientActive Erro:' + error)
        });
    }

    useEffect(() => {
        var filtered = rows.sort((a: any, b: any) => {
            if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
            else return (a[filter.column] > b[filter.column]) ? -1 : 1;
        });

        setFilteredRows(filtered);
        setVisibleFilteredRows(filtered.slice(0, filter.size));
    }, [filter, filteredRows.length > 0]);

    const effectsWebPresence = ( clientArray: any[]) => {
        setGtmLabel('Web Presence')
        setLoadingForm(true);
        // setFilteredRows([]);
        service.GetGtmConfiguration(gtmSelected).then(result => {
            if (result.status == 200) {
                let data = result.data.map((e: any, index: number) => {
                    let objetoKeys = JSON.parse(e.keys)
                    let clientName =  clientArray.find(i=> i.value === e.clientGroupID).name;
                    return {
                        id: e.id,
                        clientGroupName: clientName.charAt(0).toUpperCase() + clientName.slice(1),
                        enabled: e.enabled,
                        GTMConfig: objetoKeys.map(gtm => {
                            return ReactHtmlParser( `CostCenter:${gtm.costCenter} </br>
                                    HostGroup: ${gtm.hostGroup} </br>
                                    DashboardLiveWatch: ${gtm.dashboardLiveWatch} </br>
                                    domain: ${gtm.domain} </br>
                                    isInDeployment: ${gtm.isInDeployment} </br></br>
                            `)
                        })
                     }
                })
                setRows(data)
                setFilteredRows(data);
                setFilter({ ...filter });
            }
        }).catch(error => {
            console.error(error)
            enqueueSnackbar('Houve um erro ao obter as configurações de GTMs', {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            })
        })
        .finally(() => setLoadingForm(false));

    }

    const columns = [
        { field: 'clientGroupName', headerName: 'Cliente', width: "30%", },
        { field: 'GTMConfig', headerName: 'Configuração para GTM', width: "100%"},
        {
            field: 'enabled', headerName: 'Ativo', width: "7%",
            renderCell: (row: any) => {
                if (row.enabled) return <p>Sim</p>;
                else return <p>Não</p>;
            }
        }
    ];

    const functionsGeneric = [
        {
            id: "administracao_criarconfiguracao_button",
            title: 'Criar configuração',
            icon: 'ppi ppi-plus',
            tooltip: "Criar configuração",
            func: () => setSideModalVisible(!sideModalVisible)
        }
    ];

    const onCloseSideModal = () => {
        setSideModalVisible(!sideModalVisible)
    }
    const onCloseSideModalFunction = () => {
        setSideModalVisibleFunction(!sideModalVisibleFunction);
        setSideModalLabel("");
    }

    const onChangeSelectField = (fieldOpt: any) => {
        switch (fieldOpt.name) {
            case "ClientGroup":
                setClientSelectedSideModal(fieldOpt)
                break;

            case "GTM":
                setGtmSelectedSideModal(fieldOpt)
                break;

            case "Contract":
                onChangeContract(fieldOpt)
                break;

            default:
                break;
        }
    }

    function handleChangeSelect(fieldOpt: any) {

        const { name, value } = fieldOpt;
        let d = name.toString().split('_')[1]
        if (d) {
            const id = d as number;
            let _allConfiguration: any[] = allConfiguration;
            if (value) {
                _allConfiguration[id][name.toString().split('_')[0]] = value;
                setAllConfiguration([..._allConfiguration]);
            }
        }
    };

    const onChangeContract = (fieldOpt: any) => {
        let _d = domains.find(x => x.contractNumber == fieldOpt.value)
        if (_d) {
            let opt: any = []
            _d.domains.map((_e: any, index: any) => {
                opt.push(
                    { name: _e, value: _e }
                )
            })
            console.debug('d opt', opt)
            setDomainsOpt(opt)
        }
    }

    const onOpenSideModalEditFunction = async (row: any) => {

        setSideModalLabel("Editar");
        cleanData();
        setSideModalVisibleFunction(!sideModalVisibleFunction);
        let client = { name: "ClientGroup", value: row.clientGroupID.toString() };
        const clientSelectedSideModal = client;
        setClientSelectedSideModal(client);
        let gtm = { name: "GTM", value: row.gtmid.toString() };
        setGtmSelectedSideModal(gtm);
        setSelectedID(row.id);

        if (clientSelectedSideModal && clientSelectedSideModal.value) {
            setLoading(true);
            await Promise.all([retrieveWebSites(clientSelectedSideModal.value),
            retrieveHostGroups(clientSelectedSideModal.value),
            retrieveCostCenter(clientSelectedSideModal.value)]);
            if (row && row.configKeys) {

                try {
                    let linha = JSON.parse(row.configKeys);
                    setAllConfiguration(linha);
                    setSelected(linha);
                } catch (e) {

                }

            }
            setLoading(false);
        }
    }

    const functionsRow = [
        {
            label: 'Editar',
            fullRowAsParam: true,
            func: (row: any) => onOpenSideModalEditFunction(row)
        },
        {
            label: 'Excluir',
            fullRowAsParam: true,
            func: (row: any) => deleteLinha(row)
        }
    ]

    const onOpenSideModalFunction = () => {
        setSideModalLabel("Criar");
        setSideModalVisibleFunction(!sideModalVisibleFunction);
        onOpenSideModal();
    }

    const onOpenSideModal = async () => {
        cleanData();
        setLoading(true);
        if (clientSelectedSideModal && clientSelectedSideModal.value) {
            await Promise.all([retrieveWebSites(clientSelectedSideModal.value), retrieveCostCenter(clientSelectedSideModal.value), retrieveHostGroups(clientSelectedSideModal.value)])
            .catch((error) => console.error(error))
            .finally(() =>setLoading(false) );
        }
    }

    function cleanData() {
        setAllConfiguration([]);
        setContracts([]);
        setDomains([]);
        setHostGroupOpt([]);
        setCostCenterOpt([]);
        setSelected({});
    }

    async function retrieveWebSites(value) {
        try {
            var result = await service.GetWebSites(value);
            let contractOpt: any = [];
            let _domains: any = [];
            result.data.forEach((e: any, index: number) => {
                let domains = e.domains.toString().split(";")
                contractOpt.push(
                    {
                        name: e.contract,
                        label: e.contract
                    }
                );
                domains.forEach((d: any, dIndex: number) => {
                    _domains.push(
                        {
                            contractNumber: e.contract,
                            domain: d.trim()
                        }
                    )
                });
            })
            let auxList: any[] = [];
            _domains.forEach(element => {
                let aux = {
                    costCenter: "",
                    hostGroup: "",
                    dashboardLiveWatch: "",
                    isInDeployment: false,
                    domain: element.domain.trim()
                };
                auxList.push(aux);
            });
            setAllConfiguration(auxList);
            setContracts(contractOpt);
            setDomains(_domains);
            console.debug("_domains", _domains);
        } catch (e) {
            console.error(e);
        }
    }

    async function retrieveHostGroups(value) {
        try {
            var result = await service.GetHostGroups(value);
            let opt: any = []
            result.data.map((e: any, index: number) => {
                opt.push(
                    {
                        name: e.name,
                        value: e.name
                    }
                )
            })
            setHostGroupOpt(opt);
        } catch (e) {

        }
    }

    async function retrieveCostCenter(value) {
        try {
            var result = await service.GetCostCenter(value);
            console.debug('CostCenter', result.data);
            let opt: any = []
            result.data.map((e: any, index: number) => {
                opt.push(
                    {
                        name: e.name,
                        value: e.id
                    }
                )
            })
            setCostCenterOpt(opt);
            console.debug('costCenter Opt', opt);
        } catch (e) {

        }

    }
    const onChangeurl_DashboardLiveWatch = (fieldOpt: any) => {
        const d = fieldOpt.name.toString().split('_')
        const id = d[1] as number
        let currentRow = allConfiguration.find(x => x.id == id)
        if (currentRow) {
            currentRow[d[0]] = fieldOpt.value
            let r = allConfiguration.filter(x => x.id != id)
            r.push(currentRow)
            console.debug('newR', r)
        }
    }

    const onChangeIsInDeployment = (fieldOpt: any) => {
        const { name, value, checked } = fieldOpt;
        let d = name.toString().split('_')[1]
        if (d) {
            const id = d as number;
            let _allConfiguration: any[] = allConfiguration;
            _allConfiguration[id][name.toString().split('_')[0]] = !checked;
            setAllConfiguration([..._allConfiguration]);
        }
    }

    const saveRecord = () => {

        let record = {
            ClientGroupID: clientSelectedSideModal.value,
            GTMID: gtmSelectedSideModal.value,
            Enabled: true,
            Keys: JSON.stringify(allConfiguration)
        };
        service.SaveGTMConfiguration(record)
            .then(response => {
                setSideModalVisibleFunction(!sideModalVisibleFunction);
                setCounter(Math.random());
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar('Houve um erro ao criar as configurações de GTM.', {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                })
            });
    }

    const deleteRecord = (id) => {

        service.DeleteGTMConfiguration(id)
            .then(response => {
                setCounter(Math.random());

            })
            .catch(error => {
                enqueueSnackbar('Houve um erro ao deletar as configurações de GTM.', {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                })
                console.log(error);
            });
    }

    const editRecord = () => {

        let record = {
            ID: selectedID,
            ClientGroupID: clientSelectedSideModal.value,
            GTMID: gtmSelectedSideModal.value,
            Enabled: true,
            Keys: JSON.stringify(allConfiguration)
        };

        service.EditGTMConfiguration(record)
            .then(response => {

                setSideModalVisibleFunction(!sideModalVisibleFunction);
                enqueueSnackbar('Atualização das configurações de GTM realizada com sucesso.', {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                })
                setCounter(Math.random());
            })
            .catch(error => {
                console.log(error);
                enqueueSnackbar('Houve um erro ao editar as configurações de GTM.', {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                })

            });
    }

    // useEffect(() => {

    // }, [contracts, domains]);

    const handleDeleteClickOpen = () => {
        setOpen(true);
    };

    const handleDeleteClose = async (deleteConfirm: boolean) => {
        if (deleteConfirm) {
            idsToDelete.map((iddel) => { deleteRecord(iddel); });
            setIdsToDelete([]);
        } else {
            setIdsToDelete([]);
        }
        setOpen(false);
    };
    const deleteLinha = (row: any) => {
        let ids: any[] = [];
        ids.push(row.id.toString());
        DeleteButtonCall(ids);
    }
    const DeleteButtonCall = async (ids: any[]) => {
        setIdsToDelete(ids);
        handleDeleteClickOpen();
    }

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleFilteredRows(filteredRows.slice(0, size))
        else {
            setVisibleFilteredRows([...visibleFilteredRows, ...filteredRows.slice(visibleFilteredRows.length, visibleFilteredRows.length + 10)])
        }
    };
    return (
        <Layout cardType pageTitle={`Configurações de GTM - ${gtmLabel}`} >
            <LcLoading  loading={LoadingForm} label='Carregando GTMs...'>
                <LcInfiniteTable
                    columns={columns}
                    functionsRow={functionsRow}
                    functionGeneric={functionsGeneric}
                    rows={visibleFilteredRows}
                    size={filteredRows.length}
                    loadMore={loadMore}
                    onSortChange={onSortChange} />
            </LcLoading>

            <DialogConfirmForm onCloseEvent={handleDeleteClose}
                open={open} confirmMessage='Confirmar' abortMessage='Cancelar' title='Confirmar?' content=''>
            </DialogConfirmForm>
            <SideModal header="Configuração de GTM" visible={sideModalVisible} onClose={onCloseSideModal}>
                <SelectField name="ClientGroup" options={clients} label="Cliente" onChange={onChangeSelectField} />
                <SelectField name="GTM" options={gtms} label="GTMs" onChange={onChangeSelectField} />
                <div className={styles.footerModalBtn}>
                    <button id="59e29e50-b995-4983-8062-bb12186ae702" className="lc-button bg-primary" onClick={onOpenSideModalFunction}>Configurar</button>
                    <button id="cf99a08c-107a-450e-abe9-b78b2b1f4b32" className="lc-button" onClick={onCloseSideModal}>Cancelar</button>
                </div>
            </SideModal>
            <SideModal header={`${sideModalLabel} configuração de GTM`} visible={sideModalVisibleFunction} onClose={onCloseSideModalFunction}>
                    {
                        !loading ?
                            domains.length > 0 && contracts.length > 0 ? <>     {
                                contracts.map((contract: any) => {
                                    return (
                                        <>
                                            <span className="label">Contrato Nº {contract.name}</span><br />
                                            <hr />
                                            {
                                                domains.map((e: any, index: number) => {
                                                    if (e.contractNumber == contract.name) {
                                                        var record = domains && domains.length && domains.filter(x => x.domain.trim() == e.domain.trim()) && domains.filter(x => x.domain.trim() == e.domain.trim()).length > 0 && domains.filter(x => x.domain.trim() == e.domain.trim())[0];
                                                        return (
                                                            <>  
                                                            {
                                                                <>
                                                                <span className={`label ${styles.webSitesLabel}`}>Configuração para o WebSite {e.domain}</span><br /><br />
                                                                {<SelectField value={record.costCenter} name={`costCenter_${index}_${e.domain.trim()}`} options={costCenterOpt} label="Centros de Custo" onChange={handleChangeSelect} />}
                                                                {<SelectField value={record.hostGroup} name={`hostGroup_${index}_${e.domain.trim()}`} options={hostGroupOpt} label="Host Group Master" onChange={handleChangeSelect} />}
                                                                <InputField value={record.dashboardLiveWatch} name={`dashboardLiveWatch_${index}_${e.domain.trim()}`} toolTip={true} toolTipText="Endereço da VM do Grafana, seguido pelo dashboard." onChange={handleChangeSelect} label="URL do LiveWatch Dashboard" type="text" placeHolder="Cole a url do Dashboard do Livewatch" />
                                                                <br />
                                                                <LcCheckBox checked={record.isInDeployment} title="Em implantação" name={`isInDeployment_${index}_${e.domain.trim()}`} onChange={onChangeIsInDeployment} />
                                                                <br />
                                                                <hr />
                                                                 </>
                                                            }
                                                            </>
                                                        )
                                                    }
                                                })
                                            }
                                        </>
                                    )
                                })
                            }
                                {
                                    domains.length > 0 &&
                                    <>
                                        <div className={styles.footerModalBtn}>
                                            <button className="lc-button bg-primary" onClick={sideModalLabel == "Criar" ? saveRecord : editRecord}>{sideModalLabel}</button>
                                            <button className="lc-button" onClick={onCloseSideModalFunction}>Cancelar</button>
                                        </div>
                                    </>
                                }</> :
                                <LCFlexContainer spacing={2}>
                                    <LCFlexItem xs={12} sm={12} md={6} lg={6}>
                                        <span className="label">Não existem informações. Contate o administrador.</span><br />
                                    </LCFlexItem>
                                </LCFlexContainer>
                            :
                            <>
                                <LCFlexContainer spacing={2}>
                                    <LCFlexItem xs={12} sm={12} md={6} lg={6}>
                                        <LinearProgress about="Carregando WebSites.." />
                                    </LCFlexItem>
                                </LCFlexContainer>
                            </>
                    }
            </SideModal>
        </Layout>
    );
};

export default GTMConfiguration;