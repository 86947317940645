
import {PiFileCsv,PiFileText,PiFileDoc, PiFileHtml, PiFileImage, PiFileJpg, PiFilePdf, PiFilePng, PiFilePpt, PiFileSvg, PiFileXls, PiFileZip } from 'react-icons/pi';
import LcIconLink from '../../components/Generic/LcIconLink';

export const getType = (attachment: any): string => {
    const fileExtensions: { [key: string]: string } = {
        pdf: "application/pdf",
        txt: "text/plain",
        png: "image/png",
        jpeg: "image/jpeg",
        jpg: "image/jpeg",
        bmp: "image/bmp",
        gif: "image/gif",
    };

    const attachmentName = attachment?.originalName || "";
    const extension = attachmentName.toLowerCase().split(".").pop() || "";

    return fileExtensions[extension] || "Sem visualização";
};

export const showImage = (attachment: any) => {
    if (attachment.originalName && (attachment.originalName.endsWith(".png") || attachment.originalName.endsWith(".jpg") || attachment.originalName.endsWith(".jpeg"))) {
        return true;
    }
    else {
        return false;
    }
}

export const getFileIcon = (attachment: any) => {
    
    const fileExtensions: { [key: string]: JSX.Element } = {
        pdf: <PiFilePdf style={{ fontSize: '20px', color: "#898DA6" }} />,
        jpeg: <PiFileJpg style={{ fontSize: '20px', color: "#898DA6" }} />,
        jpg: <PiFileJpg style={{ fontSize: '20px', color: "#898DA6" }} />,
        png: <PiFilePng style={{ fontSize: '20px', color: "#898DA6" }} />,
        gif: <PiFileImage style={{ fontSize: '20px', color: "#898DA6" }} />,
        doc: <PiFileDoc style={{ fontSize: '20px', color: "#898DA6" }} />,
        docx: <PiFileDoc style={{ fontSize: '20px', color: "#898DA6" }} />,
        zip: <PiFileZip style={{ fontSize: '20px', color: "#898DA6" }} />,
        rar: <PiFileZip style={{ fontSize: '20px', color: "#898DA6" }} />,
        xls: <PiFileXls style={{ fontSize: '20px', color: "#898DA6" }} />,
        xlsx: <PiFileXls style={{ fontSize: '20px', color: "#898DA6" }} />,
        txt: <PiFileText style={{ fontSize: '20px', color: "#898DA6" }} />,
        svg: <PiFileSvg style={{ fontSize: '20px', color: "#898DA6" }} />,
        ppt: <PiFilePpt style={{ fontSize: '20px', color: "#898DA6" }} />,
        pptx: <PiFilePpt style={{ fontSize: '20px', color: "#898DA6" }} />,
        csv: <PiFileCsv style={{ fontSize: '20px', color: "#898DA6" }} />,
        html: <PiFileHtml style={{ fontSize: '20px', color: "#898DA6" }} />,
        eml: <LcIconLink notHover icon={"ppi ppi-mail"} size={"small"} color={"#898DA6"} />,
        msg: <LcIconLink notHover icon={"ppi ppi-mail"} size={"small"} color={"#898DA6"} />
    };

    const attachmentName = attachment?.originalName.toLowerCase();
    const extension = attachmentName.split('.').pop()?.toLowerCase();

    return (extension && fileExtensions[extension]) 
        ? fileExtensions[extension] 
        : <PiFileText style={{ fontSize: '20px', color: "#898DA6" }} />;
};

export const Status = [
    { value: 0, label: 'Todos' },
    { value: 1, label: 'Novos' },
    { value: 2, label: 'Andamento' },
    { value: 4, label: 'Parados' },
    { value: 5, label: 'Pausado' },
    { value: 6, label: 'Cancelado' },
    { value: 7, label: 'Concluidos' },
    { value: 8, label: 'Encerrados' },
    { value: 9, label: 'Reabertos' },
    { value: 10, label: 'Em planejamento' },
    { value: 11, label: 'Esperando solicitante' },
    { value: 12, label: 'Esperando atendente' },
    { value: 13, label: 'Esperando outra área' },
    { value: 14, label: 'Em aprovação' },
    // { value: 100, name: 'Abertos' },
    // { value: 101, name: 'Fechados' },
];

export const Criticidade = [
    { value: 0, label: 'Todas' },
    { value: 1, label: 'Normal' },
    { value: 2, label: 'Média' },
    { value: 3, label: 'Alta' },
    { value: 4, label: 'Critica' }
];

export const statusColor = (row: any): any => {
    switch (row.idSituacao) {
        case 1: return "#0975D9";
        case 2: return "#FCEBC1";
        case 3: return "#FCEBC1";
        case 4: return "#2B304F";
        case 5: return "#DCDEE9";
        case 6: return "#F9D0D0";
        case 7: return "#DBF4E5";
        case 8: return "#DBF4E5";
        case 9: return "#E2F1FF";
        case 10: return "#BE8E12";
        case 11: return "#DCDEE9";
        case 12: return "#DCDEE9";
        case 13: return "#DCDEE9";
        case 14: return "#BE8E12";

        default: return "rgb(226, 230, 243)";
    }
}

export const fontColor = (row: any): any => {
    switch (row.idSituacao) {
        case 1: return "#FFFFFF";
        case 2: return "#8F6C10";
        case 3: return "#8F6C10";
        case 4: return "#FFFFFF";
        case 5: return "#2B304F";
        case 6: return "#AF4848";
        case 7: return "#296B44";
        case 8: return "#296B44";
        case 9: return "#0975D9";
        case 10: return "#FFFFFF";
        case 11: return "#2B304F";
        case 12: return "#2B304F";
        case 13: return "#2B304F";
        case 14: return "#FFFFFF";

        default: return "#2B304F";
    }
}

export const urgencyColor = (urgency: string): string => {
    switch (urgency) {
        case 'Normal': return '#28A745'
        case 'Media': return '#FFC107'
        case 'Alta': return '#DC3545'
        case 'Critica': return '#343A40'

        default: return '#28A745'
    }
}

export const copyToClipbord = (solicitationNumber: number) => {
    navigator.clipboard.writeText(solicitationNumber.toString());
};