import { PPInput } from "processor-plataform-ui";
import { useState, useEffect, useCallback } from "react";
import { AiOutlineSave } from "react-icons/ai";
import { MdOutlineSettingsBackupRestore, MdOutlineEdit, MdOutlineDeleteOutline, MdOutlineCancel } from "react-icons/md";
import WebixPivotTableService, { PivotView, PivotViewRequest } from "../../../services/webixPivotTableService";
import Confirmation from "../../Dialog/Confirmation";
import LcIconLink from "../../Generic/LcIconLink";
import LcLoading from "../../Generic/LcLoading";
import LcNoData from "../../Generic/LcNoData";
import { useLocation } from "react-router-dom";
import './index.css'
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { UserState } from "../../../store/reducers/userReducer";
import { PiXLight } from "react-icons/pi";
import { useSnackbar } from "notistack";

interface PivotViewProps {
    show: boolean;
    focusedStyle?: boolean;
    props: any
    pivotStructure: any
    styleVision?: CSSProperties
    setShowPivotViewPanel: (show: boolean) => void
    setRefreshPivot: (key: (prevKey: number) => number) => void;
    setPivotStructure: (structure: any) => void
    setSelectitedView?: (selected: boolean) => void
}

export const PivotViewPanel: React.FC<PivotViewProps> = (
    {
        show = false,
        setShowPivotViewPanel,
        props,
        pivotStructure,
        styleVision,
        setPivotStructure,
        setRefreshPivot,
        setSelectitedView,
        focusedStyle
    }) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const service = new WebixPivotTableService(props);
    const clientGroupSelected = sessionStorage.getItem('ClientGroupSelected');
    const [viewLabel, setViewLabel] = useState<string>('');
    const [viewLabelEditable, setViewLabelEditable] = useState<string>('');
    const [editableIndex, setEditableIndex] = useState<number | null>(null);
    const [viewToRemove, setViewToRemove] = useState<PivotView>();
    const [currentPivotViewLabel, setCurrentPivotViewLabel] = useState<string>('');
    const [pivotViews, setPivotViews] = useState<PivotView[]>([]);
    const [loadingPivotViews, setLoadingPivotViews] = useState<boolean>(false);
    const location = useLocation();
    const currentPath = location.pathname;
    const [isSavingPivotView, setIsSavingPivotView] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    const loadPivotConfigsFromCloud = useCallback(()=> {
        setLoadingPivotViews(true);
        setEditableIndex(null);
        const service = new WebixPivotTableService({});

        service.ListPivotViews()
            .then(data => {
                setPivotViews(data.filter(d => d.viewRoute === currentPath))
                checkSelectedView(data.filter(d => d.viewRoute === currentPath))
            })
            .finally(() => setLoadingPivotViews(false));
    },[currentPath])

    useEffect(() => { 
        if(show && pivotViews.length === 0 ) {
            loadPivotConfigsFromCloud();
        }  
    }, [user.ClientGroupSelected, user.refreshFlag, show, pivotViews.length, loadPivotConfigsFromCloud]);


    const checkSelectedView = (views: PivotView[]) => {
        let selected = localStorage.getItem('visionSelected')
        let getSavedView = views.find(view => view.viewJson === JSON.stringify(selected))
        if(getSavedView) {
            setCurrentPivotViewLabel(getSavedView?.viewLabel)
        }
    }

    const validJson = (json: string) => {
        try { JSON.parse(json); }
        catch (e) { return false; }
        return true;
    }
    const handleClosePanel = () => {
        setViewLabel('');
        setShowPivotViewPanel(false);
        setEditableIndex(null);
    }

    const handleUpdatePivotViewOnCloud = (label: string, pivotViews: PivotView) => {
        const viewJson = JSON.stringify(pivotStructure);

        if (pivotViews && validJson(viewJson))
            updatePivotConfigOnCloud(pivotViews.viewLabel, viewJson, label, pivotViews);
    }

    function updatePivotConfigOnCloud(label: string, structure: any, newLabel: string, pivotView: PivotView) {
        setLoadingPivotViews(true);
        const pivotViewRequest: PivotViewRequest = {
            viewLabel: label,
            newViewLabel: newLabel,
            viewRoute: currentPath,
            viewKey: pivotView.viewKey,
            partitionKey: pivotView.partitionKey,
            rowKey: pivotView.rowKey,
            viewCSPTag: props.idView ?? null,
            viewJson: JSON.stringify(structure),
        }
        setIsSavingPivotView(true);
        service.SavePivotView(pivotViewRequest)
            .then(() => {
                enqueueSnackbar(`Visão atualizada com sucesso`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
            })
            .catch(err =>  {
                enqueueSnackbar(`Falha ao atualizar visão da pivot: ${err}`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
                console.error(`Falha ao salvar visão da pivot: ${err}`)
            })
            .finally(() => {
                savePivotConfig(JSON.parse(structure));
                setRefreshPivot(prevKey => prevKey + 1);
                loadPivotConfigsFromCloud();
                setIsSavingPivotView(false);
                setEditableIndex(null);
            })
    }

    const handleSavePivotViewToCloud = () => {
        const viewJson = JSON.stringify(pivotStructure);
        if (!viewLabel)
            setViewLabel('')

        if (viewLabel && viewLabel.length > 0 && validJson(viewJson))
            savePivotConfigToCloud(viewLabel, viewJson, true);
    }

    function savePivotConfigToCloud(label: string, structure: any, refreshList: boolean) {
        setLoadingPivotViews(true);
        const pivotViewRequest: PivotViewRequest = {
            viewLabel: label.toLowerCase(),
            viewRoute: currentPath,
            viewCSPTag: props.idView ?? null,
            viewJson: JSON.stringify(structure),
        }
        setIsSavingPivotView(true);
        service.SavePivotView(pivotViewRequest)
            .then(() => {
                enqueueSnackbar(`Visão salva com sucesso`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
            })
            .catch(err =>  {
                enqueueSnackbar(`Falha ao salvar visão da pivot: ${err}`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
                console.error(`Falha ao salvar visão da pivot: ${err}`)
            })
            .finally(() => {
                if (refreshList)
                    loadPivotConfigsFromCloud()

                setIsSavingPivotView(false)
                setViewLabel('');
            })
    }

    const handleRemovePivotViewFromCloud = () => {
        setLoadingPivotViews(true);
        if (viewToRemove)
            disabledPivotConfigOnCloud(viewToRemove.rowKey);

        setViewToRemove(undefined);
    }

    function disabledPivotConfigOnCloud(key: string): any {
        setIsSavingPivotView(true);
        service.DisablePivotView(key)
            .catch(err => console.error(`Falha ao remover visão da pivot: ${err}`))
            .finally(() => {
                loadPivotConfigsFromCloud();
                setIsSavingPivotView(false);
            })
    }
    const wasUpdated = (pv: PivotView) => {
        return pv.viewLabel === currentPivotViewLabel
    }

    const handleSelectPivotView = (pv: PivotView) => {
        if (validJson(pv.viewJson)) {
            let _unescaped = JSON.parse(pv.viewJson);
            savePivotConfig(JSON.parse(_unescaped));
            setRefreshPivot((prevKey: number) => prevKey + 1);
            setCurrentPivotViewLabel(pv.viewLabel);
            localStorage.setItem("visionSelected",JSON.parse(pv.viewJson) )
        }
    }

    function savePivotConfig(structure: any) {
        localStorage.setItem(`pivotConfig-${currentPath}-${clientGroupSelected}-${props.idView}`, JSON.stringify(structure));
    }

    const handleResetPivotViewToDefault = () => {
        localStorage.removeItem(`pivotConfig-${currentPath}-${clientGroupSelected}-${props.idView}`);
        // setPivotStructure(props.structure);
        setCurrentPivotViewLabel('')
        setRefreshPivot(prevKey => prevKey + 1);
        setSelectitedView && setSelectitedView(false)
        localStorage.removeItem("visionSelected")
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, pv: PivotView) => {
        if(event.key === 'Enter') handleUpdatePivotViewOnCloud(viewLabelEditable, pv)
        else if(event.key === 'Escape') setEditableIndex(null)      
    }

    return (
        <div className='pivot_view_panel_container' style={{ display: show ? 'flex' : 'none' }}>
            <div className={focusedStyle ? 'pivot_view_panel focusedStyle' : `pivot_view_panel`} style={ styleVision ? styleVision :  { position: show ? 'absolute' : 'unset' }}>
                <div className='pivot_view_panel_content'>
                    <div className='pivot_view_panel_title'>
                        <span >Minhas visões</span>
                        <LcIconLink icon={<PiXLight size={20} />} onClick={handleClosePanel} tooltip='Fechar' tooltipPosition='right' />
                    </div>
                    <div className='pivot_view_panel_footer_reset' style={{ cursor: isSavingPivotView ? 'not-allowed' : 'pointer', borderRadius: '8px' }}>
                        <a className='pivot_view_panel_item' onClick={isSavingPivotView ? undefined : handleResetPivotViewToDefault}>
                            <MdOutlineSettingsBackupRestore size='16px' style={{ marginRight: '5px' }} />
                            Redefinir para visão padrão
                        </a>
                    </div>
                    <hr />
                    <div className='pivot_view_panel_list scrollable-v'>
                        <LcLoading loading={loadingPivotViews} label='Carregando visões...'>

                            {
                                pivotViews.length > 0 ?
                                    pivotViews
                                        .map((pv, pvi) => (
                                            <div key={pvi}  >
                                                {
                                                    editableIndex === pvi ?
                                                        <div className='pivot_view_panel_item' onKeyDown={(event) => handleKeyDown(event, pv)}>
                                                            <PPInput
                                                                name='pivotViewLabelField'
                                                                type='text'
                                                                required={true}
                                                                defaultValue={pv.viewLabel}
                                                                onChange={(e) => e.target.value !== '' ? setViewLabelEditable(e.target.value): setViewLabelEditable(pv.viewLabel)}
                                                                disabled={isSavingPivotView}
                                                            />
                                                            <span className='gap-2 functions'>
                                                                <LcIconLink size='small' className='notHover' icon={<AiOutlineSave size='16px' />} 
                                                                onClick={() => handleUpdatePivotViewOnCloud(viewLabelEditable, pv)} tooltip='Atualizar' tooltipPosition='inline-left' />
                                                                <LcIconLink size='small' className='notHover' icon={<MdOutlineCancel size='16px' />} color='#DC291E'
                                                                onClick={() => setEditableIndex(null)}   tooltip='Cancelar' tooltipPosition='right' />
                                                            </span>
                                                        </div>
                                                        :
                                                        <div className='pivot_view_panel_item' style={{
                                                            justifyContent: 'space-between',
                                                            width: '100%',
                                                            border: wasUpdated(pv) ? '1px solid #FFBE15' : '', background: '#f5f5f5'
                                                        }}>
                                                            <span className='row' onClick={() => { handleSelectPivotView(pv); setSelectitedView && setSelectitedView(true) }}
                                                                style={{ width: '65%', height: '100%', alignItems: 'center' }} >
                                                                <p className="viewLabel">
                                                                    {pv.viewLabel}
                                                                </p>
                                                            </span>
                                                            <span className='gap-1 functions'>
                                                                <LcIconLink size='small' icon={<MdOutlineEdit size='16px' />} onClick={() => setEditableIndex(pvi)}
                                                                    tooltip='Editar' className='notHover' tooltipPosition='inline-left' />
                                                                <LcIconLink size='small' className='notHover' icon={<MdOutlineDeleteOutline color='#DC291E' size='16px' />} 
                                                                    onClick={() => setViewToRemove(pv)} tooltip='Remover' tooltipPosition='right' />
                                                            </span>
                                                        </div>

                                                }

                                            </div>

                                        ))
                                    :
                                    <LcNoData label='Nenhuma visão encontrada' />
                            }
                        </LcLoading>
                    </div>
                </div>
                <div className='pivot_view_panel_footer' >
                    <hr />
                    <div className='row pv-4' style={{ alignItems: 'flex-end' }}>
                        <PPInput
                            name='pivotViewLabelField'
                            type='text'
                            title='Nova visão'
                            required={true}
                            value={viewLabel}
                            onChange={(e) => {setViewLabel(e.target.value); console.log(e.target.value) }}
                            error={{
                                show: viewLabel !== '' && viewLabel.length === 0,
                                help: 'Digite um nome para a visão'
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSavePivotViewToCloud();
                                }
                            }}
                            disabled={isSavingPivotView}
                        />
                        <button
                            className='bg-primary lc-button p-6'
                            onClick={handleSavePivotViewToCloud}
                            disabled={isSavingPivotView || !viewLabel || viewLabel.length == 0}
                        >
                            Salvar
                        </button>
                    </div>
                </div>
            </div>

            <Confirmation
                title={`Remover "${viewToRemove?.viewLabel}"?`}
                text='Esta operação NÃO PODE SER REVERTIDA caso a confirme!'
                display={viewToRemove !== undefined}
                confirm={handleRemovePivotViewFromCloud}
                close={() => setViewToRemove(undefined)}
                textBtnOk='Sim, desejo remover'
                textBtnCancel='Não desejo remover'
            />
        </div>
    )
}