import { useQuery } from 'react-query';
import EndpointDetectionResponseService from '../../../../services/endpointDetectionResponse/EndpointDetectionResponseService';

const defaultValue = {
    allocated: 0,
    inUse: 0,
    sip: 0
};

const fetchWorkEnvironmentData = async (provider)  => {
    const endpointDetectionResponseService = new EndpointDetectionResponseService({});
    if (provider === 1) {
        const response = await endpointDetectionResponseService.RetrieveEndpointSecurityMetrics();
        return {
            iotData: response.data.iot || defaultValue,
            serversData: response.data.servers || defaultValue,
            workstationsData: response.data.workstations || defaultValue
        };
    } else {
        const response = await endpointDetectionResponseService.GetProtectedEnvironment();
        return {
            iotData: null, // ou defaultValue se quiser manter o padrão
            serversData: response?.data?.servers || defaultValue,
            workstationsData: response?.data?.workstations || defaultValue
        };
    }
    };

export const useWorkEnvironmentData = (provider) => {
    return useQuery(['workEnvironmentData', provider], () => fetchWorkEnvironmentData(provider), {
        retry: 5,        
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onError: (error) => console.error('Erro ao buscar dados do ambiente de trabalho:', error)
    });
};
