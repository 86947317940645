import React, { useEffect, useState } from 'react';
import { Question, Answer, NpsService } from '../../../services/npsService';
import { HeyHoCallService } from '../../../services/Heyho/heyHoCallService';
import Moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import { useSnackbar } from 'notistack';
import { PPTextArea } from 'processor-plataform-ui';
import LcVote from '../../../components/Form/LcVote';
import Confirmation from '../../../components/Dialog/Confirmation';

const NPS: React.FC = () => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const nps = new NpsService({});
    const heyho = new HeyHoCallService({});
    const { enqueueSnackbar } = useSnackbar();

    const [questions, setQuestions] = useState<Question[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [answer, setAnswer] = useState<{ note: number | null; reason: string }>({ note: null, reason: '' });
    const [showReason, setShowReason] = useState<boolean>(false);
    const [showPostponeConfirm, setShowPostponeConfirm] = useState<boolean>(false);
    const [isPostponingByClosing, setIsPostponingByClosing] = useState<boolean>(false);
    const [sendingAnswer, setSendingAnswer] = useState<boolean>(false);
    const [reasonTitle, setReasonTitle] = useState<string>('Justificativa (obrigatório)');

    const loadQuestions = () => {
        nps.GetQuestionsForTheUser()
            .then(response => {
                if (response.data) {
                    let _data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
                    setQuestions(_data);
                }
            })
            .catch(console.warn)
    }

    useEffect(loadQuestions, []);

    const handleNoteChange = (note: number) => {
        setAnswer({ note, reason: '' });
        const currentQuestion = questions[currentQuestionIndex];
        const threshold = currentQuestion.triggerAlertBelow ?? currentQuestion.minimum;
        // setShowReason(note <= threshold); // mostrar quando a nota for menor ou igual ao limite
        setShowReason(true);
        setReasonTitle(note <= threshold ? 'Justificativa' : 'O que você mais gosta em LiveCloud? (opcional)')
    }

    const handleReasonChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAnswer(prev => ({ ...prev, reason: event.target.value }));
    }

    const handleSubmit = () => {
        if (answer.note === null) {
            enqueueSnackbar('Por favor, selecione uma nota antes de responder.', { variant: 'error' });
            return;
        }

        const currentQuestion = questions[currentQuestionIndex];
        const threshold = currentQuestion.triggerAlertBelow ?? currentQuestion.minimum;

        if (answer.note <= threshold && answer.reason.length < 10) {
            enqueueSnackbar('Por favor, forneça um motivo com pelo menos 10 caracteres para a nota escolhida.', { variant: 'error' });
            return;
        }

        submitAnswer(false);
    }

    const confirmPostpone = () => {
        submitAnswer(true);
        setShowPostponeConfirm(false);
        setIsPostponingByClosing(false);
    }

    const createHeyHoTicket = (question: Question, answerNote: number) => {
        if (answerNote <= (question.triggerAlertBelow ?? question.minimum)) {
            const agendamento = {
                id: 0,
                titulo: 'NPS com nota baixa',
                descricao: `Ao responder a pergunta "${question.name}" o usuário "${user.name} (${user.email})" deu a nota ${answerNote}`,
                contrato: 0,
                dataInicio: Moment().format('YYYY-MM-DD HH:mm:ss'),
                duracao: null,
                idCliente: null,
                idContratoProduto: null,
                idUsuario: '863b6970-d717-419f-b97a-467f19bae0ae',
                tipoTarefa: null,
                situacao: null,
                dataCriacao: Moment().format('YYYY-MM-DD HH:mm:ss'),
                idSource: null,
                dataAtualizacao: Moment().format('YYYY-MM-DD HH:mm:ss'),
                status: null,
                clientGroupId: 492, //Fixo Processor
                clientGroup: null,
                aspNetUsers: null,
                validationMenssage: null
            };

            heyho.CriarAnonimo(agendamento, null).catch(console.error);
        }
    }

    const submitAnswer = (isPostponed: boolean) => {
        setSendingAnswer(true);
        const currentQuestion = questions[currentQuestionIndex];
        const answerToSubmit: Answer = {
            clientGroup: user.ClientGroupList.find(c => c.defaultClientGroup)?.clientGroupId || 0,
            user: user.email?.toLowerCase() || '',
            question: currentQuestion.id,
            note: isPostponed ? undefined : answer.note!,
            reason: isPostponed ? '...adiada' : answer.reason
        };

        nps.PostAnswer(answerToSubmit)
            .then(() => {
                if (!isPostponed && answer.note !== null) {
                    createHeyHoTicket(currentQuestion, answer.note);
                }

                if (currentQuestionIndex < questions.length - 1) {
                    setCurrentQuestionIndex(prev => prev + 1);
                    setAnswer({ note: null, reason: '' });
                    setShowReason(false);
                } else {
                    enqueueSnackbar(
                        'Agradecemos pela sua participação na pesquisa de satisfação!',
                        {
                            variant: 'success',
                            preventDuplicate: true,
                            autoHideDuration: 15000,
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            },
                        }
                    );
                }
            })
            .catch(console.warn)
            .finally(() => {
                currentQuestionIndex === questions.length - 1 && setQuestions([]);
                setSendingAnswer(false);
            })
    }

    const handleClosingPanel = () => {
        if (!sendingAnswer) {
            setShowPostponeConfirm(true);
            setIsPostponingByClosing(true);
        }
    }

    const handleCancelPostponing = () => {
        if (isPostponingByClosing) {
            setIsPostponingByClosing(false);
            setQuestions([]);
        }
        setShowPostponeConfirm(false);
    }

    return (
        <>
            <SideModal header="Pesquisa de satisfação" visible={questions.length > 0} onClose={handleClosingPanel}>
                {questions.length > 0 && (
                    <div style={{ position: 'relative', height: '100%', width: '100%', paddingBottom: '0' }}>
                        <div>
                            <LcVote
                                name={`${questions[currentQuestionIndex].id}_vote`}
                                min={questions[currentQuestionIndex].minimum}
                                max={questions[currentQuestionIndex].maximum}
                                lessText={questions[currentQuestionIndex].lessText}
                                moreText={questions[currentQuestionIndex].moreText}
                                question={questions[currentQuestionIndex].text}
                                onChange={handleNoteChange}
                            />
                            {showReason && answer.note !== null && (
                                <div>
                                    <p style={{ fontSize: '16px', padding: '8px 0', color: 'var(--text-default)' }}>{reasonTitle}:</p>
                                    <PPTextArea
                                        name={`${questions[currentQuestionIndex].id}_reason`}
                                        rows={5}
                                        value={answer.reason}
                                        onChange={handleReasonChange}
                                        required={showReason && answer.note !== null && answer.note <= (questions[currentQuestionIndex].triggerAlertBelow ?? questions[currentQuestionIndex].minimum)}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="space-between" style={{ position: 'absolute', bottom: 0, left: 0, right: 0, padding: 0 }}>
                            <button className={`lc-button bg-info ${sendingAnswer && 'disabled'}`} disabled={sendingAnswer} onClick={handleSubmit}>{sendingAnswer ? 'Enviando...' : 'Responder'}</button>
                            <button className={`lc-button bg-gray ${sendingAnswer && 'disabled'}`} disabled={sendingAnswer} onClick={() => setShowPostponeConfirm(true)}>Adiar</button>
                        </div>
                    </div>
                )}
            </SideModal>

            <Confirmation
                display={showPostponeConfirm}
                close={handleCancelPostponing}
                confirm={confirmPostpone}
                title='Deseja adiar?'
                text="Você será lembrado novamente depois de algum tempo."
                textBtnOk="Sim, adiar"
                textBtnCancel={isPostponingByClosing ? 'Apenas fechar' : 'Responder pesquisa'}
            />
        </>
    );
}

export default NPS;