import React, { useEffect, useState } from 'react'

import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import PPExpand2 from '../../../components/Generic/PPExpand2'

import Layout from '../../../components/Layout/Layout'

import './index.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import { getRecomendationsInfos } from './Columns';
import { FinopsRecommendationsService, Info_Items_Options, Organizations_Infos } from '../../../services/finOps/finOpsRecommendations';
import { PPIconLink } from 'processor-plataform-ui';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { formatDecimal } from '../../../services/currency';
import LcNoData from '../../../components/Generic/LcNoData';
import { PiEyeClosedLight, PiEyeLight, PiEyeSlash, PiEyeSlashLight } from 'react-icons/pi';
import LcIconLink from '../../../components/Generic/LcIconLink';


export const FinOpsRecommendations: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);

    moment.locale('pt-br');

    const recomendationService = new FinopsRecommendationsService(props);

    const [totalDecimal, setTotalDecimal] = useState(0.00);
    const [isLoadingRecomendations, setIsLoadingRecomendations] = useState(true);

    const [type, setType] = useState(window.location.href.includes("cost") ? 'cost' : 'security');

    const [recommendationsData, setRecommendationsData] = useState<Info_Items_Options[]>();

    const [allDataInfos, setAllDataInfos] = useState<Organizations_Infos>();

    const [recomendationFilter, setRecomendationFilter] = useState({ type: type, showEmpty: false })


    useEffect(() => {
        getAllDatas();
    }, [user.ClientGroupSelected])

    useEffect(() => {
        setType(window.location.href.includes("cost") ? 'cost' : 'security')
    }, [window.location.href])

    const getAllDatas = async () => {
        setIsLoadingRecomendations(true);

        await recomendationService.GetRecommendationsDashboard()
            .then((response) => {
                setAllDataInfos(response);
            })
            .catch((error) => {
                console.warn(error);
            })
            .then(() => {

            })

        await recomendationService.GetRecommendation()
            .then((response) => {
                var total = 0.00;
                response && setRecommendationsData(response);
                response && response.map((item) => {
                    if (item.infos?.saving && item.infos?.saving > 0.00) {
                        total += item.infos?.saving;
                    }

                });
                setTotalDecimal(total);
            })
            .catch((error) => {
                console.warn(error);
            })
            .then(() => {

            })

        setIsLoadingRecomendations(false);
    }

    useEffect(() => { setRecomendationFilter({ ...recomendationFilter, type: type }) }, [type]);

    const createRowElement = (element: any, infos) => {
        return <div className='otimizacao'>
            <PPExpand2
                title={infos.title}
                onExpand={() => { }}
                titleRight={
                    <div className='row between w-100p' >
                        <span className='spanExpandLeft'> {element.infos.count} </span>
                        <span className='spanExpandRight'> {infos.type == 'c' && element.infos.saving && formatDecimal(parseFloat(element.infos.saving))} </span>
                    </div>}
                status={infos.type == 'c' ? 'danger' : 'warning'} >


                {
                    element && element.items && element.items.length > 0 ?
                        <>
                            <div className='definitionText'>
                                {infos.definition}
                            </div>

                            <LcInfiniteTable
                                density='medium'
                                hidePagination
                                rows={element && element.items ? element.items : []}
                                columns={infos.columns}
                                loadMore={() => { }}
                                size={element && element.items ? element.items.length : 0}
                            />
                        </>
                        :
                        <div className='mt-7'>
                            <LcNoData label='Não existem recomendações ativas no momento' />
                        </div>
                }

            </PPExpand2 >
        </div>
    }

    const renderTable = () => {
        let list: any[] = [];
        recommendationsData && recommendationsData.map((recomendation) => {
            let infos = getRecomendationsInfos(recomendation);

            if (!recomendationFilter.showEmpty && recomendation.infos!.count == 0) {
                return;
            }
            switch (recomendationFilter.type) {
                case 'all':
                    list.push({
                        type: 'all',
                        saving: recomendation.infos?.saving ? recomendation.infos?.saving : 0,
                        count: recomendation.infos?.count,
                        element: createRowElement(recomendation, infos)
                    })
                    break;
                case 'cost':
                    if (infos.type == 'c') {
                        list.push({
                            type: 'cost',
                            saving: recomendation.infos?.saving ? recomendation.infos?.saving : 0,
                            count: recomendation.infos?.count,
                            element: createRowElement(recomendation, infos)
                        })
                    }
                    break;
                case 'security':
                    if (infos.type == 's') {
                        list.push({
                            type: 'security',
                            saving: recomendation.infos?.saving ? recomendation.infos?.saving : 0,
                            count: recomendation.infos?.count,
                            element: createRowElement(recomendation, infos)
                        })
                    }
                    break;
                default:
                    break;
            }
        })
        var elementList = ordenaLista(list).map((item) => { return item.element });
        return elementList;
    }

    const ordenaLista = (list) => {
        switch (recomendationFilter.type) {
            case 'all':
                return list.sort((itemA, itemB) => {
                    return itemB.count - itemA.count
                });
            case 'cost':
                return list.sort((itemA, itemB) => {
                    return itemB.saving - itemA.saving
                });
            case 'security':
                return list.sort((itemA, itemB) => {
                    return itemB.count - itemA.count
                });
            default:
                break;
        }
    }

    return (
        <Layout
            cardType
            loading={isLoadingRecomendations}
            pageTitle={type == 'cost' ? 'Otimização de custo' : 'Recomendações de segurança'}
            row={
                <LcIconLink
                    id='visualizarVazias'
                    icon={recomendationFilter.showEmpty ? <PiEyeLight/> : <PiEyeSlashLight/>}
                    onClick={() => setRecomendationFilter({ ...recomendationFilter, showEmpty: !recomendationFilter.showEmpty })}
                    tooltip={!recomendationFilter.showEmpty ? "Visualizar vazias" : "Não visualizar vazias"}
                />
            }
        >
            <div className='row between w-100p recomendationHeader'>
                <div className='row left recomendation'>{recomendationFilter.type == 'all' ? 'Recomendações' : recomendationFilter.type == 'cost' ? 'Otimização de custos' : 'Recomendações de segurança'}</div>
                <div className='row left quantity'> Quantidade </div>
                <div className='row right economy'> {recomendationFilter.type == 'cost' ? <>
                    Economia estimada possível <br />
                    (R$ {totalDecimal && formatDecimal(totalDecimal)} / mês)
                </> :
                    ''}
                </div>
            </div>
            {
                recommendationsData && renderTable()
            }
        </Layout >
    )
}
