import { PPFormInput } from 'processor-plataform-ui/dist/components/PPForm2';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LcIconLink from '../LcIconLink';
import { PPModal, PPForm2, PPDropMenu } from 'processor-plataform-ui';
import { useSnackbar } from 'notistack';
import Attachment from '../../../pages/Heyho/Attachment';
import { HeyHoCallService } from '../../../services/Heyho/heyHoCallService';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import moment from 'moment';
import HHContentAI from '../../../pages/Heyho/HHContentAI';
import { AiOutlineSound } from 'react-icons/ai';
import { BsArrowsAngleContract, BsArrows, BsType } from 'react-icons/bs';
import { PiMagicWand } from 'react-icons/pi';
import { HeyHoService } from '../../../services/Heyho/heyHoService';

interface Props {
    openModal: boolean;
    formData: FormDataHeyho
    needAtachmente?: boolean;
    enebleIA?: boolean
    onClose: Function
    submittingRequest?: Function
    header?: string,

}

export interface FormDataHeyho {
    title: string,
    description: string,
    gtmType?: any,
}


const HeyHoNewRequest: React.FC<Props> = ({formData,onClose, openModal, enebleIA, header, needAtachmente,submittingRequest }, props) => {
    const [ServiceHeyho] = useState<HeyHoService>(new HeyHoService(props));
    const user = useSelector<RootState, UserState>(state => state.user);
    const service = new HeyHoCallService(props);

    const [loading, setLoading] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const [anexos, setAnexos] = useState<File[]>([]);
    const dispatch = useDispatch();
    const [GTMTreeDefault, setGTMTreeDefault] = useState<any>('');
    const [submitting, setSubmitting] = useState(false);
    const [sugestaoExp, setSugestaoExp] = useState<boolean>(false);
    const [updateField, setUpdateField] = useState<boolean>(false);
    const [openAIMenu, setOpenAIMenu] = useState<boolean>(false);
    const [AiSelectedMenu, setAiSelectedMenu] = useState<number>(0);
    const [AiSelected, setAiSelected] = useState<number>(0);

    const AIOpts = [
        {
            icon: <PiMagicWand />,
            text: 'Elaborar',
            value: 1
        },
        {
            icon: <BsArrowsAngleContract style={{ transform: ' rotate(45deg)' }} />,
            text: 'Reduzir',
            value: 2
        },
        {
            icon: <BsArrows />,
            text: 'Expandir',
            value: 3
        },
        {
            icon: <BsType />,
            text: 'Corrigir ortografia',
            value: 5
        },
        {
            icon: <AiOutlineSound />,
            text: 'Mudar tom ',
            submenu: true,
            value: 4
        }
        
    ]

    const changeToneOpts = {
        icon: <AiOutlineSound />,
        text: 'Mudar tom',
        subMenu: [
            {
                text: 'Comunicativo',
                value: 6
            },
            {
                text: 'Bem-humorado',
                value: 7
            },
            {
                text: 'Entusiasmado',
                value: 8
            },
            {
                text: 'Informativo',
                value: 9
            },
            {
                text: 'Profissional',
                value: 10
            },
            {
                text: 'Inteligente',
                value: 11
            },
            {
                text: 'Confiável',
                value: 12
            },
        ],
        value: 4
    }

    
    const emptyRecordMessage = {
        detail: false,
        edit: false,
        add: false,
        record: ServiceHeyho.emptyRecord
    };
    const [newRequest, setNewRequest] = useState(emptyRecordMessage);
    useEffect(() => {
        setLoading(true);
        ServiceHeyho.RetrieveContractsAndGTM();
        if (formData.gtmType) {
            let gtmType: any = formData?.gtmType;
            service.RetrieveDefaultChamadoTreeItem(gtmType)
            .then(response => {
                setGTMTreeDefault(response.data);
                setLoading(false);
            })
            .catch(error => {
                    console.error(error);
                    setLoading(false);
                });
        }

        if (formData !== null) {
            setNewRequest(prevRequest => ({
                ...prevRequest,
                record: {
                    ...prevRequest.record,
                    titulo: formData.title,
                    Descricao: formData.description
                }
            }));
            setLoading(false);
        }
    }, [formData]);


    const fieldsForm: PPFormInput[] = [
        {
            name: 'titulo',
            type: 'text',
            title: 'Assunto',
            required: true,
            value: formData.title ? formData.title : ''
        },
        {
            name: 'Descricao',
            type: 'textarea',
            title: 'Descrição detalhada',
            required: true,
            rows: sugestaoExp ? 3 : 7,
            value: formData.description ? formData.description: ''
        },
    ]

    const onChangeForm = (event) => {
        setNewRequest(prevRequest => ({
            ...prevRequest,
            record: {
                ...prevRequest.record,
                titulo: event.titulo,
                Descricao: event.Descricao
            }
        }));
        formData.description = event.Descricao
        setUpdateField(true)
    }

    async function saveTicket(
        ServiceHeyho: any,
        setNewRequest: React.Dispatch<React.SetStateAction<{ detail: boolean; edit: boolean; add: boolean; record: any; }>>,
        emptyRecordMessage: { detail: boolean; edit: boolean; add: boolean; record: any; },
    ) {
        if (newRequest.record) {
            if (!newRequest.record.Descricao ||  /^\s*$/.test(newRequest.record.Descricao)) {
                enqueueSnackbar(`Erro ao criar o chamado é obrigatorio o preenchimento do campo "Descrição detalhada"`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                })
                return;
            }
            if (!newRequest.record.titulo ||  /^\s*$/.test(newRequest.record.Descricao)) {
                enqueueSnackbar(`Erro ao criar o chamado é obrigatorio o preenchimento do campo "Assunto"`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                })
                return;
            }
        }
        let idSolicitation;
        let idUserRequester
        setLoading(true);
        try {
            if(formData.gtmType && GTMTreeDefault !== '') {
                var values = GTMTreeDefault ? GTMTreeDefault?.value.split('_') : null;
                let agendamento = {
                    id: 0,
                    titulo: formData.title,
                    descricao: formData.description,
                    contrato: 0,
                    dataInicio: moment().format('YYYY-MM-DD HH:mm:ss'),
                    duracao: null,
                    idCliente: null,
                    idContratoProduto: null,
                    idUsuario: user.UserId,
                    tipoTarefa: null,
                    situacao: null,
                    dataCriacao: moment().format('YYYY-MM-DD HH:mm:ss'),
                    idSource: null,
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm:ss'),
                    status: null,
                    clientGroupId: user.ClientGroupSelected,
                    clientGroup: null,
                    aspNetUsers: null,
                    validationMenssage: null,
                    idService: values ? values[1] : null,
                    idProcess: values ? values[2] : null,
                }
                await service.Criar(agendamento,[])
                .then((response: any) => {
                        idSolicitation = response
                        enqueueSnackbar(
                            <div style={{ display: 'flex' }}>
                                Chamado {response} criado com sucesso.
                                <LcIconLink
                                    icon={"ppi ppi-duplicate"}
                                    size={'small'}
                                    tooltip={'Copiar número de chamado'}
                                    tooltipPosition={'inline-left'}
                                    onClick={() => navigator.clipboard.writeText(response.toString())}
                                />
                            </div>
                            , {
                                variant: 'success',
                                preventDuplicate: true,
                                persist: false,
                            });
                    setLoading(false);
                }).catch((err: any) => {
                    enqueueSnackbar(`Um erro ocorreu ao criar chamado.`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                    console.error(err);
                    setLoading(false);
                }).finally(() => {
                    setNewRequest({ ...emptyRecordMessage });
                    setLoading(false);
                    dispatch({ type: 'MENU_NEW_REQUEST', payload: false });
                    onClose(false);
                    submittingRequest && submittingRequest(false);

                });
            } else {
                await ServiceHeyho.Save(newRequest.record)
                .then(async (response) => {
                    idSolicitation = response.data.id
                    idUserRequester = response.data.idUserRequester
                    enqueueSnackbar(
                        <div style={{ display: 'flex' }}>
                            Chamado {response.data.id} criado com sucesso.
                            <LcIconLink
                                icon={"ppi ppi-duplicate"}
                                size={'small'}
                                tooltip={'Copiar número de chamado'}
                                tooltipPosition={'inline-left'}
                                onClick={() => navigator.clipboard.writeText(response.data.id.toString())}
                            />
                        </div>
                        , {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        });

                })
                .catch((error) => {
                    enqueueSnackbar(`Erro na criação do chamado.`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                    console.error(error);
                })
                .finally(() => {
                    setNewRequest({ ...emptyRecordMessage });
                    setLoading(false);
                    setUpdateField(false);
                    dispatch({ type: 'MENU_NEW_REQUEST', payload: false });
                    onClose(false);
                    submittingRequest && submittingRequest(false);

                })
            }

        } catch (e) {
            console.error(e);
            setLoading(false);
        }
        await handleFile(idSolicitation, idUserRequester)

    }

    const handleFile = async (id: number, idUserRequester: number) => {
        if (anexos.length > 0) {
            await ServiceHeyho.sendFile(anexos, id,idUserRequester,formData.description)
            .then((response2) => {
                if (response2.data === 201) {
                    enqueueSnackbar(`Anexo para o ticket ${id} criado com sucesso.`, {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                    });
                }
                else if (response2.data == 400) {
                    enqueueSnackbar(`Erro ao anexar arquivo.`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                }
            })
            .catch((error) => {
                enqueueSnackbar(`Erro ao anexar arquivo.`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                });
                console.warn(error);
            }).finally(() => {
                setAnexos([])
                dispatch({ type: 'MENU_NEW_REQUEST', payload: false });
                setLoading(false);
            })
        }
    }
    const handleSubmit = () => {
        setSubmitting(true);
        saveTicket(ServiceHeyho, setNewRequest, emptyRecordMessage)
            .then(result => {
            })
            .catch(console.warn)
            .then(() => {
                setSubmitting(false);
                onClose(false);
            })
    }

    const removeFile = (fileName: string) => {
        const files_ = anexos && anexos.filter(item => item.name !== fileName);
        setAnexos(files_);
    };

    const handleFiles = (files_: any[]) => {
        let _newRequest = newRequest.record;
        _newRequest.Anexo = [...anexos, ...files_];
        setNewRequest({ ...newRequest, record: _newRequest })
        setAnexos([...anexos, ...files_]);
    }

    return(
        
        <PPModal
            visible={openModal}
            onClose={() => {
                onClose(false);
                setNewRequest({ ...emptyRecordMessage });
                dispatch({ type: 'MENU_NEW_REQUEST', payload: false });

            }}
            size={enebleIA ? 'large' : ''}
            title={header ? header : 'Novo chamado'}
            loading={loading}
        >
            <div className='newRequestModal scrollable-v'>
                <PPForm2
                    fields={fieldsForm}
                    returnType="object"
                    onChange={onChangeForm}
                    onSubmit={handleSubmit}
                    submitting={submitting}
                    updateFieldsOnChangeOrigin={updateField}
                    functions={
                        {
                            submitButton: {
                                hide: true,
                                label: 'Ok',
                            },
                            // otherButtos: [
                            //     functionsNew[0] as any,
                            //     functionsNew[1] as any
                            // ]
                        }
                    }
                >

                {
                    enebleIA &&
                    <>
                        <div className='AiMenu' style={{marginTop: !sugestaoExp ? '-145px' : '-75px'}}>
                            <LcIconLink
                                icon='ppi ppi-lightning-bolt'
                                id='aiLCChamados'
                                degrade
                                pulse
                                onClick={() => {
                                    setOpenAIMenu(!openAIMenu)
                                    setUpdateField(true)
                                }}
                                tooltip={!formData.description ?  
                                    "Digite algo para habilitar o Assistente de AI" : "Assistente de AI"
                                }
                                tooltipPosition='right'

                                disabled={!formData.description}
                                />
                            {
                            openAIMenu &&
                            <PPDropMenu
                                subItems={
                                    AiSelectedMenu === 4 &&
                                    changeToneOpts.subMenu.map(sub => {
                                        return (
                                            <div className={'item AiMenuItem'} onClick={(e) => {
                                                    setSugestaoExp(true);
                                                    setAiSelectedMenu(sub.value)
                                                    setAiSelected(sub.value)
                                                    setOpenAIMenu(false);
                                            }}>
                                                <div className="row item">
                                                    <span style={{ fontSize: '12px' }}>{sub.text}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                items={
                                <>
                                    {
                                    AIOpts.map(Ai => {
                                            return (
                                                <div className={'item AiMenuItem'} onClick={(e) => {
                                                    setAiSelectedMenu(Ai.value)
                                                    if(Ai.value !== 4) {
                                                        setAiSelected(Ai.value)
                                                        setSugestaoExp(true); 
                                                        setOpenAIMenu(false); 
                                                    }else {
                                                        AiSelectedMenu === 4 && setAiSelectedMenu(0)
                                                    }
                                                    }}>
                                                    <div className="row item ">
                                                        {Ai.icon}
                                                        <span style={{ fontSize: '12px' }}>{Ai.text}</span>
                                                    </div>
                                                    { Ai.submenu && <i className="ppi ppi-chevron-right" />}
                                                </div>
                                            )
                                        })
                                    }
                                    </>
                                }
                                onLeave={() => {setOpenAIMenu(!openAIMenu); setAiSelectedMenu(0)}}
                                    position='left'
                            />
                            }
                        </div>
                        <div className='w-100p' style={{marginTop: '-10px'}}>
                            <HHContentAI 
                                originalText={formData.description} 
                                visible={sugestaoExp} 
                                handleVisible={() => setSugestaoExp(!sugestaoExp)} 
                                setText={(e) =>  { onChangeForm({titulo: formData.title, Descricao: e})} }
                                user={user} 
                                instructionType={AiSelected}
                            />
                        </div>
                    </>

                }
                {
                    needAtachmente &&
                    <Attachment
                        anexos={anexos}
                        removeFile={(e) => removeFile(e)}
                        setAnexos={handleFiles}
                        visible={true}
                    />
                }
                <div className="w-100p space-between">
                    <button id='form_novochamado_abrirchamado_button' className='pp-button bg-info' onClick={() =>    saveTicket(ServiceHeyho, setNewRequest, emptyRecordMessage) }>Abrir chamado</button>
                    <button id='form_novochamado_cancelar_button' className='pp-button bg-gray' onClick={() => {  onClose(false); setNewRequest({ ...emptyRecordMessage });dispatch({ type: 'MENU_NEW_REQUEST', payload: false }); setUpdateField(false); setSugestaoExp(false);} }>Cancelar</button>

                </div>
                </PPForm2>
            </div>
        </PPModal>
    );
}

export default HeyHoNewRequest;
