import React, { useState } from 'react';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LcLoading from '../../../components/Generic/LcLoading';
import LcTooltip from '../../../components/Generic/LcTooltip';
import { useQuery } from 'react-query';
import LcAccordion from '../../../components/Generic/LcAccordion';
import moment from 'moment';
import LcSegment from '../../../components/Generic/LcSegment';
import { RenderAttachment } from './RenderAttachment';
import { RenderImage } from './RenderImage';
import { showImage } from '../HHUtilities';
import { HeyHoService } from '../../../services/Heyho/heyHoService';
import { Comment } from '../../../services/Heyho/heyhoModel';

interface CommentListProps {
    commentsDetailsId: number;
    setData: (data: string) => void;
    setOpenAttachment(openAttachment: boolean): void;
    setAttachment(attachment: any): void;

}

export const HHCommentList: React.FC<CommentListProps>  = ( {
    commentsDetailsId,
    setOpenAttachment,
    setData,
    setAttachment
}) => {
    const [selectedRow, setSelectedRow] = useState<any>();
    const [openAccordion, setopenAccordion] = useState<boolean>(true);

    const { data: commentData, isLoading } = useQuery({
        queryKey: ['commentDetails', commentsDetailsId],
        queryFn: async () => {
            const ServiceHeyho = new HeyHoService({});
            const resultDetails = await ServiceHeyho.getCommentsWithAttachments2(commentsDetailsId);
            const commentsMap: { [key: number]: Comment } =[]

            resultDetails.data.forEach((item: Comment) => {
                const commentId = item.id ?? item.attachment?.idComment;
                if (!commentId) return;

                if (!commentsMap[commentId]) {
                    commentsMap[commentId] = {
                        ...item,
                        attachments: item.attachment ? [item.attachment] : [],
                    };
                } else {
                    if (item.attachment) {
                        commentsMap[commentId].attachments.push(item.attachment);
                    }
                }
            });
            return Object.values(commentsMap).sort((a, b) => {
                return new Date(b.commentDate).getTime() - new Date(a.commentDate).getTime();
            });
        },
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onError: (error) => {
            console.error(error);
        }
    });

    return(
        <LcLoading loading={isLoading}>
            <div className='commentDetails'>
                <h4>Comentários:</h4>
                <div className='comment'>
                    {commentData && commentData.map((detail, index) => {
                    return (
                        <LcSegment
                            shadow
                            key={index}
                            title={
                                <div>
                                    {detail.nameUser}
                                    <br />
                                    <LcTooltip position='left' trigger='hover' content={moment(detail.commentDate).format('DD/MM/YYYY  HH:mm:ss')}>
                                        <div style={{ fontSize: '0.8rem' }}>
                                            {moment(detail.commentDate).fromNow()}
                                        </div>
                                    </LcTooltip>
                                </div>
                            }
                        >
                        <LcLoading key={detail.attachment?.id} loading={false} label={"Carregando anexo..."}>
                                {                                                                
                                (detail.attachments && detail.attachments.length > 0)
                                    ? <div className={"divOrderAttachment"} onMouseEnter={() => setSelectedRow(detail)} onMouseLeave={() => setSelectedRow([])}>
                                        <div className={"spanCommentsContent"}>
                                            {(detail.text ?? "")}
                                        </div>
                                        {
                                        <LcAccordion 
                                            title=
                                            {
                                                <div style={{width:"90px"}}>                                                                                        
                                                        <LcTooltip 
                                                            trigger={"hover"}
                                                            content={(openAccordion) ?"Ocultar" :"Exibir"} 
                                                            position={"left"}
                                                            width={"100%"}
                                                            >
                                                                <div className={"spanCommentsTitleContent"}>
                                                                    <LcIconLink notHover icon={"ppi ppi-paper-clip"} size={"small"} color={"#898DA6"} />
                                                                    <span className={"spanCommentsText"}>Anexos</span>
                                                                </div>
                                                        </LcTooltip>
                                                </div>                                                                                    
                                                }
                                            customIcon={<></>}
                                            visible={openAccordion}
                                            onChange={setopenAccordion}                                 
                                            >
                                            {/* Renderização dos anexos do tipo imagem */}
                                            {(detail.attachments.length > 0) && (
                                                <div className={"divOrderAttachmentsImages"}>
                                                    {detail.attachments.map((attachment) => (
                                                            <LcLoading
                                                            key={attachment.id}
                                                            loading={false}
                                                            label={"Carregando anexo..."}
                                                        >
                                                        {
                                                            showImage(attachment) &&
                                                            <RenderImage
                                                                attachment={attachment} 
                                                                selectedRow={selectedRow} 
                                                                setData={setData} 
                                                                setOpenAttachment={setOpenAttachment} 
                                                                setAttachment={setAttachment} 
                                                                setSelectedRow={setSelectedRow}
                                                            />
                                                        }
                                                        </LcLoading>
                                                    ))}
                                                </div>
                                            )}
                                            {/* Renderização dos anexos do tipo arquivo */}
                                            <div className={"divOrderAttachmentsContent"}>
                                                {detail.attachments.map((attachment) =>(                                                                          
                                                    <LcLoading
                                                        key={attachment.id}
                                                        loading={false}
                                                        label={"Carregando anexo..."}
                                                    >      
                                                    {
                                                        !showImage(attachment) &&
                                                        <RenderAttachment    
                                                            attachment={attachment} 
                                                            setData={setData} 
                                                            setOpenAttachment={setOpenAttachment}
                                                            setAttachment={setAttachment} 
                                                            setSelectedRow={setSelectedRow}
                                                        />
                                                    }                                 
                                                    </LcLoading>
                                                ))}
                                            </div>
                                        </LcAccordion>                                                                            
                                        }
                                    </div>
                                    
                                    : <span className={"spanCommentsContent"}> {(detail.text ?? "")} </span>
                                }
                        </LcLoading>
                            </LcSegment> 
                        )})}
                </div>
            </div>
        </LcLoading>
    );
}
