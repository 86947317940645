import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { SavingPlanReserveModel } from '../../../../services/finOps/FinOpsSavingPlanReserve/FinOpsSavingPlanReserveModel';
import moment from 'moment';

interface Props {
    data: SavingPlanReserveModel
}

const UsedVsNotUsedChart: React.FC<Props> = (props) => {

    const [usedChart, setUsedChart] = useState<string[]>([]);
    const [notusedChart, setNotUsedChart] = useState<string[]>([]);
    const [dateChart, setDateChart] = useState<string[]>([]);


    useEffect(() => {
        let usedData: any[] = props.data.resouces.map(i => ((i.quantityUsed / (i.quantityUsed + i.quantityNotUsed)) * 100).toFixed(1));
        let notusedData: any[] = props.data.resouces.map(i => (i.quantityNotUsed / ((i.quantityNotUsed + i.quantityUsed)) * 100).toFixed(1));
        let dataChart: any[] = props.data.resouces.map(i => formatarData(new Date(i.purchaseDateTime)))
            .sort((a, b) => (moment(a, "DD/MMM") as any) - (moment(b, "DD/MMM") as any))

        if (props.data.provider === 'AWS') {
            usedData = props.data.dailyUsage ? props.data.dailyUsage.map(i => i.usagePercentage) : [];
            notusedData = props.data.dailyUsage ? props.data.dailyUsage.map(i => 100 - i.usagePercentage) : [];
            dataChart = props.data.dailyUsage ? props.data.dailyUsage.map(i => formatarData(new Date(i.usageStart)))
                .sort((a, b) => (moment(a, "DD/MMM") as any) - (moment(b, "DD/MMM") as any)) : []
        }

        setUsedChart(usedData)
        setNotUsedChart(notusedData)
        setDateChart(dataChart)
    }, [props.data])

    function formatarData(data) {
        const meses = [
            'jan', 'fev', 'mar', 'abr', 'mai', 'jun',
            'jul', 'ago', 'set', 'out', 'nov', 'dez'
        ];

        const dia = ('0' + data.getDate()).slice(-2); // Adiciona zero à esquerda e pega os dois últimos dígitos
        const mesAbreviado = meses[data.getMonth()];

        return `${dia}/${mesAbreviado}`;
    }

    const optionsChart = {
        backgroundColor: '#ffffff',
        title: {
            top: 8,
            left: 10,
            // text: 'Percentual utilizado vs não utilizado',
            text: 'Percentual utilizado',
            textStyle: {
                color: '#8B90A3',
                fontSize: 16,
                fontWeight: 'normal',
                fontFamily: 'Ubuntu',
                lineHeight: 14,
            }
        },
        tooltip: {
            backgroundColor: '#f1f5fb',
            borderWidth: 0,
            textStyle: {
                fontSize: 11,
                fontFamily: 'Ubuntu',
                color: '#2B304F',
            },
        },
        legend: {
            // data: ['Utilizado', 'Não utilizado'],
            data: ['Utilizado'],
            padding: [0, 0, 10, 0],
            top: 'bottom',
            itemWidth: 0,
            itemHeight: 0,
            formatter: function (name) {
                if (name === 'Utilizado') {
                    return `{circleUsed|} {title|${name}}`;
                }
                // else if (name === 'Não utilizado') {
                //     return `{circleNotUsed|} {title|${name}}`;
                // }
            },
            textStyle: {
                rich: {
                    title: {
                        fontSize: 10,
                        color: '#8B90A3',
                        fontFamily: 'Ubuntu',
                    },
                    circleUsed: {
                        width: 12,
                        height: 12,
                        borderRadius: 6,
                        backgroundColor: "#3ebb3f",
                        align: 'center',
                        lineHeight: 14
                    },
                    // circleNotUsed: {
                    //     width: 12,
                    //     height: 12,
                    //     borderRadius: 6,
                    //     align: 'center',
                    //     backgroundColor: "#dc291e",
                    //     lineHeight: 14
                    // }
                }
            }
        },
        grid: {
            top: 50,
            left: 10,
            right: 5,
            bottom: 10,
            containLabel: true
        },
        width: '90%',
        height: '60%',
        toolbox: {
            feature: {
                saveAsImage: false,
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: dateChart,
            axisLabel: {
                fontSize: '11px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            },
        },
        yAxis: {
            type: 'value',
            max: 100,
            axisLabel: {
                fontSize: '11px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
                formatter: '{value}%',
            },
        },
        series: [
            {
                name: 'Utilizado',
                type: 'line',
                data: usedChart,
                itemStyle: {
                    normal: {
                        color: '#3ebb3f'
                    }
                }
            },
            // {
            //     name: 'Não utilizado',
            //     type: 'line',
            //     data: notusedChart,
            //     itemStyle: {
            //         normal: {
            //             color: '#dc291e'
            //         }
            //     }
            // },
        ]
    };

    return (
        <ReactECharts
            opts={{ renderer: 'svg', locale: 'PT-br' }}
            style={{ maxHeight: '35vh' }}
            option={optionsChart}
        />
    )
}

export default UsedVsNotUsedChart;