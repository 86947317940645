import api, { APIClient } from "../api";

import axios from 'axios';

export interface Agendamento {
    id?: number
    titulo?: string,
    descricao?: string,
    contrato?: string,
    dataInicio?: string,
    duracao?: any, //
    idCliente?: any, //
    idContratoProduto?: any, //
    idUsuario?: string,
    tipoTarefa?: any, //
    situacao?: any, //
    dataCriacao?: string,
    idSource?: any, //
    dataAtualizacao?: string,
    status?: any, //
    clientGroupId?: number,
    clientGroup?: any, //
    aspNetUsers?: any, //
    validationMenssage?: any //
}

export class HeyHoCallService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    async RetrieveChamadoTreeList(GtmId: number) {

        const url = `${this.endpoint}/api/Agendamentos/GetChamadoTreeByGTMId/${GtmId}`;
        return api({
            method: 'get',
            url,
        }).then((response: any) => {

            return response.data;
        });
    }
    //      ,
    //gtmType: GTMType
    async GetContracts(gtmType: any) {
        const url = this.endpoint + `/api/Agendamentos/getContractsAgendamento?GTMType=${gtmType}`;
        return api({
            method: 'get',
            url
        })
            .then((response) => {
                let contracts: any[] = [];
                response.data.dataCollection.map((item: any) => {
                    contracts.push({
                        value: item.value,
                        name: item.label
                    });
                });
                return contracts;
            }).catch((err: any) => {
                return [];
            });
    }

    async RetrieveDefaultChamadoTreeItem(gtmId: any) {
        const url = `${this.endpoint}/api/Agendamentos/GetDefaultGTMLTree?GTMId=${gtmId}`;
        return api({
            method: 'get',
            url,
        });
    }

    async Criar(agendamento: any, attachment: any) {
        debugger;
        var bodyFormData = new FormData();
        bodyFormData.append('agendamentoString', JSON.stringify(agendamento))
        bodyFormData.append('attachment', attachment);

        const url = this.endpoint + `/api/Agendamentos/Criar`;
        return api({ method: 'post', url, data: bodyFormData })
            .then((response) => {

                return response.data.id;
            }).catch((err: any) => {

                return false;
            });
    }
    
    async CriarAnonimo(agendamento: any, attachment: any) {

        var bodyFormData = new FormData();
        bodyFormData.append('agendamentoString', JSON.stringify(agendamento))
        bodyFormData.append('attachment', attachment);

        const url =  this.endpoint + `/api/Agendamentos/Criar?anonymous=true`;
        return api({ method: 'post', url, data: bodyFormData })
            .then((response) => {

                return response.data.id;
            }).catch((err: any) => {

                return false;
            });
    }
}