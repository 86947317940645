import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import DialogConfirmForm from '../../../components/Layout/Dialog/DialogConfirmForm';
import { useSnackbar } from 'notistack';
import DetailForm from '../DetailForm';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { MenuState } from '../../../store/reducers/menuReducer';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';
import { setFilterActive } from '../../../store/reducers/filterReducer';
import { PPForm2} from 'processor-plataform-ui';
import LcLoading from '../../../components/Generic/LcLoading';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import { PiArrowLeftLight, PiCopyLight, PiFileTextLight, PiPlusLight } from 'react-icons/pi';
import '../index.css'
import FilterPanel from '../../../components/Data/FilterPanel';
import { HHCommentList } from './HHCommentList';
import { DocumentView } from '../../../components/Generic/DocumentView/DocumentView';
import { fontColor, statusColor, urgencyColor } from '../HHUtilities';
import { HeyHoService } from '../../../services/Heyho/heyHoService';
import { FilterPage, HeyhoRows } from '../../../services/Heyho/heyhoModel';
import { ModalNewRequest } from './ModalNewRequest';

export interface HeyHoProps {
    Title?: string,
    Detalhes?: string,
    Servico?: number,
}

const HeyHoList: React.FC<HeyHoProps> = (props) => {

    const [ServiceHeyho] = useState<HeyHoService>(new HeyHoService(props))
    const [ListData, setListData] = useState<any[]>([]);
    const [ListDataFiltered, setListDataFiltered] = useState<any[]>([]);
    const [visibleListDataFiltered, setVisibleListDataFiltered] = useState<HeyhoRows[]>([]);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [showDetailComment, setShowDetailComment] = useState(false);
    const [commentsDetails, setCommentsDetails] = useState<any>([]);
    const { enqueueSnackbar } = useSnackbar();
    const [openModalNewRequest, setOpenModelNewReqeust] = useState<boolean>(false)
    const [commentId, setCommentId] = useState<number>(0);
    const [data, setData] = useState<string>("");
    const [openAttachment, setOpenAttachment] = useState<boolean>(false);
    const [attachment, setAttachment] = useState<any>([]);
    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();
    const emptyRecordMessage = useMemo(() => ({
        detail: false,
        edit: false,
        add: false,
        record: ServiceHeyho.emptyRecord
    }), [ServiceHeyho.emptyRecord]);
    
    const [record, setRecord] = useState(emptyRecordMessage);
    const [filterPage, setFilterPage] = useState<FilterPage>({
        size: 20000,
        term: "",
        start: 0,
        situacao: "",
        criticidade: "",
        column: "id",
        direction: "desc",
        filterStatus: 0,
        filterCriticidade: 0,
        filter: '',
        ordered: 'id'

    });
    const [template, setTemplate] = useState<any>(ServiceHeyho.templateEdit2());
    const [templateEdit, setTemplateEdit] = useState<any>([]);
    const [counter, setCounter] = useState(0);
    const [filterVisible, setFilterVisible] = useState(false);
    
    const loadData = useCallback(async (filter: any) => {
        setLoading(true);
        if (filter === undefined) {
            filter = "";
        }
        const ServiceHeyho = new HeyHoService({})
        await ServiceHeyho.RetrieveList(filter)
            .then((tt: any) => {
                setListData(tt.data.dataCollection);
                setListDataFiltered(tt.data.dataCollection);
            }).catch((error) => {
                console.error(error);  
            })
            .finally(() => {
                setLoading(false);
            });
    },[])

    useEffect(() => {
        loadData(filterPage)
        const ServiceHeyho = new HeyHoService({})
        ServiceHeyho.RetrieveContractsAndGTM();
        let templateLO = ServiceHeyho.getTemplate();
        setTemplate && setTemplate({ ...templateLO });
        setOpenModelNewReqeust(false);
    }, [user.refreshFlag, user.ClientGroupSelected, filterPage, loadData]);

    const ADD = useCallback(() => {
        enqueueSnackbar(`Recolhendo informações para abertura de chamados.`, {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        });
        setRecord(() => ({
            record: { ...ServiceHeyho.emptyRecord },
            edit: false,
            detail: false,
            add: true
        }));
        setOpenModelNewReqeust(true);
    }, [ServiceHeyho.emptyRecord, enqueueSnackbar]);
    
    useEffect(() => {
        if (menu.newRequest) ADD();
    }, [ADD, menu.newRequest]);

    const columns = [
        { field: 'id', headerName: 'Ticket', width: "10%" },
        { field: 'titulo', headerName: 'Assunto', width: "70%" },
        {
            field: 'heyHoSatatus', headerName: 'Situação', width: "18%",
            renderCell: (row: HeyhoRows) => {
                return (
                    <div className={"divStatusColorDisplay"} style={{ backgroundColor: statusColor(row) }}>
                        <span style={{ fontWeight: "700", color: fontColor(row) }}>{row.heyHoSatatus}</span>
                    </div >
                )
            }
        },
        {
            field: 'criticidade', headerName: 'Criticidade', width: "15%",
            renderCell: (row: HeyhoRows) => {
                return (
                    <div className={"divStatusColorDisplay"} style={{ backgroundColor: urgencyColor(row.criticidade) }}>
                        <span style={{ fontWeight: "700", color: "#fff" }}>{row.criticidade}</span>
                    </div >
                )
            }
        },

        {
            field: 'functions', headerName: '', width: "5%", overflow: "visible", align: "center",
            renderCell: (row: any) => <LcIconLink icon={<PiFileTextLight />} size="small" tooltip="Detalhes" tooltipPosition='inline-left' onClick={() =>{ setCommentId(row.id); selecionadoEditar(row.id)}} />
        }
    ];  

    async function saveComment(item: any) {
        setLoading(true);
        if (item.Anexo !== undefined) {
            await ServiceHeyho.sendFile(item.Anexo, item.id, 0, item.Descricao )
                .then((response) => {
                    if (response.data === 201) {
                        selecionadoEditar(item.id)
                        enqueueSnackbar(`Comentário para o ticket ${item.id} criado com sucesso.`, {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        });
                    }
                })
                .catch((error) => {
                    enqueueSnackbar(`Erro ao criar comentário.`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                    console.error(error)
                })
                .finally(() => {
                    setShowDetailComment(false);
                    selecionadoEditar(item.id)
                });
        } else {
            ServiceHeyho.CreateComment(item)
                .then((items: any) => {
                    if (items.status === 200) {
                        enqueueSnackbar(`Comentário para o ticket ${item.id} criado com sucesso.`, {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        });
                    }
                })
                .catch((error) => {
                    enqueueSnackbar(`Erro ao criar comentário.`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                    console.error(error)
                }).finally(() => {
                    setShowDetailComment(false);
                    selecionadoEditar(item.id)
                });
        }
    }

    const functions = {
        functionsList: [
            {
                title: 'Novo',
                tooltip: 'Novo',
                icon: <PiPlusLight/>,
                onClick: () => {
                    ADD();
                },
                disabled: (template && !template.Fields )||( template && template.Fields && template.Fields.length === 0)
            }
        ],
    };

    const selecionadoEditar = (childData: any) => {
        if (childData !== null) {
            EDIT(childData);
        }
    };

    const ADDComment = async () => {
        setLoading(true);
        setShowDetailComment(true)
        setLoading(false);
    };

    const EDIT = async (item: any) => {
        setLoading(true);
        let addRecord = { ...emptyRecordMessage };
        addRecord.edit = true;
        addRecord.add = false;
        addRecord.detail = false;
        ServiceHeyho
            .Retrieve(item)
            .then(async (response) => {
                let addRecord = { ...emptyRecordMessage };
                addRecord.edit = true;
                addRecord.add = false;
                addRecord.detail = false;
                let itemDetail = response.data.dataCollection[0];
                addRecord.record = itemDetail;
                setRecord({ ...addRecord });
                setTemplateEdit(ServiceHeyho.templateEdit2(addRecord.record));
                let resultDetails = await ServiceHeyho.GetSolicitationCommentDetails(item);
                setCommentsDetails(resultDetails.data.sort((a, b) => { let bDate: any = new Date(b.commentDate); let aDate: any = new Date(a.commentDate); return bDate - aDate })
                );

            }).catch((error) => {
                setCommentsDetails([]);
                setTemplateEdit(undefined);
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const resetData = () => {
        var counterPlus = counter + 1;
        setCounter(counterPlus);
        loadData(filterPage)
    };

    useEffect(() => {
        let filtered = ListData.sort((a: any, b: any) => {
            if (filterPage.direction === 'asc') return (a[filterPage.column] > b[filterPage.column]) ? 1 : -1;
            else return (a[filterPage.column] > b[filterPage.column]) ? -1 : 1;
        });
        setListDataFiltered(filtered);
        setVisibleListDataFiltered(filtered.slice(0, filterPage.size));
    }, [ListData, filterPage]);

    const loadMore = (size: number) => {
        if (visibleListDataFiltered.length >= size) {
            setVisibleListDataFiltered(ListDataFiltered.slice(0, size));
            
        } 
        else {

            setVisibleListDataFiltered([...visibleListDataFiltered, ...ListDataFiltered.slice(visibleListDataFiltered.length, visibleListDataFiltered.length + 10)]);
        }
    };

    const card: Card[] = [
        {
            id: 1,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            hideHeader: true,
            title: () => {
                if (templateEdit && record.edit === true && !loading) {
                    return (
                        <div className="lc-title ellipsis">
                            Ticket: {record.record.heyHoId}
                            &nbsp;
                            Situação: {record.record.heyHoSatatus}
                        </div>
                    );
                } else {
                    return null;
                }
            },
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={loading}>
                        {(record.edit !== true && ListDataFiltered) ? (
                            <LcInfiniteTable
                                columns={columns}
                                rows={visibleListDataFiltered}
                                size={visibleListDataFiltered.length}
                                loadMore={loadMore}
                                density={pref.data?.density || "high"}
                                status={urgencyColor}
                                tooltipContentColumn='criticidade'
                                disableFilterModal
                                hidePagination
                            />
                        ) : (
                            templateEdit && (record.edit === true) && (
                                <>
                                    <div className={'scrollable-v'} style={{ marginBottom: '20px' }}>
                                        <PPForm2
                                            loading={loading}
                                            functions={{
                                                submitButton: {
                                                    hide: true,
                                                    label: 'Ok'
                                                },
                                            }}
                                            fields={templateEdit}
                                        />

                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                        }}>
                                            {commentsDetails && commentsDetails.length > 0 && (
                                                <HHCommentList 
                                                    commentsDetailsId={commentId}
                                                    setData={setData}   
                                                    setOpenAttachment={setOpenAttachment}
                                                    setAttachment={setAttachment}

                                                />
                                            )}
                                        </div>
                                    </div>
                                </>
                            )
                        )}
                    </LcLoading>
                );
            }
        }
    ];
    const translations = {
        heyHoId: {
            label: "Ticket",
            type: "text",
        },
        subject: {
            label: "Assunto",
            type: "text",
        },
        heyHoSatatus: {
            label: "Situação",
            type: "multiSelect",
        },
        criticidade: {
            label: "Criticidade",
            type: "multiSelect",
        }

    };
    const filterSystem = () => {
        return (
            <div className="right-sidepanel-filters">
            <FilterPanel
                filter={Object.keys(translations).map(key => ({ 
                    label: translations[key].label,
                    name: key,
                    type: translations[key].type,
                    values: translations[key].values,
                }))}
                data={ListData}
                onFilteredData={(filteredData) =>{ setVisibleListDataFiltered(filteredData as HeyhoRows[]);}}
                translations={translations}
            />
        </div>
        );
    };

    // UseEffect para limpar o filtro ativo quando o componente é desmontado
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    // Condição 1
    const isList = ServiceHeyho.state.data.chamadoTree && functions.functionsList;

    // Condição 2
    const isDetails = templateEdit && record.edit === true && !loading;
    
    // Condição 3
    const isAddComment = record.record?.idSituacao !== 7 && record.record?.idSituacao !== 6 && record.record?.idSituacao !== 8 && !loading;


    const functionBack = [
        {
            title: 'Voltar',
            icon: <PiArrowLeftLight />,
            tooltip: "Voltar",
            onClick: () => {
                setRecord({ ...emptyRecordMessage });
            }
        }
    ]

    const functionAddComment = [
        {
            title: 'Adicionar comentário',
            icon: 'lci lci-annotation',
            tooltip: "Adicionar comentário",
            onClick: () => ADDComment()
        }
    ]
    let functionsToUse;

    if (isList) {
        if (isDetails) {
            functionsToUse = functionBack;
            if (isAddComment) {
                functionsToUse = [...functionBack, ...functionAddComment];
            }
        } else {
            functionsToUse = functions.functionsList;
        }
    }

    const downloadAttachment = async (attachment) => {
        const ServiceHeyho = new HeyHoService({})
        await ServiceHeyho.downloadAttachmentById(attachment)
    }

    return (
        <Layout pageTitle="Chamados LiveCloud"
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: visibleListDataFiltered.length
            }}
            functionsGeneric={functionsToUse}

        >
            <ModalNewRequest
                setLoading={setLoading}
                openModalNewRequest={openModalNewRequest}
                setOpenModalNewRequest={setOpenModelNewReqeust} 
                resetData={resetData} 
            />
            {showDetailComment && (
                <DetailForm
                    ticketId={record.record.id}
                    visible={true}
                    parentCallbackSave={saveComment}
                    onClose={() => setShowDetailComment(false)}
                />
            )}

            <DialogConfirmForm onCloseEvent={() => setOpen(false)}
                open={open} confirmMessage='Confirmar' abortMessage='Cancelar' title='Confirmar?' content=''>
            </DialogConfirmForm>

            <LCDashboard
                cards={card}
                cssClass={templateEdit && (record.edit === true) ? 'hidefilterPanel' : ''}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                }}
            />
            <DocumentView
                data={data}
                downloadAttachment={downloadAttachment}
                attachment={attachment}
                handleCloseAttachment={() => setOpenAttachment(false)}
                openAttachment={openAttachment}
            />

        </Layout >
    );

};

export default HeyHoList;
