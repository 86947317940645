import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import { WebSitesService } from '../../../../services/websites';
import LcLoading from '../../../../components/Generic/LcLoading';
import GaugeChartCore from '../../../../components/Layout/Dashboard/gaugeChartCore';
import LcNoData from '../../../../components/Generic/LcNoData';
import LCDashboard from '../../../../components/Data/Dashboard/LCDashboard';
import DoughNutEChart from '../../../../components/Data/Dashboard/DoughnutEchart';
import './index.css';

interface Props {
    monthParam: string;
    yearParam: string;
    selectedServiceLevel: string;
}

interface DoughnutChartValue {
    title: string;
    data: Array<{
        value: number;
        name: string;
        itemStyle: { color: string };
    }>;
}

const LiveWatchServiceLevels: React.FC<Props> = ({ monthParam, yearParam, selectedServiceLevel }) => {
    const webSitesService = new WebSitesService({});
    const user = useSelector<RootState, UserState>(state => state.user);
    const [sloChart, setSloChart] = useState<number>(0);
    const [slaChart, setSlaChart] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const [isLoadingChamadosChart, setIsLoadingChamadosChart] = useState<boolean>(true);

    const [isLoadingTotalChamadosChart, setIsLoadingTotalChamadosChart] = useState<boolean>(true);

    const [isLoadingChamadosPorOrigemChart, setIsLoadingChamadosPorOrigemChart] = useState<boolean>(true);
    const [day, setDay] = useState<string>('01');
    const [month, setMonth] = useState<string>(monthParam);
    const [year, setYear] = useState<string>(yearParam);
    const [searchStartDate, setSearchStartDate] = useState<string>(`${year}${month.padStart(2, '0')}${day}`);
    const [hostData, setHostData] = useState<{ host: number, contratado: number }>({ host: 0, contratado: 0 })
    const [isLoadingHostData, setIsLoadingHostData] = useState<boolean>(false)

    const [chamadosChart, setChamadosChart] = useState<DoughnutChartValue>({ title: '', data: [] });
    const [totalChamadosChart, setTotalChamadosChart] = useState<DoughnutChartValue>({ title: '', data: [] });
    const [chamadosPorOrigemChart, setChamadosPorOrigemChart] = useState<DoughnutChartValue>({ title: '', data: [] });
    const [updateTrigger, setUpdateTrigger] = useState(0);

    useEffect(() => {
        setLoading(true);

        if (user.ClientGroupSelected) {
            const serviceLevelFilter = selectedServiceLevel === 'LW' ? 94 : undefined;
            webSitesService.GetServiceLevels(month.toString(), year.toString(), serviceLevelFilter)
                .then(response => {
                    if (response.data) {
                        const data = response.data;
                        const product = data.products.find(p => p.product === 'LiveWatch');

                        // Update SLA and SLO charts
                        setSloChart(selectedServiceLevel === 'LC' ? data.slaConclusao : (product ? product.slaConclusao : 0));
                        setSlaChart(selectedServiceLevel === 'LC' ? data.slaAtendimento : (product ? product.slaAtendimento : 0));

                        // Process chamados chart
                        setChamadosChart({
                            title: 'Chamados por horário',
                            data: [
                                {
                                    value: selectedServiceLevel === 'LC' ? data.totalChamadosHC : (product ? product.totalChamadosHC : 0),
                                    name: "Comercial",
                                    itemStyle: { color: '#8C40E3' }
                                },
                                {
                                    value: selectedServiceLevel === 'LC' ? data.totalChamadosFHC : (product ? product.totalChamadosFHC : 0),
                                    name: "Não comercial",
                                    itemStyle: { color: '#DA43F2' }
                                }
                            ]
                        });

                        // Process total chamados chart
                        setTotalChamadosChart({
                            title: 'Chamados por situação',
                            data: [
                                {
                                    value: selectedServiceLevel === 'LC' ? data.totalChamadosAbertas : (product ? product.totalChamadosAbertas : 0),
                                    name: 'Abertos',
                                    itemStyle: { color: '#DA43F2' }
                                },
                                {
                                    value: selectedServiceLevel === 'LC' ? data.totalChamadosFechadas : (product ? product.totalChamadosFechadas : 0),
                                    name: 'Fechados',
                                    itemStyle: { color: '#8C40E3' }
                                },
                                {
                                    value: selectedServiceLevel === 'LC' ? data.totalChamadosCanceladas : (product ? product.totalChamadosCanceladas : 0),
                                    name: 'Cancelados',
                                    itemStyle: { color: '#00DBFF' }
                                }
                            ]
                        });

                        // Process chamados por origem chart
                        setChamadosPorOrigemChart({
                            title: 'Chamados por origem',
                            data: [
                                {
                                    value: selectedServiceLevel === 'LC' ? data.totalChamadosAbertosPeloCliente : (product ? product.totalChamadosAbertosPeloCliente : 0),
                                    name: user.ClientGroupSelectedDescription || 'Cliente',
                                    itemStyle: { color: '#8C40E3' }
                                },
                                {
                                    value: selectedServiceLevel === 'LC' ? data.totalChamadosAbertosPelaProcessor : (product ? product.totalChamadosAbertosPelaProcessor : 0),
                                    name: 'Processor',
                                    itemStyle: { color: '#DA43F2' }
                                }
                            ]
                        });

                        setIsLoadingChamadosChart(false);
                        setIsLoadingTotalChamadosChart(false);
                        setIsLoadingChamadosPorOrigemChart(false);

                    } else {
                        resetCharts();
                    }
                })
                .catch(error => {
                    console.error('Error fetching service level data:', error);
                    resetCharts();
                })
                .finally(() => {
                    setLoading(false);
                    setUpdateTrigger(prev => prev + 1); // Força re-renderização

                });
        }
    }, [user.ClientGroupSelected, searchStartDate, user.refreshFlag, selectedServiceLevel, monthParam, yearParam]);

    const resetCharts = () => {
        setSloChart(0);
        setSlaChart(0);
        setChamadosChart({ title: 'Chamados por horário', data: [] });
        setTotalChamadosChart({ title: 'Chamados por situação', data: [] });
        setChamadosPorOrigemChart({ title: 'Chamados por origem', data: [] });
    };

    const renderDoughnutChart = (chartData: DoughnutChartValue) => {
        if (!chartData || !chartData.data || chartData.data.length === 0) {
            return <LcNoData />;
        }

        return (
            <DoughNutEChart
                key={`${selectedServiceLevel}-${JSON.stringify(chartData)}`}
                ArrayData={chartData.data}
                decimals={0}
                showPercent={false}
                legendOrientation="vertical"
            />
        );
    };

    const card = [
        { // 1. SLA de atendimento
            id: 1,
            type: 'Custom', position: { ColSpan: 6, RowSpan: 1, row: 1, col: 1 },
            showFocusButton: false,
            hideHeader: true,
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading label="Carregando gráfico..." loading={loading}>
                        <GaugeChartCore value={slaChart} title="SLA de atendimento" style={{ maxHeight: '140px', width: '100%' }} />
                    </LcLoading>
                )
            }
        },
        { // 2. SLO de conclusão
            id: 2,
            type: 'Custom', position: { ColSpan: 7, RowSpan: 1, row: 1, col: 7 },
            showFocusButton: false,
            hideHeader: true,
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading label="Carregando gráfico..." loading={loading}>
                        <GaugeChartCore value={sloChart} title="SLO de conclusão" style={{ maxHeight: '140px', width: '100%' }} />
                    </LcLoading>
                )
            }
        },
        { // 3. Chamados por horário
            id: 3,
            type: 'Custom', position: { ColSpan: 4, RowSpan: 4, row: 2, col: 1 },
            showFocusButton: false,
            title: () => <div className="lc-title ellipsis">Chamados por horário</div>,
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading label="Carregando gráfico..." loading={isLoadingChamadosChart}>
                        {renderDoughnutChart(chamadosChart)}
                    </LcLoading>
                )
            }
        },
        { // 4. Chamados por origem
            id: 4,
            type: 'Custom', position: { ColSpan: 4, RowSpan: 4, row: 2, col: 5 },
            showFocusButton: false,
            title: () => <div className="lc-title ellipsis">Chamados por origem</div>,

            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading label="Carregando gráfico..." loading={isLoadingChamadosPorOrigemChart}>
                        {renderDoughnutChart(chamadosPorOrigemChart)}
                    </LcLoading>
                )
            }
        },
        { // 5. Chamados por situação
            id: 5,
            type: 'Custom', position: { ColSpan: 4, RowSpan: 4, row: 2, col: 9 },
            showFocusButton: false,
            title: () => <div className="lc-title ellipsis">Chamados por situação</div>,

            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading label="Carregando gráfico..." loading={isLoadingTotalChamadosChart}>
                        {renderDoughnutChart(totalChamadosChart)}
                    </LcLoading>
                )
            }
        },

    ]

    return <LCDashboard cards={card} grid={[6, 12]} key={`${selectedServiceLevel}-${monthParam}-${yearParam}`} />
}

export default LiveWatchServiceLevels;