import React, { useCallback, useEffect, useState } from 'react';
import { Resouces, SavingPlanReserveModel, DailyUsage } from '../../../../services/finOps/FinOpsSavingPlanReserve/FinOpsSavingPlanReserveModel';
import LcLoading from '../../../../components/Generic/LcLoading';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';

interface MediaUsageChartProps {
	data: SavingPlanReserveModel[];
	loading: boolean
	periodSelected: number;
}

interface chartModel {
	type: string;
	Azure: string;
	AWS: string

}

export const MediaUsageChart: React.FC<MediaUsageChartProps> = ({
	data,
	loading,
	periodSelected,
}) => {
	const user = useSelector<RootState, UserState>(state => state.user);
	const [chartData, setChartData] = useState<chartModel[]>([])

	const getResourcePeriod = (data: any, periodSelected: number): Resouces[] => {
		const hoje = new Date();
		const mesAtual = hoje.getMonth();
		const anoAtual = hoje.getFullYear();
		const objetosFiltrados = data.filter(objeto => {
			const effectiveDateTime = new Date(objeto.hasOwnProperty('purchaseDateTime') ? objeto.purchaseDateTime : objeto.usageStart);
			const filteringCurrentMonth = periodSelected === 1 && effectiveDateTime.getMonth() === mesAtual && effectiveDateTime.getFullYear() === anoAtual;
			const filteringPreviousMonth = periodSelected === 2 && effectiveDateTime.getMonth() === (mesAtual === 0 ? 11 : mesAtual - 1) && effectiveDateTime.getFullYear() === (mesAtual === 0 ? anoAtual - 1 : anoAtual);

			return filteringCurrentMonth || filteringPreviousMonth || periodSelected === 3
		});
		return objetosFiltrados

	}

	const getChartParams = useCallback(() => {
		let somatorioReservaAWS = 0;
		let somatorioReservaAzure = 0;
		let somatorioSavingPlanAWS = 0;
		let somatorioSavingPlanAzure = 0;

		const agrupadoPorProviderETipo: any = data.reduce((acc, obj) => {
			const { provider, type, resouces, dailyUsage } = obj;

			if (!acc[provider]) {
				acc[provider] = {};
			}

			if (!acc[provider][type]) {
				acc[provider][type] = [];
			}

			if (provider === 'AWS')
				acc[provider][type].push(...dailyUsage!);
			else
				acc[provider][type].push(...resouces);

			return acc;
		}, {});
		if (agrupadoPorProviderETipo.Azure && agrupadoPorProviderETipo.Azure.Reserva) {
			let objetosFiltradosAzureReserva = getResourcePeriod(agrupadoPorProviderETipo?.Azure.Reserva, periodSelected)
			somatorioReservaAzure = objetosFiltradosAzureReserva && buildSomatory(objetosFiltradosAzureReserva);
		}
		if (agrupadoPorProviderETipo.Azure && agrupadoPorProviderETipo.Azure?.['Savings Plan']) {
			let objetosFiltradosAzureSavingsPlan = getResourcePeriod(agrupadoPorProviderETipo?.Azure['Savings Plan'], periodSelected)
			somatorioSavingPlanAzure = objetosFiltradosAzureSavingsPlan && buildSomatory(objetosFiltradosAzureSavingsPlan);
		}
		if (agrupadoPorProviderETipo.AWS && agrupadoPorProviderETipo.AWS.Reserva) {
			let objetosFiltradosAWSReserva = getResourcePeriod(agrupadoPorProviderETipo?.AWS.Reserva, periodSelected)
			somatorioReservaAWS = objetosFiltradosAWSReserva && buildSomatory(objetosFiltradosAWSReserva);
		}
		if (agrupadoPorProviderETipo.AWS && agrupadoPorProviderETipo.AWS?.['Savings Plan']) {
			let objetosFiltradosAWSSavingsPlan = getResourcePeriod(agrupadoPorProviderETipo?.AWS['Savings Plan'], periodSelected)
			somatorioSavingPlanAWS = objetosFiltradosAWSSavingsPlan && buildSomatory(objetosFiltradosAWSSavingsPlan);
		}
		return [
			{ type: 'Saving Plan', Azure: ((somatorioSavingPlanAzure) * 100).toFixed(1), AWS: ((somatorioSavingPlanAWS) * 100).toFixed(1) },
			{ type: 'Reserva de instâncias', Azure: ((somatorioReservaAzure) * 100).toFixed(1), AWS: ((somatorioReservaAWS) * 100).toFixed(1) },
		];
	}, [data, periodSelected])


	const buildSomatory = (ObjFiltrado: any[]) => {
		const resultado = ObjFiltrado.reduce((acc, objeto) => {
			acc.somaQuantityUsed += objeto.quantityUsed || 0;
			acc.somaQuantityNotUsed += objeto.quantityNotUsed || 0;
			acc.count++;
			return acc;
		}, { somaQuantityUsed: 0, somaQuantityNotUsed: 0, count: 0 });
		return (resultado.somaQuantityUsed / (resultado.somaQuantityUsed + resultado.somaQuantityNotUsed));
	}

	useEffect(() => {
		if (data.length > 0) setChartData(getChartParams());
	}, [user.ClientGroupSelected, user.refreshFlag, data, periodSelected, getChartParams])

	const optionsChart = {
		grid: {
			top: 10,
			bottom: 5,
			left: 0,
			right: 70,
			containLabel: true,
		},
		tooltip: {
			backgroundColor: '#f1f5fb',
			borderWidth: 0,
			textStyle: {
				fontSize: 11,
				fontFamily: 'Ubuntu',
				color: '#2B304F',
			},
		},
		dataset: {
			dimensions: ['type', 'Azure', 'AWS'],
			source: chartData,
		},
		xAxis: {
			type: 'category',
			show: true,
			axisLabel: {
				interval: 0,
				fontSize: '.6rem',
				fontFamily: 'Ubuntu',
				color: '#8b90a3',
			},
		},
		yAxis: {
			type: 'value',
			max: 100,
			axisLabel: {
				fontSize: '.6rem',
				fontFamily: 'Ubuntu',
				color: '#8b90a3',
			},
		},
		series: [
			{ type: 'bar', name: 'Azure', itemStyle: { color: '#173192' }, barGap: 0, },
			{ type: 'bar', name: 'AWS', itemStyle: { color: '#ff9900' }, barGap: 0, },

		],
		legend: {
			data: ['Azure', 'AWS'],
			orient: 'vertical',
			top: 'middle',
			right: 10,
			itemWidth: 0,
			itemHeight: 0,
			formatter: function (name) {
				if (name === 'Azure') {
					return `{circleAzure|} {title|${name}}`;
				} else if (name === 'AWS') {
					return `{circleAWS|} {title|${name}}`;
				}
			},
			textStyle: {
				rich: {
					title: {
						fontSize: 10,
						color: '#a4a9b9',
						fontFamily: 'Ubuntu',
					},
					circleAzure: {
						width: 10,
						height: 10,
						borderRadius: 5,
						backgroundColor: "#173192",
						align: 'center',
						lineHeight: 14
					},
					circleAWS: {
						width: 10,
						height: 10,
						borderRadius: 5,
						align: 'center',
						backgroundColor: "#ff9900",
						lineHeight: 14
					}
				}
			}
		}
	};

	return (
		<LcLoading loading={loading} >
			<ReactECharts
				opts={{ renderer: 'svg', locale: 'PT-br' }}
				style={{ maxHeight: '20vh' }}
				option={optionsChart}
			/>
		</LcLoading >
	)
}
