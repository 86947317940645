import React, { useEffect, useState } from 'react';
import Layout from '../../../../components/Layout/Layout';

import { SaasUserService, SaaSUser } from '../../../../services/SaasUserService';
import UserDetail from '../userDetails';
import {
    Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, IconButton, Snackbar
} from '@material-ui/core';

//Redux
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import { PrefState } from '../../../../store/reducers/prefReducer';
import { MenuState } from '../../../../store/reducers/menuReducer';
import { setFilterActive } from '../../../../store/reducers/filterReducer';

import LcIconLink from '../../../../components/Generic/LcIconLink';
import { useSnackbar } from 'notistack';
import exportFromJSON from 'export-from-json'
import LcTooltip from '../../../../components/Generic/LcTooltip';

//Img Fluxo
import ImgFluxoCSP from '../../../../assets/images/Fluxos/csp.png';
import { SaasService } from '../../../../services/saas/saasService';
import LcInfiniteTable from '../../../../components/Data/LcInfiniteTable';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import LcCheckList from '../../../../components/Form/LcCheckList';
import { PPModal } from 'processor-plataform-ui';

import { CacheManagerService } from '../../../../services/cacheManagerService';
import LCDashboard, { Card } from '../../../../components/Data/Dashboard/LCDashboard';
import { FilterLcInfiniteTable } from '../../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';

import PPFormWithWizard, { FormFields } from '../../../../components/Form/PPFormModal';
import LcLoading from '../../../../components/Generic/LcLoading';
import LcNoData from '../../../../components/Generic/LcNoData';
import {
    PiArrowSquareOutLight, PiClipboardTextLight, PiCloudLight,
    PiDesktopLight, PiFileCsvLight, PiHardDrivesLight, PiKeyLight,
    PiLinkLight, PiPencilSimpleLight, PiPlusLight, PiQuestionLight,
    PiUserLight, PiUsersThreeLight, PiXLight
} from 'react-icons/pi';

//test update devops
const UsersSaaS: React.FC = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const [openError, setOpenError] = useState<boolean>(false);
    const history = useHistory();
    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const dispatch = useDispatch();
    const SaaS_Service = new SaasUserService(props)
    const saasService = new SaasService(props);
    const CacheManager_Service = new CacheManagerService(props);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingTable, setLoadingTable] = useState<boolean>(true);
    const [records, setRecords] = useState<any[]>([]);
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
    const [displayName, setDisplayName] = useState<string>("");
    const [id, setId] = useState<string>("");
    const [refresh, setRefresh] = useState<boolean>(false);
    const [typeForm, setTypeForm] = useState<string>('');
    const [modalTitle, setModalTitle] = useState<string>('');
    const [currentUser, setCurrentUser] = useState<SaaSUser[]>([]);
    const [filter, setFilter] = useState<any>();
    const [fullDataRowCount, setFullDataRowCount] = useState<number>(0);
    const [open, setOpen] = useState(false);
    const [showMemberOfForm, setShowMemberOfForm] = useState<boolean>(false);
    const [isLoadingMemberOfForm, setIsLoadingMemberOfForm] = useState<boolean>(true);
    const [membersOfUserLabel, setMembersOfUserLabel] = useState<string>("");
    const [membersOfUserId, setMembersOfUserId] = useState<string>("");
    const [memberOfGroups, setMemberOfGroups] = useState<any[]>();
    const [allCloudGroups, setAllCloudGroups] = useState<any[]>([]);
    const [listGroups, setListGroups] = useState<any[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<any[]>([]);
    const [lastUpdate, setLastUpdate] = useState<string>("");
    const [editData, setEditData] = useState<object>({});
    const [filterVisible, setFilterVisible] = useState(false);
    const [showFluxoCSP, setShowFluxoCSP] = useState<boolean>(false);
    //Criação de usuário em Modal
    const [addUserVisible, setAddUserVisible] = useState(false);
    //Edição de usuário
    const [editUserVisible, setEditUserVisible] = useState(false);
    const [idToEdit, setIdToEdit] = useState("");
    const [formModalFieldsData, setFormModalFieldsData] = useState<FormFields>(() => {
        SaaS_Service.Initialize().then(result => {
            setFormModalFieldsData(SaaS_Service.getUserFormFieldsData());
        })

        return SaaS_Service.getUserFormFieldsData();
    });

    const refreshLastUpdate = () => {
        CacheManager_Service.LastSyncUpdate("LiveCloudSyncSaasService", "SaaSUserConsolidateds")
            .then(response => {
                setLastUpdate(response.data);
            })
            .catch((err) => {
                console.warn(err);
                setLastUpdate(new Date().toISOString());
            })
    }

    useEffect(() => {
        setLoading(true);
        setOffAll()
        setRecords([]);
        let f = SaaS_Service.getFilter();
        f = { ...f, start: 0, size: f.size };
        setFilter(f);
        refreshLastUpdate();
    }, [user.ClientGroupSelected, user.refreshFlag, refresh])

    const getUserService = async (filter) => {
        try {
            const response = await SaaS_Service.UserServiceGet(filter)
            setFullDataRowCount(response.data.pageRecordCount === 0 ? records.length : response.data.recordCount);
            const modifiedUsers = response.data.userCollection.map(user => {
                if (user.mailBox !== null && typeof user.mailBox === 'string' && user.mailBox !== "")
                    return { ...user, mailBox: user.mailBox.replace(/GB/g, "").trim() };
                return user;
            });
            // setRecords(prevRecords => [...prevRecords, ...modifiedUsers]);
            return modifiedUsers;
        } catch (error) {
            console.error(error)
        }
    }

    const getOnedriveUsage = async (days: number) => {
        try {
            const response = await saasService.GetOneDriveUsageAccountDetail(days);
            return response;
        } catch (error) {
            console.error(error);
        }
    }
    const mergeStorageInfo = (userService, onedriveUsage) => {
        return userService.map(obj2 => {
            const matchingObj = onedriveUsage.find(obj1 => obj1.ownerPrincipalName === obj2.mail);

            if (matchingObj) {
                let storageUsedGB = (matchingObj.storageUsed / (1024 ** 3)).toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })
                let storageAllocatedGB = (matchingObj.storageAllocated / (1024 ** 3)).toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })
                return {
                    ...obj2,
                    storageUsedOnedrive: `${storageUsedGB} / ${storageAllocatedGB}`,
                };
            } else {
                return {
                    ...obj2,
                    storageUsedOnedrive: `0.0 / 0.0 `,
                };
            }
        });
    }
    useEffect(() => {
        const fetchData = async (filter) => {
            const [userService, onedriveUsage] = await Promise.all([
                getUserService(filter),
                getOnedriveUsage(30)
            ]);
            let mergeStorage = mergeStorageInfo(userService, onedriveUsage)
            setRecords(prevRecords => [...prevRecords, ...mergeStorage]);

        }
        if (filter) {
            fetchData(filter).finally(() => setLoading(false))
        }
    }, [filter])

    const messageFlow = async (action: string) => {
        (loading) &&
            enqueueSnackbar(` ${action} foi iniciada, pode demorar alguns segundos.`, {
                variant: 'warning',
                preventDuplicate: true,
                persist: false,
            });
        setTimeout(() => (loading) && infoMessage(`${action} está em andamento, ainda pode demorar alguns segundos.`), 5000);
    }
    const infoMessage = async (action: string) => {

        enqueueSnackbar(`${action}`, {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        })
    }
    const successMessage = async (action: string) => {

        enqueueSnackbar(`${action}`, {
            variant: 'success',
            preventDuplicate: true,
            persist: false,
        })
    }
    const errorMessage = async (action: string) => {
        enqueueSnackbar(`${action}`, {
            variant: 'error',
            preventDuplicate: true,
            persist: false,
        })
    }

    function deleteUser(id: string) {
        let founds = records.filter(x => x.id == id);

        if (!window.confirm(`Tem certeza que deseja deletar a caixa de e-mail ${founds[0].login}?`))
            return;

        setLoading(true);
        messageFlow(`A operação de delete do usuário ${founds[0].login} `);
        SaaS_Service.DeleteUserById(id).then(result => {
            successMessage(`Usuário ${founds[0].login} deletado com sucesso`)
            setRefresh(!refresh);
            setLoading(false);
        }).catch(error => {
            console.error(error)
            setLoading(false);
            errorMessage("Erro para deletar usuário")
        })
    }

    function ConverterCaixa(id: string) {
        let founds = records.filter(x => x.id == id);

        setId(id)
        setCurrentUser(founds)
        setDisplayName(founds[0].login);
        setOpen(true);
    }

    function Grupos(email: string, nome: string, id: string) {
        setMembersOfUserLabel(nome);
        setMembersOfUserId(id);
        setShowMemberOfForm(true);
        setIsLoadingMemberOfForm(true);
        SaaS_Service.GetGroupsByUser(email)
            .then(response => {
                let _memberOfGroups = response.data;
                setMemberOfGroups(_memberOfGroups);
            })
            .catch(error => {
                console.error(error);
            });
    }

    useEffect(() => {
        if (memberOfGroups)
            SaaS_Service.GetGroupsByClientGroup()
                .then(response => {
                    let _memberOfGroups = memberOfGroups;
                    let _cloudGroups = response.data.filter(item => {
                        return item.onPremisesSyncEnabled == undefined || item.onPremisesSyncEnabled == null
                    });
                    let _allCloudGroups = _cloudGroups.map(item => {
                        return ({
                            value: item.id,
                            selected: _memberOfGroups.find(x => x.id == item.id) ? true : false,
                            text: item.displayName,
                        })
                    });
                    let notSelected = _allCloudGroups.filter(g => g.selected == false);
                    notSelected = notSelected.sort((a: any, b: any) => {
                        if (a.text < b.text) return -1;
                        if (a.text > b.text) return 1;
                        return 0;
                    });
                    let selected = _allCloudGroups.filter(g => g.selected == true);
                    selected = selected.sort((a: any, b: any) => {
                        if (a.text < b.text) return -1;
                        if (a.text > b.text) return 1;
                        return 0;
                    });
                    _allCloudGroups = selected.concat(notSelected);

                    setAllCloudGroups(_cloudGroups);
                    setListGroups(_allCloudGroups);
                    setIsLoadingMemberOfForm(false);
                })
                .catch(error => {
                    console.error(error);
                });
    }, [memberOfGroups]);

    const addMemberToGroups = async () => {
        let _selectedGroups = selectedGroups;
        let _allCloudGroups = allCloudGroups;
        let _memberOfGroups = memberOfGroups;
        let filteredGroups = _selectedGroups.filter(sg => {
            return !_memberOfGroups?.find(g => g.id == sg.value)
        });
        var groupsToAdd = filteredGroups.map(selectedItem => {
            let item = _allCloudGroups.find(group => group.id == selectedItem.value);
            return ({
                Type: item.type,
                GroupId: item.id,
                UserId: membersOfUserId
            })
        });
        setIsLoadingMemberOfForm(true);
        SaaS_Service.CreateUserGroupLinks(groupsToAdd)
            .then((egm: any) => {
                setIsLoadingMemberOfForm(true);
                setShowMemberOfForm(false);
                enqueueSnackbar(`Usuário vinculado com sucesso!`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false, anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
            })
            .catch((sad) => {
                setIsLoadingMemberOfForm(true);
                setShowMemberOfForm(false);
                enqueueSnackbar(`Erro ao vincular usuário.`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false, anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
                console.error(sad);
            });
    }

    function Editar(id: string) {
        setIdToEdit(id);
        setEditUserVisible(true);
    }

    function RedefinirSenha(id: string) {
        let founds = records.filter(x => x.id == id);

        setId(id);
        setCurrentUser(founds);

        if (!editModalVisible) {
            setTypeForm('password')
            setModalTitle(`Redefinição de senha ${founds[0].name}`)
            setEditModalVisible(true)
        }
        else {
            setModalTitle('')
            setTypeForm('')
            setEditModalVisible(false)
            setOffAll();
        };
    }
    function memberOf(id: string) {
        let founds = records.filter(x => x.id == id);
        setTypeForm('');
        setId(id)
        setCurrentUser(founds)
        setDisplayName(founds[0].login);
        if (!editModalVisible) {
            setTypeForm('memberOf')
            setModalTitle(`${founds[0].name}`)
            setEditModalVisible(true)
        }
        else {
            setModalTitle('')
            setTypeForm('')
            setEditModalVisible(false)
            setOffAll();
        };
    }
    function Informacoes(id: string) {
        let founds = records.filter(x => x.id == id);

        setId(id)
        setCurrentUser(founds)
        setDisplayName(founds[0].login);
        if (!editModalVisible) {
            setTypeForm('information')
            setModalTitle(`Detalhes de ${founds[0].name}`)
            setEditModalVisible(true)
        }
        else {
            setModalTitle('')
            setTypeForm('')
            setEditModalVisible(false)
            setOffAll();
        };
    }

    function Licencas(id: string) {
        let founds = records.filter(x => x.id == id);

        setId(id)
        setCurrentUser(founds)
        setDisplayName(founds[0].login);

        if (!editModalVisible) {
            setTypeForm('licenses')
            setModalTitle(`Subscrições de ${founds[0].name}`)
            setEditModalVisible(true)
        }
        else {
            setModalTitle('')
            setTypeForm('')
            setEditModalVisible(false)
            setOffAll();
        }
    }

    function DeletarUsuario(id: string) {
        var confirmation = window.confirm("Deseja realmente excluir esse usuário?");
        if (!confirmation) return
        deleteUser(id);
    }

    function setOffAll() {
        setEditModalVisible(false)
        setTypeForm('');
        setId('')
        setCurrentUser([])
        setDisplayName('');
    }

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            setOffAll()
        }, 500)
    };

    const convertMailBox = () => {
        setLoading(true);
        let mail = currentUser[0].mail as string;
        setOpen(false);
        enqueueSnackbar("A conversão da caixa de e-mail foi inciada, pode levar alguns segundos!", {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        });

        setTimeout(() => {
            infoMessage("Conversão da caixa esta em andamento, preparando dados para conversão !")
        }, 5000)

        setTimeout(() => {
            if (loading)
                infoMessage("Conversão da caixa esta em andamento, executando pedido de conversão!")
        }, 16000)

        setTimeout(() => {
            if (loading)
                infoMessage("Conversão da caixa esta em andamento, prestes a terminar a conversão!")
        }, 25000)

        currentUser && currentUser[0] && SaaS_Service.ConvertMailBox(false, mail)
            .then(response => {
                setOpen(false);
                successMessage(`Caixa ${mail} convertida com exito!`);
                setLoading(false);
                setRefresh(!refresh);
            })
            .catch(error => {

                var message = error;
                message = error && error.response && error.response;
                message = error && error.response.data && error.data;
                setLoading(false);
                errorMessage(`Caixa não pode ser convertida, erro: ${message ? message : 'Falta de cadastro de assinatura'}!`);
                setOpen(false);
                setOpenError(true);
            });
    }

    const StatusAD = [
        { value: 0, label: 'Todos' },
        { value: 1, label: 'Ativos' },
        { value: 2, label: 'Inativos' },
    ];
    const TemSubscricao = [
        { value: 0, label: 'Todos' },
        { value: 1, label: 'Sim' },
        { value: 2, label: 'Não' },
    ];
    const Origem = [
        { value: 2, label: 'Todos' },
        { value: 0, label: 'Cloud' },
        { value: 1, label: 'On premises' },
    ];

    const mailboxTypes = [
        { value: -1, label: 'Todas' },
        { value: 0, label: 'Desconhecida' },
        { value: 1, label: 'Pessoal' },
        { value: 2, label: 'Vinculada' },
        { value: 3, label: 'Compartilhada' },
        { value: 4, label: 'Sala' },
        { value: 5, label: 'Equipamento' },
        { value: 6, label: 'Outros' },
    ];

    // SUMÁRIO PARA MAILBOX SETTINGS USEPURPOSE - SDK BETA GRAPH
    // desconhecido  | Enum(0)  |	 Nenhuma informação encontrada sobre a caixa de correio.
    // user 	     | Enum(1)  |    Uma conta de usuário com uma caixa de correio na floresta local.
    // ligado 	     | Enum(2)  |    Uma caixa de correio vinculada a uma conta de usuário em outra floresta.
    // compartilhado | Enum(3)  | 	 Uma caixa de correio compartilhada por duas ou mais contas de usuário.
    // cômodo 	     | Enum(4)  |    Uma caixa de correio representando uma sala de conferência.
    // equipa 	     | Enum(5)  |    Uma caixa de correio representando uma peça de equipamento.
    // usuários 	 | Enum(6)  |    Caixa de correio encontrada, mas o objetivo do usuário é diferente dos especificados acima.
    const TipoDeCaixa = [
        { enum: 0, icon: <PiQuestionLight size={20} />, from: 'unknown', for: 'Desconhecida' },
        { enum: 1, icon: <PiUserLight size={20} />, from: 'user', for: 'Pessoal' },
        { enum: 2, icon: <PiLinkLight size={20} />, from: 'linked', for: 'Vinculada' },
        { enum: 3, icon: <PiArrowSquareOutLight size={20} />, from: 'shared', for: 'Compartilhada' },
        { enum: 4, icon: <PiUsersThreeLight size={20} />, from: 'room', for: 'Sala' },
        { enum: 5, icon: <PiDesktopLight size={20} />, from: 'equipment', for: 'Equipamento' },
        { enum: 6, icon: <PiQuestionLight size={20} />, from: 'others', for: 'Outros' },
    ];

    const columns = [
        {
            field: 'name', headerName: 'Nome', width: '18%', truncate: true,
        },
        {
            field: 'login', headerName: 'Login', width: '35%', truncate: true,
        },
        {
            field: 'mailBox', headerName: 'MailBox Usado/Disponível (GB)', width: '18%', align: 'left', truncate: true,
        },
        {
            field: 'storageUsedOnedrive', headerName: 'OneDrive Usado/Disponível(GB)', width: '18%', align: 'left', truncate: true,
        },
        {
            field: 'dirSyncEnabled',
            headerName: 'Origem',
            width: '75px',
            overflow: 'visible',
            align: 'center',
            renderCell: (row: any) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', textAlign: 'center' }}>
                        <LcTooltip content={row.dirSyncEnabled as boolean ? 'On premises' : 'Cloud'} trigger='hover' position='right'>
                            {
                                row.dirSyncEnabled ? (
                                    <PiHardDrivesLight size={20} />
                                ) : (
                                    <PiCloudLight size={20} />
                                )
                            }
                        </LcTooltip>
                    </div>
                )
            },
        },
        {
            field: 'mailboxSettings',
            headerName: 'Mailbox',
            width: '75px',
            overflow: 'visible',
            align: 'center',
            renderCell: (row: any) => {
                const mailboxSettings = row.mailboxSettings ? JSON.parse(row.mailboxSettings) : undefined
                if (mailboxSettings) {
                    let option = TipoDeCaixa.find(x => x.enum == mailboxSettings.UserPurpose)
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', textAlign: 'center' }}>
                            <LcTooltip content={option?.for} trigger='hover' position='right'>
                                {option?.icon}
                            </LcTooltip>
                        </div>
                    )
                } else {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', textAlign: 'center' }}>
                            <LcTooltip content={'Sem informações no provedor'} trigger='hover' position='right'>
                                <PiQuestionLight size={20} />
                            </LcTooltip>
                        </div>
                    )
                }
            },
        },
        {
            field: 'id',
            width: '130px',
            headerName: 'Ações',
            align: 'center',
            sort: false,
            overflow: 'visible',
            renderCell: (row: any) => {

                let editButton: { label: string, icon: React.ReactChild, func: Function, color?: string, disabled?: boolean } = {
                    label: 'Editar usuário',
                    icon: <PiPencilSimpleLight />,
                    func: (row: any) => {
                        Editar(row.id.toString())
                    },
                    disabled: false
                };

                let changePasswordButton: { label: string, icon: React.ReactChild, func: Function, color?: string, disabled?: boolean } = {
                    label: 'Redefinir senha',
                    icon: <PiKeyLight />,
                    func: (row: any) => {
                        RedefinirSenha(row.id.toString())
                    },
                    disabled: false
                };

                let actions: { label: string, icon: React.ReactChild, func: Function, color?: string, disabled?: boolean }[] = [
                    {
                        label: 'Subscrições',
                        icon: <PiClipboardTextLight />,
                        func: (row: any) => {
                            Licencas(row.id.toString())
                        },
                        disabled: false,
                    },

                ];

                if (!row.dirSyncEnabled) {
                    actions.push(editButton);
                    actions.push(changePasswordButton);
                } else {
                    changePasswordButton = {
                        ...changePasswordButton,
                        label: 'A redefinição de senha está habilitada somente para usuários de origem Cloud',
                        color: '#2E19563d',
                        disabled: true,
                    }

                    editButton = {
                        ...editButton,
                        label: 'A edição está habilitada somente para usuários de origem Cloud',
                        color: '#2E19563d',
                        disabled: true,
                    }
                    actions.push(editButton)
                    actions.push(changePasswordButton);
                }

                return (
                    <>
                        {
                            actions.map((r, k) => {
                                return (
                                    <LcIconLink
                                        tooltipPosition="right"
                                        key={k}
                                        icon={r.icon}
                                        tooltip={r.label}
                                        onClick={() => { r.func(row); }}
                                        color={(r.color) ? r.color : undefined}
                                        disabled={r.disabled}
                                        size="small"
                                    />
                                )
                            })
                        }
                    </>
                );
            }
        }
    ]

    const functionGeneric = [
        {
            title: 'Criar User',
            icon: <PiPlusLight />,
            tooltip: 'Criar usuário',
            onClick: () => {

                setAddUserVisible(true);

            }
        },
        {
            title: 'Exportar CSV',
            icon: <PiFileCsvLight />,
            tooltip: 'Exportar CSV',
            onClick: () => {
                infoMessage(`Gerando arquivo com todos os usuários`);
                SaaS_Service.UserServiceGetUsersExcel().then(result => {
                    const data = result && result.data;
                    const fileName = 'Usuarios'
                    const exportType = 'xls'

                    exportFromJSON({ data, fileName, exportType })
                    successMessage(`Completada geração de excel de usuários`);
                }).catch(error => {

                    console.error(error)
                    setLoading(false);

                });
            }
        },
    ]

    const functionsRow = [
        {
            label: 'Converter caixa',
            func: (row: any) => {
                ConverterCaixa(row.id.toString())
            }
        },
        {
            label: 'Informações',
            func: (row: any) => {

                Informacoes(row.id.toString())
            }
        },
        {
            label: 'Adicionar a Grupos',
            func: (row: any) => {
                Grupos(row.mail, row.name, row.id);
            }
        },
        {
            label: 'Grupos',
            func: (row: any) => {
                memberOf(row.id.toString())
            }
        },
        {
            label: 'Excluir usuário',
            disabled: (row: any) => { return row.dirSyncEnabled },
            func: (row: any) => {
                DeletarUsuario(row.id.toString());
            }
        }
    ]

    //#region Verifica se há GTM CSP
    useEffect(() => {
        if (menu && menu.selectedItem && menu.selectedItem.id == '67aa56e4-ad63-44b0-aa27-d2d2a8665a63') {
            saasService.GetbyClientGroupIdAndTileId('13')
                .then(response => {
                    let asGTMCSP = response.data;
                    setShowFluxoCSP(!asGTMCSP);
                })
                .catch(error => {
                    console.warn(error);
                });
        }
    }, [menu.selectedItem])
    //#endregion

    const handleCloseAlert = () => {
        setOpenError(false);
    }

    const loadMore = (size?: number) => {
        setLoadingTable(true)
        if (size) {
            setRecords([]);
            let f = SaaS_Service.getFilter();
            f = { ...f, start: 0, size: size };
            setFilter(f);
        } else {
            setFilter({ ...filter, start: records.length, size: 100 });
        }
        setLoadingTable(false)
    }

    // const onSortChange = (sortData: any) => {
    //     const { sort, size } = sortData;
    //     // setRecords([]);
    //     setFilter({ ...filter, start: 0, size: size, ordered: sort.column, direction: sort.direction });
    // }

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }
    const filterAdvanced = {
        fields: [{ label: 'Nome', name: 'name', type: 'text' },
        { label: 'Login', name: 'login', type: 'text' },
        { label: 'Origem', name: 'dirSyncEnabled', type: 'select', options: Origem },
        { label: 'Tipo de mailbox', name: 'mailboxType', type: 'select', options: mailboxTypes, topDropdown: true }],
        onChange: (f: any, size: number) => {
            setRecords([]);
            setLoading(true);
            setFilter({
                ...filter,
                start: 0,
                size: size,
                name: f[0].value?.toLowerCase(),
                filter: f[1].value,
                dirSyncEnabled: f[2].value !== '' ? f[2].value : 2,
                mailboxType: f[3].value !== '' ? f[3].value : -1,

            });

            //dispatch(setFilterActive(true));
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }



    const card: Card[] = [
        {
            id: 1,
            hideHeader: true,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <>
                        {
                            showFluxoCSP ?
                                <div className="lc-segment">
                                    <div className="body">
                                        Para utilizar as funcionalidades de Tech - EA/EAS, é necessário
                                        fornecer e/ou atualizar as credenciais de acesso conforme os passos abaixo. . Caso precise de ajuda abra uma requisição, clicando <span className="link text-link" onClick={() => { history.push('/Chamados'); dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }}>aqui</span>, e teremos o prazer em atender.
                                        <img src={ImgFluxoCSP} width="100%" alt="Procedimento" />
                                    </div>
                                </div>
                                :
                                <>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"

                                        aria-describedby="alert-dialog-description" >
                                        <DialogTitle style={{ fontSize: '18px' }} disableTypography id="alert-dialog-title">
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '16px', justifyContent: 'space-between' }}>
                                                <div className="truncate-container">
                                                    <div className="truncate-text" title={displayName || 'Conversão de caixa de correio'}>
                                                        {`Conversão de caixa de correio${displayName ? `: ${displayName}` : ''}`}
                                                    </div>
                                                </div>

                                                <LcIconLink
                                                    aria-label="close"
                                                    onClick={handleClose}
                                                    icon={<PiXLight />}
                                                >
                                                </LcIconLink>
                                            </div>
                                        </DialogTitle>

                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Deseja converter a caixa de correio selecionada?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions style={{ display: 'block' }}>
                                            <Button className='lc-button bg-info' onClick={() => { convertMailBox() }} color="primary" style={{ float: 'left', textTransform: 'none' }}>
                                                Sim, converter
                                            </Button>
                                            <Button className='lc-button' onClick={handleClose} color="default" style={{ float: 'right', textTransform: 'none' }}>
                                                Cancelar
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <LcLoading loading={loading} loadingType='Helix'>
                                        {
                                            records.length > 0 ?
                                                <LcInfiniteTable
                                                    loading={loadingTable}
                                                    columns={columns}
                                                    rows={records}
                                                    filter={filterAdvanced}
                                                    size={fullDataRowCount}
                                                    functionsRow={functionsRow}
                                                    loadMore={loadMore}
                                                    density={pref.data?.density || "high"}
                                                    disableFilterModal
                                                />
                                                :
                                                <LcNoData label='Usuários não encontrados' />
                                        }
                                    </LcLoading>
                                </>
                        }
                    </>
                )
            }
        }
    ]

    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={filterAdvanced.fields}
                onFilterChange={(updatedFilter, size) => {
                    filterAdvanced.onChange(updatedFilter, size || 10);
                }}
            />
        );
    };

    // UseEffect para limpar o filtro ativo quando o componente é desmontado
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    //#region Handlers para o novo componente FormModal
    const handleCreateOpening = async () => {
        await SaaS_Service.Initialize();

        setFormModalFieldsData(SaaS_Service.getUserFormFieldsData());
    }

    const handleEditOpening = async () => {
        let _response = await SaaS_Service.GetUserDetail(idToEdit);

        if (_response.status == 200) {
            let _userInfo: SaaSUser = _response.data;
            _userInfo.id = idToEdit;
            _userInfo.objectid = idToEdit;
            _userInfo.mailNickname = _userInfo.userPrincipalName?.split('@')[0];
            _userInfo.alias = _userInfo.userPrincipalName?.split('@')[0] as string;
            _userInfo.domain = _userInfo.userPrincipalName?.split('@')[1];
            SaaS_Service.SetUsageLocation(_userInfo.usageLocation!);
            setEditData(_userInfo);
        } else console.error(_response);

        await SaaS_Service.Initialize();
        setFormModalFieldsData(SaaS_Service.getUserFormFieldsData());
    }

    const handleCreateUser = (data) => {

        infoMessage('Criando usuário SaaS...');
        let user: SaaSUser = data;
        user.accountEnabled = true;
        SaaS_Service.CreateSaaSUser(user)
            .then(result => {
                if (result.status === 200)
                    successMessage("Usuário criado com sucesso!");
            })
            .catch(error => {
                console.error(error);
                let errorMsg = error.response?.data || 'Problemas ao criar usuário, entre em contato com o suporte!';
                errorMessage(translateErrorMsg(errorMsg));
            })
            .then(() => {
                setRefresh(!refresh);
                setAddUserVisible(false);
            });
    }

    const handleUpdateUser = (data) => {
        infoMessage('Atualizando usuário SaaS...');
        let user: SaaSUser = data;
        SaaS_Service.UpdateUser(user)
            .then(result => {
                if (result.status == 200)
                    successMessage("Usuário atualizado com sucesso!");
            })
            .catch(error => {
                console.error(error);
                let errorMsg = error.response?.data || 'Problemas ao atualizar usuário, entre em contato com o suporte!';
                errorMessage(translateErrorMsg(errorMsg));
            })
            .then(() => {
                setRefresh(!refresh);
                setAddUserVisible(false);
            });
    }

    const translateErrorMsg = (errorMsg) => {
        if (errorMsg.includes("Invalid value specified for property 'mailNickname'"))
            return 'Alias escolhido para o usuário é inválido (não deve conter @ nem pontos!)';

        if (errorMsg.includes("password") && errorMsg.includes("username"))
            return 'Senha não pode conter o próprio nome de usuário!'

        return errorMsg;
    }

    const handleExitCreate = () => setAddUserVisible(false);
    const handleExitEdit = () => setEditUserVisible(false);
    //#endregion

    return (
        <Layout
            pageTitle="Gestão de usuários"
            functionsGeneric={functionGeneric}
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }

            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: fullDataRowCount
            }}
            lastUpdate={lastUpdate}
        >
            <Snackbar
                key={'error'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openError}
                autoHideDuration={4000}
                onExited={handleCloseAlert}
            >
                <Alert
                    onClose={handleCloseAlert}
                    severity="error"
                    action={
                        <div>
                            <Button color="secondary" size="large" onClick={() => { history.push('/Chamados'); dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }}>
                                <i className="lci lci-hand" style={{ color: '#611A15' }} />
                            </Button>
                            <IconButton aria-label="close"
                                color="secondary"
                                onClick={handleCloseAlert}
                            >
                                x
                            </IconButton>
                        </div>
                    }
                >
                    Houve um problema na comunicação com o fabricante. Por favor tente mais tarde. Caso precise podemos fazer isto por você através de uma requisição.
                </Alert>
            </Snackbar>

            {<PPFormWithWizard
                mode="create"
                type="wizard"
                title="Criação de usuário"
                // btnSaveTextLabel='Criar usuário'
                size="large"
                show={addUserVisible}
                data={{}}

                formFields={formModalFieldsData}
                onOpen={handleCreateOpening}
                onSave={handleCreateUser}
                onCancel={handleExitCreate}
                onClose={handleExitCreate}
            />}

            {<PPFormWithWizard
                mode="edit"
                type="wizard"
                title="Edição de usuário"
                // btnSaveTextLabel='Editar usuário'
                size="large"
                show={editUserVisible}
                data={editData}
                formFields={SaaS_Service.getUserFormFieldsData(true)}
                onOpen={handleEditOpening}
                onSave={handleUpdateUser}
                onCancel={handleExitEdit}
                onClose={handleExitEdit}
            />}

            <PPModal
                loading={isLoadingMemberOfForm}
                title={'Grupos ' + membersOfUserLabel} visible={showMemberOfForm}
                onClose={() => { setShowMemberOfForm(false); setIsLoadingMemberOfForm(true); }}
                functions={[{ label: "Adicionar a selecionados", onClick: () => addMemberToGroups(), disabled: selectedGroups.length == 0 }]}
            >
                <LcCheckList
                    onChange={(eventList: any[]) => { return setSelectedGroups(eventList.filter(item => { return item.selected == true })); }}
                    title='Grupos'
                    list={listGroups}
                    search={true}
                    height="18"
                    toggleAll={false}
                />
            </PPModal>
            <PPModal loading={loading} size={typeForm == 'licenses' ? 'large' : "medium"} title={modalTitle} visible={editModalVisible} onClose={() => { setOffAll() }}>
                {id &&
                    <UserDetail
                        guid={id}
                        typeForm={typeForm}
                        user={currentUser[0]}
                        isLoading={loading}
                        cancelFunction={() => { setOffAll() }}
                    />
                }
            </PPModal>
            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                }}
            />
        </Layout>
    )
}

export default UsersSaaS;