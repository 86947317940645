import React, { useEffect, useState } from 'react';

import LcInfiniteTable from '../LcInfiniteTable';

interface Props {
    data: any[],
    columns: any[],
    filterFields?: any[],
    fontSize?: 'tiny' | 'small' | 'medium' | 'large' | 'big',
    functions?: any[],
    functionGeneric?: any[],
    functionsRow?: any[],
    onSelectChange?: Function,
    status?: Function,
    height?: string,
    density?: "high" | "medium" | "low",
    filterExternal?: {
        visible: boolean,
        onChange: Function
    },
    hidetopBar?: boolean,
    className?: string,
}

const LcTable: React.FC<Props> = (props) => {

    const [dataFiltered, setDataFiltered] = useState<any[]>(props.data);
    const [dataVisible, setDataVisible] = useState<any[]>([]);

    const loadMore = (size?: number) => {
        if (size)
            setDataVisible(dataFiltered.slice(0, size));
        else
            setDataVisible([...dataVisible, ...dataFiltered.slice(dataVisible.length, dataVisible.length + 10)])
    }
    useEffect(() => { setDataVisible(props.data) }, [props.data])
    const filter = {
        fields: props.filterFields || [],
        onChange: (fields, size) => {
            const _filtered = props.data.filter(item => {
                if (fields.length > 1) {
                    return fields.every(field => {

                        if (!item.hasOwnProperty(field.name)) return false;

                        if (!field.value) return true;

                        const fieldValue = item[field.name];
                        const filterValue = field.value;

                        switch (field.type) {
                            case "spanDateTime":
                                if (filterValue.startDate && filterValue.endDate) {
                                    const startDate = new Date(filterValue.startDate);
                                    const endDate = new Date(filterValue.endDate);
                                    const itemDate = new Date(fieldValue);
                                    return itemDate >= startDate && itemDate <= endDate;
                                }
                                return true;
                            case "interval":
                                const [start, end] = filterValue.split(',').map(Number);
                                return fieldValue >= start && fieldValue <= end;
                            case "select":
                                return field.multiselect ?
                                    filterValue.some(value => new RegExp(fieldValue?.toString(), "iu").test(value.toString())) :
                                    new RegExp(filterValue, "iu").test(fieldValue?.toString());
                            default:
                                return new RegExp(filterValue, "iu").test(fieldValue?.toString());
                        }
                    });
                } else if (fields.length === 1 && fields[0].value) {
                    const term = fields[0].value.toLowerCase().replace('$', '');
                    return props.columns.some(column => {
                        return item.hasOwnProperty(column.field) &&
                            new RegExp(term, "iu").test(item[column.field]?.toString());
                    });
                }
            });

            setDataFiltered(_filtered);
            setDataVisible(size > 0 ? _filtered.slice(0, size) : _filtered);
        },
        visible: props.filterExternal?.visible,
        onChangeVisibility: props.filterExternal?.onChange
    };

    const onSortChange = (data: any) => {
        const { size, sort } = data;
        const dataSize = size > 0 ? size : dataFiltered.length;
        var _ordered = dataFiltered.sort((a: any, b: any) => {
            try {
                return (a[sort.column] ? a[sort.column] : '') > (b[sort.column] ? b[sort.column] : '') ? sort.direction == 'asc' ? 1 : -1 : sort.direction == 'asc' ? -1 : 1

            } catch (e) {
                return 1;
            }
        });
        setDataFiltered(_ordered);
        setDataVisible(_ordered.slice(0, dataSize));

    }

    return (
        <LcInfiniteTable
            rows={dataVisible}
            columns={props.columns}
            loadMore={loadMore}
            size={dataFiltered.length}
            filter={props.filterFields ? filter : undefined}
            fontSize={props.fontSize}
            functions={props.functions}
            functionGeneric={props.functionGeneric}
            functionsRow={props.functionsRow}
            onSelectChange={props.onSelectChange}
            onSortChange={onSortChange}
            status={props.status}
            height={props.height}
            density={props.density}
            hidetopBar={props.hidetopBar}
            className={props.className}
        />
    );
}

export default LcTable;