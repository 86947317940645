import React, { useEffect, useState } from 'react';
import { Account } from '../main';
import LcLoading from '../../../components/Generic/LcLoading';
import LcCheckBox from '../../../components/Form/LcCheckBox';
import LcAccordion from '../../../components/Generic/LcAccordion';
import accessProfileService from '../../../services/accessProfile/accessProfile';
import { useSnackbar } from 'notistack';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { useSelector } from 'react-redux';

interface Props {
    type: string;
    account: Account;
    close: Function;
    activeOnly?: boolean
}

const MenuItems: React.FC<Props> = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const accountService = new accessProfileService(props);
    const { enqueueSnackbar } = useSnackbar();
    const [reload, setReload] = useState<boolean>(false)
    const [loadingMenuItens, setLoadingMenuItens] = useState<boolean>(false);
    const [menuItens, setMenuItens] = useState<any[]>([]);
    const [newMenuItem, setNewMenuItem] = useState<any[]>([])
    const [newMenuItemAux, setNewMenuItemAux] = useState<any[]>([])

    useEffect(() => {
        setLoadingMenuItens(true);

        accountService.GetClientGroupPerfilMenu(props.account.id)
            .then(result => {
                //if (Number(user.ClientGroupSelected) != 492) {
                //    setMenuItens(result.data.filter(v => v.permitido == true))
                //}
                //else {
                setMenuItens(result.data)
                //}

            })
            .catch(console.warn)
            .finally(() => {
                setLoadingMenuItens(false);
                setReload(false)
            })

    }, [user.ClientGroupSelected, user.refreshFlag, reload])

    const changePermission = (menu: any) => {

        const { name, checked } = menu;
        //no name vem o Id do item de menu

        var _menuItem = [
            {
                clientGroupPerfilId: props.account.id,
                menuId: name,
                permitido: checked
            }
        ]

        setNewMenuItem(_menuItem)
        setNewMenuItemAux(prev => [...prev, ..._menuItem])

        let newmenu = menuItens.map(menu => {
            if (menu.role.id === name) {
                return {
                    ...menu,
                    permited: checked
                }
            } else {
                return {
                    ...menu
                }
            }
        })
        setMenuItens(newmenu)

        let getParants = menuItens.filter(item => item.role.idParent === name)
        getParants.length > 0 && parentPermission(getParants, checked);
    }

    const parentPermission = (parents: any[], checked: boolean) => {
        parents.forEach(parent => {
            let _menuItem = [
                {
                    clientGroupPerfilId: props.account.id,
                    menuId: parent.role.id,
                    permitido: checked
                }
            ]
            setNewMenuItem(prev => [...prev, ..._menuItem])
            setNewMenuItemAux(prev => [...prev, ..._menuItem])
        })

        parents.forEach(parent => {
            let getParants = menuItens.filter(item => item.role.idParent === parent.role.id)
            getParants.length > 0 && parentPermission(getParants, checked);
        })
    }



    useEffect(() => {
        setMenuItens(menuItens.map(menu => {
            const item = newMenuItem.find(item => item.menuId.toLowerCase() === menu.role.id.toLowerCase());
            if (item) {
                return {
                    ...menu,
                    permited: item.permitido
                };
            } else {
                return { ...menu };
            }
        }))
    }, [newMenuItem])

    const handleSaveMenu = () => {
        setLoadingMenuItens(true);

        accountService.SendMenuOpt(newMenuItemAux, props.account.id)
            .then(response => {
                enqueueSnackbar(`Item(s) ${newMenuItem.find(i => i.permitido) ? 'incluído(s) no' : 'removido(s) do'} perfil.`, {
                    variant: newMenuItem.find(i => i.permitido) ? 'success' : 'warning',
                    preventDuplicate: true,
                    persist: false,
                });
            })
            .catch(console.warn)
            .finally(() => {
                setReload(!reload)
                setNewMenuItem([]);
                setNewMenuItemAux([]);
                setLoadingMenuItens(false);
                props.close()
            })
    }

    return (
        <LcLoading loading={loadingMenuItens && !reload} label="Carregando menu...">
            <div style={{ height: '60vh' }} className="scrollable-v">
                {
                    menuItens.map((mA, iA) => {
                        if (mA.role.idParent === null || mA.role.idParent === 'NULL') {

                            return (
                                <div key={iA}>
                                    {
                                        (() => {
                                            var itensB = menuItens.filter(m => m.role.idParent === mA.role.id);
                                            if (itensB.length > 0) {
                                                let itemC: any = itensB.map(mb => menuItens.filter(m => m.role.idParent === mb.role.id))
                                                return (
                                                    <LcAccordion
                                                        visible={itemC.filter(i => i.find(j => j.permited)).length > 0
                                                            || mA.permited
                                                            || itensB.find(i => i.permited)}
                                                        title={
                                                            <div style={{ color: itensB.filter(x => menuItens.filter(m => m.role.idParent == x.role.id && m.permited == true).length > 0).length > 0 ? '#ffb800' : '' }}>
                                                                <LcCheckBox
                                                                    checked={
                                                                        itensB.filter(x => menuItens.filter(m => (m.role.id === x.role.id) && m.permited === true).length > 0).length > 0
                                                                        || (itemC[0].filter(x => menuItens.filter(m => (m.role.id === x.role.id) && m.permited === true).length > 0).length === itemC[0].length
                                                                            && itemC[0].length > 0)
                                                                    }
                                                                    name={mA.role.id}
                                                                    onChange={changePermission}
                                                                    disabled={props.account.originTemplateId !== 0}
                                                                />
                                                                <span >{mA.role.name}</span> {itensB.filter(x => menuItens.filter(m => (m.role.id === x.role.id) && m.permited === true).length > 0).length}/{itensB.length}
                                                            </div>
                                                        }>
                                                        {
                                                            itensB.map((mB, iB) => {
                                                                return (
                                                                    <div key={iB} style={{ marginLeft: '30px' }}>
                                                                        {
                                                                            (() => {
                                                                                var itensC = menuItens.filter(m => m.role.idParent === mB.role.id);
                                                                                if (itensC.length > 0) {
                                                                                    return (
                                                                                        <LcAccordion visible={mB.permited || itensC.find(i => i.permited)}
                                                                                            title={
                                                                                                <div style={{ color: itensC.filter(x => x.permited == true).length > 0 ? '#ffb800' : '' }}>
                                                                                                    <LcCheckBox
                                                                                                        checked={
                                                                                                            itensC.filter(x => x.permited == true).length > 0
                                                                                                        }
                                                                                                        name={mB.role.id}
                                                                                                        onChange={changePermission}
                                                                                                        disabled={!(user.email?.includes("@processor.com.br") || user.email?.includes("@gotobiz.com.br"))}
                                                                                                    />
                                                                                                    <span>{mB.role.name}</span> {' ' + itensC.filter(x => x.permited == true).length}/{itensC.length}
                                                                                                </div>
                                                                                            }>
                                                                                            {
                                                                                                itensC.map((mC, iC) => {
                                                                                                    return (
                                                                                                        <div key={iC} style={{ marginLeft: '25px' }}>
                                                                                                            <LcCheckBox
                                                                                                                checked={mC.permited}
                                                                                                                title={mC.role.name}
                                                                                                                name={mC.role.id}
                                                                                                                onChange={changePermission}
                                                                                                                disabled={!(user.email?.includes("@processor.com.br") || user.email?.includes("@gotobiz.com.br"))}
                                                                                                            />
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </LcAccordion>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <div style={{ margin: '10px 0 20px 39px ' }}>
                                                                                            <LcCheckBox
                                                                                                checked={mB.permited}
                                                                                                name={mB.role.id}
                                                                                                onChange={changePermission}
                                                                                                disabled={!(user.email?.includes("@processor.com.br") || user.email?.includes("@gotobiz.com.br"))}
                                                                                            />
                                                                                            <span>{mB.role.name}</span>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            })()
                                                                        }

                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </LcAccordion>

                                                )

                                            } else {
                                                return (
                                                    <div style={{ marginLeft: '29px' }}>
                                                        <LcCheckBox
                                                            checked={mA.permited}
                                                            name={mA.role.id}
                                                            onChange={changePermission}
                                                            disabled={!(user.email?.includes("@processor.com.br"))}
                                                        />
                                                        <span>{mA.role.name}</span>
                                                    </div>
                                                )
                                            }
                                        })()
                                    }

                                </div>
                            )
                        }
                    })
                }
            </div>
            {(user.email?.includes("@processor.com.br") || user.email?.includes("@gotobiz.com.br")) &&
                <div className="centralized-row">
                    <button className='lc-button bg-primary mr-6' onClick={() => handleSaveMenu()}>Salvar</button>
                    <button className='lc-button bg-gray ml-6' onClick={() => props.close()}>Cancelar</button>
                </div>}
        </LcLoading>
    )
}

export default MenuItems;