import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Typography, List, ListItem, Paper, Card, CardContent, Popover } from '@mui/material';
import { FaCommentDots, FaRegCopy } from 'react-icons/fa';
import { Markdown } from 'react-showdown';
import 'eventsource-polyfill';
//import IcebreakerQuestions from '../IcebreakerQuestion/IcebreakerQuestions';
//import Base64ImageGallery from '../../../LLMAssistant/ThreadDialog/Base64ImageGallery';
//import MessageService from '../../../../services/AIx/messages/MessageService';
//import ThreadService from '../../../../services/AIx/threads/ThreadService';
//import ExpandingTextarea from '../ExpandingTextArea'; 
import { IconButton } from '@material-ui/core';
import moment from 'moment';
//import LcLoading from '../../../../components/Generic/LcLoading';
//import { MessageResponse, Thread } from '../../../../services/AIx/assistantsTypes';
import { PiChatDotsLight, PiChatTeardropDots, PiChatTeardropDotsLight, PiCopyLight } from 'react-icons/pi';
import { BsCopy } from 'react-icons/bs';
import { useSnackbar } from 'notistack';
//import LcIconLink from '../../../../components/Generic/LcIconLink'; 
import ThreadService from '../../../services/AIx/threads/ThreadService';
import MessageService from '../../../services/AIx/messages/MessageService';
import { Thread, MessageResponse } from '../../../services/AIx/assistantsTypes';
import './index.css';
import LcLoading from '../../../components/Generic/LcLoading';
import Base64ImageGallery from '../../../components/aix/ChatClient/Base64ImageGallery';
import { TbArrowBigUpLines } from 'react-icons/tb';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LLMExpandingTextarea from './ExpandingTextArea';
import LLMIcebreakerQuestions from './IcebreakerQuestion/IcebreakerQuestions';

interface ThreadDialogProps {
    assistantId: string;
    assistantName: string;
    eventChat?: (event: any) => void;
}

export const ThreadDialog: React.FC<ThreadDialogProps> = ({
    assistantId,
    assistantName,
    eventChat,
}) => {
    const [message, setMessage] = useState<string>(''); 
    const [runRequest, setRunRequest] = useState<number>(0);
    const [messages, setMessages] = useState<MessageResponse[]>([]);
    const [messagesMap, setMessagesMap] = useState<{ [id: string]: MessageResponse }>({}); 
    const [threadId, setThreadId] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingStream, setLoadingStream] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { enqueueSnackbar } = useSnackbar();
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const endOfMessagesRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const createOrFetchThread = async () => {
            setLoading(true);
            setMessagesMap({});
            if (!assistantId) return;
            const threadService = new ThreadService({});

            try {
                const storedThreadId = localStorage.getItem(`threadId_${assistantId}`);
                let threadIdToUse = storedThreadId;
                if (threadIdToUse && threadIdToUse.length > 0) {
                    setRunRequest(0);
                    const messageService = new MessageService({});
                    const allMessages = await messageService.listMessagesAIX(threadIdToUse);
                    if (allMessages) {
                        setMessagesMap(allMessages.reduce(
                            (map, message) => {
                                map[message.id] = message;
                                return map;
                            },
                            {} as { [id: string]: MessageResponse }
                        ))
                        setMessages(allMessages);
                    }
                    setThreadId(threadIdToUse);
                }
                else {
                    const newThread = {} as Thread;
                    const createdThread = await threadService.createThread(newThread);
                    if (createdThread && createdThread.id) {
                        setThreadId(createdThread.id);
                        threadIdToUse = createdThread.id;
                        localStorage.setItem(`threadId_${assistantId}`, createdThread.id);
                    }
                }
            } catch (error) {
                console.error('Error creating or fetching thread:', error);
            }
            setLoading(false);
        };

        if (assistantId) {
            createOrFetchThread();
        }
    }, [assistantId,]);

    const [retryCount, setRetryCount] = useState(0);
    const maxRetries = 0; // Maximum number of retry attempts
    const retryDelay = 900; // Delay between retries in milliseconds
    console.log()
    useEffect(() => {
        if (runRequest === 0) return;
        const baseUrl = process.env.REACT_APP_AIX_LLM_API
        const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
        const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
        setRetryCount(0); // Reset retry count on successful connection
        const headerData = {
            headers: {
                Authorization: `Bearer ${LiveCloud_AuthMS}`,
                AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                Platform: `1`,
                PathRoute: window.location.pathname,
                'Access-Control-Allow-Origin': '*',
            },
        };

        const eventSourceInit = {
            headers: {
                Authorization: `Bearer ${LiveCloud_AuthMS}`,
                AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                Platform: `1`,
                PathRoute: window.location.pathname,
                'Access-Control-Allow-Origin': '*',
            },
            withCredentials: true,
        };
        const url = `${baseUrl}/api/threads/stream/${assistantId}/thread/${threadId}?Authorization=${`${LiveCloud_AuthMS}`}&AuthorizationPlatform=${`${LiveCloud_AuthLC}`}&Platform=1`;
        console.log("EventSource");
        console.log(url);

        let eventSource: EventSource | null = null;

        const connectToEventSource = () => {
            eventSource = new EventSource(url);
            eventSource.onopen = () => {
                setLoadingStream(true);
                console.log('Connected to EventSource');
            };

            eventSource.onmessage = (event) => {
                try {
                    setLoadingStream(true);
                    if (event.data.includes("[DONE]")) {
                        return;
                    }
                    console.log('event.data')
                    console.log(event.data)
                    const data = JSON.parse(event.data.replace('data: ', ''));
                    console.log(data)
                    if (data.object === 'thread.message') {
                        console.log("  if (data.object === 'thread.message') {")
                        const completeMessage: MessageResponse = data;
                        console.log(completeMessage)
                        console.log(messagesMap)

                        messagesMap[completeMessage.id] = completeMessage;
                        setMessages(Object.values(messagesMap));

                    } else if (data.object === 'thread.message.delta') {
                        const delta = data.delta;
                        const messageId = data.id;

                        if (!messagesMap[messageId]) {
                            messagesMap[messageId] = {
                                id: messageId,
                                object: 'message',
                                created_at: data.created_at,
                                assistant_id: data.assistant_id,
                                thread_id: data.thread_id,
                                run_id: data.run_id,
                                role: data.role,
                                content: [],
                                attachments: [],
                                metadata: {}
                            };
                        }

                        const contentPart = delta.content[0]?.text?.value || '-';

                        if (contentPart.includes('[DONE]'))
                            setLoadingStream(false);

                        messagesMap[messageId].content.push({
                            type: 'text',
                            text: {
                                value: contentPart,
                                annotations: delta.content[0]?.text?.annotations || []
                            }
                        });

                        setMessages((prevMessages) => {
                            const existingMessageIndex = prevMessages.findIndex((msg) => msg.id === messageId);
                            if (existingMessageIndex !== -1) {
                                const updatedMessages = [...prevMessages];
                                updatedMessages[existingMessageIndex] = messagesMap[messageId];
                                return updatedMessages;
                            } else {
                                return [...prevMessages, messagesMap[messageId]];
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error parsing message data:', error);
                }
            };

            eventSource.onerror = (error) => {

                console.error('EventSource failed. Attempting to reconnect...');
                if (retryCount < maxRetries) {
                    setTimeout(() => {
                        setRetryCount((prev) => prev + 1);
                        // connectToEventSource(); // Retry the connection
                    }, retryDelay);
                    setTimeout(() => {
                        setLoading(false);
                        setLoadingStream(false);
                        setMessage('');
                        eventSource && eventSource.close();
                    }, 60000);
                } else {
                    console.error('Max retries reached. Stopping connection attempts.');
                    setLoadingStream(false);
                    console.log(error);
                    console.error('EventSource failed:', error);
                    eventSource && eventSource.close();
                }
            };
        }
        connectToEventSource();
        return () => {
            setLoadingStream(false);
            setLoading(false);
            setMessage('');
            eventSource && eventSource.close();
        };
    }, [runRequest]);

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleSendMessage = async () => {
        if (!assistantId || !message.trim() || !threadId) { return };
        setLoadingStream(true);
        try {
            const newMessage: MessageResponse = {
                id: `msg_${Date.now()}`,
                object: 'message',
                created_at: Math.floor(Date.now() / 1000),
                assistant_id: assistantId,
                thread_id: threadId,
                run_id: `run_${Date.now()}`,
                role: 'user',
                content: [{ type: 'text', text: { value: message, annotations: [] } }],
                attachments: [],
                metadata: {},
            };
            messagesMap[newMessage.id] = newMessage;
            setMessages((prevMessages) => [...prevMessages, newMessage]);

            const messageService = new MessageService({});
            await messageService.createChat(threadId, assistantId, {
                role: 'user',
                content: message,
            });

            // Limpa o campo de texto após o envio
            setMessage(''); // Isso deve limpar o textarea
            console.log(message);
        } catch (error) {
            console.error('Failed to send message:', error);
        } finally {
            setRunRequest(Math.random());
            setLoading(false);
        }
    };

    const handleSuggestionClick = (suggestedMessage: string) => {
        setMessage(suggestedMessage);
        setAnchorEl(null);
        if (textareaRef.current) {
            textareaRef.current.focus(); // Foca no textarea após selecionar uma pergunta
        }
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const successMessage = async (action: string, time: number = 0) => {
        setTimeout(() => {
            enqueueSnackbar(`${action}`, {
                variant: 'success',
                preventDuplicate: true,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            })
        }, time);
    }

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(
            () => successMessage("Texto copiado com sucesso!", 400),
            (err) => console.error('Falha ao copiar o texto: ', err)
        );
    };

    const questions = [];

    const handleQuestionClick = (selectedQuestion: string) => {
        handleSuggestionClick(selectedQuestion);
    };

    const removeFileCitations = (text: string, annotations: any[]) => {
        let cleanedText = text;
        annotations.forEach((annotation) => {
            if (annotation.type === 'file_citation') {
                cleanedText = cleanedText.replace(annotation.text, '');
            }
        });
        return cleanedText;
    };

    return (
        <Box className="chat-container">
            <div className='list-container'>
                <LcLoading loading={loading}>
                    <List className="message-list scrollable-v">
                        {messages && messages.length > 0 ? (
                            messages.map((msg, index) => (
                                <ListItem key={index} className={`message-item ${msg.role}`}>
                                    <Paper elevation={msg.role === 'user' ? 2 : 1}
                                        className={`message-paper ${msg.role}`} >
                                        {msg.role !== 'user' && (
                                            <Box className="message-header">
                                                <Typography variant="subtitle2" className="assistant-name">
                                                    {assistantName || 'Assistant'}
                                                </Typography>
                                                <div className="message-header-right">
                                                    <Typography variant="caption" className="message-timestamp">
                                                        {msg.created_at && moment(new Date(msg.created_at * 1000)).format("DD/MM/yy HH:mm")}
                                                    </Typography>
                                                    <LcIconLink
                                                        onClick={() =>
                                                            copyToClipboard(
                                                                msg.content
                                                                    .map((c) => c.text && c.text.value)
                                                                    .join('')
                                                            )
                                                        }
                                                        size="small"
                                                        className="copy-icon"
                                                        tooltip='Copiar texto'
                                                        tooltipPosition='inline-left'
                                                        icon={<BsCopy fontSize="small" />}
                                                    >

                                                    </LcIconLink>
                                                </div>
                                            </Box>
                                        )}
                                        <Typography component="div" variant="body1" className="message-content">
                                            <Markdown
                                                markdown={
                                                    msg.content &&
                                                    msg.content
                                                        .map((c) => {
                                                            const textValue = c.text?.value || '';
                                                            return c.text?.annotations
                                                                ? removeFileCitations(textValue, c.text.annotations)
                                                                : textValue;
                                                        })
                                                        .join('')
                                                }
                                            />
                                        </Typography>
                                        {msg.content && (
                                            <Base64ImageGallery
                                                key={index}
                                                message={msg}
                                                imageEvent={(eventDATA) => {
                                                    eventChat && eventChat(eventDATA);
                                                }}
                                            />
                                        )}
                                    </Paper>
                                </ListItem>
                            ))
                        ) : (
                            <Box className="suggestions-container">
                                <Box className="suggestions-grid">
                                    {questions.map((suggestion, index) => (
                                        <Card
                                            key={index}
                                            onClick={() => handleSuggestionClick(suggestion)}
                                            className="suggestion-card scrollable-v"
                                        >
                                            <CardContent>
                                                {suggestion}
                                            </CardContent>
                                        </Card>
                                    ))}
                                </Box>
                            </Box>
                        )}
                        <div ref={endOfMessagesRef} />
                    </List>
                </LcLoading>
            </div>
            {!loading && <LcLoading loading={loadingStream}>
                <Box className="input-container">
                    <Button
                        style={{ textTransform: 'none', marginLeft: '10px' }}
                        onClick={handlePopoverOpen}
                        startIcon={<PiChatTeardropDotsLight />}
                        className={anchorEl ? 'darkgrafittibutton' : 'greybutton'}
                    > Mais prompts...
                    </Button>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <LLMIcebreakerQuestions questions={questions} onQuestionClick={handleQuestionClick} />
                    </Popover>
                </Box>
                <Box className="textarea-container-parent">
                    <LLMExpandingTextarea
                        value={message}
                        setValue={setMessage}
                        onSend={handleSendMessage}
                        ref={textareaRef}
                    />
                </Box>
            </LcLoading>}
        </Box>
    );
};
