import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import EventsGraph from '../resumeEvent/EventsGraph';
import { groupBy } from '../resume';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';

interface TotalEventsChartProps {
    period: string

}

const TotalEventsChart: React.FC<TotalEventsChartProps> = (
    {
        period,
    }) => {
    
    const checkpointService = new EndpointDetectionResponseService({});
    const [eventsChart, setEventsChart] = useState<any>();
    const [loadingData, setLoadingData] = useState<boolean>(true);

    const { isLoading } = useQuery({
        queryKey: ["TotalEvents",period],
        queryFn: () => checkpointService.GetAllLogs(period),
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
            setEventsChart(getClassificationChart(response.record))
            setLoadingData(false)

        },

        onError: (error) => {
            console.error('Error fetching Total Events data:', error);
            eventsChart(null)
        }
    });

    const getClassificationChart = useMemo(() => (attacksReport: any[]) => {
        setLoadingData(true)
        const ress = groupBy(attacksReport, "product");
        return Object.keys(ress).map((t, index) => {
            const filteredEvents = attacksReport.filter(b => b.product === t);
            const value = filteredEvents.length > 0
                ? filteredEvents.reduce((sum, event) => sum + 1, 0)
                : 0;
            return {
                name: t,
                value,
                itemStyle: {
                    // color: StatusColor(t)
                }
            };
        });
    },[])
    return (
        <LcLoading loading={isLoading || loadingData}>
        {
            eventsChart ?
            <EventsGraph
                data={eventsChart}
                legendOrientation={"vertical"}

            />
            :
            <LcNoData label='Sem informações no período.'/>
        }
    </LcLoading>
    );
}

export default TotalEventsChart;