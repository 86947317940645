import React from 'react';
import LcTooltip from '../../../Generic/LcTooltip';
import './index.css'
interface ProgressBarProps {
    atualPercentScore: number,
    maxPercentScore: number,
    label?: string
    textTooltip?: string
    size?: 'small' | 'normal'
    barColor?: string
    decimals?: number
}

export const ProgressBarWithLabel: React.FC<ProgressBarProps> = ({
    atualPercentScore,
    maxPercentScore,
    label,
    textTooltip,
    size,
    barColor,
    decimals
}) => {

    let leftDeslocament = (atualPercentScore && maxPercentScore) ? `${((atualPercentScore * 100) / maxPercentScore).toFixed(0)}%` : '0%'
    return (
        <div className="progress-bar-container">
            <LcTooltip position="center" top trigger="hover" content={textTooltip}>
                <div className="progress-bar" style={{ background: barColor ? barColor : 'linear-gradient(90deg, #E64646 0%, #F3C944 50%, #75f945 100%)' }}></div>
                <div className="slider-thumb" style={{ left: `calc(${leftDeslocament} - 10px)` }}></div>
                <div className="progress" style={{ width: "100%" }}></div>
            </LcTooltip>
            <div
                className={`percentage ${label ? '' : 'noLabel'} ${size ? size : 'normal'}`}
                style={{
                    left: `calc(${leftDeslocament} - ${leftDeslocament === "100%" ? "5px" : "0%"})`, // Centraliza o texto
                    transform: 'translateX(-50%)'
                }}
            >
                {
                    label &&
                    <p className='percentLabel'>{label}</p>
                }
                {!Number.isNaN((atualPercentScore * 100) / maxPercentScore)
                    ? ((atualPercentScore * 100) / maxPercentScore).toFixed(decimals ? decimals : 0) : 0}
                %
            </div>
        </div>
    );
};
