import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import EditMember from '../editMember';
import EditProfile from '../editProfile';
import Permission from '../permission';
import CreateProfile from '../createProfile';
import AccountService from '../../../services/accessProfile/accessProfile';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';
import { useDebounce } from 'use-debounce/lib';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import { useSnackbar } from 'notistack';
import { PPModal } from 'processor-plataform-ui'
import MenuItems from '../menuItems';

export interface Account {
    id: number,
    clientGroupId: number,
    nome: string,
    ativo: boolean,
    deletado: boolean,
    isTemplate: boolean,
    originTemplateId: number,
    managedBy: number,
    isNF: number,
    gtmItemId: number,
    gtmId: number,
    gtmMetric?: number
    nomeGrupoAd?: string,
    description?: string
}
// id: any; clientGroupId: any; nome: any; ativo: any; deletado: any; isTemplate: any; originTemplateId: any;


export interface Columns {
    field: string,
    headerName: string,
    width: string
    renderCell?: Function
}

interface TableColumn {
    id?: string;
    field: string;
    headerName?: string;
    width?: string | number;
    align?: string;
    hide?: boolean;
    truncate?: boolean;
    expandable?: boolean;
    renderCell?: (row: any, density?: string) => JSX.Element | string | undefined;
    showOnHover?: boolean;
    fontSize?: string;
    overflow?: string;
}
export interface Filter {
    size: number,
    term: string,
    column: string,
    direction: string;
    isTemplate: string;
}

const AccessProfile: React.FC = (props) => {

    const AcountDefaul: Account = {
        id: 0,
        clientGroupId: 0,
        nome: '',
        ativo: false,
        deletado: false,
        isTemplate: false,
        originTemplateId: 0,
        gtmItemId: 0,
        gtmId: 0,
        managedBy: 0,
        isNF: 0
    }
    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    const [records, setRecords] = useState<any[]>([]);
    const [filteredRecord, setFilteredRecord] = useState<Account[]>([]);
    const [visibleFilteredRecord, setVisibleFilteredRecord] = useState<Account[]>([]);
    const [typePage, setTypePage] = useState<string>('main');
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [columns, setColumns] = useState<TableColumn[]>([
        { field: 'name', headerName: 'Nome do perfil', width: "65%" },
        { field: 'active', headerName: 'Habilitado', width: "15%" },
    ]);
    const [modalHeader, setModalHeader] = useState<string>("");
    const [selectedUser, setSelectedUser] = useState<Account>(AcountDefaul);
    const [ValueSearch, setValueSearch] = React.useState<string>('');
    const [ValueSearchDebounce] = useDebounce(ValueSearch, 500);
    const [reload, setReload] = useState<boolean>(true);
    const [refresh, setRefresh] = useState<boolean>(false);
    const service = new AccountService(props);
    const [filter, setFilter] = useState<Filter>({ size: 25, term: "", column: "", direction: "asc", isTemplate: '' });
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        let localRecords: any[] = [];
        setRecords([]);
        setFilteredRecord([]);

        if (user.ClientGroupSelected || reload == true) {
            service.GetListClientGroupsPerfis()
                .then(result => {
                    if ((user.IsAdm)) {
                        setColumns([
                            { field: 'nome', headerName: 'Nome do perfil', width: "65%" },
                            { field: 'ativo', headerName: 'Habilitado', width: "15%", renderCell: (row: Account) => row.ativo ? 'Sim' : 'Não' },
                            { field: 'isTemplate', headerName: 'Template', width: "15%", renderCell: (row: Account) => row.isTemplate ? 'Sim' : 'Não' },
                        ]);
                    } else {
                        setColumns([
                            { field: 'nome', headerName: 'Nome do perfil', width: "65%"},
                            {
                                field: 'ativo', headerName: 'Habilitado', width: "15%",
                                renderCell: (row: Account) => {
                                    return row.ativo ? 'Sim' : 'Não'
                                }
                            },
                        ]);
                    }
                    var data = result.data;
                    var filteredData = data.sort((a: any, b: any) => { return a.name > b.name ? -1 : 1; });
                    // .map((account: Account) => {
                    //     return {
                    //         id: account.id,
                    //         clientGroupId: account.clientGroupId,
                    //         name: account.nome,
                    //         active: account.ativo ? 'Sim' : 'Não',
                    //         deleted: account.deletado,
                    //         isTemplate: account.isTemplate ? 'Sim' : 'Não',
                    //         originTemplateId: account.originTemplateId,
                    //     };
                    // })
                    localRecords = filteredData;
                    setRecords(filteredData);
                    setFilteredRecord(filteredData);
                    setFilter({ ...filter, column: "isTemplate", direction: "asc", size: data.length + 1 });
                })
                .catch(console.warn);
        }
        let arrayFiltered: any[] = [];
        (localRecords).filter(record => record.name.includes(ValueSearchDebounce)).map(record => {
            arrayFiltered.push(record);
        });
        setFilteredRecord(arrayFiltered);

    }, [ValueSearchDebounce, refresh, user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        var filtered = records.filter((p: Account) =>
            filter.term === '' ||
            p.nome.toLowerCase().indexOf(filter.term) > -1

        )
            .sort((a: any, b: any) => {
                if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

        if (filter.isTemplate !== "") {
            filtered = filter.isTemplate === "Sim" ?
                filtered.filter(fil => fil.isTemplate === true) :
                filtered.filter(fil => fil.isTemplate === false)
        }

        setFilteredRecord(filtered);
        setVisibleFilteredRecord(filtered);
    }, [filter, filteredRecord.length > 0]);
    function editMembers(row: Account) {
        setSelectedUser(row);
        if (isVisible) {
            setIsVisible(false);
            setTypePage('');
            setModalHeader('');
        } else {
            setTypePage("editMember");
            setModalHeader(`Edição de membro ${row.nome}`);
            setIsVisible(true);
        }
    };
    function editProfile(row: Account) {

        setSelectedUser(row);
        if (isVisible) {
            setIsVisible(false);
            setTypePage('');
            setModalHeader('');
        } else {
            setTypePage("editProfile");
            setModalHeader(`Edição de perfil`);
            setIsVisible(true);
        }
    };
    function globalPermissions(row: Account) {

        setSelectedUser(row);
        if (isVisible) {
            setIsVisible(false);
            setTypePage('');
            setModalHeader('');
        } else {
            setTypePage("globalPermission");
            setModalHeader(`Permissões globais de  ${row.nome}`);
            setIsVisible(true);
        }
    };
    function menuItens(row: any) {
        let obj = row;
        setSelectedUser(obj);
        if (isVisible) {
            setIsVisible(false);
            setTypePage('');
            setModalHeader('');
        } else {
            setTypePage("menuItens");
            setModalHeader(`Acesso a itens do menu de  ${obj.nome}`);
            setIsVisible(true);
        }
    };
    function menuItensAtivos(row: any) {
        let obj = row;
        setSelectedUser(obj);
        if (isVisible) {
            setIsVisible(false);
            setTypePage('');
            setModalHeader('');
        } else {
            setTypePage("menuItensAtivos");
            setModalHeader(`Acesso a itens do menu de  ${obj.nome}`);
            setIsVisible(true);
        }
    };
    function deleteFunction(row: any) {
        let response = window.confirm("Deseja realmente deletar esse grupo de perfil?");

        if (!response) return;

        enqueueSnackbar('Verificando dados para exclusão..', {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        });

        service.GetMemberGroupPerfis(row.id).then(result => {
            if (result.data.length > 0) {
                enqueueSnackbar('Remova todos os membros do perfil e tente novamente', {
                    variant: 'info',
                    preventDuplicate: true,
                    persist: false,
                });
                return;
            } else {
                service.DeleteClientGroupPerfil(row.id)
                    .then(result => {
                        if (result.status == 200) {
                            enqueueSnackbar('Perfil deletado com sucesso.', {
                                variant: 'success',
                                preventDuplicate: true,
                                persist: false,
                            });
                            setReload(true);
                            setRefresh(!refresh);
                        }
                    })
                    .catch(console.warn);
            }
        }).catch(error => {
            console.log(error);
        });
    };

    const closeModal = () => {
        setTypePage('');
        setIsVisible(false);
        setModalHeader('');
        setReload(true);
        setRefresh(!refresh);
    };

    const functions = [
        {
            title: 'Criar perfil',
            tooltip: "Criar",
            icon: 'ppi ppi-plus',
            onClick: () => {
                if (isVisible) {
                    setIsVisible(false);
                    setTypePage('');
                    setModalHeader('');
                } else {
                    setTypePage("create");
                    setModalHeader(`Criar perfil de acesso`);
                    setIsVisible(true);
                }
            }
        }
    ];

    const functionsRow = [
        {
            label: 'Editar membros',
            func: (row: any) => editMembers(row),
        },
        {
            label: 'Editar perfil',
            func: (row: any) => editProfile(row)
        },
        {
            label: 'Permissões globais',
            func: (row: any) => globalPermissions(row),
            hidden: (row: any) => !(user.email?.includes("@processor.com.br") || user.email?.includes("@gotobiz.com.br")),

        },
        {
            label: 'Itens do menu',
            func: (row: any) => menuItens(row),
            hidden: (row: any) => !(user.email?.includes("@processor.com.br") || user.email?.includes("@gotobiz.com.br")),
        },
        {
            label: 'Excluir',
            func: (row: any) => deleteFunction(row),
            hidden: (row: any) => !(user.email?.includes("@processor.com.br") || user.email?.includes("@gotobiz.com.br")),
        }
    ];

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size });
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleFilteredRecord(filteredRecord.slice(0, size));
        else {
            setVisibleFilteredRecord([...visibleFilteredRecord, ...filteredRecord.slice(visibleFilteredRecord.length, visibleFilteredRecord.length + 10)]);
        }
    };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    const isTemplate = [
        { value: '', name: 'Todas' },
        { value: "Sim", name: 'Sim' },
        { value: "Não", name: 'Não' },
    ];

    const [filterVisible, setFilterVisible] = useState(false);
    const filterAdvanced = {
        fields: [
            { label: 'Pesquisar', name: 'search', type: 'text' },
            { label: 'Template', name: 'isTemplate', type: 'select', options: isTemplate, value: '', topDropdown: true },

        ],
        onChange: (_filter: any, size: number) => {
            setFilter(
                {
                    ...filter,
                    size: size,
                    term: _filter[0].value.toLowerCase(),
                    isTemplate: _filter[1].value
                }
            )
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    };

    return (
        <Layout
            pageTitle="Perfis de Acesso"
            cardType
            functionsGeneric={(user.email?.includes("@processor.com.br") || user.email?.includes("@gotobiz.com.br")) ? functions : undefined}
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: filteredRecord.length
            }}
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
        >
            <LcInfiniteTable
                loading={filteredRecord.length > 0 || reload == false ? false : true}
                columns={columns}
                functionsRow={functionsRow}
                rows={visibleFilteredRecord}
                filter={filterAdvanced}
                size={filteredRecord.length}
                loadMore={loadMore}
                onSortChange={onSortChange}
                density={pref.data?.density || "high"}
            />

            <PPModal visible={isVisible} onClose={closeModal} title={modalHeader}>
                {
                    (() => {
                        switch (typePage) {
                            case "editMember":
                                return <EditMember account={selectedUser} close={closeModal} />;
                            case "editProfile":
                                return <EditProfile account={selectedUser} close={closeModal} />;
                            case "globalPermission":
                                return <Permission type={typePage} account={selectedUser} close={closeModal} />;
                            case "menuItens":
                                return <MenuItems type={typePage} account={selectedUser} close={closeModal} />;
                            case "menuItensAtivos":
                                return <MenuItems activeOnly={true} type={typePage} account={selectedUser} close={closeModal} />;
                            case "create":
                                return <CreateProfile account={selectedUser} close={closeModal} />;
                        }
                    })()
                }
            </PPModal>

        </Layout>
    );
};

export default AccessProfile;