import React, { useState, useEffect } from 'react';
import { PPModal } from 'processor-plataform-ui';
import { Zoom } from '@material-ui/core';
import './index.css'
interface Props {
    data: string,
    attachment: any,
    downloadAttachment: Function,
    handleCloseAttachment: Function,
    openAttachment: boolean
}
const getType = (attachment: any): string => {
    console.log(attachment)
    if (!attachment || !attachment.internalName) {
        return "";
    }
    const lower = attachment.internalName.toLowerCase();
    const fileTypes: { [key: string]: string } = {
        "pdf": "application/pdf",
        "doc": "application/msword",
        "txt": "text/html",
        "png": "image/png",
        "jpeg": "image/jpeg",
        "jpg": "image/jpeg",
        "bmp": "image/bmp",
        "gif": "image/gif",
        "xls": "application/vnd.ms-excel",
        "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    };

    for (const [extension, mimeType] of Object.entries(fileTypes)) {
        if (lower.endsWith(extension)) {
            return mimeType;
        }
    }

    return "";
}
export const DocumentView: React.FC<Props> = (props) => {
    const [content, setContent] = useState<string>("");
    const [type, setType] = useState<string>("");

    const handleClose = () => {
        props.handleCloseAttachment();
    }
    // const getType = () => {

    //     if (props.attachment.length === 0) {
    //         setType("");
    //     }
    //     else {
    //         const lower = props.attachment.internalName.toLowerCase();
    //         let typeTemp = "";

    //         if (lower.endsWith("pdf")) {
    //             typeTemp = "application/pdf";
    //         }
    //         else if (lower.endsWith("doc")) {
    //             typeTemp = "application/msword";
    //         }
    //         else if (lower.endsWith("txt")) {
    //             typeTemp = "text/html";
    //         }
    //         else if (lower.endsWith("png")) {
    //             typeTemp = "image/png";
    //         }
    //         else if (lower.endsWith("jpeg") || lower.endsWith("jpg")) {
    //             typeTemp = "image/jpeg";
    //         }
    //         else if (lower.endsWith("bmp")) {
    //             typeTemp = "image/bmp";
    //         }
    //         else if (lower.endsWith("gif")) {
    //             typeTemp = "image/gif";
    //         }
    //         else if (lower.endsWith("xls")) {
    //             typeTemp = "application/vnd.ms-excel";
    //         }
    //         else if (lower.endsWith("xlsx")) {
    //             typeTemp = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    //         }

    //         setType(typeTemp);
    //     }
    // }

    useEffect(() => {
        let dataArray: string[] = [];

        if (props.data) {
            dataArray = props.data.split("base64");

            if (dataArray.length === 1) {
                setContent(dataArray[0].slice(1))
                setType(getType(props.attachment));
            }
            else if (dataArray.length > 1) {
                setContent(dataArray[1].slice(1))
                setType(getType(props.attachment));

            }
        }
    }, [props.attachment, props.data]);


    const downloadFile = () => {
        props.downloadAttachment(props.attachment);
        props.handleCloseAttachment();
    }


    const getSrcIframe = () => {
        return `data:${type};charset=utf-8;base64,${content}`;
    }

    const getSrcEmbed = () => {
        return `text/html;charset=utf-8;base64,${content}`;
    }

    return (
        <PPModal 
            title={props.attachment.originalName}
            visible={props.openAttachment}
            onClose={() => handleClose()}
            size='medium'
        >
            <div className={'divOrderView'}>
                {
                    type.includes("image")
                        ? <Zoom>
                            <img src={getSrcIframe()} alt={""} className={'imgSize'} />
                        </Zoom>

                        : <iframe title={props.attachment.originalName} src={getSrcIframe()} className='iframeSize'>
                            <embed src={getSrcEmbed()} type={type} />
                        </iframe>
                }
            </div>
            <button type={"button"} className={"lc-button bg-info"} autoFocus onClick={() => downloadFile()} style={{ float: "right" }}>
                Download
            </button>
        </PPModal>
    );
}