import React, { useCallback, useEffect, useState } from 'react';
import './index.css'
import { HostsAvailability, HostsResume, Incident, LiveWatchService } from '../../../services/LiveWatchServiceGroupService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import Layout from '../../../components/Layout/Layout';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import { PiMonitorLight } from 'react-icons/pi';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import { ProgressBarWithLabel } from '../../../components/Layout/Dashboard/ProgressBarWithLabel';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import PPDropDown from '../../../components/PPDropDown';
import DoughNutEChart from '../../../components/Data/Dashboard/DoughnutEchart';
import MessageService from '../../../services/AIx/messages/MessageService';
import AIxChatComponent from '../../../components/aix/ChatClient/AIxChatComponent';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';

interface Props {
    isHome: boolean;
    isSecOpsPage?: boolean
    qtdGroups?: number;
    secOpsQtdGroups?: number;
}

interface FilterData {
    size: number;
    hostname: string;
    trigger_name: string,
    severity: number,
    direction: string;
}

const LiveWatchSummary: React.FC<Props> = ({ isHome, qtdGroups, secOpsQtdGroups, isSecOpsPage }) => {

    const service = new LiveWatchService({});
    const messageService = new MessageService({});
    const user = useSelector<RootState, UserState>(state => state.user);

    // [Label, BGColor, TextColor]
    const severityDic = [
        ['Não classificado', 'var(--icon-disabled)', 'var(--text-default)'],
        ['Informação', 'var(--color-secondary-cyan)', 'var(--text-default)'],
        ['Aviso', 'var(--status-yellow)', 'var(--text-default)'],
        ['Alta', '#FFA400', 'var(--background-white)'],
        ['Alta', '#FFA400', 'var(--background-white)'],
        ['Desastre', 'var(--status-red)', 'var(--background-white)'],
    ]

    // [Label, TextColor]
    const statusDic = {
        'Unresolved': ['Em alerta', 'var(--status-red)'],
        'Resolved': ['Resolvido', 'var(--status-green)'],
    }

    const incidentPeriods = [
        { label: 'Semana atual', value: 'CURR_WEEK' },
        { label: 'Semana anterior', value: 'PREV_WEEK' },
    ]

    const incidentTypes = [
        { label: 'Categoria', value: 'CAT' },
        { label: 'Severidade', value: 'SEV' },
    ]

    // Formata data em DD/MM/YYYY - HH:MM
    const formatAsDateDashTime = (dateString: string): string => {
        const date = new Date(dateString);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${day}/${month}/${year} - ${hours}:${minutes}`;
    }

    const renderSeverityCell = (row: any) =>
        <div className='severity-container' style={{ backgroundColor: row.severity ? severityDic[row.severity][1] : severityDic[0][1] }}>
            <span style={{ color: row.severity ? severityDic[row.severity][2] : severityDic[0][2] }}>
                {row.severity ? severityDic[row.severity][0] : severityDic[0][0]}
            </span>
        </div>

    const renderHostnameCell = (row: any) => <span className='overflowEllipsis ml-4'>{row.hostname}</span>

    const renderTriggerNameCell = (row: any) => <span className='overflowEllipsis ml-4'>{row.trigger_name}</span>

    const renderStatusCell = (row: any) => <span style={{ color: statusDic[row.status][1] }}>{statusDic[row.status][0]}</span>

    // #region Recursos - dados
    const [loadingHostsResume, setLoadingHostsResume] = useState<boolean>(false);
    const [hostsResume, setHostsResume] = useState<HostsResume>();

    const loadHostsResume = () => {
        setLoadingHostsResume(true);
        service.GetHostsResume()
            .then(setHostsResume)
            .catch(console.error)
            .finally(() => setLoadingHostsResume(false));
    }
    useEffect(loadHostsResume, [user.ClientGroupSelected, user.refreshFlag]);
    // #endregion

    // #region Indice de risco - dados
    const [loadingHostsAvailability, setLoadingHostsAvailability] = useState<boolean>(false);
    const [currentWeekHostsAvailability, setCurrentWeekHostsAvailability] = useState<HostsAvailability[]>([]);
    const [previousWeekHostsAvailability, setPreviousWeekHostsAvailability] = useState<HostsAvailability[]>([]);
    const [hostsAvailability, setHostsAvailability] = useState<HostsAvailability[]>([]);
    const [filteredHostsAvailability, setFilteredHostsAvailability] = useState<HostsAvailability[]>([]);
    const [riskPercent, setRiskPercent] = useState<number>(0);
    const [hostsAvailabilityPanelIsFocused, setHostsAvailabilityPanelIsFocused] = useState<boolean>(false);
    const [incidentPeriod, setIncidentPeriod] = useState<{ label: string, value: string }>(incidentPeriods[0]);
    const [selectedPeriodLabel, setSelectedPeriodLabel] = useState<string>();

    const loadHostsAvailability = () => {
        setLoadingHostsAvailability(true);
        service.GetHostsAvailability()
            .then((response) => {
                const sevenDaysAgo = new Date();
                sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
                sevenDaysAgo.setHours(0, 0, 0, 0);

                const currentWeek = response.problems
                    .filter(event => new Date(event.started_at) >= sevenDaysAgo)
                    .sort((a, b) => new Date(b.started_at).getTime() - new Date(a.started_at).getTime());

                const previousWeek = response.problems
                    .filter(event => new Date(event.started_at) < sevenDaysAgo)
                    .sort((a, b) => new Date(b.started_at).getTime() - new Date(a.started_at).getTime());

                setHostsAvailability(currentWeek);
                setFilteredHostsAvailability(currentWeek);
                setCurrentWeekHostsAvailability(currentWeek);
                setPreviousWeekHostsAvailability(previousWeek);
                setRiskPercent(response.total_unavailability)
            })
            .catch(console.error)
            .finally(() => setLoadingHostsAvailability(false));
    }
    useEffect(loadHostsAvailability, [user.ClientGroupSelected, user.refreshFlag]);

    const filterHostsAvailabilityByPeriod = (period: string) => {
        const today = new Date();
        let startDate = new Date(today);
        let endDate = new Date(today);

        if (period === 'CURR_WEEK') {
            endDate = today;
            startDate.setDate(today.getDate() - 7);
            setHostsAvailability(currentWeekHostsAvailability);
            setFilteredHostsAvailability(currentWeekHostsAvailability);
        }

        if (period === 'PREV_WEEK') {
            endDate.setDate(today.getDate() - 8);
            startDate.setDate(today.getDate() - 14);
            setHostsAvailability(previousWeekHostsAvailability);
            setFilteredHostsAvailability(previousWeekHostsAvailability);
        }

        const formattedStartDate = startDate.toLocaleDateString('pt-BR');
        const formattedEndDate = endDate.toLocaleDateString('pt-BR');
        setSelectedPeriodLabel(`(${formattedStartDate} a ${formattedEndDate})`);
    }
    useEffect(() => filterHostsAvailabilityByPeriod(incidentPeriod.value), [incidentPeriod]);
    // #endregion

    // #region Sugestões da IA - dados
    const [loadingAIData, setLoadingAIData] = useState<boolean>(false);
    const [loadingAIAssistant, setLoadingAIAssistant] = useState<boolean>(false);
    const [assistantId, setAssistantId] = useState<string>("");
    const [messageLLMAIx, setMessageLLMAIx] = useState<string[]>([]);
    const [AIXAgentVisible, setAIXAgentVisible] = useState<boolean>(true);

    const getAssitantData = async () => {
        setAssistantId("");
        if (user && user.ClientGroupSelected) {
            setLoadingAIData(true);
            setLoadingAIAssistant(true);
            var assistantid = await messageService.getAssistantId("4", user.ClientGroupSelected?.toString(), "1");
            setAssistantId(assistantid);
            setLoadingAIAssistant(false);

            var Message = await messageService.fast_assistant_Inquery(assistantid,
                "Faça cinco recomendações de prioridade baseado nos arquivos anexados, " +
                "no máximo 3 linhas cada, " +
                "duas recomendações devem ser sobre hosts específicos com severidade entre 4 e 5, " +
                "remova citações do texto resultante, " +
                "inicie cada recomendação com um hífen (-)."
            );

            const topics = Message &&
                Message.messages &&
                Message.messages.length > 0 &&
                Message.messages[0].content &&
                Message.messages[0].content.length > 0 &&
                Message.messages[0].content[0].text &&
                Message.messages[0].content[0].text.value &&
                Message.messages[0].content[0].text.value.split("\n\n- ");

            if (topics && topics.length > 0) {
                topics[0] = topics[0].replace(/^- /, '');
                setMessageLLMAIx(topics);
            } else
                setMessageLLMAIx([]);

            setLoadingAIData(false);
        }
    }
    useEffect(() => { getAssitantData() }, [user.refreshFlag, user.ClientGroupSelected]);
    // #endregion

    // #region Incidentes NÃO resolvidos - dados
    const [loadingActiveIncidents, setLoadingActiveIncidents] = useState<boolean>(false);
    const [activeIncidents, setActiveIncidents] = useState<Incident[]>([]);

    const loadActiveIncidents = () => {
        setLoadingActiveIncidents(true);
        service.GetActiveIncidents()
            .then((response) => {
                const sortedIncidents = response.sort((a, b) =>
                    b.severity !== a.severity ? b.severity - a.severity :
                        new Date(b.started_at).getTime() - new Date(a.started_at).getTime()
                );
                setActiveIncidents(sortedIncidents);
            })
            .catch(console.error)
            .finally(() => setLoadingActiveIncidents(false));
    }
    useEffect(loadActiveIncidents, [user.ClientGroupSelected, user.refreshFlag]);
    // #endregion

    // #region NÃO resolvidos por categoria/severidade - dados
    const [groupedActiveIncidents, setGroupedActiveIncidents] = useState<{ name: string, value: number }[]>([]);
    const [hostgroupActiveIncidents, setHostgroupActiveIncidents] = useState<{ name: string, value: number }[]>([]);
    const [severityActiveIncidents, setSeverityActiveIncidents] = useState<{ name: string, value: number }[]>([]);
    const [loadingGroupedActiveIncidents, setLoadingGroupedActiveIncidents] = useState<boolean>(false);
    const [incidentType, setIncidentType] = useState<{ label: string, value: string }>(incidentTypes[0]);

    const loadGroupedActiveIncidents = () => {
        setLoadingGroupedActiveIncidents(true);
        service.GetGroupedActiveIncidents()
            .then((response) => {
                const hostgroups = Object.entries(response.hostgroups).map(([name, value]) => ({ name, value }))
                setHostgroupActiveIncidents(hostgroups);
                setGroupedActiveIncidents(hostgroups);

                const severities = Object.entries(response.severities).map(([name, value]) => ({ name: severityDic[Number(name)][0], value }))
                setSeverityActiveIncidents(severities);
            })
            .catch(console.error)
            .finally(() => setLoadingGroupedActiveIncidents(false));
    }
    useEffect(loadGroupedActiveIncidents, [user.ClientGroupSelected, user.refreshFlag]);

    const filterGroupedActiveIncidentsByType = (type: string) => {
        if (type === 'CAT') setGroupedActiveIncidents(hostgroupActiveIncidents);
        if (type === 'SEV') setGroupedActiveIncidents(severityActiveIncidents);
    }
    useEffect(() => filterGroupedActiveIncidentsByType(incidentType.value), [incidentType]);
    // #endregion

    const dashboardCards = [
        {// 1. Recursos
            id: 1,
            type: "Custom",
            showFocusButton: false,
            hideHeader: false,
            bgColor: "var(--color-primary-purple)",
            title: () => <div style={{ color: "white" }} className="lc-title ellipsis">Recursos</div>,
            position: { col: 1, ColSpan: 4, row: 1, RowSpan: 1 },
            customContentRender: () => (
                <LcLoading loading={loadingHostsResume}>
                    {
                        hostsResume ?
                            <IconValue
                                icon={<PiMonitorLight size={28} />}
                                value={`${hostsResume.enabled} de ${hostsResume.total}`}
                                color='white'
                                description='Monitorados / Total'
                                descriptionColor='white'
                            />
                            :
                            <LcNoData />
                    }
                </LcLoading>
            ),
        },
        {// 2. Índice de Risco
            id: 2,
            type: "Custom",
            showFocusButton: riskPercent > 0,
            hideHeader: false,
            infoContent: () => !hostsAvailabilityPanelIsFocused && 'Incidentes da semana',
            title: () =>
                <div className='title-with-selector-container'>
                    <span>{hostsAvailabilityPanelIsFocused ? `Eventos da semana ${selectedPeriodLabel}` : 'Índice de risco'}</span>
                    {
                        hostsAvailabilityPanelIsFocused &&
                        <PPDropDown
                            options={incidentPeriods}
                            value={incidentPeriod}
                            defaultValue={incidentPeriods[0]}
                            onChange={setIncidentPeriod}
                            width='161px'
                        />
                    }
                </div>,
            position: { col: 5, ColSpan: 4, row: 1, RowSpan: 1 },
            customContentRender: (focused?: boolean) => {
                //setHostsAvailabilityPanelIsFocused(focused !== undefined && focused === true);
                const isPanelFocused = focused !== undefined && focused === true;
                const progressBarColor = Number(riskPercent.toFixed(2)) > 0 ? 'var(--status-red)' : 'var(--status-green)';
                const renderDurationCell = (row: any) => <span style={{ color: 'var(--status-red)' }}>{row.duration}</span>
                const renderUnavailabilityCell = (row: any) => {
                    const value = row.unavailability_percentage !== null ? `${Number(row.unavailability_percentage).toFixed(2)}%` : '-'
                    return (<span style={{ color: 'var(--status-red)' }}>{value}</span>)
                }
                const tableFields = [
                    { field: 'hostname', headerName: 'Recurso', width: 600, renderCell: renderHostnameCell },
                    { field: 'trigger_name', headerName: 'Sensor', width: 700, renderCell: renderTriggerNameCell },
                    { field: 'duration', headerName: 'Duração do alerta', width: 200, align: 'right', renderCell: renderDurationCell },
                    { field: 'unavailability_percentage', headerName: 'Taxa de falha na semana (%)', width: 400, align: 'right', renderCell: renderUnavailabilityCell },
                    { field: 'status', headerName: 'Status', width: 200, align: 'center', renderCell: renderStatusCell },
                    { field: 'severity', headerName: 'Severidade', width: 200, renderCell: renderSeverityCell }
                ];
                return (
                    <LcLoading loading={loadingHostsAvailability}>
                        {
                            isPanelFocused ?
                                hostsAvailability && filteredHostsAvailability && hostsAvailability.length > 0 && filteredHostsAvailability.length > 0 ?
                                    <LcInfiniteTable
                                        rows={filteredHostsAvailability.sort((a, b) => b.status.localeCompare(a.status))}
                                        columns={tableFields}
                                        size={filteredHostsAvailability.length}
                                        hidePagination
                                    />
                                    : <LcNoData />
                                :
                                riskPercent && riskPercent > 0 ?
                                    <ProgressBarWithLabel
                                        atualPercentScore={riskPercent}
                                        maxPercentScore={100}
                                        size='normal'
                                        barColor={progressBarColor}
                                        decimals={2}
                                    />
                                    : <LcNoData />
                        }
                    </LcLoading>
                )
            },
        },
        {// 3. Sugestões da IA
            id: 3,
            type: "Custom",
            showFocusButton: true,
            hideHeader: false,
            title: () => 'Sugestões da IA',
            position: { col: 9, ColSpan: 4, row: 1, RowSpan: 1 },
            customContentRender: (focused?: boolean) =>
                <LcLoading loading={loadingAIData}>
                    {
                        messageLLMAIx.length > 0 ?
                            focused ?
                                messageLLMAIx.map((item, index) =>
                                    <div className='row '>
                                        <i className='ppi ppi-lightning-bolt' style={{ fontSize: '12px' }}></i>
                                        <p className='suggestionAIText' key={index}>{item}</p>
                                    </div>
                                ) : (
                                    <div className='row '>
                                        <i className='ppi ppi-lightning-bolt' style={{ fontSize: '12px' }}></i>
                                        <p className='suggestionAIText'>{messageLLMAIx[0]}</p>
                                    </div>
                                )
                            : <LcNoData />
                    }
                </LcLoading>,
        },
        {// 4. Incidentes NÃO resolvidos
            id: 4,
            type: "Custom",
            showFocusButton: true,
            hideHeader: false,
            title: () => 'Incidentes NÃO resolvidos',
            position: { col: 1, ColSpan: 8, row: 2, RowSpan: 4 },
            customContentRender: (focused?: boolean) => {
                const renderDateCell = (row: any) => formatAsDateDashTime(row.started_at);
                const renderTicketCell = (row: any) => <span style={{ marginRight: '5px' }}>{row.ticket === 'No ticket available.' ? '--' : row.ticket}</span>;
                const renderHostgroupsCell = (row: Incident) => row.hostgroups.length > 1 ?
                    row.hostgroups.map(hg => hg.name).join(', ') : row.hostgroups[0].name;
                const tableFields = [
                    { field: 'started_at', headerName: 'Data', width: 300, renderCell: renderDateCell },
                    { field: 'hostname', headerName: 'Recurso', width: 700, renderCell: renderHostnameCell },
                    { field: 'severity', headerName: 'Severidade', width: 200, renderCell: renderSeverityCell }
                ];
                const focusedTableFields = [
                    { field: 'started_at', headerName: 'Data', width: 300, renderCell: renderDateCell },
                    { field: 'hostname', headerName: 'Recurso', width: 800, renderCell: renderHostnameCell },
                    { field: 'hostgroups', headerName: 'Grupo', width: 500, renderCell: renderHostgroupsCell },
                    { field: 'trigger_name', headerName: 'Sensor', width: 600, renderCell: renderTriggerNameCell },
                    { field: 'severity', headerName: 'Severidade', width: 400, renderCell: renderSeverityCell, align: 'center' },
                    { field: 'ticket', headerName: 'Ticket', width: 200, renderCell: renderTicketCell, align: 'right' }
                ];
                return (
                    <LcLoading loading={loadingActiveIncidents}>
                        {
                            activeIncidents && activeIncidents.length > 0 ?
                                <LcInfiniteTable
                                    rows={activeIncidents}
                                    columns={focused ? focusedTableFields : tableFields}
                                    size={activeIncidents.length}
                                    hidePagination
                                /> : <LcNoData />
                        }
                    </LcLoading>
                )
            },
        },
        {// 5. NÃO resolvidos por categoria/severidade
            id: 5,
            type: "Custom",
            showFocusButton: false,
            hideHeader: false,
            title: () =>
                <div className='title-with-selector-container'>
                    <span>
                        {isHome || isSecOpsPage ? "Incidentes NÃO resolvidos" : "NÃO resolvidos por:"}
                    </span>
                    <PPDropDown
                        options={incidentTypes}
                        value={incidentType}
                        defaultValue={incidentTypes[0]}
                        onChange={setIncidentType}
                        width={'115px'}
                    />
                </div>,
            position: { col: 9, ColSpan: 4, row: 2, RowSpan: 4 },
            customContentRender: () => {
                const totalData = groupedActiveIncidents ? groupedActiveIncidents.reduce((acc, item) => acc + item.value, 0) : 0;
                const severityColors = ['#FFA400', '#F6DE00', '#DC291E', '#00DBFF', '#EBEBEB']
                const categoryChart = (groupedActiveIncidents ? <DoughNutEChart
                    ArrayData={groupedActiveIncidents}
                    showPercent={false}
                    decimals={0}
                    totalNumber={totalData}
                    legendOrientation={pinnedAIPanel ? 'horizontal' : 'vertical'}
                /> : <LcNoData />);
                const severityChart = (groupedActiveIncidents ? <DoughNutEChart
                    ArrayData={groupedActiveIncidents}
                    showPercent={false}
                    decimals={0}
                    totalNumber={totalData}
                    customColors={severityColors}
                    legendOrientation={pinnedAIPanel ? 'horizontal' : 'vertical'}
                /> : <LcNoData />);
                return (
                    <LcLoading loading={loadingGroupedActiveIncidents}>
                        {incidentType.value === 'CAT' && categoryChart}
                        {incidentType.value === 'SEV' && severityChart}
                    </LcLoading>
                )
            },
        },
    ];

    // Função para ajustar propriedades dinamicamente
    const adjustCardProperties = (cards: any[]) => {
        return cards.map((card) => {
            // Ajuste específico para o cartão de ID 2
            if (card.id === 2) {
                card.showFocusButton = !(isHome || isSecOpsPage) && riskPercent > 0;
            }

            // Ajuste de posições com base no contexto
            if (isHome) {
                switch (card.id) {
                    case 1:
                        card.position = { col: 1, ColSpan: 1, row: 1, RowSpan: 1 }; // home
                        break;
                    case 2:
                        card.position = { col: 1, ColSpan: 1, row: 2, RowSpan: 1 }; // home
                        break;
                    case 5:
                        card.position = { col: 1, ColSpan: 1, row: 3, RowSpan: 3 }; // home
                        break;
                }
            } else if (isSecOpsPage) {
                switch (card.id) {
                    case 1:
                        card.position = { col: 1, ColSpan: 1, row: 1, RowSpan: 1 }; // SecOps
                        break;
                    case 2:
                        card.position = { col: 1, ColSpan: 1, row: 2, RowSpan: 1 }; // SecOps
                        break;
                    case 5:
                        card.position = { col: 1, ColSpan: 1, row: 3, RowSpan: 3 }; // SecOps
                        break;
                }
            }
            return card;
        });
    };

     // Selecionar e ajustar cartões para exibição
    const filteredDashboardCards = isHome || isSecOpsPage
    ? adjustCardProperties(dashboardCards.filter((card) => [1, 2, 5].includes(card.id)))
    : adjustCardProperties(dashboardCards);

    const defaultFilterValue = { size: 20, hostname: "", trigger_name: "", severity: -1, direction: "asc" };
    const [pinnedAIPanel, setPinnedAIPanel] = useState<boolean>(true);
    const [filterData, setFilterData] = useState<FilterData>(defaultFilterValue);
    const [showFilter, setShowFilter] = useState<boolean>(false);

    const filterStructure = {
        fields: [
            { label: 'Recurso', name: 'hostname', type: 'select', options: [...new Set(hostsAvailability.map(i => i.hostname))].map(i => ({ label: i, value: i })) },
            { label: 'Sensor', name: 'trigger_name', type: 'select', options: [...new Set(hostsAvailability.map(i => i.trigger_name))].map(i => ({ label: i, value: i })) },
            { label: 'Severidade', name: 'severity', type: 'select', options: severityDic.map((item, index) => ({ label: item[0], value: index })) },
        ],
        onChange: (_filter: any, size: number) => setFilterData({
            ...filterData, size: size,
            hostname: _filter[0].value.toLowerCase(),
            trigger_name: _filter[1].value.toLowerCase()
        }),
        visible: showFilter,
        onChangeVisibility: () => setShowFilter(!showFilter)
    }
    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={filterStructure.fields}
                onFilterChange={(updatedFilters) => {
                    const newFilter = { ...filterData };
                    updatedFilters.forEach(f => newFilter[f.name] = f.value);
                    setFilterData(newFilter);
                }}
            />
        );
    };
    const applyFilter = useCallback(() => {
        const filtered = hostsAvailability.filter(item => {
            if (filterData.hostname !== defaultFilterValue.hostname || filterData.trigger_name !== defaultFilterValue.trigger_name || filterData.severity !== defaultFilterValue.severity) {
                const matchesHostname = !filterData.hostname || item.hostname.toLowerCase().includes(filterData.hostname.toLowerCase());
                const matchesTriggerName = !filterData.trigger_name || item.trigger_name.toLowerCase().includes(filterData.trigger_name.toLowerCase());
                const matchesSeverity = !filterData.severity || item.severity === filterData.severity;
                return matchesHostname && matchesTriggerName && matchesSeverity;
            }
            return true;
        });

        setFilteredHostsAvailability(filtered);
    }, [hostsAvailability, filterData]);

    useEffect(() => {
        applyFilter();
    }, [applyFilter]);

    const gridFilter = {
        toggleVisibility: () => {
            setShowFilter(!showFilter)
        },
        size: filteredHostsAvailability.length
    }

    const layoutFunctions = [{
        icon: (
            <LcIconLink
                icon='ppi ppi-lightning-bolt'
                id='aiLCChamados'
                size='small'
                degrade
                tooltip="LiveWatch AI"
                tooltipPosition="right"
            />
        ),
        onClick: () => !loadingAIAssistant && setAIXAgentVisible(!AIXAgentVisible),
        tooltip: "LiveWatch AI"
    }]
    const questions = [
        "Qual recurso teve o maior percentual de consumo?",
        "Quantas automações tenho em meu ambiente?",
        "Foi detectado algum alerta de consumo?",
        "Quais VMs estão desligadas agora?",
        "Qual a média de uso dos meus recursos?",
        "Quais os principais indicadores?"
    ];
    const dashboardRightPanelAI = {
        title: 'LiveCloud AI',
        titleClass: 'ai-gradient-text',
        pinned: true,
        hiddenPin: false,
        show: AIXAgentVisible,
        focusable: true,
        togglePinned: () => setPinnedAIPanel(!pinnedAIPanel),
        content: () => (
            <LcLoading loading={loadingAIData || loadingAIAssistant}>
                <div style={{ alignSelf: 'center' }}>
                    <AIxChatComponent questions={questions} eventChat={(_event) => { }} assistantId={assistantId} assistantName={"LiveWatch"} />
                </div>
            </LcLoading>
        ),
    }

    const dashboardRightPanelFilter = {
        title: 'Filtros',
        pinned: false,
        show: showFilter,
        content: filterSystem
    }
    return (isHome || isSecOpsPage) ? (
        <LCDashboard
            isHomeType
            cssClass={secOpsQtdGroups === 1 ? '' : 'LiveWatchHome'}
            grid={[4, 1]}
            cards={filteredDashboardCards}
        />
    ) : (
        <Layout
            pageTitle="Resumo"
            functionsGeneric={layoutFunctions}
            gridFilter={hostsAvailabilityPanelIsFocused ? gridFilter : undefined}
        >
            <LCDashboard
                cards={filteredDashboardCards}
                rightSidePanel={hostsAvailabilityPanelIsFocused ? dashboardRightPanelFilter : dashboardRightPanelAI}
            />
        </Layout>
    );
};

export default LiveWatchSummary;
