import React, { useEffect, useState } from 'react';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcLoading from '../../../components/Generic/LcLoading';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import Layout from '../../../components/Layout/Layout';

import { NpsService, Question } from '../../../services/npsService'
import { InputField, SelectField } from '../../../components/Form/Input';
import LcToggle from '../../../components/Form/LcToggle';
import LcTooltip from '../../../components/Generic/LcTooltip';
import Interactions from './Interactions';
import LcCheckBox from '../../../components/Form/LcCheckBox';
import LcTable from '../../../components/Data/LcTable';
import { PPInput } from 'processor-plataform-ui';
import { event } from 'react-ga';
import PPDropDown from '../../../components/PPDropDown';


const Nps: React.FC = (props) => {

    const nps = new NpsService(props);

    const [loading, setLoading] = useState<boolean>(false);

    const [questions, setQuestions] = useState<Question[]>([]);
    const [questionSelected, setQuestionSelected] = useState<Question>();
    const [modalCreateVisible, setModalCreateVisible] = useState<boolean>(false);
    const [periodType, setPeriodType] = useState<string>('0');

    const [questionToInteractions, setQuestionToInteractions] = useState<Question>();

    const loadQuestions = () => {
        setLoading(true);
        nps.GetQuestions()
            .then(response => {
                setQuestions(response.data || []);
            })
            .catch(console.warn)
            .then(() => {
                setLoading(false);
            })
    }

    useEffect(loadQuestions, []);

    const columns = [
        { field: 'name', headerName: 'Nome', width: '10%' },
        { field: 'text', headerName: 'Pergunta', width: '50%' },
        { field: 'owner', headerName: 'Responsável', width: '30%' },
        {
            headerName: 'Tipo', width: '5%',
            renderCell: (question: Question) => {
                switch (question.periodType) {
                    case 0:
                        return 'Dias'
                    case 1:
                        return 'Logins'
                    default:
                        return 'Desconhecido'
                }
            }
        },
        {
            width: '5%', overflow: 'visible',
            renderCell: (row: Question) => {
                return <LcTooltip trigger="hover" content={row.active ? 'Desativar' : 'Ativar'}>
                    <LcToggle toggled={row.active} onChange={() => { toogleQuestionActive(row) }} />
                </LcTooltip>
            }
        }
    ]
    const deleteQuestion = (question: Question) => {
        nps.DeleteQuestion(question.id)
            .then(response => {

            })
            .catch(console.warn)
            .then(() => {
                loadQuestions();

            })
    }
    const functionsRow = [
        {
            label: 'Editar',
            func: setQuestionSelected
        },
        {
            label: 'Excluir',
            func: deleteQuestion
        },
        {
            label: 'Interações',
            func: (question: Question) => {
                setQuestionToInteractions(question);
            }
        }
    ]

    const functionsGeneric = [
        {
            id: 'nps_create_question',
            icon: 'ppi ppi-plus',
            tooltip: 'Criar pergunta',
            func: () => { setModalCreateVisible(true) },
        }
    ]

    const status = (row: any) => {
        return row.active ? 'success' : 'danger';
    }


    const handleSubmitUpdate = (event: any) => {
        event.preventDefault();

        const { name, text, minimum, maximum, lessText, moreText, showReason, periodType, periods, triggerAlertBelow } = questionSelected!;

        const _question = {
            ...questionSelected,
            name: name,
            text: text,
            minimum: minimum,
            maximum: maximum,
            lessText: lessText,
            moreText: moreText,
            //showReason: showReason == 'on',
            showReason: false,
            periodType: periodType,
            periods: periods,
            triggerAlertBelow: triggerAlertBelow
        } as Question;

        setQuestionSelected(_question);

        questionSelected &&
            nps.PutQuestion(_question)
                .then(response => {
                    console.debug(response);
                })
                .catch(console.warn)
                .then(() => {
                    setQuestionSelected(undefined);
                    event.target.reset();
                    loadQuestions();
                })

    }

    const handleSubmitCreate = (event: any) => {
        event.preventDefault();
        const { name, text, minimum, maximum, lessText, moreText, showReason, periods, triggerAlertBelow } = event.target;

        const _question = {
            ...questionSelected,
            name: name.value,
            text: text.value,
            minimum: minimum.value,
            maximum: maximum.value,
            lessText: lessText.value,
            moreText: moreText.value,
            //showReason: showReason.value == 'on',
            showReason: false,
            periodType: Number(periodType),
            periods: periods.value,
            triggerAlertBelow: triggerAlertBelow.value
        } as Question;

        nps.PostQuestion(_question)
            .then(response => {
                console.debug(response);
            })
            .catch(console.warn)
            .then(() => {
                event.target.reset();
                loadQuestions();
                setModalCreateVisible(false);
            })

    }

    const toogleQuestionActive = (question: Question) => {

        const _question = { ...question, active: !question.active } as Question;

        nps.PutQuestion(_question)
            .then(response => {

            })
            .catch(console.warn)
            .then(() => {
                loadQuestions();
            })
    }

    const updateSelection = (field: string, value: any) => {
        questionSelected && setQuestionSelected({ ...questionSelected, [field]: value })
    }

    const periodOptions = [
        { label: 'Dias', value: "0" },
        { label: 'Logins', value: "1" }
    ]

    return (
        <Layout pageTitle="NPS">
            <LcLoading loading={loading}>

                <LcTable
                    data={questions}
                    columns={columns}
                    functionsRow={functionsRow}
                    functionGeneric={functionsGeneric}
                    status={status}
                    filterFields={[{ label: "Nome", name: "name", type: "text" }, { label: "Pergunta", name: "text", type: "text" }]}
                />
            </LcLoading>

            <SideModal header="Criar pergunta" visible={modalCreateVisible} onClose={() => setModalCreateVisible(false)}>
                <form className="form" onSubmit={handleSubmitCreate}>
                    <PPInput type="text" name="name" title="Nome da pesquisa" required />
                    <PPInput type="text" name="text" title="Texto da pergunta" required />
                    <PPInput type="number" name="minimum" title="Nota mínima" required />
                    <PPInput type="number" name="maximum" title="Nota máxima" required />
                    <PPInput type="text" name="lessText" title="Descrição da menor nota" required />
                    <PPInput type="text" name="moreText" title="Descrição da maior nota" required />
                    {/* <SelectField label="Solicitar novamente" name="periodType" options={[{ name: 'Dias', value: "0" }, { name: 'Logins', value: "1" }]} required /> */}
                    <PPDropDown name="periodType" title="Período de solicitação da pesquisa" options={periodOptions} onChange={(e) => setPeriodType(e.value)} required />
                    <PPInput type="number" name="periods" title="Número de dias/logins até a próxima pesquisa" required />
                    {/* <LcCheckBox checked={false} name="showReason" title="Solicitar motivo" /> */}
                    <PPInput type="number" name="triggerAlertBelow" title="Solicitar motivo e gerar chamado para notas até..." />

                    <div className="space-between">
                        <button className="lc-button bg-primary" type="submit">Criar</button>
                        <button className="lc-button bg-grey" type="reset" onClick={() => setModalCreateVisible(false)}>Cancelar</button>
                    </div>
                </form>
            </SideModal>

            <SideModal header="Editar pergunta" visible={questionSelected != undefined} onClose={() => setQuestionSelected(undefined)}>
                <form className="form" onSubmit={handleSubmitUpdate}>
                    <PPInput type="text" name="name" title="Nome da pesquisa" value={questionSelected?.name} onChange={(event) => updateSelection('name', event.target.value)} required />
                    <PPInput type="text" name="text" title="Texto da pergunta" value={questionSelected?.text} onChange={(event) => updateSelection('text', event.target.value)} required />
                    <PPInput type="number" name="minimum" title="Nota mínima" value={questionSelected?.minimum} onChange={(event) => updateSelection('minimum', event.target.value)} required />
                    <PPInput type="number" name="maximum" title="Nota máxima" value={questionSelected?.maximum} onChange={(event) => updateSelection('maximum', event.target.value)} required />
                    <PPInput type="text" name="lessText" title="Descrição da menor nota" value={questionSelected?.lessText} onChange={(event) => updateSelection('lessText', event.target.value)} required />
                    <PPInput type="text" name="moreText" title="Descrição da maior nota" value={questionSelected?.moreText} onChange={(event) => updateSelection('moreText', event.target.value)} required />
                    <PPDropDown name="periodType" title="Período de solicitação da pesquisa" value={periodOptions[Number(questionSelected?.periodType)]} options={periodOptions} onChange={(event) => updateSelection('periodType', event.value)} required />
                    {/* <SelectField label="Solicitar novamente" value={questionSelected?.periodType.toString()} name="periodType" options={[{ name: 'Dias', value: "0" }, { name: 'Logins', value: "1" }]} onChange={(option) => updateSelection('periodType', option.value)} required /> */}
                    <PPInput type="number" name="periods" title="Número de dias/logins até a próxima pesquisa" value={questionSelected?.periods.toString()} onChange={(event) => updateSelection('periods', event.target.value)} required />
                    {/* <LcCheckBox checked={questionSelected?.showReason} name="showReason" title="Solicitar motivo" /> */}
                    <PPInput type="number" name="triggerAlertBelow" title="Solicitar motivo e gerar chamado para notas até..." value={questionSelected?.triggerAlertBelow} onChange={(event) => updateSelection('triggerAlertBelow', event.target.value)} required />
                    <div className="space-between">
                        <button className="lc-button bg-primary" type="submit">Salvar</button>
                        <button className="lc-button bg-grey" type="reset" onClick={() => setQuestionSelected(undefined)}>Cancelar</button>
                    </div>
                </form>
            </SideModal>



            <SideModal header='Interações' fullWidth visible={questionToInteractions != undefined} onClose={() => setQuestionToInteractions(undefined)}>
                {
                    questionToInteractions &&
                    <Interactions question={questionToInteractions} onClose={() => setQuestionToInteractions(undefined)} />
                }
            </SideModal>
        </Layout >
    );
}

export default Nps;