import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    FaFileAlt,
    FaFileArchive,
    FaFileAudio,
    FaFileCode,
    FaFileCsv,
    FaFileExcel,
    FaFileImage,
    FaFilePdf,
    FaFilePowerpoint,
    FaFileVideo,
    FaFileWord,
    FaFile,
    FaExclamationTriangle,
    FaTimes,
    FaPaperclip
} from 'react-icons/fa';
import './index.css';
import FileSearchService from '../../../../services/AIx/filesearch/fileSearchService';
import { Box, IconButton, LinearProgress, Tooltip, Typography } from '@material-ui/core';
import LcLoading from '../../../../components/Generic/LcLoading';
import { Assistant } from '../../../../services/AIx/assistantsTypes';

interface VectorStoreFileListProps {
    title?: string;
    vector_store_ids: string[];
    assistant: Assistant;
}

interface VectorStoreFile {
    id: string;
    filename: string;
    uploaded_at: string;
    status: string;
    error_message: string;
}

interface FileUploadProgress {
    filename: string;
    progress: number;
    isUpload: boolean;
    completed: boolean;
    error?: string;
}

const VectorStoreFileList: React.FC<VectorStoreFileListProps> = ({ vector_store_ids, assistant }) => {
    const [fileSearchFiles, setFileSearchFiles] = useState<VectorStoreFile[]>([]);
    const [dataVectorStoreName, setDataVectorStoreName] = useState<string>(vector_store_ids[0]);
    const [loadingFileSearchFiles, setLoadingFileSearchFiles] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<FileUploadProgress[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const fileSearchService = new FileSearchService({});

    const allowedFileTypes = new Set([
        ".c",
        ".cpp",
        ".css",
        ".csv",
        ".docx",
        ".gif",
        ".html",
        ".java",
        ".jpeg",
        ".jpg",
        ".js",
        ".json",
        ".md",
        ".pdf",
        ".php",
        ".png",
        ".pptx",
        ".py",
        ".rb",
        ".tar",
        ".tex",
        ".ts",
        ".txt",
        ".webp",
        ".xlsx",
        ".xml",
        ".zip"
    ]);

    const getFileIcon = (file: VectorStoreFile) => {
        if (file.status === 'error') {
            return <FaExclamationTriangle className="file-icon" style={{ color: '#E74C3C' }} />;
        }

        const extension = file.filename.split('.').pop()?.toLowerCase();

        const iconMap: { [key: string]: JSX.Element } = {
            'pdf': <FaFilePdf className="file-icon" />,
            'doc': <FaFileWord className="file-icon" />,
            'docx': <FaFileWord className="file-icon" />,
            'ppt': <FaFilePowerpoint className="file-icon" />,
            'pptx': <FaFilePowerpoint className="file-icon" />,
            'xls': <FaFileExcel className="file-icon" />,
            'xlsx': <FaFileExcel className="file-icon" />,
            'csv': <FaFileCsv className="file-icon" />,
            'zip': <FaFile className="file-icon" />,
            'rar': <FaFileArchive className="file-icon" />,
            'jpg': <FaFileImage className="file-icon" />,
            'jpeg': <FaFileImage className="file-icon" />,
            'png': <FaFileImage className="file-icon" />,
            'gif': <FaFileImage className="file-icon" />,
            'mp3': <FaFileAudio className="file-icon" />,
            'wav': <FaFileAudio className="file-icon" />,
            'mp4': <FaFileVideo className="file-icon" />,
            'avi': <FaFileVideo className="file-icon" />,
            'mkv': <FaFileVideo className="file-icon" />,
            'js': <FaFileCode className="file-icon" />,
            'css': <FaFileCode className="file-icon" />,
            'html': <FaFileCode className="file-icon" />,
            'json': <FaFileCode className="file-icon" />,
            'c': <FaFileCode className="file-icon" />,
            'cpp': <FaFileCode className="file-icon" />,
            'java': <FaFileCode className="file-icon" />,
            'py': <FaFileCode className="file-icon" />,
            'rb': <FaFileCode className="file-icon" />,
            'php': <FaFileCode className="file-icon" />,
            'txt': <FaFileAlt className="file-icon" />,
        };

        return iconMap[extension!] || <FaFileAlt className="file-icon" />;
    };

    const getFiles = async () => {
        setLoadingFileSearchFiles(true);
        if (vector_store_ids.length > 0) {
            
            const reportFiles = (await fileSearchService.getFiles(assistant.id)).files;
            if (reportFiles) {
                setFileSearchFiles(reportFiles);
            }
            setLoadingFileSearchFiles(false);
            setUploadProgress([]);
        }
    };

    useEffect(() => {
        getFiles();
    }, []);

    useEffect(() => {
        if (uploadProgress.length > 0 && uploadProgress.every(x => x.progress >= 100 && x.isUpload === true)) {
            setTimeout(() => {
                getFiles();
            }, 2000); // Esperar 2 segundos para mostrar o progresso do upload
        }
    }, [uploadProgress]);

    useEffect(() => {
        if (vector_store_ids && vector_store_ids.length > 0) {
            setDataVectorStoreName(vector_store_ids[0]);
        }
    }, [vector_store_ids]);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            uploadFilesToVectorStore(Array.from(e.target.files), fileSearchService);
            e.target.value = ''; // Limpar o input
        }
    };

    const uploadFilesToVectorStore = async (filesToUpload: File[], fileSearchService: FileSearchService) => {
        let vectorStoreName = `VectorStore${vector_store_ids[0]}`;
        vectorStoreName = vector_store_ids[0];
        setDataVectorStoreName(vectorStoreName);
        if (filesToUpload.length > 0) {
            await uploadFiles(filesToUpload, fileSearchService, vectorStoreName);
        }
        return vectorStoreName;
    };

    const uploadFiles = async (filesToUpload: File[], fileSearchService: FileSearchService, vectorStoreName: string) => {
        const progressArray = filesToUpload.map(file => ({ filename: file.name, progress: 0, isUpload: false, completed: false, error: '' }));
        setUploadProgress(progressArray);
        for (const file of filesToUpload) {
            const reader = new FileReader();
            reader.onload = async (event) => {
                try {
                    const base64Content = (event.target?.result as string).split(',')[1];
                    await fileSearchService.uploadBase64File(assistant.id, {
                        filename: file.name,
                        content: base64Content,
                    });

                    setUploadProgress(prev => prev.map(p => p.filename === file.name ? { ...p, isUpload: true, completed: true } : p));
                } catch (error: any) {
                    const errorMsg = error.response?.data?.message || error.message || '';
                    const invalidFormat = extractInvalidFileFormat(errorMsg);
                    var mssgErrorp = "";
                    if (invalidFormat) {
                        mssgErrorp = `Formato de arquivo inválido: ${file.name}. O formato "${invalidFormat}" não é suportado.`;

                    } else {
                        mssgErrorp = `Erro ao carregar o arquivo: ${file.name}. Mensagem: ${errorMsg}`
                    }
                    setErrorMessage(mssgErrorp);
                    setUploadProgress(prev => prev.map(p => p.filename === file.name ? { ...p, error: mssgErrorp } : p));
                }
            };
            reader.onprogress = (event) => {
                if (event.lengthComputable) {
                    const progress = (event.loaded / event.total) * 100;
                    setUploadProgress(prev => prev.map(p => p.filename === file.name ? { ...p, progress } : p));
                }
            };
            reader.readAsDataURL(file);
        }
    };

    // Função para extrair o formato inválido
    function extractInvalidFileFormat(message: string): string | null {
        const match = message.match(/Invalid file format (.+?)\. Supported formats/);
        return match ? match[1].trim() : null;
    }

    const getFileItemClass = (file: VectorStoreFile) => {
        switch (file.status) {
            case 'completed':
                return 'file-item completed';
            case 'error':
                return 'file-item error';
            default:
                return 'file-item default';
        }
    };

    const handleDelete = async (fileId: string) => {
        try {
            setLoadingFileSearchFiles(true);
            const fileSearchService = new FileSearchService({});
            if (dataVectorStoreName) {
                await fileSearchService.deleteBase64File(dataVectorStoreName, fileId);
            }
        } catch (error) {
            console.error('Erro:', error);
        }
        setLoadingFileSearchFiles(false);
    };

    const generateTooltipContent = () => {
        const fileExtensions = Array.from(allowedFileTypes);
        const readableExtensions = fileExtensions.map(ext => ext.toUpperCase().replace('.', ''));
        return `Formatos válidos: ${readableExtensions.join(', ')}`;
    };

    return (
        <Box sx={{ mt: 1 }}>
            <LcLoading loading={loadingFileSearchFiles}>
                <Box sx={{ mt: 1, display: 'flex', mb: 2 }}>
                    <div style={{ padding: "5px" }}>
                        <Tooltip title={generateTooltipContent()}>
                            <IconButton component="label" size="small">
                                <FaPaperclip />
                                <input
                                    type="file"
                                    hidden
                                    multiple={false}
                                    accept={Array.from(allowedFileTypes).join(',')}
                                    onChange={handleFileChange}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{ display: 'contents' }}>
                        {fileSearchFiles && fileSearchFiles.length > 0 && (
                            <div className="scrollable-containerA">
                                <ul className="file-list">
                                    {fileSearchFiles.map((file) => (
                                        <li key={file.id} className={getFileItemClass(file)}>
                                            {getFileIcon(file)}
                                            <div className="file-details">
                                                <p>{file.filename}</p>
                                            </div>
                                            <Tooltip title="Remover este arquivo">
                                                <button className="delete-button" onClick={() => handleDelete(file.id)}>
                                                    <FaTimes />
                                                </button>
                                            </Tooltip>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </Box>
                {uploadProgress.length > 0 && (
                    <div className="upload-progress-container small-font">
                        {uploadProgress.map((fileProgress, index) => (
                            <div key={fileProgress.filename} className={`upload-progress ${fileProgress.completed && index > 1 ? 'hide' : ''}`}>
                                <p>{fileProgress.filename}</p>
                                {!fileProgress.completed && (
                                    <LinearProgress
                                        className="progress-bar"
                                        variant="determinate"
                                        value={fileProgress.progress}
                                    />
                                )}
                                {fileProgress.error && (
                                    <Typography color="error" variant="body2">
                                        {fileProgress.error}
                                    </Typography>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                {/* {errorMessage && (
                    <Typography color="error" variant="body1">
                        {errorMessage}
                    </Typography>
                )} */}
            </LcLoading>
        </Box>
    );
};

export default VectorStoreFileList;
