import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { HostingService } from '../../services/hostingService';
import "./index.css";
import HeyhoCall from '../../components/Generic/HeyhoCall/HeyhoCall';
import SideModal from '../../components/Layout/SideModal/SideModal';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';
import { PrefState } from '../../store/reducers/prefReducer';
import { setFilterActive } from '../../store/reducers/filterReducer';
import LcIconLink from '../../components/Generic/LcIconLink';
import { useParams } from 'react-router-dom';
import history from '../../history'
import LcInfiniteTable from '../../components/Data/LcInfiniteTable';
import LcLoading from '../../components/Generic/LcLoading';
import { CacheManagerService } from '../../services/cacheManagerService';
import LCDashboard, { Card } from '../../components/Data/Dashboard/LCDashboard';
import HeyHoNewRequest, { FormDataHeyho } from '../../components/Generic/HeyHoNewRequest';
import { CMSEnvironmenetState } from '../../store/reducers/CmsEnvironmentReducer';
import { VMsInfoCard } from '../../components/Layout/Cards/VMsInfoCard';
import { DatabasesInfoCard } from '../../components/Layout/Cards/DatabasesInfoCard';
import { StorageInfoCard } from '../../components/Layout/Cards/StorageInfoCard';
import { ContainersInfoCard } from '../../components/Layout/Cards/ContainersInfoCard';
import { SavingAndReserveInfoCard } from '../../components/Layout/Cards/SavingAndReserveInfoCard';
import FilterPanel from '../../components/Data/FilterPanel';
import { PiPlusLight } from 'react-icons/pi';

interface VM {
    size?: string,
    signature?: string,
    vnet?: string,
    subnet?: string,
    so?: string,
    name?: string,
    contract?: string,
    environment?: string
}

export interface Param {
    type: string;
}

const Hosting: React.FC = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const cmsEnvironment = useSelector<RootState, CMSEnvironmenetState>(state => state.cmsEnvironment);
    const dispatch = useDispatch();
    const { type } = useParams<Param>();
    const [typeLC, setTypeLC] = useState<string>(type === "1" ? "17" : "6");
    // Estados filtros
    const [filterVisible, setFilterVisible] = useState(false);
    const [filtered, setFiltered] = useState<boolean>(false);
    //const [visibleHostingViewResponsesFiltered, setVisibleHostingViewResponsesFiltered] = useState<any[]>([]);
    const [filterPage, setFilterPage] = useState<{
        size: number,
        term: string,
        clientGroupId: string,
        isLinked: boolean,
        column: string,
        direction: string
    }>({
        size: 20,
        term: "",
        clientGroupId: "",
        isLinked: true,
        column: "name",
        direction: "asc"
    });
    const [isLoading, setIsLoading] = useState(true);
    const [modalVisibleHeyHoCall, setModalVisibleHeyHoCall] = useState<boolean>(false);
    const [lastUpdate, setLastUpdate] = useState<string>("");
    const [formData, setFormData] = useState<FormDataHeyho>({
        title: '',
        description: '',
        gtmType: typeLC
    });

    useEffect(() => {
        setFormData({
            title: ``,
            description: ``,
            gtmType: typeLC
        });
    }, [typeLC]);

    useEffect(() => {
        return history.listen((location) => {
            (location.pathname.toLowerCase().indexOf("0") >= 0) && setTypeLC("6");
            (location.pathname.toLowerCase().indexOf("1") >= 0) && setTypeLC("117");
        })
    }, [props]);

    useEffect(() => {
        const refreshLastUpdate = () => {
            const cacheManagerService = new CacheManagerService(props);
            cacheManagerService.LastSyncUpdate("LiveCloudSyncHosting", "VirtualMachineBase")
                .then(response => {
                    setLastUpdate(response.data);
                })
                .catch((err) => {
                    console.warn(err);
                    setLastUpdate(new Date().toISOString());
                })
        }
        refreshLastUpdate();
    }, [user.ClientGroupSelected, user.refreshFlag, type, props]);

    useEffect(() => {
        let filtered = cmsEnvironment.rowsListFiltered.sort((a: any, b: any) => {
            if (filterPage.direction === 'asc') {
                return a[filterPage.column] > b[filterPage.column] ? 1 : -1;
            } else {
                return a[filterPage.column] < b[filterPage.column] ? 1 : -1;
            }
        });
        dispatch({type:'CMS_ENVIRONMENT_FILTER_ROWS', payload: filtered })
    }, [cmsEnvironment.rowsListFiltered, dispatch, filterPage]);

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilterPage({ ...filterPage, column: sort.column, direction: sort.direction, size: size })

    };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }


    const opcoesSituacao = [
        { value: '', label: 'Todas' },
        { value: 'Started', label: 'Inicializado' },
        { value: 'Stopped', label: 'Parado' },
    ];

    const filterAdvanced = {
        fields: [
            { label: 'Nome', name: 'nome', type: 'text' },
            { label: 'Nome interno', name: 'hostname', type: 'text' },
            { label: 'Assinatura', name: 'assinaturaNome', type: 'text' },
            { label: 'Situação', name: 'situacao', type: 'select', options: opcoesSituacao },

        ],
        onChange: (updatedFilters, size) => {
            // Atualize o estado com os valores dos filtros
            setFilterPage({ ...filterPage, size: size });
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    };

    const handleNewRequestHH = (type: string ) => {
        setModalVisibleHeyHoCall(true)
        setFormData({
            title: '',
            description: '',
            gtmType: typeLC
        })
    }

    const getStatus = (row) => {
        const statusColors = {
            available: '#3ebb3f',
            ready: '#3ebb3f',
            online: '#3ebb3f',
            started: '#3ebb3f',
            running: '#3ebb3f',
            ativo: '#3ebb3f',
            active: '#3ebb3f',
            succeeded: '#3ebb3f',
            stopped: '#dc291e',
            inactive: '#dc291e',
            encerrado: '#dc291e',
            failed: '#dc291e',
            erro: '#dc291e',
            desconhecido: 'var(--text-light)'
        };


        return statusColors[row.status?.toLowerCase()] || statusColors[row.situacao?.toLowerCase()] || '#A0A4FF';
    }

    const filterSystem = () => {
        return (
            <div className="right-sidepanel-filters">
                <FilterPanel
                    filter={Object.keys(cmsEnvironment.translationFilter).map(key => ({
                        label: cmsEnvironment.translationFilter[key].label,
                        name: key,
                        type: cmsEnvironment.translationFilter[key].type,
                        values: cmsEnvironment.translationFilter[key].values,
                    }))}
                    data={cmsEnvironment.rowsList}
                    onFilteredData={(filteredData) => { setFiltered(true); dispatch({type:'CMS_ENVIRONMENT_FILTER_ROWS', payload: filteredData })  }}
                    translations={cmsEnvironment.translationFilter}
                    clearFilters={() => { setFiltered(false); dispatch({type:'CMS_ENVIRONMENT_FILTER_ROWS', payload: cmsEnvironment.rowsList }) }}
                />
            </div>
        )
    }

    const translateStatus = (row) => {
        const translateStatus = {
            'available':'Disponível',
            'ready':'Disponível',
            'online':'Disponível',
            'started':"Inicializado",
            'stopped':'Parado',
            'ativo':'Ativo',
            'active':'Ativo',
            'encerrado': 'Encerrado',
            'failed':'Falha',
            'erro ':'Error',
            "starting":'Inicializando',
            'desconhecido':'Desconhecido',
            'inactive': 'Parado'
        };
        return translateStatus[row?.toLowerCase()];
    };

    const card: Card[]  = [ 
        {
            id: 1,
            type: 'Custom',
            hideHeader: true,
            // bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 3, row: 2, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={isLoading} label='Carregando...'>
                        <LcInfiniteTable
                            id='table_ambiente'
                            loading={isLoading}
                            columns={cmsEnvironment.columns || []}
                            rows={cmsEnvironment.rowsListFiltered || []}
                            filter={filterAdvanced}
                            size={cmsEnvironment.rowsListFiltered?.length || 0}
                            functionsRow={cmsEnvironment.funcitonGeneric}
                            loadMore={() => { }}
                            status={getStatus}
                            tooltipContentColumn={cmsEnvironment.enviromentFilter === 'VMs' ? 'situacao' : 'status'}
                            customTooltipContentColumn={translateStatus}
                            notLoadMore={true}
                            disableFilterModal={true}
                            onSortChange={onSortChange}
                            density={pref.data?.density || "medium"}
                        />
                    </LcLoading>
                )
            }

        },
        {// 2. VMs
            id:2,
            type: "Custom",
            position:  { ColSpan: 2, RowSpan: 1, row: 1, col: 1 },
            cssClass:(cmsEnvironment.enviromentFilter === 'VMs' 
                || cmsEnvironment.enviromentFilter === '') ? 'card-active' : 'card-fade',
            ticketButton: () => <LcIconLink icon={<PiPlusLight/>} size='small' disabled={(cmsEnvironment.enviromentFilter !== 'VMs')}
                    tooltipPosition='right' tooltip="Criar Vm" onClick={() => handleNewRequestHH('VMs')} />, 
            showFocusButton: false,
            title: () => "VMs",
            customContentRender: () => <VMsInfoCard setLoading={setIsLoading}  SetFormDataRequest={setFormData} openRequest={setModalVisibleHeyHoCall} />
        },
        {// 3. Databases
            id:3,
            type: "Custom",
            position:{ ColSpan: 2, RowSpan: 1, row: 1, col: 3 },
            showFocusButton: false,
            cssClass:(cmsEnvironment.enviromentFilter === 'Databases' 
                || cmsEnvironment.enviromentFilter === '') ? 'card-active' :   'card-fade',
            title: () => "Databases",
            customContentRender: () => <DatabasesInfoCard  setLoading={setIsLoading} />
        },
        {// 4. Storage
            id:4,
            type: "Custom",
            position: { ColSpan: 2, RowSpan: 1, row: 1, col: 5 },
            showFocusButton: false,
            cssClass:(cmsEnvironment.enviromentFilter === 'Storage' 
                || cmsEnvironment.enviromentFilter === '') ? 'card-active' :   'card-fade',
            title: () => "Storage",
            customContentRender: () => <StorageInfoCard  setLoading={setIsLoading}/>
        },
        {// 5. Containers
            id:5,
            type: "Custom",
            position:  { ColSpan: 2, RowSpan: 1, row: 1, col: 7 },
            showFocusButton: false,
            cssClass:(cmsEnvironment.enviromentFilter === 'Containers' 
                || cmsEnvironment.enviromentFilter === '') ? 'card-active' :   'card-fade',
            title: () => "Containers",
            customContentRender: () => <ContainersInfoCard setLoading={setIsLoading}/>,
        },
        {// 6. Savings plans
            id:6,
            type: "Custom",
            position:  { ColSpan: 2, RowSpan: 1, row: 1, col: 9 },
            showFocusButton: false,
            title: () => "Savings plans",
            cssClass:(cmsEnvironment.enviromentFilter === 'Savings plans' 
                || cmsEnvironment.enviromentFilter === '') ? 'card-active' :   'card-fade',
            customContentRender: () => <SavingAndReserveInfoCard setLoading={setIsLoading} type='Savings plans'/>

        },
        {// 7. Reservas
            id:2,
            type: "Custom",
            position:  { ColSpan: 2, RowSpan: 1, row: 1, col: 11 },
            showFocusButton: false,
            title: () => "Reservas",
            cssClass:(cmsEnvironment.enviromentFilter === 'Reservas' 
                || cmsEnvironment.enviromentFilter === '') ? 'card-active' :   'card-fade',
            customContentRender: () => <SavingAndReserveInfoCard setLoading={setIsLoading} type='Reservas'/>
        },
        
    ]

    // UseEffect para limpar o filtro ativo quando o componente é desmontado
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);


    return (
        <Layout
            pageTitle="Ambiente"
            // functionsGeneric={functionsGeneric}
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density === "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density === "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density === "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: cmsEnvironment.rowsListFiltered.length,
                color:  filtered ? 'var(--color-secondary-lime)' : '',
            }}
            // lastUpdate={hostingLastUpdate}
            lastUpdate={lastUpdate}
        >
            <HeyHoNewRequest 
                openModal={modalVisibleHeyHoCall} 
                onClose={() => setModalVisibleHeyHoCall(false)}
                formData={formData}
                needAtachmente
            />
            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                    close:setFilterVisible
                }}
            />


        </Layout>
    );
}

export default Hosting;