import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import LcLoading from '../../../components/Generic/LcLoading';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import { LiaUserSecretSolid } from 'react-icons/lia';
import { translateAttackStatus } from './MaliciousURLCard';
import moment from 'moment';
import { translateConfidenceLevel, translateAction } from './MaliciousFileCard';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { PiListLight } from 'react-icons/pi';
import { translateAttackSeverity } from './MostTargeTableCheckpoint';
import { StatusColor } from './AttacksChart';
import { Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';

export const translationsRamsowareAttack = {
    file_name: {
        label: "Nome do Arquivo",
        type: "text",
    },
    src_user_name: {
        label: "Usuário",
        type: "text",
    },
    src_machine_name: {
        label: "Dispositivo",
        type: "text",
    },
    attack_status: {
        label: "Status",
        type: "multiSelect",
        values: {
            'Dormant':'Inativo',
            'Active':'Ativo',
            'Blocked':'Bloqueado',
            'Cleaned':'Limpo',
        }
    },
    severity: {
        label: "Severidade",
        type: "multiSelect",
        values: {
            'Critical': 'Critico',
            'High': 'Alto',
            'Medium': 'Médio',
            'Low': 'Baixo',
            'Informational': 'Informativo'
        }
    },
}
export const getColumnsRansomwareAttack = (
    setSelectedDevice: (device: any) => void,
    setDetailsModalVisible: (visible: boolean) => void
    ) =>  [
    { field: 'file_name', headerName: 'Nome do Arquivo', width: "15%", renderCell: (row: any) => row.file_name[0] },
    { field: 'serverity', headerName: 'Severidade', width: "15%", renderCell: (row: any) => {
        return  <div className='severityLabelDetails' style={{ padding: '0px 8px', backgroundColor: StatusColor(row.severity) }}>
        {translateAttackSeverity(row.severity)}
    </div>
    }},
    { field: 'src', headerName: 'Origem', width: "10%" },
    { field: 'src_user_name', headerName: 'Usuário', width: "10%", renderCell:(row: any) => row.src_user_name[0]},
    { field: 'src_machine_name', headerName: 'Dispositivo', width: "18%", renderCell:(row: any) => {
        return <Tooltip title={ row.src_machine_name} arrow >
        <div className='overflowEllipsis' style={{width: '18vw'}}>
            { row.src_machine_name}
        </div>
    </Tooltip>
    }},
    { field: 'attack_status', headerName: 'Status', width: "10%", renderCell: (row: any) => translateAttackStatus(row.attack_status) },
    { field: 'confidence_level', headerName: 'Confiança', width: "8%", renderCell: (row: any) => translateConfidenceLevel(row.confidence_level) },
    { field: 'action', headerName: 'Ação', width: "10%", renderCell: (row: any) => translateAction(row.action) },
    { field: 'time', headerName: 'Data', width: "12%", renderCell: (row: any) =>  moment(row.time).format('DD/MM/YYYY HH:mm:ss') },
    {
        field: 'id', headerName: '', width: "38px", overflow: "visible",
        renderCell: (row: any) => {
            return <LcIconLink icon={<PiListLight />} size='small' tooltip={"Detalhes"} tooltipPosition='inline-left' onClick={() => {
                setSelectedDevice(row); setDetailsModalVisible(true);
            }} />
        }
    }
]

const RansowareAttackCard: React.FC = () => {
    const checkpointService = new EndpointDetectionResponseService({});
    const dispatch = useDispatch();
    const period = useSelector((state: RootState) => state.xdrCheckpointResume.periodFilter);
    const { data: ransowareAttackData, isLoading } = useQuery({
        queryKey: ["RansowareAttack",period],
        queryFn: () => checkpointService.GetRansomwareAttacks(period),
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
            dispatch({ type: 'SET_RANSOWARE_DATA', payload: response });
        },
        onError: (error) => {
            console.error('Error fetching Ramsoware data:', error);
        }
    });

    useEffect(() => {
        dispatch({ type: 'SET_LOADING', payload: { key: 'loadingRansoware', value: isLoading } });
    }, [dispatch, isLoading]);
    return (
        <LcLoading loading={isLoading}> 
        <IconValue 
            value={ransowareAttackData ? ransowareAttackData.recordCount : 0}
            icon={ 
                <LiaUserSecretSolid  size={30} />
            }
            color='var(--primary)'
            description='Detectados'
        />

    </LcLoading>
    );
}

export default RansowareAttackCard;