import React, { useEffect, useState, useMemo } from 'react';

//Input
import LcToggle from '../../../components/Form/LcToggle';
import LcCheckBox from '../LcCheckBox';
import { InputField } from '../../../components/Form/Input';

import './index.css';

interface Item {
    id?: string,
    value: string,
    selected: boolean,
    text?: string
    extraText?: string
}

interface Props {
    id?:string,
    title?: string,
    list?: Array<Item>,
    toggle?: boolean,
    toggleAll?: boolean,
    search?: boolean,
    searchPlaceHolder?: string,
    onChange?: Function,
    height?: string,
    cssClass?: string
}

const LcCheckList: React.FC<Props> = (props) => {
    const [list, setList] = useState<{
        extraText?: string; value: string, selected: boolean, text: string 
}[]>([]);
    const [checkAll, setCheckAll] = useState(true);
    const [sortType, setSortType] = useState<"asc" | "desc">("asc");
    const [filterText, setFilterText] = useState("");
    const [height, setHeight] = useState<string>('13');

    useEffect(() => {
        if (props.list)
            setList(props.list.map((item: any) => { return item.text ? item : { ...item, text: item.value } }));
    }, [props.list]);

    useEffect(() => {
        if (props.height)
            setHeight(props.height);
    }, [props.height]);

    useEffect(() => {
        setCheckAll(!list.find(i => !i.selected));
    }, [list]);

    const toggleCheckAll = () => {
        let _list = list.map(i => { return { ...i, selected: !checkAll } });
        setList(_list);
        if (props.onChange)
            props.onChange([..._list]);
    }

    const toggleItem = (value: string) => {
        let _list = [...list];
        _list = _list.map(i => { return i.value == value ? { ...i, selected: !i.selected } : i });
        setList(_list);
        if (props.onChange)
            props.onChange(_list);
    }

    const sortedList = useMemo(() => {
        const filteredList = list.filter(item => item.text.toLowerCase().includes(filterText.toLowerCase()));
        return sortType == 'asc'
            ? filteredList.sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()))
            : filteredList.sort((a, b) => b.text.toLowerCase().localeCompare(a.text.toLowerCase()));
    }, [list, filterText, sortType]);

    return (
        <div className={props.cssClass ? props.cssClass : "lc-checklist" }>
            {
                props.search &&
                <div className="filter">
                    <InputField type="text" width='100%' placeHolder={props.searchPlaceHolder} name="search" icon="lci lci-search" disableAutocomplete onChange={(event: any) => setFilterText(event.value)} />
                </div>
            }
            <div className="header">
                {
                    (props.toggleAll != false) ?
                        props.toggle ?
                            <LcToggle toggled={checkAll} onChange={() => { toggleCheckAll() }} />
                            :
                            <LcCheckBox checked={checkAll} onChange={() => { toggleCheckAll() }} />
                        :
                        <div></div>
                }
                <span className="link title" onClick={() => setSortType(prev => prev == 'asc' ? 'desc' : 'asc')} >
                    {props.title}
                    <i className={`lci lci-arrow-${sortType == 'asc' ? 'down' : 'up'}`} />
                </span>
            </div>
            <div className="list scrollable-v" style={{ height: height + 'rem' }}>
                {
                    sortedList.map((item, index) => (
                        <div key={index} className="item" onClick={() => toggleItem(item.value)}>
                            {
                                props.toggle
                                    ? <LcToggle toggled={item.selected} title={item.text ? item.text : item.value} />
                                    : <>
                                        <LcCheckBox checked={item.selected} title={item.text ? item.text : item.value} /> 
                                        {
                                        item.extraText &&
                                            <span style={{color: 'darkgray'}}>{item.extraText}</span>

                                        }
                                    </>
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default LcCheckList;