import React, { useEffect, useState } from 'react';
import AIxChatComponent from '../../components/aix/ChatClient/AIxChatComponent';
import "./Layout.css";
import "./index.css";
import "./global.css";
import LCDashboard, { Card } from '../../components/Data/Dashboard/LCDashboard';

const AssistantClient: React.FC<{}> = (props) => {

    //useEffect(() => {
    //    const root = document.querySelector(":root") as HTMLElement;
    //    root.style.setProperty("--color-primary-blue", "#471FCC");
    //    root.style.setProperty("--color-primary-purple-dark", "#2E1956");
    //    root.style.setProperty("--color-primary-purple", "#8C40E3");
    //    root.style.setProperty("--color-primary-magenta", "#AB19E7");
    //    root.style.setProperty("--color-primary-purple-light", "#966CF3");
    //    root.style.setProperty("--color-primary-pink", "#DA43F2");
    //    root.style.setProperty("--color-secondary-purple-bright", "#9FA3FF");
    //    root.style.setProperty("--color-secondary-cyan", "#00DBFF");
    //    root.style.setProperty("--color-secondary-lime", "#C9FF46");
    //    root.style.setProperty("--color-secondary-black", "#000000");
    //    root.style.setProperty("--color-secondary-mint", "#A6FFE8");
    //    root.style.setProperty("--color-secondary-green-dark", "#255A4B");
    //    root.style.setProperty("--color-secondary-green-light", "#34C38F");
    //    root.style.setProperty("--background-lightest", "#FAFBFF");
    //    root.style.setProperty("--background-light", "#E2E6F3");
    //    root.style.setProperty("--background-neutral", "#EBEBEB");
    //    root.style.setProperty("--background-white", "#FFFFFF");
    //    root.style.setProperty("--background-purple-dark", "#2E1956");
    //    root.style.setProperty("--text-default", "#2E1956");
    //    root.style.setProperty("--text-dark", "#6B6F7D");
    //    root.style.setProperty("--text-light", "#8B90A3");
    //    root.style.setProperty("--text-link", "#00DBFF");
    //    root.style.setProperty("--text-link-hover", "#471FCC");
    //    root.style.setProperty("--text-white", "#FFFFFF");
    //    root.style.setProperty("--icon-default", "#2E1956");
    //    root.style.setProperty("--icon-blue", "#471FCC");
    //    root.style.setProperty("--icon-not-selected", "#898DA6");
    //    root.style.setProperty("--icon-disabled", "#EBEBEB");
    //    root.style.setProperty("--icon-white", "#FFFFFF");
    //    root.style.setProperty("--status-green", "#3EBB3F");
    //    root.style.setProperty("--status-yellow", "#F6DE00");
    //    root.style.setProperty("--status-red", "#DC291E");
    //    root.style.setProperty("--line-default", "#E2E6F3");
    //    root.style.setProperty("--line-dark", "#9196A7");
    //    root.style.setProperty("--line-light", "#EBEBEB");
    //    root.style.setProperty("--line-outline", "#CCCCCF");
    //    root.style.setProperty("--menu-item-active", "#2E1956");
    //    root.style.setProperty("--menu-item-hover", "#E2E6F3");
    //    root.style.setProperty("--button-primary-default", "#9FA3FF");
    //    root.style.setProperty("--button-primary-hover", "#9FA3FF");
    //    root.style.setProperty("--button-secondary-default", "#E2E6F3");
    //    root.style.setProperty("--button-secondary-hover", "#E2E6F3");
    //    root.style.setProperty("--button-disabled", "#8B90A3");
    //}, []);

    const questions = [
        "Quais indicadores estão apresentando maior variação neste momento?",
        "Existe algum alerta de consumo relacionado aos serviços de segurança?",
        "Qual a disponibilidade atual dos recursos de Facility Services?",
        "Quantas otimizações de processos foram implementadas recentemente?",
        "Onde posso verificar o status das manutenções de engenharia?",
        "Há algum relatório recente sobre a eficiência operacional das equipes?"
    ];
    const cards: Card[] = [
        {
            id: 1,
            type: 'Custom',
            position: { ColSpan: 4, RowSpan: 1, row: 1, col: 1 },
            showFocusButton: true,
            title: () => "Hosts",
            customContentRender: (focused?: boolean) => {
                return <AIxChatComponent questions={questions} eventChat={(_event) => { }} assistantId={assistantid} assistantName={"Onet AI"} />;
            }
        }];

    const [assistantid, setAssistantid] = useState<string>("asst_U27xy52VY2EIojVr6T6B8ezK");
    const [isFocused] = useState<boolean>(true);
    const [pinned] = useState<boolean>(true);
    const [title] = useState<string>("Onet AI");
    return (<div className={'page'}>
        <div className={`lcdashboard`} >
        <div className={`right-sidepanel ${isFocused ? 'focused' : ''} ${pinned ? 'pinned' : ''}`} style={{ minWidth: '-webkit-fill-available', minHeight: '-webkit-fill-available' }}>
            <div className="right-sidepanel-content" style={{ minWidth: '-webkit-fill-available', minHeight: 'calc(100vh)' }}>
                    <div className="title-sidebar-content">
                
                        <div className={`title-sidebar ${'ai-gradient-text'}`}>{title}    </div> 

                </div>
                <div className="filter-content" style={{ minWidth: '70%', minHeight: 'calc(100vh)', alignSelf:'center' }}>
                    <AIxChatComponent questions={questions} eventChat={(_event) => { }} assistantId={assistantid} assistantName={"Onet AI"} />
                </div>
            </div>
        </div>
    </div></div>);
}; 
export default AssistantClient;
