import React, { ReactNode, useEffect } from 'react';
import LcIconLink from "../../Generic/LcIconLink";
import LcDropDown from "../../Generic/LcDropDown";
import Confirmation from "../../Dialog/Confirmation";
import { usePivotFunctions } from '../../../contexts/PivotContext';
import { MdOutlineSettingsBackupRestore } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LcTooltip from '../../Generic/LcTooltip';
import { RootState } from '../../../store';
import { FilterState } from '../../../store/reducers/filterReducer';
import { RiDownloadCloudLine } from 'react-icons/ri';
import { PiArrowClockwise, PiArrowCounterClockwise, PiArrowCounterClockwiseLight, PiArrowsCounterClockwiseLight, PiCloudArrowDownLight, PiDotsThreeVertical, PiDotsThreeVerticalBold, PiDotsThreeVerticalLight, PiFolderSimpleStar, PiFolderSimpleStarLight, PiFunnelDuotone, PiFunnelLight, PiLayout, PiLayoutLight, PiSliders, PiSlidersLight } from 'react-icons/pi';

interface ToolBarRightProps {
    functionsGeneric?: {
        title?:string,
        icon: string | React.ReactNode,
        color?: string,
        onClick: Function,
        tooltip?: string | Function,
        badge?: { number: number, status: string },
        disabled?: boolean,
        hidden?: boolean
        clickble?: boolean
    }[];
    selectedPeriod: any;
    onChangePeriod: Function;
    props: any;
    user: any;
    open: boolean;
    setOpen: Function;
    handleConfirmClose: Function;
    waitingUpdating: boolean;
    setShowModalCustomPeriod: Function;
    showModalCustomPeriod: boolean;
    pivot?: boolean;
    aixAssistant?: boolean;
    row?: ReactNode;
    hidePivotHeader?: boolean;
}

const ToolBarRight: React.FC<ToolBarRightProps> = ({
    functionsGeneric,
    selectedPeriod,
    onChangePeriod,
    props,
    user,
    open,
    setOpen,
    handleConfirmClose,
    waitingUpdating,
    setShowModalCustomPeriod,
    showModalCustomPeriod,
    pivot,
    aixAssistant,
    row,
    hidePivotHeader
}) => {
    const { openPivotConfiguration, customPivotConfiguration, csvPivotConfiguration, openExternalSettings, openTable, openTree, openChart, setViewMode, viewMode } = usePivotFunctions();
    const location = useLocation();
    const handleOpenPivotConfiguration = () => {
        if (openPivotConfiguration.current) {
            openPivotConfiguration.current();
        }
    };

    const handleCustomPivotConfiguration = () => {
        if (customPivotConfiguration.current) {
            customPivotConfiguration.current();
        }
    };

    const handleCsvPivotConfiguration = () => {
        if (csvPivotConfiguration.current) {
            csvPivotConfiguration.current();
        }
    };

    const handleOpenExternalSettings = () => {
        if (openExternalSettings.current) {
            openExternalSettings.current();
        }
    };

    const handleOpenTable = () => {
        if (openTable.current) {
            setViewMode('Tabela');
            openTable.current?.();
        }
    };

    const handleOpenTree = () => {
        if (openTree.current) {
            setViewMode('Árvore');
            openTree.current?.();
        }
    };

    const handleOpenChart = () => {
        if (openChart.current) {
            setViewMode('Gráfico');
            openChart.current?.();
        }
    };

    const isFilterActive = useSelector((state: RootState) => state.filter.isFilterActive);

    let functions = functionsGeneric || [];

    if (aixAssistant) {
        functions = [
            ...functions,
            {
                icon: "lci lci-template",
                tooltip: "Assistente IA",
                onClick: handleOpenExternalSettings,
            }]
    }
    if (pivot) {
        functions = [
            ...functions,
            {
                title: "Pivoteamento",
                icon: <PiLayoutLight />,
                tooltip: "Pivoteamento",
                onClick: handleOpenExternalSettings,
            },
            {
                title: "AjustarPivot",
                icon: <PiSlidersLight />,
                tooltip: "Ajustar pivot",
                onClick: handleOpenPivotConfiguration,
            },
            {
                title: "MinhasVisões",
                icon: <PiFolderSimpleStarLight />,
                tooltip: "Minhas visões",
                onClick: handleCustomPivotConfiguration,
                color: props.colorIcon ? props.colorIcon : ''
            },
            {
                title: "DownloadPivot.CSV",
                icon: <PiCloudArrowDownLight />,
                tooltip: "Download Pivot .CSV",
                onClick: handleCsvPivotConfiguration,
            }
        ];
    }

    const rowContent = pivot && !hidePivotHeader ? (
        <LcDropDown
            element={<span>{viewMode} <i className="ppi ppi-chevron-down" /></span>}
            position="right"
        >
            <div className={`item`} id="tabela_arvore_button" onClick={handleOpenTree}>Árvore</div>
            <div className={`item`} id="tabela_tabela_button" onClick={handleOpenTable}>Tabela</div>
            <div className={`item`} id= "tabela_grafico_button" onClick={handleOpenChart}>Gráfico</div>
        </LcDropDown>
    ) : (
        row
    );

    return (
        <>
            {
                functions.length > 0 &&
                <div className="functions" style={{ width: `${(functions.length * 32) + 16}px` }}>
                    {
                        functions.map((f, i) => {
                            return !f.hidden && <LcIconLink id={'toolbarright_' + (f.title && f.title) + '_button'} color={f.color ? f.color : ''} key={i} icon={f.icon} tooltip={typeof f.tooltip == 'function' ? f.tooltip() : f.tooltip} clickble={f.clickble} onClick={f.onClick} tooltipPosition="right" disabled={f.disabled} />;
                        })
                    }
                </div>
            }
            {
                props.periodSelection && (props.periodSelection.periods.length > 1 || props.periodSelection.customPeriod) ?
                    <div className='select-period'>
                        <LcDropDown position='right' maxHeight='50vh' element={<span>{selectedPeriod?.text}&nbsp;&nbsp;<i className={`ppi ppi-chevron-down`} style={{ fontWeight: 'bolder' }} /></span>}>
                            {
                                props.periodSelection.periods.map((p, i) => {
                                    return <span key={i} className="item" onClick={() => onChangePeriod(p)}>{p.label}</span>
                                })
                            }
                            {
                                props.periodSelection.customPeriod && props.periodSelection.customPeriodType &&
                                <>
                                    <hr />
                                    <span className="item" onClick={() => { setShowModalCustomPeriod(!showModalCustomPeriod) }}>Personalizado</span>
                                </>
                            }
                        </LcDropDown>
                    </div>
                    :
                    props.periodSelection && props.periodSelection.periods.length === 1 &&
                    <span style={{ width: '6.5vw', fontSize: '13px', alignItems: 'center', display: 'flex' }} className="item">{props.periodSelection.periods[0].label}</span>
            }
            {
                props.gridFilter &&
                <div className="gridfilter">
                    <div className="size">{props.gridFilter.size}</div>
                    <span>&nbsp;{props.gridFilter.size > 1 ? "itens" : "item"}&nbsp;</span>

                    <LcIconLink                        
                        icon={(isFilterActive || props.gridFilter.color) ? <PiFunnelDuotone /> : <PiFunnelLight/>}
                        color={props.gridFilter.color ?props.gridFilter.color : '' }
                        onClick={() => { props.gridFilter?.toggleVisibility() }}
                        clickble={props.gridFilter.clickble}
                        className={isFilterActive || props.gridFilter.color ? "filtered" : "unfiltered"}
                        id={'rightToolbar_filter_button'}
                    />
                </div>
            }
            {
                rowContent &&
                <div className="row">
                    {rowContent}
                </div>
            }
            {
                user.ClientGroupList && user.ClientGroupList.length > 1 &&
                <div className="clientgroup">
                    <LcTooltip content={user.ClientGroupSelected} trigger='hover'>
                        <span>{user.ClientGroupSelectedDescription}</span>
                    </LcTooltip>
                </div>
            }
            {<div className={"last"}>
                <Confirmation display={open} close={setOpen} confirm={handleConfirmClose} text="Esta operação levará vários minutos para completar. Você poderá continuar a usar o sistema enquanto isso e será avisado quando terminar. Continuar?" />
                <LcIconLink
                    tooltip={!waitingUpdating ? "Atualizar" : "Atualizando. Esta operação pode demorar algum tempo"}
                    //icon={`ppi ppi-refresh${waitingUpdating ? ' ppi-spin' : ''}`}
                    icon={<PiArrowsCounterClockwiseLight className={waitingUpdating ? 'spin' : ''} />}
                    disabled={waitingUpdating}
                    onClick={() => { setOpen(true); }}
                    tooltipPosition="right"
                    id={'rightToolbar_atualizar_button'}
                />
                {
                    props.dots &&
                    <LcDropDown position='right' element={<LcIconLink icon="ppi ppi-dots-vertical" />}>
                        {props.dots}
                    </LcDropDown>
                }
            </div>
            }
        </>
    );
};

export default ToolBarRight;