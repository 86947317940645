import React, { useEffect, useState } from 'react';

import './index.css';

interface Props {
    id?:string,
    checked?: boolean,
    disabled?: boolean,
    onChange?: Function,
    title?: string,
    className?: string,
    name?: string,
    color?: string
}

const LcCheckBox: React.FC<Props> = (props) => {

    const [checked, setChecked] = useState<boolean>();
    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked])

    const handleCheck = () => {
        !props.disabled   && setChecked(!checked);
        //Trigger
        !props.disabled &&  props.onChange &&
            props.onChange({ name: props.name, checked: !checked });
    }

    return (
        <div id={props.id} className={`input-checkbox ${props.className}`}  onClick={handleCheck}>
            <i className={`lci lci-checkbox${checked == true ? '-on' : ''}`} />
            {
                props.title && <span className="title" style={{ color: props.color ? props.color :''}}>&nbsp;{props.title}</span>
            }
            <input type="hidden" value={checked ==true? 'on' : ''} name={props.name}></input>
        </div>
    );
}

export default LcCheckBox;