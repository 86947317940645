import moment from 'moment';
import { PPModal } from 'processor-plataform-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineWindows, AiOutlineApple } from 'react-icons/ai';
import { PiLinuxLogo } from 'react-icons/pi';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import PPCheckBox from '../../../components/Form/PPCheckBox';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LcLoading from '../../../components/Generic/LcLoading';
import { useQuery } from 'react-query';
import { SystemDetail, SystemReport } from '../../../services/patchmanager/patchManagerModel';
import { PatchManagerService } from '../../../services/patchmanager/patchManagerService';
import { Tooltip } from '@material-ui/core';
import LcToggle from '../../../components/Form/LcToggle';
import { translateSeverityColor, translateSeverityLabel, translateStatus, translateScanStatusPatch } from '../utils/translations';

interface systemModalDetailsProps {
    modalOpen: boolean;
    setModalOpen: (value: boolean) => void;
    systemSelected: SystemDetail | null;
}

export const SystemModalDetails: React.FC<systemModalDetailsProps> = ({
    modalOpen,
    setModalOpen,
    systemSelected,
}) => {
    const patchManagerService = new PatchManagerService({});
    const [pendentesFilter, setPendentesFilter] = useState<boolean>(true);
    const [instaladosFilter, setInstaladosFilter] = useState<boolean>(false);
    const [implantandosPMFilter, setImplantandosPMFilter] = useState<boolean>(false);
    const [filteredPatches, setFilteredPatches] = useState<SystemReport[]>([]);

    const { data: systemReport, isLoading: systemReportListLoading } = useQuery(
        ['systemReport', systemSelected?.resource_id_string],
        async () => {
            if (systemSelected) {
                const report = await patchManagerService.GetSystemReport(systemSelected?.resource_id_string, systemSelected?.resource_name);
                applyPatchFilters(report);
                return report;
            }
        },
        {
            enabled: !!systemSelected,
        }
    );

    const translateStatusPatch = (row: SystemReport) => {
        const translateStatus = {
            202: 'Pendente',
            201: "Instalado",
            206: "Falha"
        };
        return translateStatus[row.patch_status];
    };

    const handleCheckboxChange = (name: string, e: any) => {
        switch (name) {
            case 'pendentes':
                setPendentesFilter(e.target.value);
                break;
            case 'instalados':
                setInstaladosFilter(e.target.value);
                break;
            case 'implantandosPMFilter':
                setImplantandosPMFilter(e.checked);
                break;
            default:
                break;
        }

    }

    const columnsPatchDetails = [
        {
            field: "patchId", headerName: "Patch Id", width: "60px", align: 'left', overflow: 'visible',
            renderCell: (row: SystemReport) => {
                return row.superceded_by > 0 ?
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                        {row.patch_id}
                    </div>
                    :
                    <div style={{ display: 'flex', width: '100%', alignItems: 'left' }}>{row.patch_id}</div>
            }
        },
        {
            field: "severity", headerName: "Severidade", width: "10%", align: 'center', overflow: 'visibled',
            renderCell: (row: SystemReport) => {
                return <div className='severityLabelDetails' style={{ backgroundColor: translateSeverityColor(row.severity) }}>{translateSeverityLabel(row.severity)} </div>
            }
        },
        {
            field: "patch_description", headerName: "Descrição do patch", width: "40%", align: 'left',
            renderCell: (row: SystemReport) => <div>{row.patch_description}</div>
        },
        { field: "vendor_name", headerName: "Fabricante", width: "12%", align: 'left' },
        {
            field: "installed_time", headerName: "Instalado em", width: "8%", align: 'left',
            renderCell: (row: SystemReport) => {
                return moment(row.installed_time)
                    .format('DD/MM/YYYY') !== '31/12/1969' ?
                    <>{moment(row.installed_time).format('DD/MM/YYYY')} </> : '-'
            }
        },
        {
            field: "deploy_remarks_args", headerName: "Observações", width: "22%", align: 'left',
            renderCell: (row: SystemReport) =>
                <Tooltip title={row.deploy_remarks} >
                    <div className='overflowEllipsis'>{row.deploy_remarks}</div>
                </Tooltip>
        },
    ];

    const applyPatchFilters = useCallback((patches: SystemReport[]) => {
        const filteredPatches = patches.filter(patch => {
            const isPendente = patch.patch_affected_status_label === 'dc.common.MISSING';
            const isInstalado = patch.patch_affected_status_label === 'dc.common.INSTALLED';
            const isImplantadoPM = patch.installed_time > 0;

            if (implantandosPMFilter && !isImplantadoPM) {
                return false;
            }
            if (pendentesFilter && instaladosFilter) {
                return isPendente || isInstalado;
            }
            if (pendentesFilter && !instaladosFilter) {
                return isPendente;
            }
            if (instaladosFilter && !pendentesFilter) {
                return isInstalado;
            }
            return true; // Se nenhum filtro estiver ativo, retorna todos os patches
        });

        setFilteredPatches(filteredPatches);
    }, [implantandosPMFilter, instaladosFilter, pendentesFilter]);

    useEffect(() => {
        systemReport && applyPatchFilters(systemReport);
    }, [pendentesFilter, instaladosFilter, implantandosPMFilter, applyPatchFilters, systemReport])

    return (
        <PPModal visible={modalOpen} title="Detalhes do Sistema" onClose={() => setModalOpen(false)} size='large'>
            {
                systemSelected &&
                <div className='detailsSystemPlataform'>
                    <div className="headerSystemDetails">
                        <div className='systemDetail'>
                            <h3>{systemSelected.resource_name}</h3>
                            <div>
                                <LcIconLink icon={
                                    systemSelected.os_platform_name.toLowerCase().includes('windows') ? <AiOutlineWindows size={'.9rem'} />
                                        :
                                        systemSelected.os_platform_name.toLowerCase().includes('mac') ? <AiOutlineApple size={'.9rem'} />
                                            :
                                            <PiLinuxLogo size={'.9rem'} />
                                } />
                                <span>{systemSelected.os_platform_name}</span>
                            </div>
                            <p>
                                <span className="systemDetailLabel">Status do agente: </span>
                                <strong style={{ color: systemSelected.computer_live_status === 1 ? '#3ebb3f' : '#dc291e' }}>{translateStatus(systemSelected.computer_live_status)} </strong>
                            </p>
                        </div>
                        <div className='systemDetail'>
                            <p><span className="systemDetailLabel">Idioma do SO: </span> {systemSelected.os_language_abbr}</p>
                            <p><span className="systemDetailLabel">Endereço IP: </span> {systemSelected.ip_address}</p>
                        </div>
                        <div className='systemDetail'>
                            <p><span className="systemDetailLabel">Requer reboot:</span> <strong style={{ color: Boolean(systemSelected['resourcetorebootdetails.reboot_req_status']) === false ? '#3ebb3f' : '#dc291e' }}>{Boolean(systemSelected['resourcetorebootdetails.reboot_req_status']) !== false ? 'Sim' : 'Não'}</strong></p>
                            <p><span className="systemDetailLabel">Último reboot:</span> {moment(systemSelected.agent_last_bootup_time).format('DD/MM/YYYY hh:mm')}</p>
                        </div>
                        <div className='systemDetail '>
                            <p><span className="systemDetailLabel">Última scan:</span> {translateScanStatusPatch(systemSelected.scan_status)}</p>
                            <p><span className="systemDetailLabel">Última scan com sucesso:</span> {moment(systemSelected.last_successful_scan).format('DD/MM/YYYY hh:mm')}</p>
                        </div>
                    </div>
                    <div className='bodySystem'>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignContent: 'space-evenly' }}> <h4>Patches do sistema</h4>      </div>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignContent: 'space-evenly' }}>
                                <LcToggle
                                    toggled={implantandosPMFilter}
                                    onChange={(e) => handleCheckboxChange('implantandosPMFilter', e)}
                                    title={'Implantados com LiveCloud Patch Manager ' + (systemReport?.filter(x => x.installed_time > 0)?.length ?? 0)}
                                    name='implantandosPM'
                                    color={'var(--primary'}
                                // disabled={pendentesFilter || instaladosFilter}
                                />
                                <PPCheckBox checked={pendentesFilter} name='pendentes' title={'Pendentes ' + ((systemReport?.filter(x => (x.patch_affected_status_label) === 'dc.common.MISSING'))?.length ?? 0)} onChange={(e: any) => handleCheckboxChange('pendentes', e)} className='evenly' />
                                <PPCheckBox checked={instaladosFilter} name='instalados' title={'Instalados ' + (systemReport?.filter(x => ((x.patch_affected_status_label) === 'dc.common.INSTALLED')).filter(x => ((x.patch_affected_status_label) === 'dc.common.INSTALLED')).length ?? 0)} onChange={(e: any) => handleCheckboxChange('instalados', e)} className='evenly' />
                            </div>
                        </div>

                        <LcLoading loading={systemReportListLoading} >
                            <LcInfiniteTable
                                fontSize='medium'
                                rows={filteredPatches}
                                columns={columnsPatchDetails}
                                height='35vh'
                                size={filteredPatches.length}
                                loadMore={() => { return null; }}
                                status={translateStatusPatch}
                            />
                        </LcLoading>
                    </div>
                </div>
            }
        </PPModal>
    )
}
